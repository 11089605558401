import React, { useEffect, useState } from 'react'
import MonoConnect from '@mono.co/connect.js';
import CustomButton from '../../../../CustomButton/CustomButton'
import { getUserBkStatementFn } from '../../../../../redux/fetchingFn'
import {useUserData} from '../../../../hooks/useUserData'
import { useBankStatement } from '../../../../../redux/hooks/getHooks';
import { styled } from 'styled-components'
import { useSubmitMono } from '../../../../../redux/mutation/postMutation';
import { ROUTES } from '../../../../../routes/route';
import { useNavigate } from 'react-router';
import { useQueryClient } from "@tanstack/react-query";
import { LoadingButton } from "@mui/lab";


function BankStatement({handleClick, role, submitKycFunction, handleClose, isUpdating}) {


  const { data } = useUserData();
  const [isLoading, setIsLoading] = useState(null);

  const [isSkip, setIsSkip] = useState(false)

  const SubmitMonoId = useSubmitMono()
  const navigate = useNavigate();

  const queryClient = useQueryClient({})

  const monoConnect = React.useMemo(() => {


    const customer = {
      name: `${data?.first_name} ${data?.last_name}`,
      email: data?.email,
      identity: {
        type: 'nin',
        number: data?.bioData?.nin
      },
    }



    const monoInstance = new MonoConnect({

      key: "live_pk_v9ldal9jrgagyxvrtl1a",
      scope: 'auth',
      data: { customer },
      onClose: () => console.log('Widget closed'),
      onLoad: () => console.log('Widget loaded successfully'),
      onSuccess: ({ code }) =>{
      
        if(data?.is_declined && role==='update'){
          submitKycFunction({
            key: "accountStatement",
            code: code
          })
        }
        else{
          SubmitMonoId.mutate({code:code}, {
            onSuccess:()=>{
              // queryClient.invalidateQueries({ queryKey: ["me"] });
            }
          })
          // code_0XVnkfyskkMb5Uaw5mYQ
        }},
    })
    monoInstance?.setup()
    return monoInstance;
  }, [])


  const skipBankStatement=()=>{
    setIsSkip(true)
    SubmitMonoId.mutate({code:"skip"})
  }

  useEffect(()=>{
    if(SubmitMonoId.isLoading){
      setIsLoading(true)
    }
    else if(SubmitMonoId.error){
      setIsLoading(false);
      setIsSkip(false)
      const res = SubmitMonoId?.error?.response?.data
      res?.statusCode===500?
      handleClick("An unexpected error occurred!", 'error'):
      handleClick(res?.message, 'error')
    }
    else if(SubmitMonoId.isSuccess){
      setIsLoading(false);
      setIsSkip(false)
      // console.log(SubmitMonoId?.data?.data)

        navigate(ROUTES.summary);
    }
  },[SubmitMonoId.status])
  
  return (
    <>
      <main className='col-lg-8 col-md-10 col-12 mx-auto' style={{ display: "flex", justifyContent: 'center', alignItems: "center", height: "80vh", flexDirection: "column" }}>
       
        <p className="kyc_title text-center">Bank Statement</p>
        <br />
        <section>
            <p>We analyse bank statement to confirm your capacity to shop and pay later without paying additional cost. </p>
          <br />
            <p>
              Kindly continue with Mono, our Kyc partner, to share your bank statement with Vouch.
            </p>
        </section>
        <br />
        <div className="">
          <LoadingButton
              loading={isSkip}
              loadingPosition="center"
              variant="outlined"
              size="small"
              className="my-3 mx-3"
              sx={{
                color: ""
              }}
              onClick={skipBankStatement}
            >
              Skip
            </LoadingButton>
        </div>
        <section className="text-center">
          <CustomButton text={"Share bank statement with Mono"} disabled={isSkip} functionFn={()=>monoConnect.open()} loading={isSkip? false: (isLoading ?? isUpdating)}/>
        </section>
      </main>
    </>
  )
}

export default BankStatement

const Button = styled("button")`
    background-color: #033F4B;
    color: #fff;
    font-size: 16px;
    width: 100%;
    padding: 1em;`