import "./shopNowSection.css";
import cartLogo from "../../../assets/retail.png"
import StartButtons from "../../Buttons/StartButtons";
import { relatedCompanies } from "../relatedCompanies";
function ShopNowSection() {
  return (
    <>
        <main className='shop_now_section '>
            <section className="container-lg mx-auto container-fluid row ">
                <div className="col-lg-5 col-md-5 col-12 shop_now_left_section d-flex align-items-center" data-aos="slide-right">
                    <img src={cartLogo} alt="product marketplace" className="card-img-top"/>
                </div>
                <div className="col-lg-7 col-md-7 my-auto mx-auto pt-5 pb-5">
                    <div data-aos="zoom-in-down">
                        <h3 className="section_two" style={{fontWeight: 500}}>The Amount You See On your Favourite Store Is What You Pay Later</h3><br />
                        <p className="secondary_text" style={{fontSize: "22px", fontWeight: 300}}>Vouch resells to you on credit at same price from any of the marketplaces without interest charges.</p>
                    </div>
                    <div className="bottom_content row">
                            <div className="related_companies col-">
                                <div className="tw-flex tw-gap-3">
                                    {
                                        relatedCompanies.map((item, index)=>{
                                            return (
                                                <img src={item.img} alt={item.name} key={index} data-aos={item.aos}/>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="col-12 text-end">
                                <StartButtons title={"Join Now"}/>
                            </div>
                    </div>
                </div>

            </section>
        </main>
    </>
  )
}

export default ShopNowSection