import React from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes/route";

function BottomFooter() {
  return (
    <>
      <section>
        <p className="bottom_footer_content mx-3 mx-lg-0">
          2023 Brein and Accion. All rights reserved
          <a
            href={ROUTES.privacy}
            target="_blank"
            rel="noreferrer"
            className="text-decoration-none ms-2 fw-normal"
            style={{ fontSize: "13px" }}
          >
            Privacy Policy and Terms of use
          </a>
        </p>
      </section>
    </>
  );
}

export default BottomFooter;
