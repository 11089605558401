import { useState, useEffect } from 'react'
import AuthHeader from '../../components/Auth/AuthHeader'
import { Button } from 'antd'
import { useInitiateBill } from '../../redux/mutation/postMutation';
import PropTypes from 'prop-types';
import { usePaymentModal } from '../../redux/hooks/zustands/usePaymentModal';
export default function BillWarningPage({handleClick, payloadData}) {

    const [payNowLoading, setPayNowLoading] = useState(false);
const { openModal:openPaymentModal } = usePaymentModal()
    const initiateBillMutation = useInitiateBill();
  
    const handlePayNowFn = () => {
      initiateBillMutation.mutate(payloadData);
    };



    useEffect(() => {
        if (initiateBillMutation?.isLoading) {
          setPayNowLoading(true);
        } else if (initiateBillMutation?.isSuccess) {
          setPayNowLoading(false);
          const res = initiateBillMutation?.data?.data;
    
          console.log(res);
    
          openPaymentModal({...res?.data, email: payloadData?.data?.products?.[0]?.email})

          // window.location.href = res?.data?.link;
    
        } else if (initiateBillMutation?.isError) {
          setPayNowLoading(false);
          let msg = initiateBillMutation?.error?.response?.data?.message;
          let msg2 = initiateBillMutation?.error?.response?.statusText;
    
          handleClick(msg ?? msg2, "error", {
            vertical: "top",
            horizontal: "right",
          });
        }
      }, [initiateBillMutation?.status]);



  return (
    <>
        <div className="col-lg-11 col-12 mx-auto">
        <div className="col-md-10 mx-lg-auto mx-3">
          <AuthHeader title={"Pay Later With Vouch"} />
          <p className="text-center my-4">You have exceeded maximum credit for this transaction</p>


    <div className='d-flex justify-content-center'>
          <Button
              shape="round"
              size="large"
              
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "18px",
                width: "100%"
              }}
              loading={payNowLoading}
              onClick={handlePayNowFn}
              
            >
              Pay Now
            </Button>
    </div>
        </div>

        </div>
    </>
  )
}


BillWarningPage.propTypes = {
    payloadData: PropTypes.object.isRequired,
    handleClick: PropTypes.func.isRequired
  };