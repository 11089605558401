/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./kycLayout.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/route";
import { useBackKyc } from "../../redux/mutation/postMutation";
import { useDispatch, useSelector } from "react-redux";
import { stepperAction } from "../../redux/slice/stepperSlice";
import LoadingButton from "@mui/lab/LoadingButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

function KycLayout() {
  const navigate = useNavigate({});
  const [backIsLoading, setBackIsLoading] = useState(null);
  const backKycMutation = useBackKyc();
  const location = useLocation()?.pathname;
  const { activeStep, kycStep } = useSelector((state) => state.stepper);
  const dispatch = useDispatch();

  const navigateBackKycStepper = () => {
    backKycMutation.mutate();
  };

  useEffect(() => {
    if (backKycMutation.isLoading) {
      setBackIsLoading(true);
    } else if (backKycMutation.isSuccess) {
      setBackIsLoading(false);
      if(activeStep===4){
        dispatch(stepperAction.decreaseActiveStep());
      }
      if (location === ROUTES?.otp_verification) {
        navigate(ROUTES.complete_kyc);
        dispatch(stepperAction.setStep(4));
      }
      else if (location === ROUTES?.bank_statement) {
        navigate(ROUTES.otp_verification);
      }
      else if (location === ROUTES?.summary) {
        navigate(ROUTES.bank_statement);
      }
      
    } else if (backKycMutation.isError) {
      setBackIsLoading(false);
      let msg = backKycMutation?.error?.response?.data?.message;

    }
  }, [backKycMutation.status]);

  return (
    <>
      <main className="kyc_layout">
        <section className="row mx-2">
          <section className="col-lg-6 col-md-12 kyc_left_side_container">
            <div className=""></div>
          </section>
          <section className="col-lg-6 col-md-10 col-sm-12 mx-auto kyc_right_side_container ">
            <section className="py-3">
              {((activeStep >= 4 || location===ROUTES.otp_verification || location===ROUTES.bank_statement) && (location !== ROUTES.summary))  && (
                <>
                  <LoadingButton
                    loading={backIsLoading}
                    loadingPosition="start"
                    startIcon={<ArrowBackIcon />}
                    variant="outlined"
                    onClick={navigateBackKycStepper}
                    className="my-3"
                  >
                    Back
                  </LoadingButton>
                </>
              )}
              <Outlet />
            </section>
          </section>
        </section>
      </main>
    </>
  );
}

export default KycLayout;
