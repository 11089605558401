import { configureStore, createStore } from "@reduxjs/toolkit";
import stepperSlice from "./slice/stepperSlice";
import productSlice from "./slice/productSlice";
import superStoreSlice from "./slice/superStoreSlice";

export const store = configureStore({
    reducer: {
        stepper: stepperSlice,
        product: productSlice,
        superStore: superStoreSlice,
    }
})