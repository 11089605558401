import "./becomeMember.css";
import img1 from "../../../assets/shopping_concept.png";
import StartButtons from "../../Buttons/StartButtons";
export default function BecomeMember() {
  return (
    <>
      <main className="become_member_section ">
        <section className="container-lg mx-auto container-flui row ">
          <div className="col-lg-7 col-md-12 my-auto mx-auto">
            <div className=" d-flex flex-column gap-3">
              <div data-aos="zoom-in-down">
                <h3
                  className="section_two text-black"
                >
                  Shop and Pay Later - No Credit Application Required!
                </h3>
                {/* <br /> */}
              </div>
              <p className="secondary_text" data-aos="zoom-in-down" >
              Shop now, pay later without loans or extra fees. No more repeated credit applications; simply find what you need on your favourite marketplace, buy it at the same price, and enjoy a grace period of 10 or 15 days to pay with Vouch.
              </p>
              <div className="">
                <StartButtons title={"Become a vouch Member Now"} />
              </div>
            </div>
          </div>

          <div
            className="col-lg-5 col-md-6 col-12 shop_now_left_section mx-auto"
            data-aos="slide-right"
          >
            <img
              src={img1}
              alt="product marketplace"
              className="card-img-top w-100 w-lg-75"
              style={{ borderRadius: "15%" }}
            />
          </div>
        </section>
      </main>
    </>
  );
}
