import React from 'react'
import Header from '../Header/Header'
import HeaderCard from './HeaderCard'
import CartProduct from './CartProduct'
import CheckoutDetails from './CheckoutDetails'
import PaymentDetail from './PaymentDetail'
import styled from 'styled-components'
import product from "../../../assets/bag_product.png"
import { Button } from '@mui/material'
import HelpSection from './HelpSection'
import FooterContent from './FooterContent'
import footer_bg from "../../../assets/footer_bg.png"
function EmailifyTemplate() {
  return (
    <>
        <main>
            <Header />
            <HeaderCard />
            <section className='shadow-sm'>
              <section className='d-flex justify-content-between px-lg-5 px-2 py-3'>
                <p>
                  <h2 className='fw-bold'>My Order</h2>
                </p>
                <p>Order No. SMLS650050</p>
              </section>
              <CartProduct />
              <hr />
              <CheckoutDetails />
              <PaymentDetail />
            </section>
            <Section className='py-4 px-3'>
              <div className='col-lg-10 mx-auto'>
                <h3 className='fs-2 fw-bold'>Wondering About Your Bag Size?</h3>
                <p className='fs-4 col-lg-7'>We’ll update you with tracking details as soon as your coffee is roasting and about to head your way.</p>
                <div className='row mx-2'>
                    <div className="col-md-6">
                      <Button variant={"contained"} size='large' sx={{backgroundColor: "#F53C32 !important", padding: "2vh 5vh"}} className='fw-bold'>READ FAQs</Button>
                    </div>
                    <div className="col-md-6">
                        <div className="bag_product">

                        </div>
                    </div>
                </div>
              </div>
            </Section>
            <section style={{backgroundColor: "#EFEFEF"}} className='p-4'>
              <HelpSection />
              <FooterContent />
            </section>
            <section className='footer_bg' style={{
              backgroundImage: `url(${footer_bg})`, backgroundSize: "contain", height: "30vh",
              backgroundRepeat: "no repeat",
              width: "100%"
            }}>
            </section>
        </main>
    </>
  )
}

export default EmailifyTemplate

const Section = styled.section`
background: linear-gradient(108deg, #D5B48D -0.02%, #EEDBC2 99.98%);
.bag_product{
  background-image: url(${product});
  height: 30vh;
  background-size: contain;
  width: 100%;
}
`