import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import { formNumberOnly } from "../../../../utils/formatNumber";
import { IconButton } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { exportExcel } from "../../../../utils/exportExcel";
import { Button } from "antd";
import { useGetBankStatement } from "../../../../redux/hooks/getHooks";
import { useQueryClient } from "@tanstack/react-query";

const columns = [
  { id: "s_n", label: "S/N", width: 10 },
  { id: "type", label: "Type", width: 40 },
  { id: "amount", label: "Amount", width: 70 },
  {
    id: "narration",
    label: "Narration",
    width: 250,
  },
  // {
  //   id: 'balance',
  //   label: 'Balance',
  //   width: 170,
  // },
  {
    id: "date",
    label: "Date",
    width: 170,
  },
  // {
  //   id: "currency",
  //   label: "Currency",
  //   width: 50,
  // },
];

export default function StatementDetails({ rows, ownerName, bankName, userId }) {


  // const 

  const queryClient = useQueryClient();

  const getUserBkStatement = useGetBankStatement(userId);

  const statementDetailsRes = getUserBkStatement?.data?.data;

  const statements = statementDetailsRes?.data?.statement;


  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };


  const handleRetry=()=>{
    queryClient.invalidateQueries(["bank_statement", userId])
  }


  const data = statements ?? rows



  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div className="d-flex justify-content-end">
          {rows?.length ? (
            <IconButton
              aria-label="download"
              onClick={() =>
                exportExcel({
                  excelData: rows,
                  fileName: `${ownerName}_bank_statement`,
                })
              }
            >
              <FileDownloadIcon color="primary" />
            </IconButton>
          ) : null}
        </div>
        <p className="mx-3">Account Name: {ownerName}</p>
        <p className="mx-3">Bank: {bankName}</p>
        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.length ? (
                [...data]
                  ?.reverse()
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={row?.id + "_" + index}
                      >
                        {columns.map((column) => {
                          const value = row[column.id];
                          return (
                            <TableCell
                              key={column.id}
                              align={column.align}
                              className="text-capitalize"
                            >
                              {column?.id === "s_n"
                                ? index + 1
                                : column?.id === "amount"
                                ? formNumberOnly(value)
                                : value}
                            </TableCell>
                          );
                        })}
                      </TableRow>
                    );
                  })
              ) : (
                <>
                  <TableRow>
                    <TableCell
                      colSpan={6}
                      align="center"
                      className="fs-5 text-muted"
                    >
                      <i>Empty Statements Found</i>


                      <div className="d-flex justify-content-center mt-3">
            <Button
              shape="round"
              size="large"
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "18px",
                height: "55px",
                padding: "0 50px",
              }}
              loading={getUserBkStatement?.isLoading}
              onClick={handleRetry}
            >
              Retry
            </Button>
          </div>
                    </TableCell>  
                  </TableRow>
                </>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {rows?.length ? (
          <TablePagination
            rowsPerPageOptions={[10, 25, 100]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        ) : null}
      </Paper>
    </>
  );
}
