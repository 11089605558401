import { Button, Checkbox, Input } from "antd";
import { useState, useEffect } from "react";
import { useLoanDrawer } from "../../../redux/hooks/zustands/useLoanDrawer";
import { useDebitAccount } from "../../../redux/mutation/AdminPostMutation";

export default function DebitAccount() {
    const [selectedAcc, setSelectedAcc] = useState({check: null, id: null})
    const [debitDetail, setDebitDetail] = useState({status: null, id: null})

    const [amount, setAmount ] = useState("")

    const mutation = useDebitAccount();

    const [isLoading, setIsLoading] = useState(false)

    const {openDrawer, data: {single_schedule, amountDue, accounts}} = useLoanDrawer();

    console.log(accounts)

    

    const handleSelectAcc=(e, id)=>{
        setSelectedAcc({check: e.target.checked, id})
    }

 

    const handleOpenDrawer=(amountDue)=>{
      openDrawer('SUCCESSFULL_DEBIT', {amountDue})
    }


    const startDebit=(id)=>{
        setDebitDetail({id, status: true})
    }


    const handleAmountChange=(e)=>{
        setAmount(e.target.value)
    }



    const debitAccount=(account_id)=>{

        const json = {
            "schedule_id": single_schedule?.id,
            "amount": amount,
            "account_id": account_id
        }

        console.log(json);
        // setIsLoading(true)

        // setTimeout(()=>{
        //     setIsLoading(false)
        //     openDrawer('SUCCESSFULL_DEBIT', {amountDue: amount})
        // }, 2000)

       


        mutation.mutate(json)
    }


    useEffect(()=>{
        if(mutation.isLoading){
            setIsLoading(true)
        }
        else if(mutation.isError){
            setIsLoading(false)
            const error = mutation?.error?.response?.data
    
            console.log(error)
        }
        else if(mutation.isSuccess){
            setIsLoading(false)
            const res = mutation?.data?.data?.data
    
            console.log(mutation?.data?.data)
          
             openDrawer('SUCCESSFULL_DEBIT', {amountDue})
             
        }
    }, [mutation.status])


  return (
    <>
      <main>
        <p className="tw-text-center tw-font-[300]">
          Debit <span className="tw-font-[500]">{amountDue}</span> from any of the
          account below
        </p>

        <section className="tw-mt-16 tw-flex tw-flex-col tw-gap-y-14">
            {
                accounts?.length ?
                accounts?.map((item, index)=>{
                    return(
                    <div className="tw-flex tw-gap-x-5" key={index}>
                        <div>
                        <Checkbox onChange={(e)=>handleSelectAcc(e, index)} checked={selectedAcc.check && selectedAcc.id===index}/>
                        </div>
                        <div className="tw-flex tw-flex-col tw-gap-y-8 tw-w-full">
                        <div className="tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-gap-3 md:tw-gap-14 md:tw-grid md:tw-grid-cols-3">
                            <div className="tw-flex tw-justify-between tw-col-span-2 tw-pe- tw-items-center tw-border-b tw-w-full tw-border-b-[#CFCDCC]">
                            <div className="tw-flex tw-gap-x-2 tw-items-center">
                                {/* <img
                                src={
                                    "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png"
                                }
                                alt={"bank_url"}
                                width={50}
                                /> */}
                                <p className="tw-text-lg">{item?.initiate_mandate_data?.bank}</p>
                            </div>
                            <p className="tw-font-[700] tw-text-xl">{item?.initiate_mandate_data?.account_number}</p>{" "}
                            </div>
                            <div className={`${(selectedAcc.check && selectedAcc.id===index)? "tw-block": "tw-hidden"}`}>
                            <Button
                                size="middle"
                                loading={false}
                                className={`tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF] tw-capitalize`}
                                onClick={()=>startDebit(index)}
                                disabled={debitDetail.status && debitDetail.id===index}
                            >
                                Start Debit
                            </Button>
                            </div>
                        </div>
                        <div className={`${(debitDetail.status && debitDetail.id===index && selectedAcc.id===index)? "tw-flex md:tw-flex-row tw-flex-col tw-justify-between tw-gap-3 md:tw-gap-14 md:tw-grid md:tw-grid-cols-3 tw-transition-all ": "tw-hidden"}`}>
                            <div className="tw-ps-3 tw-col-span-2">
                            <p className="tw-font-[500] tw-mb-4 tw-text-[16px]">
                                Balance: <span className="tw-font-[300]">{item?.initiate_mandate_data?.amount}</span>
                            </p>
                            {/* <Checkbox>Debit other amount</Checkbox> */}
                            <p>Debit other amount</p>
                            <div className="tw-mt-3">
                                <Input
                                type="number"
                                placeholder="Enter amount"
                                className=""
                                size="large"
                                onChange={handleAmountChange}
                                />
                            </div>
                            </div>
                            <div className="">
                            <Button
                                size="middle"
                                loading={isLoading}
                                className={`tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF] tw-capitalize`}
                                onClick={()=>debitAccount(item?.id)}
                            
                            >
                                Debit Account now
                            </Button>
                            </div>
                        </div>
                        </div>
                    </div>
                    )
                }): <i>No accounts found</i>
            }
        </section>
      </main>
    </>
  );
}
