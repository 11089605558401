import React, { useRef } from 'react'
import Navbar from '../../components/LandingPage/Navbar/Navbar';
import { Outlet } from 'react-router-dom';
import Footer from '../../components/LandingPage/Footer/Footer';

function LandingPageLayout({children}) {
    const componentWork = useRef(null);
  const componentAbout = useRef(null);
  const scrollToWork = () => {
    componentWork.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToAbount = () => {
    componentAbout.current.scrollIntoView({ behavior: 'smooth' });
  };


  return (
    <>
        <main>
            {/* <Navbar scrollToWork={scrollToWork} scrollToAbount={scrollToAbount}/> */}
            <section style={{minHeight: "60vh"}}>
                <Outlet />
            </section>
                <Footer />
        </main>
    </>
  )
}

export default LandingPageLayout