import { Button, Modal } from 'antd'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../routes/route';

export default function BillAuthComp({handlePayNowFn, payNowLoading, openModal, handleCloseModal, billData}) {

    const navigate = useNavigate();

    const contNavigation=(val)=>{
        if(val==='login'){
            localStorage.setItem('billData', JSON.stringify(billData))
          navigate(ROUTES.login)
        }
        else if(val==='signup'){
            localStorage.setItem('billData', JSON.stringify(billData))
          navigate(ROUTES.signup)
        }
        else{
          handlePayNowFn();
        }


        handleCloseModal()

      }

  return (
    <>
        <Modal open={openModal} onCancel={handleCloseModal} footer={null}>
            <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-2 align-items-center"
            style={{
                fontSize: "18px",
            }}
            >
                <Button
                size="large"
                style={{
                    background: "#000000",
                    color: "#fff",
                    fontSize: "18px",
                }}
                onClick={()=>contNavigation("login")}
                >
                Login
                </Button>
                <span>to pay later</span>
            </div>
            <div className="d-flex gap-2 align-items-center"
            style={{
                fontSize: "18px",
            }}
            >
                <Button
                size="large"
                style={{
                    background: "#000000",
                    color: "#fff",
                    fontSize: "18px",
                }}
                onClick={()=>contNavigation("signup")}
                >
                Sign up
                </Button>
                <span>as a new user</span>
            </div>
            <div className="d-flex gap-2 align-items-center" 
            style={{
                fontSize: "18px",
            }}
            >
                <Button
                size="large"
                style={{
                    background: "#000000",
                    color: "#fff",
                    fontSize: "18px",
                }}
                loading={payNowLoading}
                onClick={()=>contNavigation("continue_as_guest")}
                >
                Continue
                </Button>
                <span>as guest and Pay Now</span>
            </div>
            </div>
        </Modal>
    </>
  )
}
