import React from 'react'
import ResetPsw from '../../../components/Auth/ResetPsw/ResetPsw'
import { useParams } from 'react-router-dom';

function ResetPswScreen({handleClick}) {
  const token = useParams().token;
  return (
    <>
        <main>
            <ResetPsw handleClick={handleClick} token={token}/>
        </main>
    </>
  )
}

export default ResetPswScreen