import { Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { ROUTES } from "../routes/route";

export const useContinueSubscription = () => {

    const [modal, contextHolder] = Modal.useModal({});

    const navigate = useNavigate();

    const continueSubscription = ()=> {
      modal.confirm({
        title: "Important Notice",
        icon: <ExclamationCircleOutlined />,
        content: `You don't have an active subscription. kindly subscribe to buy on credit`,
        okText: "Continue",
        cancelText: "Cancel",
        onOk: () => {
          navigate(ROUTES.subscription);
        },
      });
    }



    return { contextHolder, continueSubscription }


  };