import React from 'react'
import ProfileScreen from '../screens/ProfileScreen/ProfileScreen'

export default function Profile({handleClick}) {
  return (  
    <>
        <ProfileScreen handleClick={handleClick}/>
    </>
  )
}
