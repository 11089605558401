
import "./merchantSidebar.css";
import TopBar from "./TopBar";
import MiddleMenu from "./MiddleMenu";
import BottomMenu from "./BottomMenu";
import { MenuIcon } from "../../../icons/icons";

export default function MerchantSidebar({ visible, show }) {

  return (
    <>
      <div className="mobile-nav">
        <button className="mobile-nav-btn" onClick={() => show(!visible)}>
        <MenuIcon clicked={visible}/>
        </button>
      </div>
      <nav className={`sidebar_nav ps-4 ${visible ? "" : "sidebar"}`}>
          <button
            className={`nav_btn`}
            type="button"
            onClick={() => show(!visible)}
          >
            {visible ? "<" : ">"}
          </button>
        <TopBar />
              <MiddleMenu fn={() => {
                    window.innerWidth < 768 && show(!visible);
                  }}/>
              <BottomMenu fn={() => {
                    window.innerWidth < 768 && show(!visible);
                  }}/>
       
          <div className={`position-relative`}>
           
          </div>
      </nav>
    </>
  );
}
