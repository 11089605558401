import React, { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useVerifyRepayment } from "../redux/hooks/getHooks";
import { ROUTES } from "../routes/route";
import { IsLoadingLoader, IsErrorLoader } from "../utils/Loader";
import { useUserData } from "../components/hooks/useUserData";
import styled, { keyframes } from "styled-components";
import Logo from "../components/logo/logo";
import { Button } from "antd";
import DataReceipt from "../components/Shop/ShopNow/DataReceipt";
import ElectricityReceipt from "./../components/Shop/ShopNow/ElectricityReceipt";
import AirtimeReceipt from "../components/Shop/ShopNow/AirtimeReceipt";
import CableReceipt from "../components/Shop/ShopNow/CableReceipt";
import { useQueryClient } from "@tanstack/react-query"

const getQueryParameter = (name) => {
  const params = new URLSearchParams(window.location.search);
  return params.get(name);
};

export default function VerifyPayment() {
  const reference = getQueryParameter("reference");

  const user = useUserData({});

  const queryClient = useQueryClient();

  const payment_purpose = sessionStorage.getItem("payment_purpose");

  if (payment_purpose) {
    // Redirect the user to the previous location

    // Clear the stored previous location
    sessionStorage.removeItem("payment_purpose");
  }

  const { data, isLoading, isError, isSuccess, error } =
    useVerifyRepayment(reference);


  const responseData = data?.data?.data;


  const navigate = useNavigate();

  const verifyCompleted = () => {
    if(isError){
      //retry
      queryClient.invalidateQueries(["verify_payment", reference])
    }
    else{

      if (user?.status) {
        navigate(ROUTES.shop);
      } else {
        navigate(ROUTES.landing_page);
      }

    }
  };


  return (
    <>
      {isLoading ? (
        <div className="d-flex justify-content-center align-items-center border" style={{
          height: "100vh"
        }}>
          <IsLoadingLoader />
        </div>
      ) : (
        <main className="mx-3 mx-lg-0" style={{ minHeight: "100vh" }}>
          <section className="lh-3 col-lg-10 mx-auto">
            <div className="my-3 my-md-5">
              <Logo />
            </div>
            <div className="d-flex flex-column justify-content-center align-items-center">
              {isSuccess ? (
                <CheckMark>
                  <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </CheckMark>
              ) : (
                isError && (
                  <ErrorContainer>
                    <ErrorIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                      </svg>
                    </ErrorIcon>
                  </ErrorContainer>
                )
              )}
              <p className="fs-5">
                {
                    isError? (error?.response?.data?.message ?? error?.message) : data?.data?.message
                }
                {/* {data?.data?.message ?? " done succcessfully"} */}
              </p>{" "}
              {/* <br /> */}
              {data?.data?.data?.order?.is_bill ? (
                responseData?.order?.order_name === "electricity" ? (
                  <ElectricityReceipt data={responseData} />
                ) : responseData?.order?.order_name === "airtime" ? (
                  <AirtimeReceipt data={responseData} />
                ) : responseData?.order?.order_name === "data" ? (
                  <DataReceipt data={responseData} />
                ) : responseData?.order?.order_name === "cable" ? (
                  <CableReceipt data={responseData} />
                ) : null
              ) : (
                ""
              )}
              <div className="d-flex flex-column">
                {payment_purpose === "subscription" ? (
                  <span>
                    You have successfully subscribed to Vouch{" "}
                    <b style={{ color: "#033F4B" }}>
                      {user?.data?.subscriptions[0]?.plan?.name}
                    </b>{" "}
                    Plan.
                  </span>
                ) : (
                  payment_purpose === "order" && (
                    <span>Your order is successfully Repaid!</span>
                  )
                )}
              </div>
              <br />
              <br />
              <Button
                shape="round"
                size="large"
                style={{
                  background: "#033F4B",
                  color: "#fff",
                  fontSize: "18px",
                }}
                onClick={
                  verifyCompleted}
              >
                {
                isError ? "Retry" : "OK"
              }
                {/* Ok */}
              </Button>
            </div>
          </section>
        </main>
      )}
    </>
  );
}

const CheckMark = styled.div`
  width: 80px;
  height: 80px;
  margin: 3vh auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::after {
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
      z-index: 2;
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
`;

const drawAnimation = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const ErrorIcon = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 10px;

  svg {
    width: 100%;
    height: 100%;
    stroke: red; /* Color of the times icon */
    stroke-width: 1.5px;
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    animation: ${drawAnimation} 0.85s ease forwards;
  }
`;
