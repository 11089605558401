import {
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import ShoppingHistory from "../Shop/ShopNow/ShoppingHistory";
import {
  useGetTransactionHistory,
  useGetUserOrder,
} from "../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../utils/Loader";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import PayContainer from "../Shop/ShopNow/PayContainer";
import TransactionHistoryCard from "./TransactionHistoryCard";
import { useUserData } from "../hooks/useUserData";
import { SearchIcon } from "../../icons/icons";
import HistoryDetail from "./HistoryDetail";

function UserTransactionHistory({ handleClick }) {
  const getPendingOrders = useGetUserOrder("all");

  const getTransactions = useGetTransactionHistory();

  const transactions = getTransactions?.data?.data?.data;

  const user = useUserData();

  const [open, setOpen] = useState({ status: false, data: "" });

  const [filteredData, setFilteredData] = useState();
  const [filteredSelected, setFilteredSelected] = useState("");

  // useEffect(()=>{
  //   setFilteredData(orders);
  // }, [orders])

  const handleOpenDialog = (_, order) => {
    setOpen({ ...open, status: true, data: order });
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  //search filter function==============
  const handleFilter = (val) => {
    let updatedData = [];

    if (val.length) {
      updatedData = filteredData?.filter((item) => {
        const includesName = item?.products.some((product) =>
          product?.name?.toLowerCase().includes(val.toLowerCase())
        );

        const includes = item?.market_place?.name
          ?.toLowerCase()
          .includes(val.toLowerCase());
        if (includesName) {
          return includesName;
        }
        if (includes) {
          return includes;
        } else return null;
      });

      setFilteredData(updatedData);
    } else {
      setFilteredData(transactions);
    }
  };
  //

  //filtered by paid or unpaid=============
  const handleChange = (event) => {
    const val = event.target.value;
    setFilteredSelected(event.target.value);

    let filteredUser = transactions?.filter((item) => {
      const paid = item?.repaymentStatus === true;
      const unpaid = item?.repaymentStatus === false;
      if (val === "paid") {
        return paid;
      } else if (val === "unpaid") {
        return unpaid;
      } else return null;
    });

    setFilteredData(filteredUser);
  };
  //=================

  return (
    <>
      <main>
        <section className="text-center">
          <DialogTitle className="fw-bold">Transaction History</DialogTitle>
        </section>
        <section>
          {getTransactions.isLoading ? (
            <IsLoadingLoader />
          ) : getTransactions.isError ? (
            <IsErrorLoader />
          ) : transactions?.length ? (
            <>
              <div className="col-lg-11 mx-auto d-flex justify-content-between">
                <div>
                  <div className="d-flex gap-3 align-items-center">
                    <TextField
                      type="text"
                      placeholder="Search store or order"
                      fullWidth
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => handleFilter(e.target.value)}
                    />

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Filter
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filteredSelected}
                        label="Filter"
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {["Paid", "Unpaid"]?.map((item, index) => (
                          <MenuItem
                            key={index + "___filter_by"}
                            value={item.toLowerCase()}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              <TransactionHistoryCard
                rows={filteredData ?? transactions}
                handleOpenDialog={handleOpenDialog}
              />
            </>
          ) : (
            <p className={`card-tex text-center my-2 text-muted fs-5`}>
              <i>Transaction History is Empty</i>
            </p>
          )}
        </section>
      </main>

      <ModalDialogBox
        open={open?.status}
        handleClose={handleClose}
        modalSize={false}
      >
        <HistoryDetail
          order={open?.data}
          dueDate={user?.data?.creditEstimate?.dueDate}
          handleClose={handleClose}
          handleClick={handleClick}
        />
      </ModalDialogBox>
    </>
  );
}

export default UserTransactionHistory;
