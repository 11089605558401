import { createSlice } from "@reduxjs/toolkit";


const productSlice = createSlice({
    name: 'product_slice',
    initialState: {
        quantity: 1,
        totalPrice: 0,
        allProducts: {},
        marketPlaces: {},
    },
    reducers: {
        manipulateQty: (state, action)=>{
            const {itemIndex, sessionKey, role} = action.payload;
            if(role==='inc'){
                state.allProducts.products[itemIndex].quantity++
        }
            else if (role==='dec'){
                state.allProducts.products[itemIndex].quantity===1?state.allProducts.products[itemIndex].quantity=1: state.allProducts.products[itemIndex].quantity--
            }
            //console.log(JSON.stringify(state.allProducts))
            //this is to update the total price after the quantity has been manipulated
            const totalPrice = state.allProducts?.products?.reduce((acc, curr)=> acc + (parseFloat(curr.price * curr.quantity)+ parseFloat(state.allProducts?.marketPlace?.delivery_amount * curr?.quantity)), 0)
            state.totalPrice = totalPrice;

            sessionStorage.setItem(sessionKey, JSON.stringify(state.allProducts));
        },
        //this is to update the total price of the products in the cart
        setTotalPrice:(state, action)=>{
            // const {} = action.payload;
            const totalPrice = state.allProducts?.products?.reduce((acc, curr)=> acc + (parseFloat(curr.price * curr.quantity)+parseFloat(state.allProducts?.marketPlace?.delivery_amount * curr?.quantity)), 0)
            state.totalPrice = totalPrice;
        },
       setAllProducts:(state, action)=>{
      
           state.allProducts = action.payload
           const totalPrice = action.payload?.products?.reduce((acc, curr)=> acc + (parseFloat(curr.price * curr.quantity)+parseFloat(state.allProducts?.marketPlace?.delivery_amount * curr?.quantity)), 0)
            state.totalPrice = totalPrice;
       },
       updateSavedProduct: (state, action)=>{
        const {sessionKey, repaymentDuration} = action.payload;
        const updatedData = {
            ...state.allProducts,
            repaymentDuration: repaymentDuration,
        }
         state.allProducts= updatedData;
         sessionStorage.setItem(sessionKey, JSON.stringify(updatedData));
        //  console.log(updatedData);
       },
       changeDeliveryAddress: (state, action)=>{
        const {sessionKey, deliveryLocation} = action.payload;
        const updatedData = {
            ...state.allProducts,
            deliveryLocation: deliveryLocation,
        }
         state.allProducts= updatedData;
         sessionStorage.setItem(sessionKey, JSON.stringify(updatedData));
        
       },
       removeItem:(state, action)=>{
        //console.log(action.payload);
        const {index, sessionKey} = action.payload
        if(state?.allProducts?.products?.length===1){
            sessionStorage.removeItem(sessionKey);
            state.allProducts = {}
        }
        else{
            let filteredProducts = state?.allProducts?.products?.filter((item, itemIndex)=>itemIndex!==index);

            state.allProducts.products = filteredProducts
            
            const dataToStore = {
                ...state.allProducts,
                products: filteredProducts
            }
            const remainingPrice = dataToStore?.products?.reduce((acc, curr)=> acc + (parseFloat(curr.price)+parseFloat(state.allProducts?.marketPlace?.delivery_amount * curr?.quantity)), 0)
            state.totalPrice = remainingPrice;
            //console.log(remainingPrice);
            sessionStorage.setItem(sessionKey, JSON.stringify(dataToStore));

        }
       },
       setMarketPlaces:(state, action)=>{
      
              state.marketPlaces = action.payload
       }
    }
})


export const productAction = productSlice.actions
export default productSlice.reducer