import React from "react";
import { formatNumber } from "../../../../utils/formatNumber";
import { useDispatch } from "react-redux";
import { superStoreAction } from "../../../../redux/slice/superStoreSlice";

export default function CartCard({ product }) {
  const dispatch = useDispatch({});

  const quantityFn = (method, id) => {
    dispatch(
      superStoreAction.manipulateQty({ itemId: id, method })
    );
  };


  return (
    <>
     

      {/* <div className="rounded border p-2 mt-2">
        <div className="header row gap-3 mt-2">
          <div className="col-lg-3 col-sm-12 text-start">
            <img
              src={product?.img}
              alt={product?.name + "_logo"}
              className=""
              style={{
                width: "70%",
              }}
            />

            <p className="text-danger my-auto">
              <span
                className="cursor-pointer"
                // onClick={() =>
                //   dispatch(
                //     productAction.removeItem({
                //       index,
                //       sessionKey,
                //     })
                //   )
                // }
              >
                Remove
              </span>
            </p>
          </div>

          <div className="w-100 d-flex gap-3 flex-column">
            <div className="d-flex justify-content-between">
              <p className="fw-bold my-auto">{product?.name ?? "xxxxxx"}</p>
              <div className="text-end d-flex gap-3">
                <p
                  style={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    cursor: "default",
                    fontSize: "13px",
                  }}
                  className="my-auto"
                >
                  <select className="form-select w-auto py-1">
                    {[1, 2, 3, 4, 5].map((qty, index) => {
                      return (
                        <option key={qty} value={qty}>
                          QTY: {qty}
                        </option>
                      );
                    })}
                  </select>
                </p>
                <p className="d-flex gap-3 flex-end my-auto">
                  {formatNumber(parseFloat(product?.price) ?? 0.0)}
                </p>
              </div>
            </div>
            <div className="d-flex justify-content-between">
              <p className="fw-bold my-auto">Delivery</p>
              
                <p className="d-flex gap-3 flex-end my-auto">
                  {formatNumber(parseFloat(1000) ?? 0.0)}
                </p>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between">
        <p className="fw-bold my-auto">Total</p>
              
              <p className="d-flex gap-3 flex-end my-auto">
                {formatNumber(parseFloat(product?.price  + 1000) ?? 0.0)}
              </p>
        </div>
      </div> */}

      <div className="row mx-2 border rounded">
        <div className="col-lg-12">
          <div className="row ">
            <div className="col-lg-4 col-md-4 col-sm-4 text-start">
              <img
                style={{
                  width: "70%",
                }}
                src={product?.img}
                alt={product?.name + "item"}
                className="market_place card-img-top"
              />
            </div>
            <div className="col-lg-8 col-md-8 col-sm-6">
              <div className="d-flex justify-content-between mt-2">
                <p className="fw-bold my-auto">{product?.name ?? "xxxxxx"}</p>
                <p className="d-flex gap-3 my-auto">
                  {/* <select
                                  className="form-select w-auto py-1"
                                  disabled
                                >
                                  <option value={`1`}>
                                    QTY: {product?.quantity}
                                  </option>
                                </select> */}
                  {formatNumber(parseFloat(product?.price) ?? 0.0)}
                </p>
              </div>
              {/* <div className="d-flex justify-content-between mt-3">
                <p className="fw-medium my-auto">Delivery</p>
                <p className="my-auto">{formatNumber(1000)}</p>
              </div> */}
            </div>
          </div>
        </div>
        <div className="my-2 d-flex justify-content-between">
          <div className="col-6 d-flex gap-3">
            {/* <p className="my-auto" style={{ cursor: "pointer" }}>
                            Edit
                          </p>
                          <div
                            style={{ height: "auto", border: "0.5px solid" }}
                          ></div> */}
            <p className="text-danger my-auto">
              <span
                className="cursor-pointer"
                onClick={() =>
                  dispatch(superStoreAction.removeItem(product?.id))
                }
              >
                Remove
              </span>
            </p>
          </div>

          <div className="col-lg- col-md- d-flex gap-2">
            <div
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#FBEBB5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded fs-3 text-center cursor-pointer"
              onClick={() => quantityFn("dec", product?.id)}
            >
              -
            </div>
            <p className="my-auto">{product?.quantity ?? 1}</p>
            <div
              style={{
                width: "30px",
                height: "30px",
                backgroundColor: "#FBEBB5",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className="rounded fs-3 text-center cursor-pointer"
              onClick={() => quantityFn("inc", product?.id)}
            >
              +
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
