import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button } from "antd";
import { useState, useEffect } from "react";
import { useInitiateMandate } from "../../../redux/hooks/zustands/useInitiateMandate";
import { useSendSchedule } from "../../../redux/mutation/postMutation";

export default function MandateSummary() {



  const { openDrawer, data: {schedules, formik} } = useInitiateMandate()
  
  const [isLoading, setIsLoading] = useState(false)
  const mutation = useSendSchedule();

  const confirmRequest=()=>{
      //I will send to backend from here

      console.log(formik.values)
  
      const formData = new FormData();
      for (const key in formik.values) {
        if (key === 'sc_file') {
          formData.append('file', formik.values[key]); // Append sc_file with key 'file'
        } else {
          formData.append(key, formik.values[key]);
        }
      }
  
      formData.append("preview", "false");
  
  
      // for (let [key, value] of formData.entries()) {
      //   console.log(key, value);
      // }
  
      // setIsLoading(true)
      // const data = response?.data;
      // setTimeout(()=>{
      //     setIsLoading(false)
      //     openDrawer("MANDATE_SUMMARY", {formik, schedules: data})
      // }, 1000)
  
  
      mutation.mutate(formData)
     
  
    
  }


  useEffect(()=>{
    if(mutation.isLoading){
        setIsLoading(true)
    }
    else if(mutation.isError){
        setIsLoading(false)
        const error = mutation?.error?.response?.data

        console.log(error)
    }
    else if(mutation.isSuccess){
        setIsLoading(false)
        const res = mutation?.data?.data?.data
      
        openDrawer("CREATE_SUCCESSFULL", {schedules})
         
    }
}, [mutation.status])

  return (
    <>
      <div className="tw-w-full tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white tw-shadow-sm">
        <h4 className="tw-font-[600] tw-text-[18px] tw-mb-2">Summary</h4>
        <div className="tw-flex tw-flex-col tw-gap-y-1">
          <p className="tw-font-[500] tw-text-[14px]">{schedules?.first_name} {schedules?.last_name}</p>
          <p className="tw-font-[500] tw-text-[14px]">
            Phone <span className="tw-font-[300]">{schedules?.phone}</span>
          </p>
          <p className="tw-font-[500] tw-text-[14px]">
            Email <span className="tw-font-[300]">{schedules?.email}</span>
          </p>
          <p className="tw-font-[500] tw-text-[14px]">
            BVN <span className="tw-font-[300]">{schedules?.bvn}</span>
          </p>

          <div className="tw-flex tw-gap-5 tw-flex-wrap">
            <p className="tw-font-[500] tw-text-[14px]">
              Loan Amount: <span className="tw-font-[300]">{schedules?.total_amount}</span>
            </p>
            <p className="tw-font-[500] tw-text-[14px]">
              Tenor: <span className="tw-font-[300]">{schedules?.tenor}{schedules?.payment_type}</span>
            </p>
            <p className="tw-font-[500] tw-text-[14px]">
              Total Interest: <span className="tw-font-[300]">{schedules?.total_interest}</span>
            </p>
          </div>
          <div className="mt-3">
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", borderRadius: 2 }}
             
            >
              <Table
                aria-label="simple table"
                sx={{ borderCollapse: "collapse" }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none" }}>
                      Due Date
                    </TableCell>
                    <TableCell
                      align="center"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Principal Payment
                    </TableCell>
                    <TableCell
                      align="center"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Interest Payment
                    </TableCell>
                    <TableCell
                      align="center"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Total Amount Due
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedules?.companies_schedules.map((row, index) => (
                    <TableRow
                      key={row?.start_date + "___" + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td, & th": { borderBottom: "none" },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.start_date}
                      </TableCell>
                      <TableCell align="center">
                        {row?.amount}
                      </TableCell>
                      <TableCell align="center">{row?.interest}</TableCell>
                      <TableCell align="center">{row?.total_amount_due}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div className="tw-mt-5 tw-flex tw-justify-center tw-items-center">
                <Button
                    size="large"
                    shape={"round"}
                    loading={isLoading}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF]"
                    onClick={confirmRequest}
                  >
                    Confirm mandate request
                  </Button>
                {/* <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="tw-rounded-[8px] tw-bg-[#8B0404] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#8B0404] hover:!tw-text-[#FFF]"
                  >
                    Cancel
                  </Button> */}

                </div>
      </div>
    </>
  );
}
