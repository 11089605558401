import { MenuItem, Select } from '@mui/material'
import React from 'react'
import CustomButton from '../../CustomButton/CustomButton'

function ProductToBuy({setItemName}) {
  return (
    <>
        <section className='col-lg-4 mx-auto col-md-8 col-sm-12'>
        <div className='my-2'>
                    <label htmlFor="what_to_buy" className='fw-bold'>What do you want to buy?</label>
                    <Select className='rounded-pill' fullWidth size='small' defaultValue={''} onChange={(e)=>setItemName(e.target.value)} placeholder='What to buy'>
                    <MenuItem value="">Item</MenuItem>
                        <MenuItem value="Off the counter medication">Off the counter medication</MenuItem>
                        <MenuItem value="clothes">Clothes</MenuItem>
                        <MenuItem value="shoes">Shoes</MenuItem>
                    </Select>
                </div>
               
        </section>
    </>
  )
}

export default ProductToBuy