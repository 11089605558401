import { useInitiateMandate } from "../../../redux/hooks/zustands/useInitiateMandate";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import LoanMandateInformation from "./LoanMandateInformation";
import MandateCreatedSuccessfull from "./MandateCreateSuccessfull";
import MandateSummary from "./MandateSummary";

export default function InitiateMandateDrawer({handleClick}){
    const {isOpen, closeDrawer, pageTitle, data: {formik}} = useInitiateMandate();
    return(
        <>
             <DrawerBox open={isOpen} handleClose={closeDrawer} size={'50rem'} title={<p className="tw-text-center">New Mandate</p>}>
        {
            pageTitle === "MANDATE_INFO" ? <LoanMandateInformation /> : pageTitle === "MANDATE_SUMMARY" ? <MandateSummary /> : pageTitle === "CREATE_SUCCESSFULL" ? <MandateCreatedSuccessfull handleClick={handleClick}/> : null
        }


        </DrawerBox>
        </>
    )
}