import React, { useState, useEffect } from 'react'
import CableOption from './CableOption'
import ConfirmCableInformation from './ConfirmCableInformation';
import CableCheckout from './CableCheckout';
import CableForm from './CableForm';
import { useGetCableProducts, useGetDataBundle } from '../../../redux/hooks/getHooks';
import BillWarningPage from '../BillWarningPage';

export default function CableTv({formik, handleClick, savedData, openModal}) {



    const [nextAction, setNextAction ] = useState("");

    const [cable, setCable] = useState("")

    

    // const getCableProducts = useGetCableProducts({
    //     // service_type: cable?.service_type,
    // })
    const getCableProducts = useGetDataBundle({
        // service_type: cable?.service_type,

        "vertical":"TV",
        "provider": cable //"DSTV"
    })


    useEffect(()=>{
        if(savedData){
            setNextAction('checkout')
        }
    }, [savedData])

    const cableProducts = getCableProducts?.data?.data?.data

    // console.log(cableProducts)

    // Initialize variables to store total price and codes
//   let totalPrice = 0 + formik?.values?.subscriptionPlan?.price;
//   const codes = [];

  // Iterate over the data array
//   formik?.values?.subscriptionPlan?.addOns?.forEach((item) => {
//     // Iterate over the options array in each item
//     item.options?.forEach((option) => {
//       // Add the price to the total price
//       totalPrice += option?.price;
//     });

//     // Push the code to the codes array
//     codes.push(item?.group?.code);
//   });

    // const billData = {
    //     smartcard_number: formik?.values?.decoderNumber,
    //     total_amount: parseFloat(totalPrice),
    //     product_code: formik?.values?.subscriptionPlan?.code,
    //     addon_code: codes?.length ? codes : null,
    //     product_monthsPaidFor: formik?.values?.subscriptionPlan?.monthsPaidFor,
    //     service_type: cable?.service_type,
    //     email: formik?.values?.email,
    //     marketId: formik?.values?.marketPlace?.id,
    //     order_name: "cable",
    //   };

      const billData = {
    "orderId": formik?.values?.distributionCompany?.verifiedData?.orderId,
    "amount": parseFloat(formik?.values?.amount), //"100",
    "meter": formik?.values?.distributionCompany?.verifiedData?.meterNo, //formik?.values?.decoderNumber,
    "phone": formik?.values?.mobileNumber,
    "disco": cable, //"DSTV",
    "vendType": "PREPAID",
    "tariffClass": formik?.values?.subscriptionPlan?.code, //"DATA-01",
    "plan": formik?.values?.subscriptionPlan?.desc, //"DATA-01",
    "email": formik?.values?.email,
    "vertical":"TV",
    "account_name": formik?.values?.distributionCompany?.verifiedData?.name, //"Dianna Doyle",
    "marketId": formik?.values?.marketPlace?.id,
    order_name: "cable",
    payment_type: "transfer"
}
    






      const payloadData = savedData ?? {
       data: {
        marketId: formik?.values?.marketPlace?.id,
        deliveryLocation: "",
        order_type: "bills",
        order_name: "cable",
        products: [
            billData
        ],
        
       }
      };


      


  return (
    <>
        <main>


            {/* {
                cable? (
                    <> */}
                    {
                         nextAction==='maximum_exceeded'? (<BillWarningPage handleClick={handleClick} payloadData={billData}/>):
                        nextAction==='confirm_information'? (
                            <ConfirmCableInformation formik={formik} setNextAction={setNextAction} cable={cable} handleClick={handleClick} payloadData={payloadData} />
                        ): nextAction==='checkout'? (
                            <CableCheckout formik={formik} cable={cable} handleClick={handleClick} setNextAction={setNextAction} payloadData={payloadData} openModal={openModal}/>
                        ): (
                            <>
                            <CableOption setCable={setCable} cable={cable} />
                            <CableForm formik={formik} cable={cable} setNextAction={setNextAction} cableProducts={cableProducts} handleClick={handleClick}/>
                               
                            </>

                        )
                    }
                    

        </main>
    </>
  )
}
