import { create } from "zustand"
export const useMandateModal = create((set)=>{
    return{
        isOpen: false,
        data: [
            {
                "bank_name": "Fidelity",
                "account_number": "9020025928",
                "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png"
            },
            {
                "bank_name": "Paystack Titan",
                "account_number": "9880218357",
                "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png"
            }
        ],

        openModal: (data)=>set((state)=>({isOpen: true, data: {...data}})),

        closeModal:()=>set({isOpen: false})
    }
})