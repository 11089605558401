import { useGetDiscos } from "../../redux/hooks/getHooks";

export const useDiscos = ()=>{
    //discos
  const { data, isLoading } = useGetDiscos();


  const discos = data?.data;

  const networksList = ["MTN", "GLO", "AIRTEL", "9MOBILE"];
  const electicityList = ["ABUJA", "BENIN", "EKO", "ENUGU", "IBADAN", "IKEJA", "JOS", "KADUNA", "KAIDA", "KANO", "KOIOS", "YOLA", "PH", "BH", "BENIN"];
  const cableList = ["GOTV", "DSTV", "STARTIMES"];

  const formatFn= (list)=>{
    return list?.filter(network => discos?.[network] === true);
  }

  const networkProvider = networksList //formatFn(networksList);
  const electricProvider = formatFn(electicityList);
  const cableProvider = cableList //formatFn(cableList);



  return {isLoading, networkProvider, electricProvider, cableProvider}
    

}