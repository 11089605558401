export const ROUTES = {
    landing_page: "/",
    bill: "/bill",
    shop_your_needs: "/shop-your-needs",
    contact: "/contact",
    privacy: "/vouch-privacy",

    // auth
    signup: "/auth/register",
    login: "/auth/login",
    verifyAccount: "/auth/verify",
    forgetPsw: "/auth/forget-password",
    resetPsw: "/auth/reset-password/:token",
    

    kyc: "/kyc",
    home: "/home",
    profile: "/profile",
    shop: "/shop",
    bills: "/shop/bills",
    request: "/shop/request",
    user_transaction_history: "/shop/transaction_history",
    credit: "/shop/credit",
    subscription: "/shop/subscription",
    cart: "/shop/cart",
    checkout: "/shop/cart/checkout",
    // kyc
    complete_kyc: "/kyc/complete-kyc",
    bank_statement: "/kyc/bank-statement",
    summary: "/kyc/summary",
    otp_verification: "/kyc/verify-phone-number",


    //admin
    adminDashboard: '/admin',
    adminLogin: "/admin/auth/login",
    merchantLogin: "/merchant/auth/login",
    merchantDashboard: "/merchant/",
    settlement: "/merchant/st",
    community: "/admin/community",
    member: "/admin/community/member",
    order: "/admin/community/order",
    transaction: "/admin/community/transaction",
    transaction_history: "/admin/community/transaction-history",
    e_mandate_history: "/admin/community/e-mandate-history",
    adminMerchant: "/admin/merchant",
    payment_settlement: "/admin/merchant/payment_settlement",
    subPlans: "/admin/plans",
    marketPlace: "/admin/market_place",
    upgradeRequest: "/admin/upgrade_request",

    e_mandate: "/emandate",



    upgradePlans: "/shop/subscription/upgrade"

}