/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { ROUTES } from "../../../routes/route";
import {
  OutlinedInput,
  IconButton,
  TextField,
  InputAdornment
} from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link, useNavigate } from 'react-router-dom';
import AuthButton from "../AuthButton/AuthButton";
import { useFormik } from "formik";
import * as yup from "yup";
import AuthHeader from "../AuthHeader";
import regions from "../../../redux/region/region.js";
import AlertDialog from "../../Buttons/dialog";
import "./signup.css"
import { useRegisterMutation } from "../../../redux/mutation/postMutation";

function SignupForm({handleClick}) {

  const [open, setOpen] = React.useState(false);
  const lagRigion = regions.filter(region => {return region.parent_ids.includes(119)})
  const [isloading, setIsloading] = useState(false)
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const navigate = useNavigate()

  // const pattern = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[^A-Za-z0-9])(?=.*[@$!%*?&]).{8,}$/
  const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/
  // const pattern = /^(?=.*[@$!%*?&])/
  const phonePattern = /^0\d{10}$/

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    navigate(ROUTES.login)
  };

  const mutation = useRegisterMutation();

  const formik = useFormik({
    initialValues: {
      "firstName": "",
      "lastName": "",
      "phone": "",
      "email": "",
      "password": ""
    },
    onSubmit: (values) => {
      setIsloading(true)
      mutation.mutate({
            "first_name": (values.firstName).trim(),
            "last_name": (values.lastName)?.trim(),
            "phone": `${(values.phone)}`,
            "email": (values.email)?.trim(),
            "password": values.password,
            "confirm_password": values.password,
          })
      // console.log(values)
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("Your First name can not empty!"),
      lastName: yup.string().required("Your Last name can not empty!"),
      phone: yup.string().required("Phone Number can not be empty!").matches(phonePattern, 'Phone number must be 11 digits and valid'),
      email: yup
        .string()
        .required("Email can not be empty!")
        .email("Invalid email address!"),
      password: yup.string().required("password is required").min(8, "password should not be less than 8").matches(pattern, "Password should contains uppercase,lowercase, digit, and at least one special characters") // include at least one upper case, lower case, and one among this special character(@$!%*?&)
      // Password should include at least one (upper case, lower case, special character)
    }),
  });
  useEffect(()=>{
    if (mutation.isLoading) {
      setIsloading(true)
    }
    else if (mutation.isError) {
      setIsloading(false)
      let message = mutation?.error?.response?.data?.message
      let msg3 = mutation?.error?.response?.data?.error
      let message2 = mutation?.error?.message
    
      handleClick(message?message: msg3 ?? message2, "error")
    } else if (mutation.isSuccess) {
      setIsloading(false)
      handleClickOpen()
    }
  },[mutation.status])




  const onChangePhoneNumber=(e)=>{

    formik.setFieldValue("phone", e.target.value)

  }

  
  return (
    <>
      <main>
        <form  className="col-lg-8 mx-auto" onSubmit={formik.handleSubmit}>
          <AuthHeader title={"Registration"} />
          <section className="row mx-2">
            <div className="firstName col-12" style={{paddingBottom: "20px"}}>
              <div className="label " style={{marginBottom: "4px"}}>
                <label htmlFor="firstName" >
                  First Name *
                </label>
              </div>
              <TextField
                type="text"
                name="firstName"
                required
                error={formik.touched.firstName && formik.errors.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your first name"
                value={formik.values.firstName}
                fullWidth
                size="medium"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    fontSize: "14px",
                  },
                }}
              />
              <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.firstName && formik.errors.firstName
                }
              </small>
            </div>
            <div className="lastName col-12" style={{paddingBottom: "20px"}}>
              <div className="label" style={{marginBottom: "4px"}}>
                <label htmlFor="lastName" >
                  Last Name *
                </label>
              </div>
              <TextField
                type="text"
                name="lastName"
                required
                error={formik.touched.lastName && formik.errors.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your last name"
                value={formik.values.lastName}
                fullWidth
                size="medium"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                    fontSize: "14px",
                  },
                }}
              />
              <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.lastName && formik.errors.lastName
                }
              </small>
            </div>
          <div className="phoneNumber col-12" style={{paddingBottom: "20px"}}>
            <div className="label" style={{marginBottom: "4px"}}>
              <label htmlFor="phoneNumber" >
                Phone Number *
              </label>
            </div>
            <TextField
              type="number"
              name="phone"
              // required
              error={formik.touched.phone && formik.errors.phone}
              onChange={onChangePhoneNumber}
              onBlur={formik.handleBlur}
              value={formik.values.phone}
              placeholder="Enter Your Phone Number"
              fullWidth
              variant="outlined"
              size="medium"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.phone && formik.errors.phone
                }
              </small>
          </div>
          <div className="Email col-12" style={{paddingBottom: "20px"}}>
            <div className="label" style={{marginBottom: "4px"}}>
              <label htmlFor="Email">
                Email *
              </label>
            </div>
            <TextField
              type="email"
              name="email"
              required
              error={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Your Email Address"
              fullWidth
              value={formik.values.email}
              variant="outlined"
              size="medium"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.email && formik.errors.email
                }
              </small>
          </div>
          <div className="password col-12" style={{paddingBottom: "20px"}}>
            <div className="label" style={{marginBottom: "4px"}}>
              <label htmlFor="password">
                Password *
              </label>
            </div>
            <TextField
              type={showPassword? "text":"password"}
              name="password"
              required
              error={formik.touched.password && formik.errors.password}
             
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter Your password"
              fullWidth
              value={formik.values.password}
              variant="outlined"
              size="medium"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.password && formik.errors.password
                }
              </small>
          </div>
          {/* <div className="password col-12">
            <div className="label my-2">
              <label htmlFor="password">
                Password *
              </label>
            </div>
            <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
            <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.password && formik.errors.password
                }
              </small>
          </div> */}
          {/* <div className="location">
            <div className="label my-2">
              <label htmlFor="location" >
                Location *
              </label>
            </div>
            <Select
              labelId="demo-simple-select-error-label"
              id="demo-simple-select-error"
              name="location"
              required
              placeholder="Location"
              error={formik.touched.location && formik.errors.location}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              defaultValue="Lagos"
              value={formik.values.location}
              fullWidth
              variant="outlined"
              sx={{ borderRadius: "30px" }}
              size="medium"
            >
              {lagRigion?.map((item, index)=>{
               return <MenuItem key={index} value={item.name}>{item.name}</MenuItem>
              })
              }
            </Select>
            <FormHelperText error>{formik.errors.location}</FormHelperText>
          </div> */}
          <div className="submit_btn pb-2">
            <AuthButton loading={isloading} text={"Join"} />
          </div>
          <div className="text-center mt-0 mb-3">
            <span className="">
              Already sign up on Vouch?
              <Link
                className="text-decoration-none mt-5"
                to={ROUTES.login}
                style={{ color: "#B41209", fontWeight: 400, fontSize: "16px" }}
              >
                Sign In
              </Link>
            </span>
            <br />
            <p className="mt-3" style={{fontSize: "16px", fontWeight: 400, fontFamily: "sans-Serif"}}>
              By clicking Create account, you agree to<br /><a href="/vouch-privacy" className="text-decoration-none"  style={{color: "#F1350E"}}><small> Vouch's Terms and Privacy Policy</small></a>
            </p>
          </div>
          </section>
        </form>
        {/* Congratulations! Your registration has been sent successfully. Expect an email from us shortly, and our dedicated team will be in touch to assist you further. */}
        <AlertDialog open={open} header={"Thank you for signing up!"} text={`To complete your registration, please click the verification link we've sent to your email.`} second_text={"Thank you for choosing Vouch!."} handleClose={handleClose}/>
      </main>
    </>
  );
}

export default SignupForm;
