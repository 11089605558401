import React from 'react'
import { formatNumber } from '../../utils/formatNumber'
import { ROUTES } from "../../routes/route";
import { useNavigate } from 'react-router-dom';
function Subscription({upgradeFn, plan, user}) {
  const navigate = useNavigate();


  return (
    <>
        <main className='col-lg-6 mx-auto'>
            <section className="mx-2 text-center my-5">

                    <h4 className="header fw-bold fs-5">Your Subscription Plan</h4>
                    <br />
                    <div className="d-flex flex-column">
                        <span>You are currently subscribe to Vouch <b style={{color: "#033F4B"}}>{plan?.plan?.name}</b> Plan.</span>
                        <span>Maximum allowable credit per month is {formatNumber(parseFloat(plan?.plan?.credit_limit))}</span>
                    </div><br />
                    <p><b style={{color: "#033F4B", borderBottom: "2px solid #033F4B"}} onClick={upgradeFn} className="cursor-pointer">Change Plan</b> To Access More Credit</p>
            </section>
        </main>
    </>
  )
}

export default Subscription