import { Button, Checkbox, Input, Radio} from "antd";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { useInitiateMandate } from "../../../redux/hooks/zustands/useInitiateMandate";
import { useSendSchedule } from "../../../redux/mutation/postMutation";
import { useEffect, useState } from "react";


const response = 
{
  "status": true,
  "message": "Preview",
  "data": {
      "first_name": "Adeniran",
      "last_name": "Akinboyewa",
      "bvn": "22267367430",
      "phone": "08067710067",
      "email": "adeakinboyewa@gmail.com",
      "total_amount": "500000",
      "tenor": "6months",
      "total_interest": "500000",
      "preview": "true",
      "payment_type": "monthly",
      "order_id": "HSVV265919",
      "start_date": "02/07/2024",
      "end_date": "13/07/2024",
      "address": "Surulere Lagos",
      "companies_schedules": [
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "02/07/2024",
              "end_date": "02/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "03/07/2024",
              "end_date": "03/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "04/07/2024",
              "end_date": "04/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "05/07/2024",
              "end_date": "05/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "06/07/2024",
              "end_date": "06/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "07/07/2024",
              "end_date": "07/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "08/07/2024",
              "end_date": "08/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "09/07/2024",
              "end_date": "09/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "10/07/2024",
              "end_date": "10/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "11/07/2024",
              "end_date": "11/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "12/07/2024",
              "end_date": "12/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "13/07/2024",
              "end_date": "13/07/2024"
          }
      ]
  }
}

export default function LoanMandateInformation(){

  const { openDrawer, data: {formik} } = useInitiateMandate()

  const [fileSelected, setFileSelected] = useState(false);

  const [tenorType, setTenorType] = useState("")
  const [isLoading, setIsLoading] = useState(false)

  const mutation = useSendSchedule();

  useEffect(()=>{
    console.log(formik.values)
  }, [formik])


  const onChangeNumber=(e)=>{
     console.log(e.target.files?.[0])
    console.log(e.target.value)
    if(e.target.name == "sc_file") {
      formik.setFieldValue("sc_file", e.target.files[0])
      console.log(formik)
      setFileSelected(true)
    }else{
      formik.setFieldValue(e.target.name, e.target.value)
    }

  }

  const continueFn=()=>{
    //I will send to backend from here
console.log(formik.values)
    const formData = new FormData();
    for (const key in formik.values) {
      if (key === 'sc_file') {
        formData.append('file', formik.values[key]); // Append sc_file with key 'file'
      } else {
        formData.append(key, formik.values[key]);
      }
    }

    formData.append("preview", "true");


    for (let [key, value] of formData.entries()) {
      console.log(key, value);
    }

    // setIsLoading(true)
    // const data = response?.data;
    // setTimeout(()=>{
    //     setIsLoading(false)
    //     openDrawer("MANDATE_SUMMARY", {formik, schedules: data})
    // }, 1000)


    mutation.mutate(formData)
    // openDrawer("MANDATE_SUMMARY", {formik, schedules: data})
   
  }



  useEffect(()=>{
    if(mutation.isLoading){
        setIsLoading(true)
    }
    else if(mutation.isError){
        setIsLoading(false)
        const error = mutation?.error?.response?.data

        console.log(error)
    }
    else if(mutation.isSuccess){
        setIsLoading(false)
        const res = mutation?.data?.data?.data
        formik.setFieldValue("payment_type", tenorType)
      
        openDrawer("MANDATE_SUMMARY", {formik, schedules: res})
         
    }
}, [mutation.status])


const onChange = (e) => {
  setTenorType(e.target.value)
  formik.setFieldValue("payment_type", e.target.value);
};

const options = [
  {
    label: 'Weekly',
    value: 'Weekly',
  },
  {
    label: 'Monthly',
    value: 'Monthly',
  },
  {
    label: 'Yearly',
    value: 'Yearly',
  }
];

    return(
        <>
              <div className="tw-flex tw-items-center tw-justify-center tw-mt-4">
        <div className="tw-w-full tw-max-w-lg tw-px-6 tw-py-4 tw-rounded-lg tw-border tw-border-[#D9D9D996] tw-bg-white tw-shadow-sm">
              <h4 className="tw-font-[600] tw-text-[18px] tw-mb-2">
              Loan Information
              </h4>
              <form action="">
                <div className="tw-mb-3">
                  <Input type="number" name="total_amount" onChange={onChangeNumber} onBlur={formik.handleBlur} status={formik.touched.total_amount && formik.errors.total_amount && "error"} placeholder="Enter Loan Amount" size={"large"} className="tw-h-[55px]" />
                </div>
                <div className="tw-mb-3">
                  <Input type="number" name="total_interest" onChange={onChangeNumber} onBlur={formik.handleBlur} status={formik.touched.total_interest && formik.errors.total_interest && "error"} placeholder="Total loan amount" size={"large"} className="tw-h-[55px]" />
                </div>
                <div className="tw-mb-3">
                  <Input
                    type="number" name="tenor" onChange={onChangeNumber} onBlur={formik.handleBlur} status={formik.touched.tenor && formik.errors.tenor && "error"}
                    placeholder="Enter loan tenor in number e.g 1"
                    size={"large"} className="tw-h-[55px]"
                  />
                  <Radio.Group onChange={onChange} value={tenorType} className="mt-2">
      <Radio value={"weekly"}>weekly</Radio>
      <Radio value={"monthly"}>monthly</Radio>
      <Radio value={'yearly'}>yearly</Radio>
    </Radio.Group>
                  {/* <div className="tw-flex tw-gap-2 tw-mt-2">
                    <Checkbox  className="tw-text-[#033F4B]">Weekly</Checkbox>
                    <Checkbox className="tw-text-[#033F4B]">Monthly</Checkbox>
                    <Checkbox className="tw-text-[#033F4B]">Yearly</Checkbox>
                  </div> */}
                </div>
                <div className="tw-mb-2 tw-flex tw-gap-x-2 tw-items-center">
                    <label htmlFor="schedule_file" className="tw-flex tw-flex-col tw-border tw-rounded-[8px] tw-items-center tw-p-3">
                      <input type="file" name="sc_file" onChange={onChangeNumber} id="schedule_file" className="tw-hidden" accept=".csv,text/csv" />
                      <FileUploadIcon sx={{fontSize: "2rem", color: formik?.values?.sc_file ? "green": ""}}/>
                      <span className="tw-font-[400] tw-text-[14px] tw-text-[#033F4B]">Upload loan schedule here in csv format</span>
                    </label>
                    {
                      fileSelected&& <span className="tw-italic tw-text-[#790707] tw-text-[16px] tw-text-center tw-font-[400]">
                    View and download csv template
                    </span>
                    }
                </div>
                <div className="tw-mt-4">
                <Button
                    size="large"
                    shape={"round"}
                    loading={isLoading}
                    onClick={continueFn}
                    className="tw-rounded-[8px] tw-bg-[#000000] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] tw-w-full hover:!tw-bg-[#000000] hover:!tw-text-[#FFF]"
                  >
                    Continue
                  </Button>

                </div>
              </form>
          </div>
        </div>
        </>
    )
}