import { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Button } from "@mui/material";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import moment from "moment";
import { formatNumber, formNumberOnly } from "../../../utils/formatNumber";
import ModalDialogBox from "../../../components/ModalDialogBox/ModalDialogBox";
import OrderSummary from "./OrderSummary";
import TransactionHistory from "./TransactionHistory";
import {
  useApproveOrder,
  useConfirmRepayment,
} from "../../../redux/mutation/AdminPostMutation";
import { useGetUserCreditEstimate, useGetMarketPlace } from "../../../redux/hooks/getHooks";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import LoadingButton from "@mui/lab/LoadingButton";
import { useResponsiveSize } from "../../hooks/useResponsiveSize";
import { useQueryClient } from "@tanstack/react-query";
import { useLocation } from "react-router-dom";

const columns = [
  // { id: "s_n", label: "S/N", minWidth: 50 },
  { id: "name", label: "Product Name", minWidth: 100 },
  {
    id: "amount",
    label: "Amount(₦)",
  },
  {
    id: "date",
    label: "Date",
  },
  {
    id: "status",
    label: "Status",
  },
];

export default function CreditSummary({
  order,
  handleClick,
  handleCloseSummaryModal,
  
}) {

  const location = useLocation({}).pathname;
  const btnOption = [
    { id: 1, text: "View Order" },
    { id: 2, text: "Confirm Repayment" },
    { id: 3, text: "Confirm Order delivery" },
    { id: 4, text: `View ${order?.user?.first_name} transaction profile` },
    { id: 5, text: `${order?.user?.first_name} transaction History` },
  ];

  const [open, setOpen] = useState({ status: false, btnId: null });
  const [openModal, setOpenModal] = useState(false);
  const [approveLoading, setApproveLoading] = useState({status: false, action: ""});
  const [confirmDeliveryLoading, setConfirmDeliveryLoading] = useState(false);
  const [confirmRepaymentLoading, setConfirmRepaymentLoading] = useState(false);

  const approveOrderMutation = useApproveOrder();
  const confirmRepaymentMutation = useConfirmRepayment(order?.id);

  

  // const 

  const getMarketPlaces = useGetMarketPlace("admin");

  const queryClient = useQueryClient({});
    
  const marketPlaces = getMarketPlaces?.data?.data?.data;

  const getUserCreditEstimate = useGetUserCreditEstimate(
    order?.userId,
    "admin"
  );


  const estimatedCredit = getUserCreditEstimate?.data?.data?.data;

  const responsiveSize = useResponsiveSize({});

  const handleOpen = (btnId) => {
    setOpen({ ...open, status: true, btnId });
  };
  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  //==================================== Approve order function ==============================
  const approveOrder = (action) => {
    //to approve order, payload will be status=approved, id
    // console.log(order);
    setApproveLoading({...approveLoading, status: true, action});
    approveOrderMutation.mutate({ status: action==="decline_order"? "declined" : "order in progress", id: order?.id }, {
      onSuccess: ()=>{
        queryClient.invalidateQueries(["all_credit"])
        handleClose();
      }
    });
  };


  //================================================= approve order function ends here ==============================

  //============================= confirm repayment function
  const confirmRepayment = () => {
    // confirmRepaymentMutation?.mutate();
    approveOrderMutation.mutate({ status: "repaid", id: order?.id }, {
      onSuccess: ()=>{
        queryClient.invalidateQueries(["all_credit"])
        handleClose();
      }
    });
  };

  useEffect(() => {
    if (confirmRepaymentMutation.isLoading) {
      setConfirmRepaymentLoading(true);
    } else if (confirmRepaymentMutation.isError) {
      setConfirmDeliveryLoading(false);
      const res = confirmRepaymentMutation?.error?.response?.data;
      handleClick(res, "error");
    } else if (confirmRepaymentMutation.isSuccess) {
      setConfirmRepaymentLoading(false);
      const res = confirmRepaymentMutation?.data?.data;
      handleClick(res?.user, "success");
      handleCloseModal();
    }
  }, [confirmRepaymentMutation.status]);

  //===============================


  //============================= confirm delivery function
  const confirmDelivery = () => {
    approveOrderMutation.mutate({ status: "order delivered", id: order?.id }, {
      onSuccess: ()=>{
        queryClient.invalidateQueries(["all_credit"])
        handleCloseModal();
      }
    });
  };




  //approve order mutation useEffect
  useEffect(() => {
    if (approveOrderMutation.isLoading) {
      setApproveLoading({...approveLoading, status: true});
    } else if (approveOrderMutation.isError) {
      setApproveLoading({...approveLoading, status: false});
      const res = approveOrderMutation?.error?.response?.data;
      // console.log(res);
      handleClick(res, "error");
    } else if (approveOrderMutation.isSuccess) {
      setApproveLoading({...approveLoading, status: false});
      const res = approveOrderMutation?.data?.data;
      // console.log(approveOrderMutation?.data);
      handleClick(res?.message, "success");

     
      
      handleCloseSummaryModal();
    }
  }, [approveOrderMutation.status]);



  return (
    <>
      <div>
        <h5 className="fw-semibold">
          {order?.user?.first_name} {order?.user?.last_name} Order
        </h5>
        <p className="text-center fw-normal">Credit Summary</p>
        <div className="overflow-x-auto">
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell align="center" colSpan={2}>
                    <h6 className="fw-medium">
                      Avail. Credit:{" "}
                      {formatNumber(estimatedCredit?.remainingCredit ?? 0)}
                    </h6>
                  </TableCell>
                  <TableCell align="center" colSpan={3}>
                    <h6 className="fw-medium">
                      Max. Credit:{" "}
                      {formatNumber(parseFloat(estimatedCredit?.max_amount) ?? 0)}
                    </h6>
                  </TableCell>
                </TableRow>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column?.id}
                      align={column?.align}
                      className="fw-semibold"
                      style={{ top: 57, minWidth: column?.minWidth }}
                    >
                      {column?.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {order?.products?.map((row, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index + "__" + row?.category}
                    >
                      {/* <TableCell>{index + 1}.</TableCell> */}
                      <TableCell>{
                        order?.is_bill? (
                          <>
                          <span className="" style={{textTransform: "capitalize"}}>{row?.order_name}</span><span style={{fontWeight: 300}}>({(row?.service_type)?.toUpperCase()})</span>
                          
                          </>
                        ): (
                          row?.name
                        )
                      }</TableCell>
                      <TableCell className="nowrap">
                        {formNumberOnly((order?.is_bill? (row?.amount ?? row?.total_amount) : row?.price) ?? 0)}
                      </TableCell>
                      <TableCell className="nowrap">
                        {moment(order?.createdAt).format("DD, MMMM YYYY")}
                      </TableCell>
                      <TableCell>
                        {order?.status}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </div>

        <div className="text-center my-3 ">
        <p className="fw-semibold">Payment is due on {moment(order?.repaymentDuration).format("DD MMMM, YYYY")}</p>
          <h5 className="fw-medium">
            What do you want to do with this request?
          </h5>
          <div className="d-flex flex-column mt-3 mx-auto align-items-center justify-content-center gap-3 w-100 w-lg-25">
            {btnOption.map((item, index) => {
              return (
                <Button
                  key={index}
                  variant="contained"
                  fullWidth
                  disabled={(item?.id===2 && order?.status === 'order delivered')? false : (item?.id===3 && order?.status === 'order in progress')? false: (item?.id===1 || item?.id ===4 || item?.id===5) ? false : true}
                  onClick={() =>
                    item.id === 3 ? handleOpenModal() : handleOpen(item.id)
                  }
                  sx={{
                    backgroundColor: "#033F4B",
                    color: "white",
                    textTransform: "capitalize",
                    "&:hover": {
                      backgroundColor: "#033F4B",
                      color: "white",
                    },
                  }}
                >
                  {item.text}
                </Button>
              );
            })}
          </div>
        </div>
      </div>

      <DrawerBox
        open= {open.status}
        handleClose= {handleClose}
        size={responsiveSize === "lg" ? 850 : responsiveSize === "md" && 1000}
      >
        {open.btnId === 1 ? (
          <OrderSummary
            order={order}
            estimatedCredit={estimatedCredit}
            approveLoading={approveLoading}
            approveOrder={approveOrder}
            handleClick={handleClick}
            marketPlaces={marketPlaces}
          />
        ) : open?.btnId === 2 ? (
          <>
            <div className="text-center d-flex align-items-center justify-content-center" style={{
              height: "70vh"
            }}>
              <div>
                <h3>Confirm Repayment</h3>
                <div className="d-flex gap-3 align-items-center justify-content-center">
                  <Button
                    autoFocus
                    onClick={handleClose}
                    sx={{
                      backgroundColor: "rgb(146, 36, 36)",
                      color: "#fff",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "rgb(146, 36, 36)",
                        color: "white",
                      },
                    }}
                  >
                    Cancel
                  </Button>
                  <LoadingButton
                    loading={confirmRepaymentLoading}
                    loadingPosition="center"
                    onClick={confirmRepayment}
                    sx={{
                      backgroundColor: "#033F4B",
                      color: "#fff",
                      fontFamily: "Poppins",
                      textTransform: "capitalize",
                      // "&:hover": {
                      //   backgroundColor: "#033F4B",
                      //   color: "white",
                      // },
                    }}
                  >
                    Confirm

                  </LoadingButton>
                </div>
              </div>
            </div>
          </>
        ) : open?.btnId === 5 ? (
          <TransactionHistory credData={{...order?.user, userId: order?.userId, orderId: order?.id}} role={location.includes('member')}/>
        ) : (
          <h1 className="nowrap text-muted">
            {" "}
            <i>Coming soon</i>
          </h1>
        )}
      </DrawerBox>

      <ModalDialogBox
        open={openModal}
        handleClose={handleCloseModal}
        modalSize={false}
      >
        <DialogTitle id="responsive-dialog-title">
          {"Delivery confirmation"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to confirm the delivery of this order?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleCloseModal}>
            Cancel
          </Button>
          <LoadingButton
            loading={confirmDeliveryLoading}
            loadingPosition="center"
            onClick={confirmDelivery}
          >
            Confirm
          </LoadingButton>
        </DialogActions>
      </ModalDialogBox>
    </>
  );
}
