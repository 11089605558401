import {
  DialogTitle,
  FormHelperText,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import CustomButton from "../../CustomButton/CustomButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route";
import { useUserData } from "../../hooks/useUserData";
import { useDispatch, useSelector } from "react-redux";
import { productAction } from "../../../redux/slice/productSlice";
import { useGetMarketPlace } from "../../../redux/hooks/getHooks";
import { Select as AntDSelect } from "antd";
import { formatMoneyNumber } from "../../../utils/formatNumber";
const categories = [
  "Foodstuff",
  "Fashion",
  "Fashion accessories",
  "boys and girl fashion",
  "Kids and Baby products",
  "Game and console",
  "beauty and perfumes",
  "home and kitchen",
  "Stationaries",
  "Event and Ticket",
  "computer accessories",
  "electronic",
  "Drinks and Groceries",
  "Restaurant",
  "Pharmacy",
];
function OnlineMarketPlace({ handleClick, handleClose }) {
  const [isLoading, setIsLoading] = useState(null);
  const [selectedMarketId, setSelectedMarketId] = useState("");

  const [sameProductLink, setSameProductLink] = useState(false);

  const location = useLocation().pathname;
  const dispatch = useDispatch();
  const { data } = useUserData();


  const getMarketPlaces = useGetMarketPlace("user");
  const marketPlaces = getMarketPlaces?.data?.data?.data?.filter(
    (val) => {
      const status = val?.status
      const notBills = val?.name !== "Bills"

      return status && notBills;

    }
  );


  const { totalPrice, quantity, allProducts } = useSelector(
    (state) => state.product
  );
  const sessionKey = data?.id + "_product";
  let products = JSON.parse(sessionStorage.getItem(sessionKey));

  const [selectedMarket, setSelectedMarket] = useState(null);

  const handleMarketChange = (event) => {
    const selectedMarketId = event.target.value;
    // console.log(selectedMarketId);
    setSelectedMarketId(selectedMarketId);
    const selectedMarket = marketPlaces.find(
      (market) => market.id === selectedMarketId
    );
    formik.setFieldValue("marketPlace", selectedMarket);
    setSelectedMarket(selectedMarket);
  };

  const handleChange = (e) => {
    const formattedValue = formatMoneyNumber(e.target.value);
    formik.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });
  };

  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      category: "",
      marketPlace: allProducts?.marketPlace ? allProducts?.marketPlace : "",
      product: "",
      productUrl: "",
      description: "",
      price: "",
      deliveryLocation: allProducts?.deliveryLocation
        ? allProducts?.deliveryLocation
        : data?.contact.address ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const {
        category,
        marketPlace,
        product,
        productUrl,
        description,
        price,
        deliveryLocation,
      } = values;
      setIsLoading(true);

      const nPrice = price?.replace(/,/g, "");

      const data = {
        marketPlace: marketPlace,
        products: [
          {
            category: category,
            name: product,
            url: productUrl,
            description: description,
            price: parseFloat(nPrice),
            quantity: 1,
          },
        ],
        deliveryLocation: deliveryLocation,
        repaymentDuration: 0,
      };

      setTimeout(() => {
        if (products) {
          products?.products?.push(data.products[0]);
          // console.log(products);
          dispatch(productAction.setAllProducts(products));
          updatePrice(products);
          sessionStorage.setItem(sessionKey, JSON.stringify(products));
        } else {
          dispatch(productAction.setAllProducts(data));
          updatePrice(data);
          sessionStorage.setItem(sessionKey, JSON.stringify(data));
        }
        location === ROUTES.cart ? handleClose() : navigate(ROUTES.cart);
        setIsLoading(false);
      }, 500);
    },
    validationSchema: yup.object().shape({
      category: yup.string().required("It can not be empty"),
      marketPlace: yup.object().required("It can not be empty"),
      product: yup.string().required("It can not be empty"),
      productUrl: yup.string().required("It can not be empty"),
      // description: yup.string().required("It can not be empty"),
      price: yup.string().required("It can not be empty and must be number"),
    }),
  });

  const updatePrice = (products) => {
    dispatch(productAction.setTotalPrice());
  };

  useEffect(() => {
    setSelectedMarketId(formik.values?.marketPlace?.id);
  }, [formik]);


  const isValidUrl=(url)=> {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

 

  // useEffect(()=>{
  //   const marketPlace = formik.values.marketPlace

  //   const productUrl = formik.values.productUrl;

    
  //   if(formik.values.marketPlace && formik.values.productUrl){


  //     const parsedUrl = isValidUrl(formik.errors.productUrl) ? new URL(productUrl) : "";

          
  //       if(productUrl.includes((marketPlace?.name)?.toLowerCase())){
  //         console.log(productUrl.includes((marketPlace?.name)?.toLowerCase()));
  //         setSameProductLink(true)
  //       }
  //       else{
  //         formik.setErrors({productUrl: "The product URL does not match the selected market place"})
  //         setSameProductLink(false)
  //       }
  //       // else if(!isValidUrl(formik.errors.productUrl) ){
  //       //   formik.setErrors({productUrl: "Invalid URL"})
  //       // }

  //   }


  // }, [formik])



  useEffect(() => {
    const marketPlace = formik.values.marketPlace;
    const productUrl = formik.values.productUrl;
  
    if (marketPlace && productUrl) {
      const isValid = isValidUrl(formik.errors.productUrl);
      const parsedUrl = isValid ? new URL(productUrl) : null;
  
      const lowerCaseProductUrl = productUrl.toLowerCase();
      const marketPlaceName = marketPlace.name?.toLowerCase();
      
      // Check if any word from marketPlace.name is included in the productUrl
      if (marketPlaceName) {
        const nameWords = marketPlaceName.split(' ');
        const containsWord = nameWords.some(word => lowerCaseProductUrl.includes(word));
        
        if (containsWord) {
          setSameProductLink(true);
        } else {
          formik.setErrors({ productUrl: "The product URL does not match the selected market place" });
          setSameProductLink(false);
        }
      } else {
        formik.setErrors({ productUrl: "Market place name is not defined" });
        setSameProductLink(false);
      }
  
      // Additional URL validation
      // if (!isValid) {
      //   formik.setErrors({ productUrl: "Invalid URL" });
      // }
    }
  }, [formik]);
  



  return (
    <>
      <section className="mx-auto ">
        <div className="text-center">
          <DialogTitle>Online Marketplace</DialogTitle>
        </div>
        <section className="m-3">
          <form onSubmit={formik.handleSubmit}>
            <div className="" style={{ paddingBottom: "15px" }}>
              <label
                htmlFor="category"
                className=""
                style={{ marginBottom: "4px" }}
              >
                Category
              </label>
              <Select
                className="rounded-pill"
                fullWidth
                size="medium"
                name="category"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.category}
                placeholder="Category"
              >
                <MenuItem value="">category</MenuItem>
                {categories?.map((category, index) => (
                  <MenuItem key={category + "_" + index} value={category}>
                    {category}
                  </MenuItem>
                ))}
              </Select>

              <FormHelperText error>
                {formik.touched.category && formik.errors.category}
              </FormHelperText>
            </div>
            <div className="" style={{ paddingBottom: "15px" }}>
              <label
                htmlFor="marketplace"
                className=""
                style={{ marginBottom: "4px" }}
              >
                Select marketplace
              </label>
              <Select
                className="rounded-pill"
                fullWidth
                size="medium"
                name="marketPlace"
                onChange={handleMarketChange}
                onBlur={formik.handleBlur}
                value={selectedMarketId ?? ""}
                disabled={allProducts?.products?.length ? true : false}
              >
                <MenuItem value="">
                  <em>marketplace</em>
                </MenuItem>
                {marketPlaces?.map((market, index) => {
                  return (
                    <MenuItem value={market.id} key={market?.id + "_" + index}>
                      {market?.name}
                    </MenuItem>
                  );
                })}
              </Select>

                {
                  allProducts?.products?.length && (
                    <p>
                      <small style={{ fontSize: "12px", fontWeight: "light" }} className="text-warning">
                  <i>marketplace cannot be changed once you have an item in your cart already!!</i>
                </small>
                    </p>
                  )
                }
              
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.marketPlace && formik.errors.marketPlace}
             
              </small>
            </div>
            <div className="" style={{ paddingBottom: "15px" }}>
              <label
                htmlFor="itemName"
                className=""
                style={{ marginBottom: "4px" }}
              >
                Enter Item you want to buy
              </label>
              <TextField
                className="rounded-pill"
                placeholder="E.g, Rolex wrist watch"
                name="product"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
                size="medium"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.product}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.product && formik.errors.product}
              </small>
            </div>
            <div className="" style={{ paddingBottom: "15px" }}>
              <label
                htmlFor="item_web_link"
                className=""
                style={{ marginBottom: "4px" }}
              >
                Provide the item weblink{" "}
                <code style={{ cursor: "pointer" }}>see how</code>
              </label>
              <TextField
                className="rounded-pill"
                placeholder="E.g, https://www.abc..."
                name="productUrl"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
                size="medium"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.productUrl}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.productUrl && formik.errors.productUrl}
              </small>
            </div>
            <div className="" style={{ paddingBottom: "15px" }}>
              <label
                htmlFor="item_price_tag"
                className=""
                style={{ marginBottom: "4px" }}
              >
                Enter the item price tag{" "}
                <small>(as it is on the marketplace)</small>
              </label>
              <TextField
                type="text"
                className="rounded-pill"
                placeholder="E.g, 20000"
                name="price"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
                size="medium"
                onChange={handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.price}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.price && formik.errors.price}
              </small>
            </div>
            <div className="" style={{ paddingBottom: "15px" }}>
              <label
                htmlFor="description"
                className=""
                style={{ marginBottom: "4px" }}
              >
                Description
              </label>
              <textarea
                id="description"
                cols="10"
                rows="5"
                style={{ resize: "none" }}
                className="form-control"
                name="description"
                placeholder="Enter brief description of order"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik?.values?.description}
              ></textarea>
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.description && formik.errors.description}
              </small>
            </div>
            <div className="next_btn">
              <CustomButton text={"Next"} disabled={!sameProductLink} loading={isLoading} />
            </div>
          </form>
        </section>
      </section>
    </>
  );
}

export default OnlineMarketPlace;
