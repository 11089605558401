/* eslint-disable react-hooks/exhaustive-deps */

import { useNavigate, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup"
import PersonalInformation from "../../../components/Auth/Kyc/KycVerification/PersonalInformation/PersonalInformation";
import AddressInformation from "../../../components/Auth/Kyc/KycVerification/AddressInformation/AddressInformation";

import KycSummary from "../../../components/Auth/Kyc/KycVerification/KycSummary/KycSummary";
import BankStatement from "../../../components/Auth/Kyc/KycVerification/BankStatement/BankStatement";
import Summary from "../../../components/Auth/Kyc/KycVerification/Summary/Summary";
import TakeSelfie from "../../../components/Auth/Kyc/KycVerification/TakeSelfie/TakeSelfie";
import WorkInformation from "../../../components/Auth/Kyc/KycVerification/WorkInformation/WorkInformation";
import { useDispatch, useSelector } from "react-redux";
import { stepperAction } from "../../../redux/slice/stepperSlice";
import CustomButton from "../../../components/CustomButton/CustomButton";
import { useEffect, useState } from "react";
import {
  useBackKyc,
  useCompleteKyc,
  useUploadImage,
} from "../../../redux/mutation/postMutation";
import { useUserData } from "../../../components/hooks/useUserData";
import { ROUTES } from "../../../routes/route";
import { useQueryClient } from "@tanstack/react-query";
import KYCStepper from "../../../components/Auth/Kyc/KYCStepper";
import useWindowSize from "../../../components/hooks/useWindowSize";
import NINVerification from "../../../components/Auth/Kyc/KycVerification/NINVerification/NINVerification";
import "./kycScreen.css";


function KycScreen({ handleClick }) {
  const navigate = useNavigate({});
  const kycMutation = useCompleteKyc();
  const uploadImageMutation = useUploadImage();

  const windowSize = useWindowSize();

  const location = useLocation({})?.pathname;
  const backKycMutation = useBackKyc();

  const queryClient = useQueryClient();

  const user = useUserData();
  const [isLoading, setIsLoading] = useState(null);
  const [backIsLoading, setBackIsLoading] = useState(null);
  const [enableNext, setenableNext] = useState(false);

  const { activeStep, kycStep } = useSelector((state) => state.stepper);

  const [imageFile, setImageFile] = useState("");

  const dispatch = useDispatch();

  const date = new Date(user?.data?.dob);

  const responsiveScreenWidth =
    windowSize?.width >= 1024
      ? "lg"
      : windowSize?.width > 600 && windowSize?.width < 1024
      ? "md"
      : windowSize?.width <= 600
      ? "sm"
      : null;

  // Get the year, month, and day components
  // const year = date.getFullYear();
  // const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Add 1 to the month since it's 0-based
  // const day = date.getDate().toString().padStart(2, "0");

  // Format the date as 'YYYY-MM-DD' for input type "date"
  // const formattedDate = `${year}-${month}-${day}`;

  // function formatDate(date) {
  //   const day = String(date.getDate()).padStart(2, "0");
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const year = date.getFullYear();
  //   return `${day}-${month}-${year}`;
  // }








  const initialValues = {
    image: user?.data?.profile_picture ? user?.data?.profile_picture : "",
    otp: "",
    gender: user?.data?.bioData?.gender ?? "",
    maritalStatus: user?.data?.bioData?.maritalStatus ?? "",
    dateOfBirth: user?.data?.bioData?.dob ?? "",
    nin: user?.data?.bioData?.nin ?? "",
    homeAddress: user?.data?.contact?.address ?? "",
    city: user?.data?.contact?.city ?? "",
    state: user?.data?.contact?.state ?? "",
    occupation: user?.data?.employmentDetails?.occupation ?? "",
    workEmail: user?.data?.employmentDetails?.workEmail ?? "",
    monthlySalary: user?.data?.employmentDetails?.salary ?? "",
    employer: user?.data?.employmentDetails?.employer ?? "",
    employerAddress: user?.data?.employmentDetails?.employeraddress ?? "",
  };
  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values, { resetForm }) => {
      const data = {
        profile_picture: values.image,
        bioData: {
          gender: values.gender,
          maritalStatus: values.maritalStatus,
          dob: values.dateOfBirth, //formatDate(new Date(values.dateOfBirth))
          nin: values.nin,
        },
        contact: {
          address: values.homeAddress,
          city: values.city,
          state: values.state,
        },
        employmentDetails: {
          occupation: values.occupation,
          workEmail: values.workEmail,
          salary: values.monthlySalary,
          employer: values.employer,
          employeraddress: values.employerAddress,
        },
      };
      // console.log(data);
      setIsLoading(true);
      kycMutation.mutate(data, {
        onSuccess: () => {
          resetForm();
        },
      });
    },
    validationSchema: yup.object().shape({
      nin: yup.string().required("NIN is required").min(11, "NIN must be 11 digits").max(11, "NIN must be 11 digits"),
    })
  });

  // set image file state is here for now

  const navigateFn = (val) => {
    const data =
      // activeStep === 2 && user?.data?.kyc_stepper === 2
      //   ? {
      //       otp: formik?.values?.otp,
      //     }
      //   : 
        {
            profile_picture: formik?.values?.image,
            bioData: {
              gender: formik?.values.gender,
              maritalStatus: formik?.values.maritalStatus,
              dob: formik?.values.dateOfBirth,
              nin: formik?.values.nin,
            },
            contact: {
              address: formik?.values.homeAddress,
              city: formik?.values.city,
              state: formik?.values.state,
            },
            employmentDetails: {
              occupation: formik?.values.occupation,
              workEmail: formik?.values.workEmail,
              salary: formik?.values.monthlySalary,
              employer: formik?.values.employer,
              employeraddress: formik?.values.employerAddress,
            },
          };

    // console.log(data);
    if (val) {
      // call the kyc endpoint to save the kyc

      if (activeStep === 0 && user?.data?.kyc_stepper === 0 && !user?.data?.profile_picture) {
      

        const formData = new FormData();
        formData.append("image", imageFile);

        // for (const [key, value] of formData.entries()) {
        //   console.log(key, value);
        // }
        setIsLoading(true);
        uploadImageMutation.mutate(formData, {
          onSuccess: (res) => {
            submitKycFunction({...data, profile_picture: res?.data?.data});
          },
          onError:()=>{
            setIsLoading(false);
            handleClick("An error occured: profile picture is not uploaded!!", "error", {vertical: "top", horizontal: "right"})
          }
        });
      }
      else{
        submitKycFunction(data);
      }

    } else {
      // back kyc
      backKycMutation.mutate();
    }
  };

  //function that calls the kyc mutation to save kyc
  const submitKycFunction = (data) => {
    kycMutation.mutate(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["me"] });
        formik.setFieldValue("otp", "");
       
        setenableNext(false);
      },
    });
  };

  // useEffect for the mutation
  useEffect(() => {
    if (kycMutation.isLoading) {
      setIsLoading(true);
    } else if (kycMutation.isSuccess) {
      setIsLoading(false);
      

      let msg = kycMutation?.data?.data?.message;
      // handleClick(msg, "success");

      if(activeStep < 4){
        dispatch(stepperAction.increaseActiveStep());
      }

      if(activeStep === 4){
        navigate(ROUTES.otp_verification);
      }

    } else if (kycMutation.isError) {
      setIsLoading(false);
      let msg = kycMutation?.error?.response?.data?.message;
      // console.log(kycMutation?.error);
      handleClick(msg, "error");
    }
  }, [kycMutation.status]);

  // useEffect for the back mutation
  useEffect(() => {
    if (backKycMutation.isLoading) {
      setBackIsLoading(true);
    } else if (backKycMutation.isSuccess) {

      setBackIsLoading(false);

     
      
      dispatch(stepperAction.decreaseActiveStep());

    } else if (backKycMutation.isError) {
      setBackIsLoading(false);
      let msg = backKycMutation?.error?.response?.data?.message;

      handleClick(msg, "error");
    }
  }, [backKycMutation.status]);


  

  return (
    <>
      <main>
        <section className="mt-3">
          <h4 className="text-center fw-semibold kyc_verification_header">
            KYC Verification
          </h4>
          <section
            className={
              responsiveScreenWidth === "lg" || responsiveScreenWidth === "md"
                ? "d-flex gap-2 justify-content-center"
                : responsiveScreenWidth === "sm" && "d-block"
            }
          >
            {activeStep < 5 ? (
              <KYCStepper activeStep={activeStep} steps={kycStep} />
            ) : null}
            <div
              className={`w-100 ${responsiveScreenWidth === "sm" && "mt-4"}`}
            >
              <div style={{ minHeight: "30vh" }}>
                {activeStep === 0 ? (
                  <TakeSelfie
                    activeStep={activeStep}
                    kycStep={kycStep}
                    handleClick={handleClick}
                    formik={formik}
                    imageFile={imageFile}
                    setImageFile={setImageFile}
                    setenableNext={setenableNext}
                  />
                ) : activeStep === 1 ? (
                  <PersonalInformation
                    formik={formik}
                    activeStep={activeStep}
                    kycStep={kycStep}
                    setenableNext={setenableNext}
                  />
                ) 
                // : activeStep === 2 ? (
                //   <NINVerification
                //     formik={formik}
                //     handleClick={handleClick}
                //     setenableNext={setenableNext}
                //     submitKycFunction={submitKycFunction}
                //   />
                // ) 
                : activeStep === 2 ? (
                  <AddressInformation
                    formik={formik}
                    activeStep={activeStep}
                    kycStep={kycStep}
                    setenableNext={setenableNext}
                  />
                ) : activeStep === 3 ? (
                  <WorkInformation
                    formik={formik}
                    activeStep={activeStep}
                    kycStep={kycStep}
                    setenableNext={setenableNext}
                  />
                ) : activeStep === 4 ? (
                  <KycSummary
                    formik={formik}
                    isLoading={isLoading}
                    setenableNext={setenableNext}
                  />
                ) : (
                  ""
                )}
              </div>
              {activeStep <= 3 ? (
                <div className="navigate_btn d-flex justify-content-between">
                  <CustomButton
                    text={"Prev"}
                    style={{ padding: "1vh 5vh" }}
                    disabled={activeStep === 0 ? true : null || isLoading}
                    functionFn={() => navigateFn(0)}
                    loading={backIsLoading}
                  />
                  {
                    // (activeStep === 0 && formik?.values?.image) ||
                    // (activeStep !== 0 && formik?.values?.image) ? (
                    <CustomButton
                      style={{ padding: "1vh 5vh" }}
                      disabled={!enableNext}
                      loading={isLoading}
                      text={"Next"}
                      functionFn={() => navigateFn(1)}
                    />
                    // ) : null
                  }
                </div>
              ) : null}
            </div>
          </section>
        </section>
      </main>
    </>
  );
}

export default KycScreen;
