import * as React from "react";
import PropTypes from "prop-types";
import { createTheme, styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Check from "@mui/icons-material/Check";
import SettingsIcon from "@mui/icons-material/Settings";
import GroupAddIcon from "@mui/icons-material/GroupAdd";
import VideoLabelIcon from "@mui/icons-material/VideoLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { ThemeProvider } from "@mui/material/styles";
import { useTimeCounter } from "../../hooks/useTimeCounter";

// Define custom breakpoints
const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0, // extra-small devices (phones, 0px and up)
      sm: 320, // small devices (320px to 600px)
      md: 601, // medium devices (601px and up)
      lg: 900, // large devices (900px and up)
      xl: 1200, // extra-large devices (1200px and up)
      "2xl": 1536, // 2x extra-large devices (1536px and up)
    },
  },
});

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  transition: 'color 0.3s ease-in-out',
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
    transition: 'background-color 0.3s ease-in-out',
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#033F4B",
      transition: 'background-color 0.3s ease-in-out',
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#033F4B",
      transition: 'background-color 0.3s ease-in-out',
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor: "#033F4B",
    borderRadius: 1,
    transition: 'background-color 0.3s ease-in-out',
    // Adjust the connector line positioning
    position: 'absolute',
    left: '50%',
    transform: 'translateX(-50%)',
    width: '100%', // Adjust the width to accommodate padding
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "#fff",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  transition: 'background-color 0.3s ease-in-out, color 0.3s ease-in-out, border 0.3s ease-in-out',
  ...(ownerState.active && {
    backgroundColor: "#FFF",
    color: "#033F4B",
    border: "2px solid #033F4B",
  }),
  ...(ownerState.completed && {
    backgroundColor: "#033F4B",
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className, icon } = props;

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, active }}
      className={className}
    >
      {completed ? <Check /> : active ? icon : null}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  className: PropTypes.string,
  completed: PropTypes.bool,
  icon: PropTypes.node,
};

const steps = ["Sent", "Received"];

export default function CheckingMandateTransfer({
  transferStep,
  setTransferStep
}) {
  const createdAtDate = new Date(
    transferStep.sentAt ?? "2024-07-18T13:03:48.232Z"
  );

  // Add 20 minutes (20 * 60 * 1000 milliseconds) to the createdAt time
  const expiredAtDate = new Date(createdAtDate.getTime() + 20 * 60000);

  // Convert the expiredAt Date object back to an ISO string
  const expiredAt = expiredAtDate.toISOString();

  const { timeRemaining, formatTime, stopTimer } = useTimeCounter(expiredAt);


  const viewAccountNumber=()=>{
    setTransferStep({...transferStep, step: 0, state: "sent" });
  }

  return (
    <main
      className="border rounded-3 col-lg- mx-auto ps-5 pe-3 pt-5 pb-4"
      style={{ backgroundColor: "#FAF9F9" }}
    >
      <div className="tw-text-center">
        <p className="fw-bold text-center mt-3">
          {transferStep.step === 2 ? "Mandate Confirmed!" : "Checking to confirm your transfer"}
        </p>
        <div className={transferStep.step === 2 ? 'tw-hidden' : 'tw-block'}>
            <p className="tw-font-[300] tw-text-[14px] my-3">
              It only takes a few minutes
            </p>
            <p className="tw-font-[500] tw-text-[18px] my-3">
              {timeRemaining > 0 ? formatTime(timeRemaining) : "00:00"}
            </p>
        </div>
      </div>
      <ThemeProvider theme={theme}>
        <Stack
          sx={{
            width: '100%',
            position: 'relative',
          }}
        >
          <Stepper
            alternativeLabel
            activeStep={transferStep.step}
            connector={<ColorlibConnector />}
            sx={{
              width: "100%",
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              margin: 0,
              position: 'relative',
              "& .MuiStep-root": {
                padding: {
                  xs: "0 20px",  // padding-right for extra-small screens
                  sm: "0 50px",  // padding-right for small screens
                  md: "0 100px", // padding-right for medium screens
                  lg: "0 90px",  // padding-right for large screens
                  xl: "0 80px", // padding-right for extra-large screens
                  "2xl": "0 130px", // padding-right for 2x extra-large screens
                },
              },
              "& .MuiStep-root:first-of-type": {
                paddingLeft: 0, // Ensure no extra padding on the first step
              }
            }}
          >
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel
                  StepIconComponent={(props) => (
                    <ColorlibStepIcon {...props} icon={index + 1} />
                  )}
                >
                  {label}
                </StepLabel>
              </Step>
            ))}
          </Stepper>
        </Stack>
      </ThemeProvider>
      <p
        className={
          "tw-text-[#033F4B] tw-font-[500] tw-text-[14px] tw-my-8 tw-cursor-pointer tw-text-center"
        }
        onClick={viewAccountNumber}
      >
        View Account Number
      </p>
    </main>
  );
}
