import { useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Paper, Chip, Button } from "@mui/material";
import { formatNumber } from "../../utils/formatNumber";

const tableHeaderData = [
  {
    id: "s_n",
    label: "S/N",
  },
  {
    id: "name",
    label: "Name",
  },
  {
    id: "logo",
    label: "Logo",
  },
  {
    id: "website_URL",
    label: "website URL",
  },
  {
    id: "service_fee",
    label: "Service Fee",
  },
  {
    id: "delivery_amount",
    label: "Delivery Amount",
  },
  {
    id: "vat",
    label: "VAT",
  },
  {
    id: "status",
    label: "status",
  },
  {
    id: "action",
    label: "Action",
  },
];

export default function MarketPlaceTable({ rows, handleOpenModal }) {

    const rowsPerPageOptions = [5, 10, 25]
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  return (
    <>
    <Paper sx={{ width: '100%', marginTop: '1rem', overflow: 'hidden' }}>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label="simple table" >
          <TableHead>
            <TableRow>
              {tableHeaderData.map((headCell, index) => (
                <TableCell
                  key={headCell.id + "_" + index}
                  className="fw-bold nowrap fs-6 text-capitalize"
                >
                  {headCell.label}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows
              ?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              ?.map((row, index) => (
                <TableRow
                  key={row.id + "__" + index}
                  hover
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {index + 1}.
                  </TableCell>
                  <TableCell component="th" scope="row">
                    {row.name}
                  </TableCell>
                  <TableCell style={{maxWidth: "125px"}}>
                    <img src={row.logo} alt={row?.name+"_logo"} className="w card-img-to" style={{width: "100px", maxWidth: "100px"}}/>
                  </TableCell>
                  <TableCell>{row.url}</TableCell>
                  <TableCell>{row?.service_fee}%</TableCell>
                  <TableCell>{formatNumber(row?.delivery_amount)}</TableCell>
                  <TableCell>{row?.tax}</TableCell>
                  <TableCell>
                   
                    <p className={`alert my-auto py-1 text-center rounded-pill ${row?.status? "alert-success": "alert-danger"}`}>{row?.status ? "Active" : "Inactive"}</p>
                  </TableCell>
                  <TableCell>
                  <Button
            variant="contained"
            onClick={() => handleOpenModal(row)}
            
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "white",
              },
            }}
          >
            Edit
          </Button>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Paper>
      {
        rows?.length > rowsPerPage && (
            <TablePagination
              rowsPerPageOptions={rowsPerPageOptions}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
        )
      }

      
    </>
  );
}
