import React, { useState } from "react";
import { ActiveNotificationIcon } from "../../../icons/icons";
import MembershipTable from "../../../components/Admin/Community/MembershipTable";
import OrderTable from "../../../components/Admin/Community/OrderTable";
import Options from "../../../components/Admin/Community/Options";
import Header from "../../../components/Shop/Header/Header";
import { useGetUsers, useGetAllCredit } from "../../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import MembershipHeader from "./MembershipHeader";
import OrderHeader from "./OrderHeader";
import NewUsersTable from "../../../components/Admin/Community/NewUsersTable";
import CreditSummary from "../../../components/Admin/Community/CreditSummary";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import SearchBar from "../../../utils/SearchBar";
import { IconButton } from "@mui/material";

export default function CommunityScreen({ handleClick }) {
  const [activeIndex, setActiveIndex] = useState(0);
  const [accountId, setAccountId] = useState("");
  const [open, setOpen] = useState({ status: false, role: "" });
  const [dataDetail, setDataDetail] = useState({})


  //queries hook
  const getUsers = useGetUsers(true);
  const getOrders = useGetAllCredit(1, "pending");
  const getNewUsers = useGetUsers(false);
  const allUsers = getUsers?.data?.data?.data;
  const newUsers = getNewUsers?.data?.data?.data;
  const orders = getOrders?.data?.data?.data;

  const handleOpen = (role, data) => {
    setDataDetail(data);
    setOpen({ ...open, status: true, role: role });

  };
  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleOnClick = (accountId) => {
 
    setAccountId(accountId);
  };
  const headerMenu = [{ name: "Membership" }, { name: "Order" }];
  const headerData = [
    { value: 0, label: "Transasction Count" },
    { value: allUsers?.length ?? "xx", label: "Total Number of customers" },
    { value: <>₦20, 000</>, label: "Total Transaction Amount" },
    { value: <>₦15, 000</>, label: "Total Settlement Received" },
  ];

  return (
    <>
      <main className="col-lg-11 mx-lg-auto mx-2">
        <p className="my-3 float-end">
          <IconButton>
            <ActiveNotificationIcon />
          </IconButton>
        </p>
        <Header
          data={
            activeIndex === 0
              ? headerData.filter((item, index) => index !== 1)
              : headerData
          }
        />
        <section className="col-lg-10 mx-auto col-md-11 header_content d-flex justify-content-between">
          {headerMenu.map((menu, index) => (
            <p
              key={index}
              className={`cursor-pointer ${index === activeIndex && "fw-bold"}`}
              onClick={() => setActiveIndex(index)}
            >
              {menu.name}
            </p>
          ))}
        </section>

        <SearchBar dataArray={allUsers} filterBy="firstName" />
        {activeIndex === 0 || activeIndex === 2 ? (
          <MembershipHeader
            activeIndex={activeIndex}
            setActiveIndex={setActiveIndex}
          />
        ) : (
          <OrderHeader />
        )}

        {activeIndex === 0 ? (
          getUsers.isLoading ? (
            <IsLoadingLoader />
          ) : getUsers.isError ? (
            <IsErrorLoader />
          ) : (
            <MembershipTable rows={allUsers} handleOpen={handleOpen} />
          )
        ) : activeIndex === 1 ? (
          getOrders.isLoading ? (
            <IsLoadingLoader />
          ) : getOrders.isError ? (
            <IsErrorLoader />
          ) : (
            <OrderTable rows={orders} handleOpen={handleOpen} />
          )
        ) : (
          activeIndex === 2 &&
          (getNewUsers.isLoading ? (
            <IsLoadingLoader />
          ) : getNewUsers.isError ? (
            <IsErrorLoader />
          ) : (
            <NewUsersTable rows={newUsers} handleOpen={handleOpen} />
          ))
        )}

        <DrawerBox open={open.status} handleClose={handleClose}>
          {open.role === "user" ? (
            <Options
              profileData={dataDetail}
              role={open.role}
              handleClick={handleClick}
            />
          ) : (
            open.role === "order" && <CreditSummary order={dataDetail} handleClick={handleClick} handleCloseSummaryModal={handleClose}/>
          )}
        </DrawerBox>
      </main>
    </>
  );
}
