import React from "react";
import "./header.css";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route";
import { Swiper, SwiperSlide } from 'swiper/react';

// import required modules
import { Keyboard, Scrollbar, Navigation, Pagination } from 'swiper/modules';


// Import Swiper styles
import 'swiper/css';
import 'swiper/css/scrollbar';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

function Header({ data, handleOpenDialog }) {
  const navigate = useNavigate();

  const purposeFn = (val) => {
    if (val === "upgrade_subscription_plan") {
      navigate(ROUTES.subscription);
    } else if (val === "accrued_dept") {
      navigate(ROUTES.credit);
    } else if (val === "next_due_date") {
      navigate(ROUTES.credit);
    }
  };

  // const goToUpgrade = () => {
  //   handleOpenDialog("upgradePlan");
  // };

  return (
    <>
      <section className="header_container">
        <section className="card_container">
        <Swiper 
        slidesPerView={2}
        spaceBetween={40}
        centeredSlides={false}
        keyboard={{
          enabled: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 100,
          },
          768: {
            slidesPerView: 3,
            spaceBetween: 120,
          },
          1024: {
            slidesPerView: 4,
            spaceBetween: 60,
          },
        }}
        navigation={true}
        pagination={{
          clickable: true,
        }}
        modules={[Keyboard, Navigation, Pagination]}
        className="mySwiper py-3"
        >
          {data?.map((item, index) => {
            return (
              <SwiperSlide key={index}>
              <div
                className={`card border-0 col-4 text-center shop_now_menu_btn mx-3 px-2 my-2 rounded shadow-sm`}
                key={index}
                style={{
                  boxShadow: "0px 4px 8px 0px #31715929",
                }}
              >
                <p className="fw-medium my-auto" style={{fontSize: "15px"}}>{item.label}</p>
                <h6 className="fw-bold">{item.value}</h6>
                <p className="my-auto" style={{color: "green", fontWeight: 500}}>
                  <i
                    className="cursor-pointer"
                    
                    onClick={() => purposeFn(item.key)}
                  >
                    {item.purpose}
                  </i>
                </p>
              </div>
              </SwiperSlide>
            );
          })}
          </Swiper>
        </section>
      </section>
    </>
  );
}

export default Header;
