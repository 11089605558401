
import { Button } from 'antd';
import React from 'react'
import { motion } from "framer-motion"
import { useResponsiveSize } from "../../components/hooks/useResponsiveSize";

export default function ShopMenu({setBill, bill, toggleBillMenu}) {

  const responsiveSize = useResponsiveSize({});

    const handleSelectBill=(val)=>{
      setBill(val);
      responsiveSize==='sm' && toggleBillMenu();
    }
  return (
    <>
        <motion.div className='border-end'
        initial={{ x: -300 }}
        animate={{ x: 0 }}
        exit={{ x: -300 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "absolute",
          minHeight: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "17rem",
          zIndex: 10
        }}
       
        >
          
        <div className="d-flex flex-column gap-3 pe-3">
            <Button
            className={`${bill==='supermarkets' && "border-secondary"}`}
            size={"large"}
            style={{
              borderRadius: "20px",
              textTransform: "capitalize"

            }}
            onClick={()=>handleSelectBill('supermarkets')}
            >
              Supermarkets
            </Button>
            <Button
            className={`${bill==='onlineMarketplaces' && "border-secondary"}`}
            size={"large"}
            style={{
                borderRadius: "20px",
                textTransform: "capitalize"
  
              }}
            onClick={()=>handleSelectBill('onlineMarketplaces')}
            >
              Online Marketplaces
            </Button>
            <Button 
            className={`${bill==='pharmacy' && "border-secondary"}`}
            size={"large"}
            style={{
                borderRadius: "20px",
                textTransform: "capitalize"
  
              }}
            onClick={()=>handleSelectBill('pharmacy')}
            >
              Pharmacies
            </Button>
            <Button 
            className={`${bill==='foodStuffMarket' && "border-secondary"}`}
            size={"large"}
            style={{
                borderRadius: "20px",
                textTransform: "capitalize"
  
              }}
            onClick={()=>handleSelectBill('foodStuffMarket')}
            >
              Food Stuff Markets
            </Button>

          </div>
        </motion.div>
    </>
  )
}
