import { useState } from "react";
import { CircularProgress } from '@mui/material'
import { changePaymentMethod } from "../../redux/mutation/postMutation";
import { useUserData } from "../hooks/useUserData";
import { useResponseModal } from "../../redux/hooks/zustands/useResponseModal";

const PaymentMethods = ({ setPaymentMethod, data, updateData }) => {
  const [isLoading, setIsLoading] = useState({status: false, name: ""});

  const { openModal } = useResponseModal();

  const user = useUserData();


  // console.log(data)

  const changeMethod =async(method) => {
    setIsLoading({status: true, name: method})
    const data = await executeMethodChange(method)
    if(data?.status){
      if(data?.data?.payment_type !== "transfer"){
        window.location.href = data?.data?.link
      }else{
        await updateData(data?.data); //update the data here
        setPaymentMethod(method);
      }
    }
    else{
      openModal({status: false, message: data?.message})
    }
    setIsLoading({status: false, name: ""})



  };



  // const executeMethodChange=async(method)=>{
  //   //make request to backend here
  //   const res = await changePaymentMethod({
  //     "transaction_id": data?.display_text,
  //      "payment_type": method, 
  //      "email": user?.data?.email ?? "jfixcoding@gmail.com", 
  //      "userId": user?.data?.id //"b406f9e8-fb58-4ae9-bca6-c6b20e9b5549"
  // }).then((res)=>{
  //   return res?.data?.data
  // }).catch((error)=>{
  //   console.log(error?.response?.data?.message ?? error?.message)
  //   return error?.response?.data?.message ?? error?.message
  // })
  // // .then((res)=>{
  // //   console.log(res)
  // //   // return res?.data?.data
  // // })

  // console.log(res);

  //   // return  method === "transfer"
  //   // ? {
  //   //     account_expires_at: "2024-06-28T13:51:29.000Z",
  //   //     account_name: "TEST-MANAGED-ACCOUNT",
  //   //     account_number: "1231619141",
  //   //     amount: 900,
  //   //     bank_id: 24,
  //   //     bank_name: "Test Bank",
  //   //     description: "Payment for subscription",
  //   //     display_text: "Please make a transfer to the account specified",
  //   //     payment_type: "transfer",
  //   //     reference: "r34xfdq6j8",
  //   //     transaction_id: "6896ced4-32c9-410f-b35a-e26ab02d7d3a",
  //   //   }
  //   // : {
  //   //     amount: 400,
  //   //     reference: "pay_7576b760e40d2acc",
  //   //     link: "https://checkout.mono.co/ODSNHHQ95363",
  //   //     payment_type: method,
  //   //     description: "Payment for subscription",
  //   //   }
  // }


  const executeMethodChange = async (method) => {
    try {
        const response = await changePaymentMethod({
            "transaction_id": data?.id,
            "payment_type": method,
            "email": data?.email ?? user?.data?.email,
            "userId": user?.data?.id //"b406f9e8-fb58-4ae9-bca6-c6b20e9b5549"
        });

        // console.log(response);
        const res = response?.data?.data;
        const status = response?.data?.data? true: false
        return {data: res, status, message: response?.data?.message};
    } catch (error) {
      console.log(error)
        const errorMessage = error?.response?.data?.message ?? "Unexpected error! please try again";
        return {message: errorMessage, status: false};
    }
};



  const MethodButton=({children, method, loading})=>{
    return <>
     <div className={`tw-bg-[#033F4B] tw-text-white tw-rounded-[10px] p-3 cursor-pointer position-relative`} onClick={() => !loading && changeMethod(method)} style={
        loading? {
            cursor: "not-allowed",
            opacity: 0.5
        }: {
            opacity: 1
        }
     }>
     
            <p className="my-auto tw-font-[400] tw-text-[16px]">
              {children}
              
            </p>
            {
                loading && (
                    <CircularProgress
                size={24}
                sx={{
                  position: 'absolute',
                  top: '30%',
                  left: '50%'
                }}
              />
                )
            }
          </div>
        
    </>
  }

  return (
    <>
      <div className=" px-md-3 mx-auto">
        <h5 className="text-center mb-3">Choose how you want to make payment</h5>
        <div className="d-flex flex-column gap-3">
            <MethodButton method={"card"} loading={isLoading?.name === 'card' && isLoading?.status} className="tw-flex tw-flex-wrap">
            Pay with Card*{" "}
              <small style={{ color: "#F9BABA", fontSize: "12px" }}>
                This may attract additional charges
              </small>
            </MethodButton>
          
            <MethodButton method={"ussd"} loading={isLoading?.name === 'ussd' && isLoading?.status} className="tw-flex tw-flex-wrap">
            Pay with USSD*{" "}
            <small style={{ color: "#F9BABA", fontSize: "12px" }}>
                This may attract additional charges
              </small>
            </MethodButton>
              {/* {
                user?.status && ( */}
            <MethodButton method={"direct_debit"} loading={isLoading?.name === 'direct_debit' && isLoading?.status}>
            Pay with Direct Debit
            </MethodButton>
                {/* )
              } */}

          <MethodButton method={"transfer"} loading={isLoading?.name === 'transfer' && isLoading?.status}>
          Pay with Bank Transfer
          </MethodButton>
        </div>
      </div>
    </>
  );
};

export default PaymentMethods;
