import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { styled } from '@mui/material/styles';
import useWindowSize from "../../hooks/useWindowSize"
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
export default function KYCStepper({activeStep, steps}) {
  const windowSize = useWindowSize();

  const responsiveScreenWidth = windowSize?.width >= 1024
  ? 'lg'
  : windowSize?.width > 600 && windowSize?.width < 1024
  ? 'md'
  : windowSize?.width <= 600
  ? 'sm'
  : null;



  const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#033F4B',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#033F4B',
      },
      [`& .${stepConnectorClasses.completed}`]: {
        backgroundColor: '#033F4B',
      },
    },
    
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderRadius: 1,
      borderLeftWidth: 2, // Adjust as needed
    borderLeftStyle: 'solid',
    height: '100%',
    },
  }));
  return (
    <Box>
      {/* sx={(responsiveScreenWidth==='lg' || responsiveScreenWidth==='md')?{height: '80vh'}: responsiveScreenWidth==='sm'&&{width: '100%'}} */}
      <Stepper activeStep={activeStep} orientation={(responsiveScreenWidth==='lg' || responsiveScreenWidth==='md')?'vertical': 'horizontal'} connector={<QontoConnector />} sx={(responsiveScreenWidth==='lg' || responsiveScreenWidth==='md')?{height: '80%', maxHeight: "100vh"}: responsiveScreenWidth==='sm'&&{width: '100%'}}>
        {steps.map((label, index) => (
          <Step key={index} sx={{
            '& .MuiStepLabel-root .Mui-completed': {
              color: '#033F4B',
            },
            '& .MuiStepLabel-root .Mui-active': {
              color: '#033F4B',
            }
          }}>
            <StepLabel></StepLabel>
          </Step>
        ))}
      </Stepper>
    </Box>
  );
}
