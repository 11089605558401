import { NumbersRounded } from "@mui/icons-material";
import { createSlice } from "@reduxjs/toolkit";
import { NumberSchema } from "yup";


const superStoreSlice = createSlice({
    name: "superStore",
    initialState: {
        allProducts: [],
        totalAmount: 0,
        storeLocation: ""
    },
    reducers: {
        addToCart: (state, action)=>{
            state.allProducts.push(action?.payload)

            state.totalAmount = state.allProducts.reduce((acc, item)=> acc + (item.price+1000), 0)
        },
        removeItem:(state, action)=>{
            //console.log(action.payload);
            const id = action.payload
            if(state?.allProducts?.length===1){
                state.allProducts = []
            }
            else{
                let filteredProducts = state?.allProducts?.filter((item, itemIndex)=>item?.id!==id);
    
                state.allProducts = filteredProducts
                
                state.totalAmount = Number(state.allProducts.reduce((acc, item)=> acc + (item.price+1000), 0).toFixed(2))
    
            }
           },
        changeStoreLocation:(state, action)=>{
            state.storeLocation = action?.payload;
        },
        manipulateQty: (state, action)=>{
            const {itemId, method} = action.payload;
            const itemIndex = state.allProducts?.findIndex(item=>item?.id === itemId);

            if(method==='inc'){
                state.allProducts[itemIndex].quantity++
        }
            else if (method==='dec'){
                state.allProducts[itemIndex].quantity===1?state.allProducts[itemIndex].quantity=1: state.allProducts[itemIndex].quantity--
            }
            //this is to update the total price after the quantity has been manipulated
            const totalPrice = Number(state.allProducts?.reduce((acc, curr)=> acc + (parseFloat(curr.price * curr?.quantity) + parseFloat(1000)), 0).toFixed(2))
            state.totalAmount = totalPrice;

        },
    }
})

export const superStoreAction = superStoreSlice.actions;
export default superStoreSlice.reducer;