import React from 'react'
import DashboardScreen from '../../screens/Admin/DashboardScreen/DashboardScreen'

function Dashboard({handleClick}) {
  return (
    <>
        <main>
            <DashboardScreen handleClick={handleClick}/>
        </main>
    </>
  )
}

export default Dashboard