import React from 'react'
import KycScreen from '../screens/AuthScreen/KYCScreen/KycScreen'

function Kyc({handleClick}) {
  return (
    <>
        <KycScreen handleClick={handleClick}/>
    </>
  )
}

export default Kyc