/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from "react"
import { TextField } from "@mui/material"
export default function NIN_UPDATE({formik, setenableNext}){
  

    useEffect(()=>{
        if(formik?.values?.nin?.length === 11){
            setenableNext(true)
        }
        else{
            setenableNext(false)
        }
    }, [formik?.values])


    const onChangeNIN=(e)=>{

      formik.setFieldValue("nin", e.target.value)
  
  
    }


    return(
        <div className="my-3">
         <p className="text-center kyc_title" style={{color: "#323C4D",fontSize: "20px", fontStyle: "normal",fontWeight: "500",lineHeight: "normal"}}>NIN Update</p>
        <div className="nin" style={{paddingBottom: "20px"}}>
          <div className="label" style={{marginBottom: "4px"}}>
            <label htmlFor="nin">NIN *</label>
          </div>
          <TextField
            type="number"
            name="nin"
            value={formik?.values.nin}
            error={formik?.touched.nin && formik?.errors.nin}
            onChange={onChangeNIN}
            onBlur={formik?.handleBlur}
            placeholder="Enter Your NIN"
            maxlength="11"
            
            fullWidth
            variant="outlined"
            className="rounded-pill"
            size="small"
            InputProps={{
              style: {
                borderRadius: "30px",
              },
            }}
          />
          <small style={{fontSize: "13px"}} className="text-danger">
            {
              formik.touched.nin && formik.errors.nin
            }
          </small>
        </div>
      </div>
    )
}