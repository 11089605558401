import React from 'react'
import Header from "./Header";
export default function WorkInformation({data, actionFn, actionLoading, actionSuccess}) {



    return (
        <>
            <main>
            <Header title={"Work Information"} actionText={"Approve"} actionFn={(status)=>actionFn('employmentDetails', status)} actionLoading={actionLoading} actionSuccess={actionSuccess}/>
            <section>
                {/* work information */}
              <section className="col-lg-11 mx-auto">
                <div className="row mx-1 my-1">
                  <div className="col-lg-4 col-4">
                    <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                      Occupation:
                    </p>
                  </div>
                  <div className="col-lg-6 col-6">
                    <small className="">{!!data?.occupation  ? data?.occupation:"Not Available"}</small>
                  </div>
                </div>
              </section>
            {/* Work email */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Work email:</p>
                              </div>
                              <div className="col-lg-6 col-6">
                                  <small className="">{!!data?.workEmail? data?.workEmail : "Not Available"}</small>
                              </div>
                  </div>
              </section>
            {/* Monthly Salary */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Monthly Salary</p>
                              </div>
                              <div className="col-lg-6 col-6">
                                  <small className="">{!!data?.salary ? data?.salary : "Not Available"}</small>
                              </div>
                  </div>
              </section>
            {/* Employer */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Employer:</p>
                              </div>
                              <div className="col-6">
                                  <small className="">{!!data?.employer?data?.employer : "Not Available"}</small>
                              </div>
                              {/* <div className="col-2">
                              <small className={data?.employerMatch?'text-success':'text-danger'}>
                                {
                                  data?.employerMatch?'Match':'Not Match'
                                }
                                </small>
                          </div> */}
                  </div>
              </section>
            {/* Employer’s Address: */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Employer’s Address:</p>
                              </div>
                              <div className="col-6">
                                  <small className="">{!!data?.employeraddress? data?.employeraddress : "Not Available"}</small>
                              </div>
                  </div>
              </section>
            </section>
          </main>
        </>
      )
}
