import ProfileContent from "../../components/Profile/ProfileContent";
import { useUserData } from "../../components/hooks/useUserData";

export default function ProfileScreen({ handleClick }) {
  const { data } = useUserData();
  return (
    <>
      <main className="col-lg-10 mx-auto my-3">
        <section className="row mx-2">
          <section className="col-lg-6"></section>
          <ProfileContent detail={data} handleClick={handleClick} />
        </section>
      </main>
    </>
  );
}
