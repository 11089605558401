import React from "react";
import Header from "./Header";

export default function PersonalInformation({ data, actionFn, actionLoading, actionSuccess }) {

  const fn=(status)=>{
    actionFn('bioData', status)
  }


  return (
    <>
      <main>
        <Header title={"Personal Information"} actionText={"Approve"} actionFn={fn} actionLoading={actionLoading} actionSuccess={actionSuccess}/>
        <section>
          {/* name */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-lg-4 col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Name:
                </p>
              </div>
              <div className="col-lg-6 col-6">
                <small className="">{data?.name ?? "Not Available"}</small>
              </div>
            </div>
          </section>
          {/* phone */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Phone:
                </p>
              </div>
              <div className="col-lg-6 col-6">
                <small className="">{data?.phone ?? "Not Available"}</small>
              </div>
              <div className="col-lg-2 col-2">
                <small
                  className={data?.phoneMatch ? "text-success" : "text-danger"}
                >
                  {data?.phoneMatch ? "Match" : "Not Match"}
                </small>
              </div>
            </div>
          </section>
          {/* Email */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1 flex-wrap">
              <div className="col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Email:
                </p>
              </div>
              <div className="col-8">
                <small className="w-100"
                style={{
                  wordWrap: "break-word",
                }}
                >{data?.email ?? "Not Available"}</small>
              </div>
            </div>
          </section>
          {/* Gender */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Gender:
                </p>
              </div>
              <div className="col-6">
                <small className="">{data?.gender ?? "Not Available"}</small>
              </div>
            </div>
          </section>
          {/* Marital Status */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Marital Status:
                </p>
              </div>
              <div className="col-6">
                <small className="">
                  {data?.maritalStatus ?? "Not Available"}
                </small>
              </div>
            </div>
          </section>
          {/* Date of birth */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Date of Birth:
                </p>
              </div>
              <div className="col-6">
                <small className="">{data?.dob ?? "Not Available"}</small>
              </div>
              <div className="col-lg-2 col-2">
                <small
                  className={data?.dobMatch ? "text-success" : "text-danger"}
                >
                  {data?.dobMatch ? "Match" : "Not Match"}
                </small>
              </div>
            </div>
          </section>
          {/* nin */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  NIN:
                </p>
              </div>
              <div className="col-6">
                <small className="">{data?.nin ?? "Not Available"}</small>
              </div>
              <div className="col-2">
                <small
                  className={data?.ninMatch ? "text-success" : "text-danger"}
                >
                  {data?.ninMatch ? "Match" : "Not Match"}
                </small>
              </div>
            </div>
          </section>
        </section>
      </main>
    </>
  );
}
