import React, { useState, useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import SideNav from "../../components/LandingPage/Navbar/SideNav";
import HomeNav from "./HomeNav/HomeNav";
import { Button, TextField } from "@mui/material";
import { ROUTES } from "../../routes/route";
import CircularProgress from "@mui/material/CircularProgress";
import { useUserData } from "../../components/hooks/useUserData";
import styled from "styled-components";
import useWindowSize from "../../components/hooks/useWindowSize";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";
import { Modal } from "antd";
import PersonalInformation from "../../components/Auth/Kyc/KycVerification/PersonalInformation/PersonalInformation";
import { useFormik } from "formik";
import NIN_UPDATE from "../../components/Auth/Kyc/KycVerification/NINVerification/NIN_Update";
import AddressInformation from "../../components/Auth/Kyc/KycVerification/AddressInformation/AddressInformation";
import WorkInformation from "../../components/Auth/Kyc/KycVerification/WorkInformation/WorkInformation";
import BankStatement from "./../../components/Auth/Kyc/KycVerification/BankStatement/BankStatement";
import SetTransactionPin from "./../../components/Auth/TransactionPin/SetTransactionPin";
import { useUpdateProfile } from "../../redux/mutation/postMutation";
import { useQueryClient } from "@tanstack/react-query";
import * as yup from "yup";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import { logoutFn } from "../../utils/logout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button as AntButton } from "antd"

function HomeLayout({ handleClick }) {
  const [openSideNav, setOpenSideNav] = useState(false);
  const [enableNext, setenableNext] = useState(false);
  const navigate = useNavigate({});

  const updateProfileMutation = useUpdateProfile({});

  const queryClient = useQueryClient();

  const [open, setOpen] = useState(false);
  const user = useUserData();
  const { width } = useWindowSize();

  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState({ status: false, type: "" });

  const location = useLocation({}).pathname;

  const formik = useFormik({
    initialValues: {
      image: user?.data?.profile_picture ?? "",
      otp: "",
      gender: user?.data?.bioData?.gender ?? "",
      maritalStatus: user?.data?.bioData?.maritalStatus ?? "",
      dateOfBirth: user?.data?.bioData?.dob ?? "",
      nin: user?.data?.bioData?.nin ?? "",
      homeAddress: user?.data?.contact?.address ?? "",
      city: user?.data?.contact?.city ?? "",
      state: user?.data?.contact?.state ?? "",
      occupation: user?.data?.employmentDetails?.occupation ?? "",
      workEmail: user?.data?.employmentDetails?.workEmail ?? "",
      monthlySalary: user?.data?.employmentDetails?.salary ?? "",
      employer: user?.data?.employmentDetails?.employer ?? "",
      employerAddress: user?.data?.employmentDetails?.employeraddress ?? "",
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      const data =
        isModalOpen.type === "bio_data"
          ? {
              key: "bioData",
              bioData: {
                gender: values.gender,
                maritalStatus: values.maritalStatus,
                dob: values.dateOfBirth,
                nin: values.nin,
              },
            }
          : isModalOpen.type === "address"
          ? {
              key: "contact",
              contact: {
                address: values.homeAddress,
                city: values.city,
                state: values.state,
              },
            }
          : isModalOpen.type === "work_info"
          ? {
              key: "employmentDetails",
              employmentDetails: {
                occupation: values.occupation,
                workEmail: values.workEmail,
                salary: values.monthlySalary,
                employer: values.employer,
                employeraddress: values.employerAddress,
              },
            }
          : isModalOpen.type === "nin"
          ? {
              key: "nin",
              nin: values?.nin,
            }
          : {};

      submitKycFunction(data);
    },
    validationSchema: yup.object().shape({
      nin: yup
        .string()
        .required("NIN is required")
        .min(11, "NIN must be 11 digits")
        .max(11, "NIN must be 11 digits"),
    }),
  });

  //useEffect to be confirming if the account is approved

  useEffect(() => {
    if (user?.data?.kyc_stepper <= 8 && !user?.data?.status && !open) {
      setOpen(true);
    } else if ((user?.data?.kyc_stepper > 8 || user?.data?.status) && open) {
      setOpen(false);
    } else if (
      user?.data?.kyc_stepper >= 7 &&
      user?.data?.status &&
      user?.data?.pin_set === false
    ) {
      showModal("pin");
    }
  }, [user?.data, open]);

  useEffect(() => {
    if (
      !user?.data?.is_declined &&
      user?.kyc_status &&
      user?.data?.subscriptions?.length < 1
    ) {
      navigate(ROUTES.subscription);
    } else {
      // navigate(ROUTES.subscription)
      // console.log(user?.data?.subscriptions?.length)
    }
  }, [user?.data?.subscriptions]);

  const toggleSideNav = (param) => {
    setOpenSideNav(param);
  };

  const showModal = (type) => {
    setIsModalOpen({ ...isModalOpen, status: true, type });
  };

  const handleOk = () => {
    formik.submitForm();
  };

  const handleCancel = (ff) => {
    setIsModalOpen({ ...isModalOpen, status: false });
  };

  useEffect(() => {
    width > 991 && setOpenSideNav(false);
  }, [width]);

  const declinedResDetails = [
    {
      name: "BIO Data",
      key: "bio_data",
      status: user?.data?.account_status?.bioData,
    },
    {
      name: "Address",
      key: "address",
      status: user?.data?.account_status?.contact,
    },
    {
      name: "Work information",
      key: "work_info",
      status: user?.data?.account_status?.employmentDetails,
    },
    {
      name: "Bank Statement",
      key: "bank_statement",
      status: user?.data?.account_status?.bankStatement,
    },
    {
      name: "NIN Verification",
      key: "nin",
      status: user?.data?.account_status?.ninIdentity,
    },
  ];

  //function that calls the kyc mutation to save kyc
  const submitKycFunction = (data) => {
    setIsLoading(true);

    updateProfileMutation.mutate(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["me"] });
      },
    });
  };

  useEffect(() => {
    if (updateProfileMutation?.isLoading) {
      setIsLoading(true);
    } else if (updateProfileMutation?.isError) {
      setIsLoading(false);
      const res = updateProfileMutation?.error?.response?.data;
      res?.statusCode === 500
        ? handleClick("An unexpected error occurred!", "error", {
            vertical: "top",
            horizontal: "right",
          })
        : handleClick(res?.message, "error", {
            vertical: "top",
            horizontal: "right",
          });
    } else if (updateProfileMutation?.isSuccess) {
      setIsLoading(false);

      handleClick(updateProfileMutation?.data?.data?.message, "success", {
        vertical: "top",
        horizontal: "right",
      });
      // setenableNext(false);
      handleCancel();
    }
  }, [updateProfileMutation?.status]);

  //logout function
  const [modal, contextHolder] = Modal.useModal({});
  const confirmLogout = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to proceed to logout",

      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <AntButton onClick={ () => {
        logoutFn("USER");
        navigate(ROUTES.login);
      }}  style={{
                            background: "#033F4B",
                            color: "#fff",
                          }} >Confirm</AntButton>
        </>
      )
    });
  };

  return (
    <>
      <main
        className="position-relative"
        style={{ backgroundColor: "#FEF9F6", minHeight: "100vh" }}
      >
        {!location.includes("/kyc") && (
          <>
            <HomeNav
              toggleSideNav={toggleSideNav}
              openSideNav={openSideNav}
              userDetail={user?.data}
              confirmLogout={confirmLogout}
            />
            {
              width >= 991 && (
                <SideNav
                  userDetail={user?.data}
                  toggleSideNav={toggleSideNav}
                  confirmLogout={confirmLogout}
                />

              )
            }
          </>
        )}
        <section onClick={() => toggleSideNav(false)} className="tw-z-0">
          {!location.includes("/kyc") ? (
            open ? (
              <>
                <div className="d-flex justify-content-center align-items-center mt-3 mx-2">
                  <div
                    className="card alert_container"
                    style={{
                      padding: "20px 16px 16px 16px",
                      backgroundColor: "#F5FBF8",
                      boxShadow: "0px 4px 8px 0px #31715929",
                      zIndex: 99,
                    }}
                  >
                    <div>
                      <div className="d-flex gap-3 align-items-center">
                        {user?.data?.is_declined ? (
                          <ReportProblemIcon
                            fontSize="large"
                            sx={{ color: "red" }}
                          />
                        ) : (
                          <CheckCircleIcon
                            fontSize="large"
                            sx={{ color: "#52BD94" }}
                          />
                        )}

                        <h4
                          style={{
                            fontWeight: 500,
                            fontSize: "21px",
                            lineHeight: "22px",
                          }}
                        >
                          {user?.data?.is_declined
                            ? "Application not approved"
                            : "KYC Completed successfully"}
                        </h4>
                      </div>
                      <div className="my-3">
                        {!user?.data?.is_declined ? (
                          <p
                            className="text-muted"
                            style={{ fontSize: "17px", fontWeight: 400 }}
                          >
                            You will be notified on the status of your KYC after
                            approval.
                          </p>
                        ) : (
                          <div>
                            <p
                              className="text-mute"
                              style={{ fontSize: "17px", fontWeight: 400 }}
                            >
                              {user?.data?.account_status?.comment ??
                                "KYC not completed!!"}
                            </p>

                            <p
                              className="text-muted"
                              style={{ fontSize: "13px" }}
                            >
                              {" "}
                              Record Overview
                            </p>
                            <div>
                              {declinedResDetails?.map((item, index) => (
                                <div
                                  key={index + "____" + item?.key}
                                  className={`rounded-pill my-3 d-flex align-items-center justify-content-between cursor-pointer p-3 alert ${
                                    item?.status === "valid"
                                      ? "alert-success"
                                      : item?.status === "updated"
                                      ? "alert-primary"
                                      : "alert-danger"
                                  }`}
                                  style={{ height: "2vh" }}
                                  onClick={() =>
                                    item?.status !== "approved" &&
                                    showModal(item?.key)
                                  }
                                >
                                  {item?.name}
                                  {item?.status === "valid" ? (
                                    <CheckCircleIcon
                                      fontSize="2.5vh"
                                      sx={{ color: "#52BD94" }}
                                    />
                                  ) : item?.status === "updated" ? (
                                    <ReportProblemIcon
                                      fontSize="2.5vh"
                                      sx={{ color: "blue" }}
                                    />
                                  ) : (
                                    <ErrorOutlineIcon
                                      fontSize="2.5vh"
                                      sx={{ color: "red" }}
                                    />
                                  )}
                                </div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* </Backdrop> */}
                <div
                  style={{ filter: "blur(8px)" }}
                  className="shop_now_outlet"
                >
                  <Div>
                    <Outlet />
                  </Div>
                </div>
              </>
            ) : (
              <Div>
                <Outlet />
              </Div>
            )
          ) : (
            <Div>
              <Outlet />
            </Div>
          )}
        </section>
        {!location.includes("/kyc") &&
          (openSideNav ? (
            <SideNav
              userDetail={user?.data}
              toggleSideNav={toggleSideNav}
              confirmLogout={confirmLogout}
            />
          ) : (
            ""
          ))}
      </main>

      <Modal
        open={isModalOpen.status}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={
          isModalOpen?.type !== "bank_statement" && isModalOpen?.type !== "pin" && [
            <Button
              key={"update_btn"}
              onClick={handleOk}
              variant="contained"
              color="primary"
              className="mx-2 position-relative"
              disabled={!enableNext || isLoading}
              sx={
                isLoading
                  ? { cursor: "not-allowed" }
                  : {
                      backgroundColor: "#033F4B",
                      color: "#fff",
                      "&:hover": {
                        backgroundColor: "#033F4B",
                        color: "#fff",
                      },
                    }
              }
            >
              Update
              {isLoading && (
                <CircularProgress
                  size={26}
                  sx={{
                    position: "absolute",
                    // top: '45%',
                    left: "50%",
                  }}
                />
              )}
            </Button>,
          ]
        }
      >
        {isModalOpen?.type === "bio_data" ? (
          <PersonalInformation formik={formik} setenableNext={setenableNext} />
        ) : isModalOpen?.type === "nin" ? (
          <NIN_UPDATE formik={formik} setenableNext={setenableNext} />
        ) : isModalOpen?.type === "address" ? (
          <AddressInformation formik={formik} setenableNext={setenableNext} />
        ) : isModalOpen?.type === "work_info" ? (
          <WorkInformation formik={formik} setenableNext={setenableNext} />
        ) : isModalOpen?.type === "bank_statement" ? (
          <BankStatement
            handleClick={handleClick}
            role={"update"}
            submitKycFunction={submitKycFunction}
            handleClose={handleCancel}
            isUpdating={isLoading}
          />
        ) : isModalOpen?.type === "pin" ? (
          <SetTransactionPin
            handleClick={handleClick}
            handleCloseModal={handleCancel}
          />
        ) : (
          "Coming soon"
        )}
      </Modal>

      {contextHolder}
    </>
  );
}

export default HomeLayout;

const Div = styled.div`
  @media only screen and (min-width: 991px) {
    margin-left: 40vh;
  }
`;
