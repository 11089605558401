import { useFormik } from "formik";
import styled from "styled-components";
import AuthButton from "../../Auth/AuthButton/AuthButton";
import { useEffect, useState } from "react";
import * as yup from "yup"
import { useStaffLogin } from "../../../redux/mutation/AdminPostMutation";
import { ROUTES } from "../../../routes/route";
import { useNavigate } from "react-router";
import Logo from "../../logo/logo";
import { API_URL } from "../../../redux/api_urls";
import { Input } from "antd";
function StaffLogin({handleClick, pageTitle}) {
    const [isLoading, setIsLoading] = useState(null);
    const staffLogin = useStaffLogin();
    const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      setIsLoading(true);
      if(pageTitle==='Admin'){
        staffLogin.mutate({url:API_URL.login, payload: values});
      }
      else if(pageTitle==='Merchant'){
        staffLogin.mutate({url:API_URL.login, payload: values});
      }
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required("Email can not be empty!")
        .email("Invalid email address!"),
      password: yup.string().required("password is required!"),
    }),
  });

  //login mutation useEffect
  useEffect(()=>{
    if (staffLogin.isLoading) {
      setIsLoading(true);
    } else if (staffLogin.isSuccess) {
      setIsLoading(false);
      let response = staffLogin?.data?.data
     
      if(pageTitle==='Admin'){
        localStorage.setItem('adminAccessToken', response?.data?.accessToken);
        localStorage.setItem('adminRefreshToken', response?.data?.refreshToken);
      }
      else if(pageTitle==='Merchant'){
        localStorage.setItem('merchantAccessToken', response?.data?.accessToken);
        localStorage.setItem('merchantRefreshToken', response?.data?.refreshToken);
      }
     
      // handleClick("Login successful", "success")
      // pageTitle==='Admin'?navigate(ROUTES.adminDashboard): pageTitle==='Merchant'&&navigate(ROUTES.merchantDashboard)
      pageTitle==='Admin'?navigate(ROUTES.e_mandate): pageTitle==='Merchant'&&navigate(ROUTES.merchantDashboard)
      
    } else if (staffLogin.isError) {
      setIsLoading(false);
      let message = staffLogin?.error?.response?.data?.message
      let message2 = staffLogin?.error?.message
      handleClick(message??message2, "error")
   
    }
  }, [staffLogin.status])
  return (
    <>
      <Main>
        <section className="col-lg-4 col-md-7 col-11">
          <div className="header text-center">
            <span className="cursor-pointer tw-mx-auto"  onClick={() => navigate(ROUTES.landing_page)}>
              <Logo />
            </span>
            <h4 className="fw-bold fs-2 mt-2">Vouch {pageTitle} Login</h4>
          </div>
          <br />
          <form onSubmit={formik.handleSubmit}>
            <div className="form_input my-3">
              <label htmlFor="email">{pageTitle} Email</label>
              <input
                type="email"
                name="email"
                className="form-control rounded-pill py-3 tw-text-lg"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                placeholder="Enter email"
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.email && formik.errors.email}
              </small>
            </div>
            <div className="form_input my-3">
              <label htmlFor="password">Password</label>
              <Input.Password name="password" className="tw-rounded-full tw-py-3 tw-text-lg" placeholder="Password" onChange={formik.handleChange}
               onBlur={formik.handleBlur}
               value={formik.values.password}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.password && formik.errors.password}
              </small>
            </div>
            <div className="form_input my-3">
              <AuthButton text="Login" loading={isLoading}/>
            </div>
          </form>
        </section>
      </Main>
    </>
  );
}

export default StaffLogin;

const Main = styled.main`
  background: #fbebb5;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;
