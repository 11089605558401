import { useEffect, useState } from "react";

export const useGetPosition=()=>{
    const [response, setResponse] = useState({status: false, msg: "", latitude: 0, longitude: 0});
    useEffect(()=>{
        if(navigator.geolocation){
                navigator.geolocation.getCurrentPosition((position)=>{
                    const latitude = position?.coords.latitude;
                    const longitude = position?.coords.longitude;
                    setResponse({...response, status: true, msg: "success", latitude, longitude})
                }, ()=>{
                    setResponse({...response, status: false, msg: "Unable to retrieve your location"})
                });
            }
            else{
                setResponse({...response, status: false, msg: "Unable to retrieve your location"})
            }
    }, [])

        return response;
}