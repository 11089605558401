import { GetMethod, PutMethod, PostMethodWithHeader} from "."
import { API_URL } from "./api_urls"
import adminAuth from "../components/RouteGuard/adminAuth";
import userAuth from "../components/RouteGuard/userAuth";


const userRole = "USER"
const adminRole = "ADMIN"
const merchantRole = "MERCHANT"


//	user profile retrieved
export const meFn=async()=>{
    return await GetMethod(API_URL.me, userRole);
}
export const adminProfileFn=async()=>{
    return await GetMethod(API_URL.me, adminRole);
}
//refresh token
export const refreshTokenFn=async(refreshToken, role)=>{
    return await PutMethod(API_URL.refreshToken+refreshToken, role==='user'? userRole: role==='merchant' ? merchantRole : adminRole);
}
//verify user account
export const verifyAccountFn=async({queryKey})=>{
    const token = queryKey[1];
    return await PutMethod(API_URL.verifyAccount+token, userRole);
}

//mono connect authorisation
export const reconnectFn=async()=>{
    return await GetMethod(API_URL.re_connect, userRole)
}

//credit
export const allCreditFn=async({queryKey})=>{
    const params = {
        page: queryKey[1],
        status: queryKey[2]
    }
    return await GetMethod(API_URL.allCredit, adminRole, params);
}
export const myCreditFn=async()=>{
    return await GetMethod(API_URL.myCredit, userRole);
}
export const activeCreditFn=async()=>{
    return await GetMethod(API_URL.activeCredit);
}
export const sendOtpFn=async()=>{
    return await GetMethod(API_URL.send_otp, userRole);
}
//resend bvn otp
export const resendBvnOtpFn=async()=>{
    return await GetMethod(API_URL.resend_bvn_otp, userRole);
}

export const getUserAccountSummary=async({queryKey})=>{
    const accountId = queryKey[1];
    return await GetMethod(API_URL.userAccountSummary+accountId, adminRole);
}


export const getUserTransactionsHistoryFn=async({queryKey})=>{
    const userID = queryKey[1];
    console.log(userID);
    return await GetMethod(API_URL.transaction_history+userID, adminRole);
}
export const getOrderTransactionFn=async({queryKey})=>{
    const orderID = queryKey[1];
    const role = queryKey[2]
    return await GetMethod(API_URL.order_transaction+orderID, role==='user'? userRole : adminRole);
}


export const getTransactionsHistoryFn=async()=>{
    return await GetMethod(API_URL.transaction_history, userRole);
}

//merchant
export const merchantOrder=async()=>{
    return await GetMethod(API_URL.merchantOrder, merchantRole);
}
export const allMerchantOrder=async()=>{
    return await GetMethod(API_URL.allMerchantOrder, adminRole);
}
export const merchantProfile=async()=>{
    return await GetMethod(API_URL.merchantProfile, adminRole);
}
export const activeCredits=async()=>{
    return await GetMethod(API_URL.activeCredit);
}
export const creditRepayment=async({queryKey})=>{
    const creditId = queryKey[1];
    return await GetMethod(API_URL.creditRepayment+creditId);
}
//admin
export const getAllMerchant=async()=>{
    return await GetMethod(API_URL.allMerchant, adminRole);
}
export const getUsers=async({queryKey})=>{
    const params = {
        status: queryKey[1]
    }
    return await GetMethod(API_URL.users, adminRole, params);
}
export const getAllUsers=async({queryKey})=>{
    const params = {
        // status: queryKey[1]
        pages: 1,
    }
    return await GetMethod(API_URL.users, adminRole, params);
}
export const getAllSettlement=async()=>{
    return await GetMethod(API_URL.getAllSettlement, adminRole);
}
export const merchantSettlement=async()=>{
    return await GetMethod(API_URL.mySettlement, merchantRole);
}
export const newUsers=async()=>{
    return await GetMethod(API_URL.newUsers, adminRole);
}
//get user bank statement
export const getUserBkStatementFn=async({queryKey})=>{
    const accountId = queryKey[1];
    return await GetMethod(API_URL.bankStatement+accountId, adminRole)
}

//get subscription plans for admin
export const getSubscriptionPlansFn=async()=>{
    return await GetMethod(API_URL.getSubscriptionPlans_admin, adminRole);
}
//get subscription type for admin
export const getAdminSubscriptionTypeFn=async({queryKey})=>{
    const typeId = queryKey[1];
    return await GetMethod(API_URL.getSubscriptionType_admin+typeId, adminRole);
}



//get admin upgrade request
export const getAdminUpgradeRequestFn=async({queryKey})=>{
    const status = queryKey[1];
    return await GetMethod(API_URL.adminUpgradeReq+status, adminRole);
}


//get upgrade request
export const getUserUpgradeRequestFn=async()=>{
    return await GetMethod(API_URL.userUpgradeReq, userRole);
}


//get subscription plans for users
export const getUserSubscriptionPlansFn=async()=>{
    return await GetMethod(API_URL.getSubscriptionPlans_user, userRole);
}

//get upgrade plans for users
export const getUserUpperPlansFn=async()=>{
    return await GetMethod(API_URL.upperPlans, userRole);
}

//get subscription plans for users
export const getUserSubscriptionFn=async()=>{
    return await GetMethod(API_URL.getUserSubscriptions, userRole);
}

//search for user order request
export const searchOrderFn=async({token, phone})=>{
    // console.log(token, phone);
    return await GetMethod(API_URL.searchOrder+token+phone, merchantRole);
}

// get all market places
export const getMarketPlacesFn=async({queryKey})=>{
    const role = queryKey[1];
    return await GetMethod(API_URL.getAllMarketPlace, role==='user'? userRole: adminRole);
}

// get single market places
export const getSingleMarketPlaceFn=async({queryKey})=>{
    return await GetMethod(API_URL.singleMarketPlace, adminRole);
}

//get approved orders
export const getUserOrderFn=async({queryKey})=>{
    const status = queryKey[1];
    return await GetMethod(API_URL.user_orders+status, userRole);
}

//get user estimated credit
export const getUserEstimatedCreditFn=async({queryKey})=>{
    const userId = queryKey[1];
    const role = queryKey[2];
    return await GetMethod(API_URL.user_credit_estimate+userId, role==='user'?userRole:adminRole)
}

//initiate payment
export const initiatePaymentFn=async({queryKey})=>{
    const orderID = queryKey[1];
    return await GetMethod(API_URL.initiate_payment+orderID, userRole);
}
export const initiateRepaymentFn=async(orderID)=>{
    return await GetMethod(API_URL.initiate_payment+orderID, userRole);
}
//verify payment
export const verifyRepaymentFn=async(referenceID)=>{
    return await GetMethod(API_URL.verify_payment+referenceID, userRole);
}
//initiate payment
export const verifyPaymentFn=async({queryKey})=>{
    const referenceID = queryKey[1]; //reference no
    return await GetMethod(API_URL.verify_payment+referenceID, userRole);
}


//verify mandate
export const verifyMandateFn=async({queryKey})=>{
    const referenceID = queryKey[1]; //reference no
    return await GetMethod(API_URL.verify_mandate+referenceID, userRole);
}
//get single user order
export const getSingleUserOrderFn=async({queryKey})=>{
    const payload = queryKey[1];
    
    return await PostMethodWithHeader(API_URL.singleUserOrder, adminAuth()?.token, payload);
}

//get data bundle
export const getDataBundleFn=async({queryKey})=>{
    const payload = queryKey[1];

    
    return await PostMethodWithHeader(API_URL.data_bundle, userAuth()?.token, payload);
}

//get airtime providers
export const airtimeProviderFn= async()=>{
    return await GetMethod(API_URL.airtime_provider, userRole);
}
//get cable providers
export const cableProviderFn= async()=>{
    return await GetMethod(API_URL.cable_provider, userRole);
}

//get electric providers
export const electricProviderFn= async()=>{
    return await GetMethod(API_URL.electric_provider, userRole);
}


//get cable products
export const getCableProductsFn=async({queryKey})=>{
    const payload = queryKey[1];
    
    return await PostMethodWithHeader(API_URL.cable_product, userAuth()?.token, payload);
}

//get cable_addons
export const getCableAddonFn=async({queryKey})=>{
    const payload = queryKey[1];
    
    return await PostMethodWithHeader(API_URL.cable_addons, userAuth()?.token, payload);
}

//get schedules
export const getSchedules=async({queryKey})=>{
    
    return await GetMethod(API_URL.scheduleMandate, adminRole);
}
//get schedule transactions
export const getScheduleTransaction=async({queryKey})=>{

const scheduleID = queryKey[1]
    
    return await GetMethod(API_URL.scheduleTransaction+scheduleID, adminRole);
}

//get discos
export const getDiscos=async()=>{
    
    return await GetMethod(API_URL.get_discos, userRole);
}

//get mandate schedule
export const getMandateSchedule=async({queryKey})=>{

    const mandateID = queryKey[1]
        
        return await GetMethod(API_URL.get_schedules+mandateID, adminRole);
    }
//get mandate schedule
export const getUserMandate=async({queryKey})=>{

    const orderID = queryKey[1]
        
        return await GetMethod(API_URL.get_user_mandate+orderID, userRole);
    }
    
    //get mandate
    export const getMandates=async()=>{
        return await GetMethod(API_URL.get_mandate, adminRole);
    }
