import "./authLayout.css";
import shopping_cart from "../../assets/shopping_cart.png";
import sign_up_img from "../../assets/sign_up_img.png";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/route";
import Logo from "../../components/logo/logo";
function AuthLayout() {
  const navigate = useNavigate({});
  const location = useLocation({}).pathname;
  // console.log(location)

  return (
    <>
      <main className="auth_layout">
        <section className="auth_layout_containe d-flex flex-wrap">
          <div className="col-lg-6 col-md-6 auth_layout_left_content">
            <div
              className="mx-3 logo mt-"
              onClick={() => navigate(ROUTES.landing_page)}
            >
              <Logo How />
            </div>
            <div className="shopping_cart text-center">
              <h4 className="fw-semibold" style={{ fontSize: "28px" }}>
                {location?.includes("register")
                  ? "Join Us Today!"
                  : location?.includes("password")? "Reset Password"
                  : "Welcome back"}
              </h4>
              {location?.includes("register") && (
                <p
                  className="text-center w-md-50"
                  style={{ fontSize: "18px", fontWeight: 300 }}
                >
                  You won’t have to apply each time you want to shop and pay
                  later.
                </p>
              )}
              <img
                src={
                  location.includes("register") ? sign_up_img : shopping_cart
                }
                alt="shopping-cart-with-red-plastic-bag-it-removebg-preview 1"
                className="card-img-top cart_img"
              />
            </div>
          </div>
          <div className="col-lg-6 col-md-6 col-12 mx-auto my-auto auth_layout_right_content">
            <Outlet />
          </div>
        </section>
      </main>
    </>
  );
}

export default AuthLayout;
