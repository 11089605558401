import React from "react";

import justrite from "../../../../assets/justrite.png";
import { MenuItem, Select } from "@mui/material";
import { Badge, Button } from "antd";
import { useSelector } from "react-redux";
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
// import { Button, Select } from "antd";

export default function SuperStoreHeader({changeCurrentStage}) {

    const { allProducts } = useSelector(state=>state.superStore);

  return (
    <>
      <div className="mx-lg-auto mx-2 mt-4 position-relative">
        <div className="text-center">
          <img
            src={justrite}
            alt={"justrite"}
            className={`cursor-pointer`}
            style={{
              width: "79px",
              height: "100%",
              borderRadius: "597.97px",
              boxShadow: "0px 4px 4px 0px #00000040",
              transition: "0.3s ease-in-out",
            }}
          />
          
        </div>
        <div className="position-absolute top-0 end-0 cursor-pointer"

        onClick={()=>changeCurrentStage('cart')}
        >
        {
                    allProducts?.length? (
                      <Badge size="small" color="orange" count={allProducts?.length} >
                        <ShoppingCartIcon fontSize="large"/>
                      </Badge>
                    ): 
                    null
                  }
        </div>
        
        {/* <div
          className="text-center"
          style={{ paddingBottom: "20px", margin: "20px 0 5px 0" }}
        >
          <Select
            className="rounded-pill"
            size="small"
            name="category"
            defaultValue="Select store location"
            fullWidth
            // onChange={formik.handleChange}
            // onBlur={formik.handleBlur}
            value={""}
            placeholder="Select Store Location"
          >
            <MenuItem value="">category</MenuItem>
            <MenuItem value="Lagos">Lagos</MenuItem>
            <MenuItem value="Abeokuta">Abeokuta</MenuItem>
          </Select>
        </div>
        <div className="d-flex gap-3 flex-wra">
          <Button
            size="large"
            style={{
              backgroundColor: "#000000",
              color: "white",
            }}
          >
            Baby Food
          </Button>
          <Button size="large">Groceries</Button>
          <Button size="large">Toiletries</Button>
          <Button size="large">Stationaries</Button>
        </div> */}
      </div>
    </>
  );
}
