import { useState } from "react";
import SuperStoreHeader from "./Header";
import molfix from "../../../../assets/molfix.png";
import molfix2 from "../../../../assets/molfix2.png";
import babyNutri from "../../../../assets/babyNutri.png";
import aptil from "../../../../assets/aptil.png";

import ProductCard from "./ProductCard";
import { useSelector } from "react-redux";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";
import { Button } from "antd";
import SuperStoreCart from "./SuperStoreCart";
import SuperStoreCheckout from "./SuperStoreCheckout";
import { useDispatch } from "react-redux";
import { superStoreAction } from "../../../../redux/slice/superStoreSlice";

export default function SuperStore({ handleClick }) {
  const products = [
    {
      id: 0,
      name: "Nutrilac",
      price: 20000,
      img: babyNutri,
      discount: 30,
    },
    {
      id: 1,
      name: "Baby Diaper",
      price: 20000,
      img: molfix,
      discount: 40,
    },
    {
      id: 2,
      name: "Aptamil",
      price: 50000,
      img: aptil,
      discount: 60,
    },
    {
      id: 3,
      name: "Nutrilac",
      price: 50000,
      img: babyNutri,
      discount: 30,
    },
    {
      id: 4,
      name: "Baby Diaper",
      price: 10000,
      img: molfix2,
      discount: 20,
    },
  ];

  const [currentStage, setCurrentStage] = useState("");

  const dispatch = useDispatch({});

  const [location, setLocation] = useState("");

  const { allProducts, totalAmount, storeLocation } = useSelector(
    (state) => state.superStore
  );

  const changeCurrentStage = (val) => {
    setCurrentStage(val);
  };

  const handleChange = (e) => {
    setLocation(e.target.value);
    dispatch(superStoreAction.changeStoreLocation(e.target.value))
  };

  return (
    <>
      <SuperStoreHeader changeCurrentStage={changeCurrentStage} />
      {currentStage === "cart" ? (
        <SuperStoreCart changeCurrentStage={changeCurrentStage} />
      ) : currentStage === "checkout" ? (
        <SuperStoreCheckout changeCurrentStage={changeCurrentStage} />
      ) : storeLocation ? (
        <div>
          <div className="col-lg-6 col-md-8 mx-auto">
            <div style={{ paddingBottom: "20px", margin: "20px 0 5px 0" }}>
              <FormControl sx={{ m: 1, mt: 3 }} fullWidth>
                <Select
                  className="rounded-pill"
                  displayEmpty
                  value={location}
                  onChange={handleChange}
                  fullWidth
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (!selected) {
                      return <em>Select Store Location</em>;
                    }
                    return selected;
                  }}
                  // MenuProps={MenuProps}
                  inputProps={{ "aria-label": "Without label" }}
                >
                  <MenuItem disabled value="">
                    <em>Select Store Location</em>
                  </MenuItem>
                  <MenuItem value="Ikeja">Ikeja</MenuItem>
                  <MenuItem value="Fadeyi">Fadeyi</MenuItem>
                  <MenuItem value="Ojodu berger">Ojodu berger</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="d-flex justify-content-center">
            <div className="d-flex gap-3 flex-wrap">
              <Button
                size="large"
                style={{
                  backgroundColor: "#000000",
                  color: "white",
                }}
              >
                Baby Food
              </Button>
              <Button size="large">Groceries</Button>
              <Button size="large">Toiletries</Button>
              <Button size="large">Stationaries</Button>
            </div>
          </div>
          <div className="row mx-3 mt-3">
            {products?.map((product, index) => {
              return (
                <ProductCard
                  key={index}
                  product={product}
                  handleClick={handleClick}
                />
              );
            })}
          </div>
        </div>
      ) : (
        <div className="d-flex flex-column align-items-center justify-content-center mt-3">
          <h3>Welcome to Justrite Super</h3>
          <div>
            <FormControl sx={{ m: 1, minWidth: 250, mt: 3 }} fullWidth>
              <Select
                className="rounded-pill"
                displayEmpty
                value={location}
                onChange={handleChange}
                fullWidth
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Select Store Location</em>;
                  }
                  return selected;
                }}
                // MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
              >
                <MenuItem disabled value="">
                  <em>Select Store Location</em>
                </MenuItem>
                <MenuItem value="Ikeja">Ikeja</MenuItem>
                <MenuItem value="Fadeyi">Fadeyi</MenuItem>
                <MenuItem value="Ojodu berger">Ojodu berger</MenuItem>
              </Select>
            </FormControl>
          </div>
          <Button
            shape="round"
            size="large"
            className="mb-3 mt-3"
            style={{
              backgroundColor: "#000000",
              color: "white",
            }}
            onClick={() =>
              dispatch(superStoreAction.changeStoreLocation(location))
            }
          >
            Continue
          </Button>
        </div>
      )}
    </>
  );
}
