import styled from "styled-components"
import CustomButton from "../components/CustomButton/CustomButton"
import { useNavigate } from "react-router-dom"
function NotFound(){
    const navigate = useNavigate()
    return(
        <>
           <Main className="d-flex justify-content-center align-items-center text-center">
                <section>
                    <h2 className="404_text">404</h2>
                    <h3>PAGE NOT FOUND.</h3>
                    <h5>Hmm, looks like that page does'nt exist</h5>
                    <CustomButton text={"Go Back Home"} style={{width: "50%"}} functionFn={()=>{navigate(-1)}}/>
                </section>
           </Main>
        </>
    )
}
export default NotFound
const Main = styled.main`
    height: 100vh;
    width: 100%;
    section{
        h2{
            font-size: 10em;
            font-weight: 500;
            font-family: 'Josefin Sans', sans-serif !important;
            color: orange;
            -webkit-animation: pulsate-fwd 0.9s ease-in-out infinite both;
	        animation: pulsate-fwd 0.9s ease-in-out infinite both;
        }
    }

    @-webkit-keyframes pulsate-fwd {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        50% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      @keyframes pulsate-fwd {
        0% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
        50% {
          -webkit-transform: scale(1.1);
                  transform: scale(1.1);
        }
        100% {
          -webkit-transform: scale(1);
                  transform: scale(1);
        }
      }
      
`