import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TopToolBar from '../../../utils/Table/TopToolBar';
import Status from '../../../utils/Status';
import { Button } from '@mui/material';
import EnhancedTableHead from '../../../utils/Table/EnhancedTableHead';
import SampleDot from '../../../utils/SampleDot';
export default function TransactionTable({topHeaderVisible, rows, headCell, handleOpen}) {

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const headCells = [
        {
          label: 'Name',
        },
        {
          label: 'Order ID',
        },
        {
          label: 'Merchant',
        },
        {
          label: 'Product',
        },
        {
          label: 'Down Payment',
        },
        {
          label: 'Amount',
        },
        {
          label: 'Repayment',
        },
        {
          label: 'Actions',
        }
      ];
      
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rows?.map((n) => n._id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, user_id) => {
      const selectedIndex = selected?.indexOf(user_id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, user_id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected?.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };
  
    const isSelected = (agent) => selected?.indexOf(agent) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  
    return (
    <React.Fragment>
        <section className="col-md-11 mx-auto table_header my-4 d-flex justify-content-between flex-wrap gap-2">
          <h6 className="fw-bold">Transaction</h6>
          <div className="d-flex gap-2 flex-wrap">
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"#F8D86E"} /> Default
            </p>
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"green"} /> Paid
            </p>
          </div>
        </section>
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          {
            selected?.length ? (
              <TopToolBar numSelected={selected?.length} topHeaderVisible={topHeaderVisible}/>
            ): null
          }
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows?.length}
                headCell={headCells}
              />
              <TableBody>
                {rows?.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.campaign)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row._id)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <div className='d-flex flex-column'>
                          <p className="fw-bold my-auto">
                            {row.firstName} {row.lastName}
                            </p>
                            {/* <small className="text-muted ">15:30:20, 21. Dec.2023</small> */}
                          </div>
                      </TableCell>
                      <TableCell>{row?.order_number}</TableCell>
                      <TableCell className='nowrap'>{row?.merchant}</TableCell>
                      <TableCell>{row?.product}</TableCell>
                      <TableCell>{row?.downPayment}</TableCell>
                      <TableCell>{row?.amount}</TableCell>
                      <TableCell>
                      <div className='d-flex flex-column text-center'>
                            <p className="my-auto text-muted">
                            
                            {row?.repayment}
                            </p>
                            <small className="text-muted nowrap">{new Date(row?.createdAt).toLocaleString()}</small>
                          </div>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex gap-2">
                          <SampleDot color={'green'}/>
                          <Button variant='outlined' size='small' sx={{color: 'gray', border: "1px solid gray"}}>View</Button>
                        </div>
                      </TableCell>
                      
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
      </React.Fragment>
    );
  }
