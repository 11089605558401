import React, { useEffect } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import justrite from "../../../assets/justrite.png";

export default function SuperMarketOption({ setOption, cable }) {


  return (
    <div className="col-lg-8 col-12 mx-auto">
      <AuthHeader title={"Supermarkets"} />
      <p className="text-center">Select store to start shopping</p>

      <div className="d-flex gap-4 flex-wrap justify-content-center mt-3 align-items-center">
  
            <img
              src={
                justrite
              }
              alt={"justrite"}
              onClick={() => setOption("justrite")}
              className={`cursor-pointer`}
              style={{
                width: "100px",
                maxWidth: "100px",
                height: "100%",
                borderRadius: "597.97px",
                boxShadow: "0px 4px 4px 0px #00000040",
                transition: "0.3s ease-in-out",
              }}
            />
            <div className="text-center cursor-pointer" style={{
                 width: "130px",
                 height: "73px",
                 borderRadius: "720px",
                 boxShadow: "0px 4px 4px 0px #00000040",
                 backgroundColor: "#1C1A1A",
                 display: "flex",
                 alignItems: "center",
                 
                 justifyContent: "center",
                 color: "#fff"

            }}>
                Supermarket
            </div>
            <div className="text-center cursor-pointer" style={{
                 width: "130px",
                 height: "73px",
                 borderRadius: "720px",
                 boxShadow: "0px 4px 4px 0px #00000040",
                 backgroundColor: "#1C1A1A",
                 display: "flex",
                 alignItems: "center",
                 
                 justifyContent: "center",
                 color: "#fff"

            }}>
                Supermarket
            </div>

          </div>
      </div>
  );
}
