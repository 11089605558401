import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { TextField } from "@mui/material";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import CustomButton from "../../CustomButton/CustomButton";
import { ROUTES } from "../../../routes/route";
import CustomizedSnackbars from "../../snackbar/snackbar";
import { useForgetPsw } from "../../../redux/mutation/postMutation";
// import { useForgotPasswordMutation } from "../../../redux/mutation";
// import CustomizedSnackbars from "../../Snackbar/snackbar";
function ForgotPsw({handleClick}) {
  const [emailSent, setEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  //mutation
  const forgotPsw = useForgetPsw();

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    onSubmit:(values)=>{
        setIsLoading(true)
        forgotPsw.mutate(values)
    },
    validationSchema: yup.object().shape({
        email: yup.string().required("Email can not be empty!").email("Invalid email address!")
      })
  });
  const openEmail =()=>{
    window.open('https://mail.google.com/', '_blank');
  }


  useEffect(()=>{
    if(forgotPsw.isLoading){
      setIsLoading(true);
    }
    else if(forgotPsw.isError){
      setIsLoading(false)
      let message = forgotPsw?.error?.response?.data?.message
      let msg2 = forgotPsw?.error?.message
      handleClick(message ?? msg2, "error");
    }
    else if(forgotPsw.isSuccess){
        setEmailSent(true);
        setIsLoading(false)
        let message = forgotPsw?.data?.data?.message
        handleClick(message, "success");
    }
  }, [forgotPsw.status]);

  return (
        <>
          <Section className="mx-3">
            <form className="col-sm-8 mx-auto my-3" onSubmit={formik.handleSubmit}>
                <h2 className="fw-bold">Forgot Password?</h2>
                {
                    emailSent?<div >
                    A password reset link was sent to the email adress <code style={{cursor: "pointer", fontWeight: "500"}} onClick={openEmail}>{formik.values.email}</code>.
                  </div>:
                <section>
                        <p>
                        Don’t worry ! It happens. Please
                        enter your <code>email address</code> and we'll send you an email to reset your password.
                        </p>
                    <div className="Email">
                        <div className="label my-2">
                        <label htmlFor="Email" className="fw-bold">
                            Email
                        </label>
                        </div>
                        <TextField
                        type="email"
                        id="standard-basic"
                        name="email"
                        size='small'
                        error={formik.touched.email && formik.errors.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        placeholder="Account Email Address"
                        fullWidth
                        variant="outlined"
                        className="rounded-pill"
                        helperText={formik.errors.email}
                        InputProps={{
                            style: {
                              borderRadius: "20px",
                            },
                          }}
                        />
                    </div>
                    <div className="submit_btn my-3">
                        <CustomButton text={"Reset Password"} loading={isLoading}/>
                    </div>
                    <div className="text-center " style={{ width: "auto" }}>
                        <p>
                        Remembered your password?{" "}
                        <Link
                            className="text-decoration-none"
                            to={ROUTES.login}
                            style={{ color: "#F1350E" }}
                        >
                            Login
                        </Link>
                        </p>
                    </div>
                </section>
            }
            </form>
          </Section>
        </>
      )}

export default ForgotPsw;

const Section = styled.section`
  @media only screen and (max-width: 575px) {
    width: 90vw;
  }
`;
