import { Button, Modal } from "antd";
import React from "react";
import CustomButton from "../../../../components/CustomButton/CustomButton";
import {
  DialogTitle,
} from "@mui/material";
import { useSelector } from "react-redux";
import CartCard from "./CartCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export default function SuperStoreCart({changeCurrentStage}) {


  const { allProducts } = useSelector((state) => state.superStore);


  return (
    <>
      <section className="col-lg-11 mx-auto">
        <div className="text-center">
          <DialogTitle>Cart</DialogTitle>
        </div>
        {
          allProducts?.length ? (
          <section className="">
            <div className="bg-white my-3">
              <section>
                {allProducts?.map((product, index) => {
                  return <CartCard key={index} product={product} />;
                })}
              </section>
            </div>
            {/* <div className="checkout_btn mt-lg-5">
                    <CustomButton
                      text={"Continue"}
                      // loading={isLoading}
                      // functionFn={checkoutFn}
                    />
                  </div> */}

                  <div className="submit_btn mt-5 d-flex justify-content-center align-items-center">
              <Button
                size="large"
                shape="round"
                // loading={isLoading}
                // disabled={!activateNextBtn}
                
                style={{
                  backgroundColor: "#033F4B",
                  
                  color: "white",
                  width: "50%",
                  border: "none",
                  height: "55px",
                }}
                onClick={()=>changeCurrentStage("checkout")}
              >
                Continue
              </Button>
            </div>
            {/* {allProducts?.length ? (
              <div className="col-lg-4 ">
                <div className="card h-50 p-2 d-flex flex-column justify-content-between" data-aos="fade-down">
                  <div className="order_summary">
                    <p className="border-0 border-bottom py-2">Order Summary</p>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Subtotal</TableCell>
                          <TableCell>
                            {formatNumber(1000)}
                          </TableCell>
                        </TableRow>
                      
                        <TableRow>
                          <TableCell className="fw-semibold">
                            Total
                          </TableCell>
                          <TableCell>
                            {formatNumber(20000)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                
                  <div className="checkout_btn mt-lg-5">
                    <CustomButton
                      text={"Check out"}
                      // loading={isLoading}
                      // functionFn={checkoutFn}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
              
                <p className={`card-text text-center my-2`}>
                  You have no product in your cart yet.
                </p>
              </>
            )} */}
          </section>
          ): (
            <div
            className="container mx-auto rounded bg-white border mt-4 d-flex justify-content-center align-items-center shadow-sm"
            style={{ height: "50vh" }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center text-center">
              <div
                className="rounded-circle mb-3 d-flex justify-content-center align-items-center"
                style={{
                  height: "15vh",
                  width: "15vh",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <ShoppingCartIcon sx={{ fontSize: "8vh", color: "#033F4B" }} />
              </div>
              <h4 className="text-sm mb-5">Your cart is empty!</h4>
              <Button
                size="large"
                sx={{
                  backgroundColor: "#033F4B",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#045E6E",
                    color: "white",
                  },
                }}
                onClick={() => changeCurrentStage("")}
              >
                START SHOPPING
              </Button>
            </div>
          </div>
          )
        }
      </section>

      {/* <ModalDialogBox open={open} handleClose={handleClose} modalSize={true}>
      {
        <OnlineMarketPlace
          handleClick={handleClick}
          handleClose={handleClose}
        />
      }
    </ModalDialogBox> */}

      {/* {contextHolder} */}
    </>
  );
}
