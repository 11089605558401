import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

function HelpCard() {
  return (
    <>
        <Section className='text-center mx-lg-5 mx-2 rounded'>
            <p>Do you need help?</p>
            <Link to="" >We're here, ready to talk</Link>
        </Section>
    </>
  )
}

export default HelpCard

const Section = styled.section
`
    background-color: #ECE9FF;
    padding: 2rem;
    margin-top: 3rem;
`