import React, { useState, useEffect } from 'react'
import Form from './Form'
import ConfirmDataInformation from './ConfirmDataInformation';
import DataCheckout from './DataCheckout';
import BillWarningPage from '../BillWarningPage';

export default function MobileData({formik, handleClick, savedData, openModal }) {


    const [nextAction, setNextAction ] = useState("");

    useEffect(()=>{
        if(savedData){
            setNextAction('checkout')
        }
    }, [savedData])

//    const billData = {
//         phone: formik?.values?.mobileNumber,
//         amount: formik?.values?.dataBundle?.price,
//         service_type: formik?.values?.service?.service_type,
//         datacode: formik?.values?.dataBundle?.datacode,
//         email: formik?.values?.email,
//         marketId: formik?.values?.marketPlace?.id,
//         order_name: "data",
//         plan: formik?.values?.dataBundle?.name,
//     };


    const billData = {
        "amount": formik?.values?.dataBundle?.price,
        "meter": formik?.values?.mobileNumber,
        "phone": formik?.values?.mobileNumber,
        "disco": formik?.values?.service,
        "vendType": "PREPAID",
        "tariffClass": formik?.values?.dataBundle?.code, //"DATA-01",
        "plan": formik?.values?.dataBundle?.desc, //"DATA-01",
        "email": formik?.values?.email,
        "vertical": "VTU",
        "name": formik?.values?.name,
        "marketId": formik?.values?.marketPlace?.id,
        order_name: "data",
        payment_type: "transfer"
    }

    const payloadData = savedData ?? {
       data: {
        marketId: formik?.values?.marketPlace?.id,
        deliveryLocation: "",
        order_type: "bills",
        order_name: "data",
        products: [
            billData
        ],
        
       }
      };


  return (
    <>
        <main>
            {
                nextAction==='maximum_exceeded'? (<BillWarningPage handleClick={handleClick} payloadData={billData}/>):
                nextAction==='confirm_information'? (
                    <ConfirmDataInformation formik={formik} handleClick={handleClick} setNextAction={setNextAction} payloadData={payloadData}/>
                ): nextAction==='checkout'? (
                    <DataCheckout formik={formik} handleClick={handleClick} setNextAction={setNextAction} payloadData={payloadData} openModal={openModal}/>
                ) : (
                    <Form formik={formik} setNextAction={setNextAction}/>
                )
            }
        </main>
    </>
  )
}
