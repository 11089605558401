import React, { useState } from 'react'
import CustomButton from '../../CustomButton/CustomButton';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useUserData } from '../../hooks/useUserData';
import { useDispatch } from 'react-redux';
import { productAction } from '../../../redux/slice/productSlice';

export default function ChangeAddress({address, setAddress, handleClose, handleClick}) {
  const [isLoading, setIsLoading] = useState(null);

    
  return (
    <>
        <section className="" style={{width: '80vh'}}>
            <h5>Change Delivery Detail</h5>
           <div className="address my-2">
                <label htmlFor="delivery_address">Delivery Address</label>
                <TextField
                type="text"
                id="address"
                name="address"
                onChange={(e)=>setAddress(e.target.value)}
                value={address}
                placeholder="Enter your address"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
                <div className="">
                  <CustomButton
                    text={"Change"}
                    loading={isLoading}
                    functionFn={handleClose}
                  />
                </div>
                
        </section>
    </>
  )
}
