import React from 'react'
import SampleDot from '../../../utils/SampleDot'

export default function MembershipHeader({activeIndex, setActiveIndex}) {
  return (
    <>
        <section className="col-md-11 mx-auto table_header my-3 d-flex justify-content-between flex-wrap gap-2">
          <div className='d-flex gap-3'>
            <p className={`fs-6 ${activeIndex===2&&'fw-bold'} cursor-pointer`} >New Users / Under review</p>
          </div>
          <div className="d-flex gap-2 flex-wrap">
            {/* <p className='cursor-pointer'>New Application</p> */}
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"#F8D86E"} /> Under review
            </p>
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"green"} /> Activated
            </p>
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"red"} /> Declined
            </p>
          </div>
        </section>
    </>
  )
}
