import { Fragment, useState } from "react";
import Box from "@mui/material/Box";
import SpeedDial from "@mui/material/SpeedDial";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import SpeedDialAction from "@mui/material/SpeedDialAction";
import ShareIcon from "@mui/icons-material/Share";
import { IconUpdate } from "../../icons/icons";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import CreatePlan from "../Admin/CreatePlan";
import PreviewIcon from "@mui/icons-material/Preview";
import SubscriptionType from "../../screens/Admin/SubscriptionPlanScreen/SubscriptionType";
import { Modal } from "antd";
import EditIcon from '@mui/icons-material/Edit';


const actions = [
  // { icon: <PreviewIcon />, name: <span className="nowrap">View Subscription Types</span> },
  { icon: <IconUpdate />, name: "Update" },
  // { icon: <ShareIcon />, name: 'Cancel' },
];

export default function ActionButton({ plan, handleClick }) {
  const [open, setOpen] = useState({ type: "", status: false });

  const handleOpen = (type) => {
    setOpen({ ...open, status: true, type });
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };
  return (
    <Fragment>
      <Box
        sx={{
          transform: "translateZ(0px)",
          flexGrow: 1,
          position: "absolute",
          top: 0,
          right: 0,
        }}
      >
        <Box sx={{ height: 300 }}>
          <SpeedDial
            ariaLabel="SpeedDial playground example"
            icon={<EditIcon />}
            direction={"down"}
            FabProps={{ size: "small", style: { backgroundColor: "#033F4B" } }}
            onClick={() =>
              handleOpen("update_plan"
              )
            }
          >
            {/* {actions.map((action, index) => (
              <SpeedDialAction
                key={index}
                icon={action.icon}
                onClick={() =>
                  handleOpen("update_plan"
                  )
                }
                tooltipTitle={action.name}
                tooltipOpen
              />
            ))} */}
          </SpeedDial>
        </Box>
      </Box>

      <ModalDialogBox
        open={open.status}
        handleClose={handleClose}
        // modalSize={true}
      >
        <CreatePlan
          handleClose={handleClose}
          handleClick={handleClick}
          plan={plan}
        />
      </ModalDialogBox>

      {/* <Modal
        centered
        open={open?.status}
        onCancel={handleClose}
        footer= {null}
        style={{
          paddingTop: "1rem"
        }}
      >
        <CreatePlan
              handleClose={handleClose}
              handleClick={handleClick}
              plan={plan}
            />
      </Modal> */}
    </Fragment>
  );
}
