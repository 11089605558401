import React from 'react'
import sofa from "../../../assets/sofa.png"
import "./howItWork.css"
function ProductCard({name, title, image, brake}) {
  return (
    <>
        <main className="card border-0 details shadow-xsm mx-auto">
            <img src={image} alt="sofa" className='card-img-top image'/>
            <div className="card-body text-center">
                      <p className="card-title"><span>{name}  {brake ===" " ? <><br/> <br/></>: <><br/> {brake}</>}</span></p>
                      <h5 className="card-text">{title}</h5>
                    </div>
        </main>
    </>
  )
}

export default ProductCard