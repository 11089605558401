import { useState } from "react";
import Button from "@mui/material/Button";
import ModalDialogBox from "../../components/ModalDialogBox/ModalDialogBox";
import CreateMarketPlace from "../../components/Admin/CreateMarketplace";
import MarketPlaceTable from "../../components/Admin/MarketPlaceTable";
import { useGetMarketPlace } from "../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../utils/Loader";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Modal } from "antd";


export default function AdminMarketPlace({ handleClick }) {

    const {data, isLoading, isError, isSuccess} = useGetMarketPlace('admin');
    const marketPlaces = data?.data?.data
    


  const [open, setOpen] = useState({status: false, data: ""});

  const handleOpen = (marketPlace) => {
    setOpen({...open, status: true, data: marketPlace});
  };
  const handleClose = () => {
    setOpen({...open, status: false});
  };

  return (
    <>
      <main className="mt-5 mx-lg-5 mx-2">
        <h4 className="header fw-bold fs-5 text-center">Market place</h4>
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            onClick={() => handleOpen()}
            startIcon={<AddOutlinedIcon />}
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "white",
              },
            }}
          >
            Create New Market place
          </Button>
        </div>

        {
            isLoading ? (
                <IsLoadingLoader />
            ) : isError ? (
                <IsErrorLoader />
            ) : (
                marketPlaces?.length ? (
                    <MarketPlaceTable  rows={marketPlaces} handleOpenModal={handleOpen}/>
                ): (
                    <p className={`card-text text-center my-2`}>No Record of market places available!!</p>
                )
            )
        }



        <section >
            
        </section>
      </main>


<Modal
        centered
        open={open?.status}
        onCancel={handleClose}
        footer= {null}
        
      >
        <CreateMarketPlace
          handleClose={handleClose}
          handleClick={handleClick}
          marketPlace={open?.data}
        />
      </Modal>




    </>
  );
}
