import React from 'react'
import CommunityScreen from '../../screens/Admin/CommunityScreen/CommunityScreen'

export default function Community({handleClick}) {
  return (
    <React.Fragment>
        <CommunityScreen handleClick={handleClick}/>
    </React.Fragment>
  )
}
