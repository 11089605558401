import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../routes/route'

export default function MiddleMenu({fn}) {
    const location = useLocation().pathname;
    const menu = [
        {id: 1, path: ROUTES.merchantDashboard, title: "Dashboard", },
        {id: 2, path: ROUTES.settlement, title: "Payment"}
    ]
  return (
    <>
        <section className='d-flex '>
            <div className='d-flex flex-column gap-3'>
                {
                    menu.map((item, index)=>{
                        return (
                                <Link to={item.path} onClick={fn} key={index} className={`text-decoration-none text-dark ${location===item.path&&'fw-bold'}`}>{item.title}</Link>
                        )
                    })
                }
            </div>
        </section>
    </>
  )
}
