import React, { useState, useEffect } from 'react'
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import PaymentMethods from "./PaymentMethods"
import PayWithTransfer from "./PayWithTransfer"
import PayWithDirectDebit from "./PayWithDirectDebit"
import PayWithCard from "./PayWithCard"
import PayWithUSSD from "./PayWithUSSD"
import {usePaymentModal} from "../../redux/hooks/zustands/usePaymentModal"
import DrawerBox from "../../utils/Drawer/DrawerBox";


const PaymentView = () => {

    const [paymentMethod, setPaymentMethod] = useState('transfer')


    const {isOpen, closeModal, data, updateData} = usePaymentModal()

  useEffect(()=>{
    setPaymentMethod(data?.payment_type)
  }, [data])

    // console.log(data)

    const handleClose=()=>{
        closeModal()
    }

   
  return (
    <>
          {/* <ModalDialogBox
        open={isOpen}
        handleClose={handleClose}
        modalSize={false}
      > */}
        <DrawerBox open={isOpen} handleClose={handleClose} size={'40rem'} >
            <h3 className="tw-text-center tw-text-[#033F4B] tw-text-[25px] tw-font-[500] tw-mb-3">Payment Method</h3>
            {
                paymentMethod==='transfer'? (
                    <PayWithTransfer setPaymentMethod={setPaymentMethod} details={data} handleCloseModal={handleClose} updateData={updateData}/>
                  ):
                  paymentMethod==="direct_debit" ? (
                  <PayWithDirectDebit setPaymentMethod={setPaymentMethod} details={data} handleCloseModal={handleClose}/>
                )
                : 
                  paymentMethod==="card" ? (
                  <PayWithCard setPaymentMethod={setPaymentMethod} details={data} handleCloseModal={handleClose}/>
                )
                : 
                  paymentMethod==="ussd" ? (
                  <PayWithUSSD setPaymentMethod={setPaymentMethod} details={data} handleCloseModal={handleClose}/>
                )
                : 
                (
                    <PaymentMethods setPaymentMethod={setPaymentMethod} updateData={updateData} data={data}/>
                )
            }
        </DrawerBox>




        {/* </ModalDialogBox> */}

    </>
  )
}

export default PaymentView