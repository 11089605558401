import { Box, Button, CircularProgress, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import * as yup from "yup";
import {
    useCreateMarketPlace, useUpdateMarketPlace
} from "../../redux/mutation/AdminPostMutation";
import { formNumberOnly, formatMoneyNumber } from "../../utils/formatNumber";
import { Switch } from 'antd';



export default function CreateMarketPlace({ handleClose, handleClick, marketPlace }) {
  const [isLoading, setIsLoading] = useState(false);

  const createMarketPlaceMutation = useCreateMarketPlace({});
  const updateMarketPlace = useUpdateMarketPlace(marketPlace?.id);

  const formik = useFormik({
    initialValues: {
      name: marketPlace?.name ?? "",
      logo: marketPlace?.logo ?? "",
      url: marketPlace?.url ?? "",
      delivery_amount: marketPlace?.delivery_amount ?? "",
      service_fee: marketPlace?.service_fee ?? "",
      tax: marketPlace?.tax ?? "",
      status: marketPlace?.status ?? true,
      sur_charge: marketPlace?.sur_charge ?? ""
    },
    enableReinitialize: true,
    validationSchema: yup.object().shape({
      name: yup.string().required("Name of market place can not be empty"),
      logo: yup.string().url("Invalid URL format").required("Market place logo url is required"),
      url: yup
        .string().url("Invalid URL format")
        .required("Market place website url is required"),
    }),
    onSubmit: (values, { resetForm }) => {
      const {
        name,
        logo, url, service_fee, tax, status, sur_charge
      } = values;

      const delivery_amount = (values.delivery_amount)?.replace(/,/g, '')


      if(marketPlace){
        updateMarketPlace.mutate(
          {
            name,
            logo,
            url, delivery_amount, service_fee, tax, status, sur_charge
          },
          {
            onSuccess:()=>{
              resetForm()
            }
          }
        );
      }
      else{
        createMarketPlaceMutation.mutate(
          {
            name,
            logo,
            url, delivery_amount, service_fee, tax, status, sur_charge
          },
          {
            onSuccess:()=>{
              resetForm()
            }
          }
        );
      }

    },
  });


  const onChange = (checked) => {
    formik.setFieldValue("status", checked);
  };



  const handleChange = (e) => {
    const formattedValue = formatMoneyNumber(e.target.value);
    // const formattedValue = e.target.value
    formik.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });
  };





  // mutation to create market place
  useEffect(() => {
    if (createMarketPlaceMutation?.isLoading) {
      setIsLoading(true);
    } else if (createMarketPlaceMutation?.isError) {
      setIsLoading(false);
      let message = createMarketPlaceMutation?.error?.response?.data?.message;
      let message2 = createMarketPlaceMutation?.error?.message;

      handleClick(message ? message : message2, "error");


    } else if (createMarketPlaceMutation?.isSuccess) {
      setIsLoading(false);
      const res = createMarketPlaceMutation?.data?.data;

      handleClick(res?.message, "success");
      handleClose();
    }
  }, [createMarketPlaceMutation?.status]);

  // mutation to create market place
  useEffect(() => {
    if (updateMarketPlace?.isLoading) {
      setIsLoading(true);
    } else if (updateMarketPlace?.isError) {
      setIsLoading(false);
      let message = updateMarketPlace?.error?.response?.data?.message
      let message2 = updateMarketPlace?.error?.message

      handleClick(message ? message : message2, "error");

      console.log(updateMarketPlace?.error, createMarketPlaceMutation?.error)

    } else if (createMarketPlaceMutation?.isSuccess || updateMarketPlace?.isSuccess) {
      setIsLoading(false);
      const res = updateMarketPlace?.data?.data

      handleClick(res?.message, "success");
      handleClose();
    }
  }, [updateMarketPlace?.status]);


  return (
    <>
      <main>
        <form
          className="col-lg- col-md- col-sm-12 mx-auto"
          onSubmit={formik.handleSubmit}
        >
                <h3 className="text-center">Market place</h3>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">Name</label>
                  </div>
                  <TextField
                    type="text"
                    name="name"
                    required
                    error={formik.touched.name && formik.errors.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.name}
                    placeholder="Enter the merket place name"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.name && formik.errors.name}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">Logo Url</label>
                  </div>
                  <TextField
                    type="url"
                    name="logo"
                    required
                    error={formik.touched.logo && formik.errors.logo}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.logo}
                    placeholder="Logo URL"
                    fullWidth
                    variant="standard"
                    size="small"
                 
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.logo && formik.errors.logo}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">URL</label>
                  </div>
                  <TextField
                    type="url"
                    name="url"
                    required
                    error={
                      formik.touched.url &&
                      formik.errors.url
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.url}
                    placeholder="Enter the Url of the marketplace"
                    fullWidth
                    variant="standard"
                    size="small"
                    
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.url &&
                      formik.errors.url}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">Delivery Amount</label>
                  </div>
                  <TextField
                    type="text"
                    name="delivery_amount"
                    required
                    error={
                      formik.touched.delivery_amount &&
                      formik.errors.delivery_amount
                    }
                    onChange={handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.delivery_amount}
                    placeholder="Enter the delivery amount of the marketplace"
                    fullWidth
                    variant="standard"
                    size="small"
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.delivery_amount &&
                      formik.errors.delivery_amount}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">Service Charge</label>
                  </div>
                  <TextField
                    type="number"
                    name="service_fee"
                    required
                    error={
                      formik.touched.service_fee &&
                      formik.errors.service_fee
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.service_fee}
                    placeholder="Enter the Service for the marketplace"
                    fullWidth
                    variant="standard"
                    step="any"
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.service_fee &&
                      formik.errors.service_fee}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">Surcharge</label>
                  </div>
                  <TextField
                    type="number"
                    name="sur_charge"
                    required
                    error={
                      formik.touched.sur_charge &&
                      formik.errors.sur_charge
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.sur_charge}
                    placeholder="Enter the Service for the marketplace"
                    fullWidth
                    variant="standard"
                    step="any"
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.sur_charge &&
                      formik.errors.sur_charge}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="">VAT</label>
                  </div>
                  <TextField
                    type="number"
                    name="tax"
                    required
                    error={
                      formik.touched.tax &&
                      formik.errors.tax
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.tax}
                    placeholder="Enter the Service for the marketplace"
                    fullWidth
                    variant="standard"
                    size="small"
                    step="any"
                  />
                  <small style={{ fontSize: "13px" }} className="text-danger">
                    {formik.touched.tax &&
                      formik.errors.tax}
                  </small>
                </div>
                <div className="col-12" style={{paddingBottom: "20px"}}>
                  <div className="label" style={{marginBottom: "4px"}}>
                    <label htmlFor="" className="mx-3">Market place status</label>
                    <Switch defaultChecked checked={formik?.values?.status} onChange={onChange} />
                  </div>
                    
                </div>

                

          <div
            className="d-flex justify-content-flex-end"
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Box sx={{ m: 1, position: "relative" }}>
              <Button
                disabled={isLoading}
                type="submit"
                variant="contained"
                sx={
                  isLoading
                    ? { cursor: "not-allowed !important", backgroundColor: "#033F4B" }
                    : {
                        backgroundColor: "#033F4B",
                        color: "white",
                        textTransform: "capitalize",
                        "&:hover": {
                          backgroundColor: "#033F4B",
                          color: "white",
                        },
                      }
                }
              >
                {
                  marketPlace? "Edit": "Create"
                }
              {isLoading && (
                <CircularProgress
                  size={24}
                  sx={{
                    position: "absolute",
                    top: "25%",
                    left: "40%",
                  }}
                />
              )}
              </Button>
            </Box>
          </div>
        </form>
      </main>
    </>
  );
}
