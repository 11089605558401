import { useLoanDrawer } from "../../../redux/hooks/zustands/useLoanDrawer"
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import DebitAccount from "./DebitAccount";
import LoanDetail from "./LoanDetail";
import SingleLoanDetail from "./SingleLoanDetail";
import SuccessfullDebit from "./SuccessfullDebit";

export default function LoanWithDrawers(){
    const {isOpen, closeDrawer, pageTitle, data} = useLoanDrawer();
    return(
        <>
             <DrawerBox open={isOpen} handleClose={closeDrawer} size={'50rem'} title={<p className="tw-text-center">{data?.user?.name}</p>}>
        {
            pageTitle === "LOAN_DETAIL" ? <LoanDetail /> : pageTitle === "SINGLE_LOAN_DETAIL" ? <SingleLoanDetail /> : pageTitle === "DEBIT_ACCOUNT" ? <DebitAccount /> : pageTitle === "SUCCESSFULL_DEBIT" ? <SuccessfullDebit /> : null
        }
        </DrawerBox>
        </>
    )
}