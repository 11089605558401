import React from 'react'
import LoginForm from '../../../components/Auth/Login/LoginForm'

function LoginScreen({handleClick}) {
  return (
    <>
        <LoginForm handleClick={handleClick}/>
    </>
  )
}

export default LoginScreen