import { useCallback, useMemo, useRef, useState } from "react";
import vouch_logo from "../../../assets/new_vouch.png";
import mono_logo from "../../../assets/mono.png";
import styled from "styled-components";
import { Button } from "antd";
import { useMandateModal } from "../../../redux/hooks/zustands/useMandateModal";
import ModalDialogBox from "../../ModalDialogBox/ModalDialogBox";
import { verifyMandateFn, verifyPaymentFn } from "../../../redux/fetchingFn";
import { useResponseModal } from "../../../redux/hooks/zustands/useResponseModal";
import { useTimeCounter } from "../../hooks/useTimeCounter";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route";
import CheckingMandateTransfer from "./CheckingMandateTransfer";
import MandateTransferResult from "./MandateTransferResult";
import DrawerBox from "../../../utils/Drawer/DrawerBox";

export default function Mandate() {
  const { isOpen, closeModal, data } = useMandateModal();


  const navigate = useNavigate();

  const createdAt = useRef(new Date().toISOString())


  const createdAtDate = new Date(data?.createdAt ?? createdAt.current);


  // Add 25 minutes (25 * 60 * 1000 milliseconds) to the createdAt time
  const expiredAtDate = new Date(createdAtDate.getTime() + 30 * 60000);

  // Convert the expiredAt Date object back to an ISO string


  const expiredAt = expiredAtDate.toISOString();

  // console.log(expiredAt, expiredAtDate.getTime()/60000/1000)

  const { timeRemaining, formatTime, stopTimer } = useTimeCounter(expiredAt);

  const { openModal } = useResponseModal();

  const mandateCred = data?.recipient;

  const [selectedBankIndex, setSelectedBankIndex] = useState(1);

  const [transferStep, setTransferStep] = useState({
    step: 0,
    state: "not sent",
    sentAt: null,
    message: "",
  });

  const [isLoading, setIsLoading] = useState(false);

  const recipientAccount = useMemo(() => {
    return mandateCred?.[selectedBankIndex];
  }, [selectedBankIndex, mandateCred]);

  const handleChangeRecipientAcc = () => {
    selectedBankIndex ? setSelectedBankIndex(0) : setSelectedBankIndex(1);
  };

  const confirmTransferDone = () => {
    setIsLoading(true);

    const currentTime = new Date(new Date().toISOString());

    setTransferStep({ step: 1, sentAt: currentTime, state: "sent" });

    // details?.reference
    verifyMandateFn({ queryKey: ["verify_mandate", data?.reference] })
      .then((res) => {
        // closeModal();
        // openModal({ status: true, message: res?.data?.message });

        setTransferStep({
          ...transferStep,
          step: 2,
          setAt: currentTime,
          state: "recieved",
        });

        setTimeout(() => {
          setTransferStep({
            ...transferStep,
            step: 3,
            setAt: currentTime,
            state: "success",
            message: "Order Complete",
          });
        }, 3000);
      })
      .catch((error) => {
        setTransferStep({
          ...transferStep,
          step: 1,
          setAt: currentTime,
          state: "error",
          message: error?.response?.data?.message ?? error?.message,
        });
       
      })
      .finally(() => {
        setIsLoading(false);
      });
  };


  const generateNewAccount=()=>{
    console.log("generate account")
  }

  const handleCancel=()=>{
    closeModal();
    navigate(ROUTES.shop)
  }

  return (
    <>
      {/* <ModalDialogBox open={isOpen} handleClose={closeModal} modalSize={false}> */}
      <DrawerBox open={isOpen} handleClose={closeModal} size={"40rem"}>
        {transferStep?.state === "success" ||
        transferStep?.state === "error" ? (
          <MandateTransferResult
            setTransferStep={setTransferStep}
            transferStep={transferStep}
            closeModal={closeModal}
          />
        ) : (
          <div className="col-lg-10 mx-auto col-md-8 col-12">
            <div>
              <h3 className="text-center">Complete Order</h3>
              <>
                <div className="d-flex gap-3 w-auto justify-content-center align-items-center mt-4 mb-3">
                  <VouchImage src={vouch_logo} alt={"vouch"} />
                  <MonoImage src={mono_logo} alt={"mono"} />
                </div>
                {transferStep?.step === 0 ? (
                  <>
                    <p className="mx-4 mb-3">
                      Mono is the payment partner on Vouch. By making the
                      transfer below, you authorize Mono to set a direct debit
                      mandate on your bank account to ensure repayment.{" "}
                    </p>

                    <div>
                      <div
                        className="border rounded-3 mx-auto ps-5 pe-3 py-4"
                        style={{ backgroundColor: "#FAF9F9" }}
                      >
                        <p className="fw-bold text-center mt-2 mb-3">
                          Transfer 50 naira
                        </p>
                        <div>
                          <div className="h-100 d-flex flex-column gap-5 ">
                            <div>
                              <p
                                className="d-flex justify-content-between align-items-center"
                                style={{
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  lineHeight: "5px",
                                }}
                              >
                                {"SEND FROM"}
                              </p>
                              <p
                                className="d-flex justify-content-between my-2 align-items-center"
                                style={{
                                  fontWeight: 600,
                                  fontSize: "20px",
                                  color: "#000",
                                  lineHeight: "12px",
                                }}
                              >
                                <span className="d-flex flex-column mt-2">
                                  {data?.user?.account_number ?? ""}
                                  <small
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "13px",
                                      lineHeight: "5px",
                                      marginTop: "13px",
                                    }}
                                  >
                                    {data?.user?.institution?.name}
                                  </small>
                                </span>
                                {
                                  //   <img
                                  //   src={mandateCred?.[0]?.icon}
                                  //   alt={"Fidelity"}
                                  //   width={50}
                                  // />
                                }
                              </p>
                            </div>

                            <div>
                              <p
                                className="d-flex justify-content-between align-items-center"
                                style={{
                                  fontWeight: 500,
                                  fontSize: "15px",
                                  lineHeight: "5px",
                                }}
                              >
                                {"SEND TO"}
                                <span
                                  className="text-primary fw-bold cursor-pointer"
                                  onClick={handleChangeRecipientAcc}
                                >
                                  CHANGE
                                </span>
                              </p>
                              <p
                                className="d-flex justify-content-between my-2 align-items-center"
                                style={{
                                  fontWeight: 600,
                                  fontSize: "20px",
                                  color: "#000",
                                  lineHeight: "12px",
                                }}
                              >
                                <span className="d-flex flex-column">
                                  {recipientAccount?.account_number}
                                  <small
                                    style={{
                                      fontWeight: 400,
                                      fontSize: "13px",
                                      lineHeight: "5px",
                                      marginTop: "12px",
                                    }}
                                  >
                                    {recipientAccount?.bank_name}
                                  </small>
                                </span>
                                <img
                                  src={recipientAccount?.icon}
                                  alt={recipientAccount?.bank_name}
                                  width={50}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="mt-4">
                            {timeRemaining <= 0 ? (
                              <p>
                                {" "}
                                <small
                                  style={{ color: "#940B0B", fontSize: "16px" }}
                                >
                                  This account details has expired
                                </small>
                              </p>
                            ) : (
                              <div className="tw-flex tw-items-center">
                                <p>
                                  This {recipientAccount?.bank_name} account
                                  expires in{" "}
                                <span
                                  className="w-auto py-1 tw-px-3 text-sm tw-text-[#333332]"
                               
                                >
                                  {timeRemaining > 0
                                    ? formatTime(timeRemaining)
                                    : "00:00"}
                                </span>
                                </p>
                              </div>
                            )}


                          </div>
                        </div>
                      </div>

                      <div className="col-lg-8 mx-auto d-flex flex-column gap-3 mt-3">
                        <Button
                          shape="round"
                          size="large"
                          style={{
                            background: "#033F4B",
                            color: "#fff",
                            fontSize: "18px",
                            height: "55px",
                          }}
                          loading={isLoading}
                          onClick={confirmTransferDone}
                        >
                          I’ve sent the money
                        </Button>
                        <Button
                          shape="round"
                          size="large"
                          style={{
                            background: "#FFFF",
                            border: "2px solid #033F4B",
                            color: "#033F4B",
                            fontSize: "18px",
                            height: "55px",
                          }}
                          onClick={handleCancel}
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                  </>
                ) : (
                  <CheckingMandateTransfer
                    transferStep={transferStep}
                    setTransferStep={setTransferStep}
                  />
                )}
              </>
            </div>
          </div>
        )}
      </DrawerBox>
      {/* </ModalDialogBox> */}
    </>
  );
}

const VouchImage = styled.img`
  width: 10%;
  maxwidth: 10%;
  @media only screen and (max-width: 864px) {
    width: 20%;
    maxwidth: 20%;
  }
`;
const MonoImage = styled.img`
  width: 20%;
  maxwidth: 20%;
  @media only screen and (max-width: 864px) {
    width: 30%;
    maxwidth: 30%;
  }
`;
