import { useEffect, useState } from "react";
import CustomButton from "../../CustomButton/CustomButton";
import "./verifyAccount.css";
import { useVerifyAccount } from "../../../redux/hooks/getHooks";
import { ROUTES } from "../../../routes/route";
import { useNavigate } from "react-router-dom";
import { Button } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { verifyAccountFn } from "../../../redux/fetchingFn";
import styled from "styled-components";
const VerifyAccount=({token})=>{
    const verifyAccount = useVerifyAccount(token);

    const queryClient = useQueryClient();

    const navigate = useNavigate();
    const verifyconfirmed=()=>{
        navigate(ROUTES.login)
    }

    const reVerify=()=>{
      queryClient.invalidateQueries(['verify_account', token], verifyAccountFn)
    }


    if(verifyAccount?.isLoading){
        return(
            <main className="loading_container d-flex align-items-center justify-content-center" style={{height: "80vh"}}>
        <img src="https://gcro.openup.org.za/loading.a08ac827.gif" width={40} height={40} alt="loader" />
      </main>
        )
    }
    if(verifyAccount?.isError){
        return(
          <main className="loading_container d-flex align-items-center justify-content-center flex-column gap-3" style={{height: "80vh"}}>
            <img src="https://assets-v2.lottiefiles.com/a/b5641ed8-1152-11ee-ada0-8f4e8e17569e/RpGVFAqeZF.gif" width={50} height={50} alt="loader" />
            <h5>Oops! An error occurred. Account is not verified yet</h5>


            <Button
              shape="round"
              size="large"
              style={{
                backgroundColor: "#033F4B",
                color: "#fff",
                fontSize: "18px",
                height: "49px",
              }}
              loading={verifyAccount?.isLoading}
              onClick={reVerify}
            >
              Retry
            </Button>
          </main>
        )
      }

    return(
        <>
            <main className="verify_account">
                <section className="lh-3">
                <CheckMark>
                  <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </CheckMark>
                   <p>Your {verifyAccount?.data?.data?.message ?? "Email is verified successfully"}</p> <br />
                    <CustomButton text="Ok" functionFn={verifyconfirmed}/>
                </section>
            </main>
        </>
    )
}
export default VerifyAccount;


const CheckMark = styled.div`
  width: 80px;
  height: 80px;
  margin: 3vh auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::after {
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
      z-index: 2;
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
`;