import { useState } from "react";
import { ActiveNotificationIcon, NotificationIcon } from "../../../icons/icons";

export default function HeaderContainer({changeStatus, activeIndex, changeActiveIndex, status, handleOpenDrawer}){
    const menuData= {
        shopper: [
            { name: 'New Applicant', code: 'newApplicant' },
            { name: 'Active User', code: "activeUser" },
            { name: 'Credit', code: 'credit' },
        ],
        merchant: [
            { name: 'New onboarding Application', code: "newOnboardApplication" },
            { name: 'Active Merchant', code: "activeMerchant" },
            { name: 'Settlement', code: "settlement" },
            { name: 'Request', code: "request" },
        ]
    }

    return(
        <>
            <main className="mt-4" style={{transition: 'all 0.5s ease'}}>
                <section className="d-flex justify-content-end align-items-end">
                    {
                        status==='shopper'&&
                <div className="d-flex gap-3 ">
                    <p className="fw-bold cursor-pointer"><ActiveNotificationIcon/> Credit Notification</p>
                    <p className="fw-bold cursor-pointer"><NotificationIcon /> Merchant Notification</p>
                </div>
                }
                </section>
                <section className="d-flex gap-4">
                    <p className={`cursor-pointer ${status==='shopper'&&'fw-bold'}`} onClick={()=>changeStatus('shopper')}>Shopper</p>
                    <p className={`cursor-pointer ${status==='merchant'&&'fw-bold'}`} onClick={()=>changeStatus('merchant')}>Merchants</p>
                </section>
                <section className="d-flex justify-content-between flex-wrap">
                    <section className="d-flex gap-3">
                        {
                            menuData[status]?.map((item, index)=>{
                                return(
                                    <p key={index} className={`cursor-pointer ${index===activeIndex.index &&'fw-bold'}`} onClick={()=>{ 
                                    changeActiveIndex(index, item.code)
                                }}>{item.name}</p>
                                )
                            })
                        }
                    </section>
                    <section className="d-flex gap-3">
                        <p className="cursor-pointer fw-bold" onClick={()=>handleOpenDrawer('admin')}>
                            + Add New Admin
                        </p>
                        {
                            status==='shopper'&&
                            <p className="cursor-pointer fw-bold">
                            Manage Admin
                            </p>
                        }

                    </section>
                </section>
            </main>
        </>
    )
}
