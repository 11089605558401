
import { useCallback, useMemo, useState } from "react";
import { DialogTitle, TableRow, TableCell } from "@mui/material";
import { useGetUserOrder } from "../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../utils/Loader";
import UserOrderTable from "../Shop/ShopNow/UserOrderTable";
import ShoppingHistory from "../Shop/ShopNow/ShoppingHistory";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import PayContainer from "../Shop/ShopNow/PayContainer";
import { useUserData } from "../hooks/useUserData";
import { formatNumber } from "../../utils/formatNumber";
import moment from "moment";
import BillDetail from "../Shop/ShopNow/BillDetail";
import { Modal } from "antd";

function Credit({handleClick}) {
  const { data, isLoading, isError } = useGetUserOrder("all");
  // const credits = (data?.data?.data)?.filter(item => item.status === 'order delivered' && item.status === 'repaid');

  const credits = (data?.data?.data)?.filter(order => order.status === 'order delivered');


  const unPaidCredits = credits?.filter(item=>item?.status !== 'repaid');

  const penaltyRate = 0.3/100

  const user = useUserData();

  const [open, setOpen] = useState({status: false, data: ""})

  const handleOpenDialog=(_, order)=>{
    setOpen({...open, status: true, data: order})
  }

  const handleClose=()=>{
    setOpen({...open, status: false});
  }

    // const dueDateExceeded = new Date("2024-03-02T07:51:57.000Z") < new Date();

    const calculatePenalCharge = useCallback((order) => {

      const totalAmount = parseFloat(order?.total_amount)
      const repaymentDate = order?.repaymentDuration
      const daysOfDefault = moment().diff(moment(repaymentDate), "days")

      const dueDateExceeded = new Date(repaymentDate) < new Date();
      if(dueDateExceeded){
        let totalPenalCharges = penaltyRate * totalAmount * daysOfDefault
        
        return totalPenalCharges
      } else return 0
    }, []);


    const penalCharges = useMemo(()=>{
      const totalCreditAmount = unPaidCredits?.reduce((acc, item)=>acc + calculatePenalCharge(item), 0);
    
      // console.log(totalCreditAmount)
      return totalCreditAmount;
    }, [unPaidCredits, calculatePenalCharge])



 
    
  

  return (
    <>
      <main className="mx-3">
        <section className="text-center">
          <DialogTitle className="fw-bold">My Credits</DialogTitle>
        </section>
        <section className="col-lg-11 col-md-11 mx-auto d-flex justify-content-between my-4 bg-white p-4 flex-wrap"
         style={{
          boxShadow: "0px 4px 8px 0px #31715929",
        }}
        >
        <h6 className="fw-medium">
                      Available Credit:{" "}
                      {formatNumber(user?.data?.creditEstimate?.remainingCredit ?? 0)}
                    </h6>
                    <h6 className="fw-medium">
                      Total Penal charges:{" "}
                      { formatNumber(penalCharges ?? 0) }
                    </h6>

        </section>
      
        <section>
          {isLoading ? (
            <IsLoadingLoader />
          ) : isError ? (
            <IsErrorLoader />
          ) : (
            credits?.length?(
              <ShoppingHistory rows={credits} handleOpenDialog={handleOpenDialog}/>
              // <UserOrderTable rows={credits}/>
            )
            : (
              <p className={`card-tex text-center my-2 text-muted fs-5`}>
              <i>No Credit available!!</i>
            </p>
            )
          )}
        </section>
      </main>


      
      {open?.data?.is_bill ? (
        <Modal
          centered
          open={open?.status}
          onCancel={handleClose}
          footer={null}
        >
          {" "}
          <BillDetail billData={open?.data} handleClick={handleClick} handleClose={handleClose}/>
        </Modal>
      ) : (
        <ModalDialogBox
          open={open?.status}
          handleClose={handleClose}
          modalSize={false}
        >
          <PayContainer
            order={open?.data}
            dueDate={user?.data?.creditEstimate?.dueDate}
            handleClose={handleClose}
            handleClick={handleClick}
          />
        </ModalDialogBox>
      )}


    </>
  );
}

export default Credit;
