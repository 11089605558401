import React from "react";
import { LogoutIcon, SettingIcon } from "../../../icons/icons";
import { Link, useNavigate } from "react-router-dom";
import { logoutFn } from "../../../utils/logout";
import { ROUTES } from "../../../routes/route";

export default function BottomMenu({ fn }) {
  const navigate = useNavigate();
  const menu = [{ id: 0, path: "", title: "Setting", icon: <SettingIcon /> }];

  const logout = () => {
    let shouldLogout = window.confirm(
      "Do you Really want to Log out this account?"
    );
    if (shouldLogout) {
      logoutFn("ADMIN");
      navigate(ROUTES.adminLogin);
    }
  };

  return (
    <>
      <section className="">
        <div className="d-flex flex-column gap-3 border">
          {menu.map((item, index) => {
            return (
              <Link
                to={item.path}
                key={index}
                onClick={fn}
                className={`text-decoration-none text-dark d-flex gap-2 ${
                  item.active && "fw-bold"
                }`}
              >
                <span >{item.icon}</span>
                <span>
                    {item.title}
                </span>
              </Link>
            );
          })}
          <div
            className="side_nav_menu d-flex gap-2"
            style={{ cursor: "pointer" }}
            onClick={logout}
          >
            <LogoutIcon size={"25"} />
            <p className="my-auto">
              <span>Logout</span>
            </p>
          </div>
        </div>
      </section>
    </>
  );
}
