import { useEffect, useRef, useState } from "react";

export const useTimeCounter = (accountExpiresAt) => {
    const timerInterval = useRef(null);
    const [timeRemaining, setTimeRemaining] = useState(() => {
        const expiresAtUTC = new Date(accountExpiresAt);
        const nowUTC = new Date(new Date().toISOString());
        return Math.max(0, expiresAtUTC.getTime() - nowUTC.getTime());
    });


    const startTimer = () => {
        const updateTimer = () => {
            const expiresAtUTC = new Date(accountExpiresAt);
            const nowUTC = new Date(new Date().toISOString());
            const timeLeft = expiresAtUTC.getTime() - nowUTC.getTime();


            if (timeLeft <= 0) {
                clearInterval(timerInterval.current);
                setTimeRemaining(0);
            } else {
                setTimeRemaining(timeLeft);
            }
        };

        if (accountExpiresAt) {
            updateTimer(); // Initialize the time remaining immediately
            timerInterval.current = setInterval(updateTimer, 1000);
        }
    };

    const stopTimer = () => {
        if (timerInterval.current) {
            clearInterval(timerInterval.current);
            timerInterval.current = null;
        }
    };

    useEffect(() => {
        startTimer();

        return () => {
            stopTimer();
        };
    }, [accountExpiresAt]);

    const formatTime = (time) => {
        const hours = Math.floor((time % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        const minutes = Math.floor((time % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((time % (1000 * 60)) / 1000);

        let formattedTime = '';

        if (hours > 0) {
            formattedTime += `${hours.toString().padStart(2, '0')}:`;
        }
        
        formattedTime += `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;

        return formattedTime;
    };

    return { timeRemaining, formatTime, stopTimer };
};
