import { useDispatch } from "react-redux";
import profile from "../../../../../assets/avatar.png";
import CustomButton from "../../../../CustomButton/CustomButton";
import AddressInfo from "./AddressInfo";
import PersonalInfo from "./PersonalInfo";
import WorkInfo from "./WorkInfo";
import { stepperAction } from "../../../../../redux/slice/stepperSlice";
function KycSummary({formik, isLoading}) {
  
  const dispatch = useDispatch();
  const editFn = (step)=>{
    dispatch(stepperAction.setStep(step));
  }

  const profileStyle ={
    backgroundImage: `url(${formik.values.image??formik.values.image})`,
    width: '30vh',
    height: '30vh',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: "50%"
  }
  return (
    <>
      <main>
        <p className="text-center"  style={{color: "#323C4D",fontSize: "20px", fontStyle: "normal",fontWeight: "500",lineHeight: "normal"}}>Summary</p>
        <br />
        <section>
          <div className="d-flex justify-content-center align-items-center">
          {
            formik.values.image?
            <div style={profileStyle}></div>:""
          }
          </div>
          <section>
            <PersonalInfo formik={formik} editFn={()=>editFn(1)}/>
            <AddressInfo formik={formik} editFn={()=>editFn(2)}/>
            <WorkInfo formik={formik} editFn={()=>editFn(3)}/>
          </section>
        </section>
        <section className="text-center">
              <CustomButton text={"Submit"} type="submit" functionFn={formik.handleSubmit} loading={isLoading}/>
            </section>
      </main>
    </>
  );
}

export default KycSummary;
