import React, { useEffect, useState } from 'react'
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import * as yup from "yup";
import styled from 'styled-components';
import CustomButton from '../../CustomButton/CustomButton';
import { useResetPsw } from '../../../redux/mutation/postMutation';
import AlertDialog from '../../Buttons/dialog';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/route';
function ResetPsw({handleClick, token}) {
    const [isLoading, setIsLoading] = useState(false)
    const [open, setOpen] = useState(false);
    const resetPswMutation = useResetPsw({});
    const navigate = useNavigate()

    const pattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/

    const handleClickOpen = () => {
      setOpen(true);
    };
  
    const handleClose = () => {
      setOpen(false);
      navigate(ROUTES.login)
    };
    const formik = useFormik({
        initialValues: {
            password: "",
            confirm_password: ""
        },
        onSubmit: async(values, {resetForm})=>{
            setIsLoading(true)
            resetPswMutation.mutate({password: values.confirm_password, confirm_password: values?.confirm_password, token: token}, {onSuccess:()=>resetForm()});
        },
        validationSchema: yup.object().shape({
            password: yup.string().required("Password cannot be empty!").min(8, "The password you entered is too short!").matches(pattern, "Password should contains uppercase,lowercase, digit, and at least one special characters"),
            confirm_password: yup.string().label('confirm password').required().oneOf([yup.ref('password'), null], 'Passwords must match'),
          })
    })

    useEffect(()=>{
        if(resetPswMutation.isLoading){
          setIsLoading(true);
        }
        else if (resetPswMutation.isError){
            setIsLoading(false);
            let message = resetPswMutation?.error?.response?.data?.message;
            handleClick(message, "error");
        }
        else if (resetPswMutation.isSuccess){
          handleClickOpen();
          setIsLoading(false);
        }
    }, [resetPswMutation.status]);

  return (
    <>
        <Section className="mx-3">
      <form className='col-sm-8 my-3 mx-auto' onSubmit={formik.handleSubmit}>
        <h2 className="fw-bold">Reset Password</h2>
        <p>Enter your new password for your account</p>
        <div className='Password'>
          <div className="label my-2">
            <label htmlFor="password" className='fw-bold'>Password</label>
          </div>
          <TextField type='password' id="standard-basic" size='small' name='password' error={formik.touched.password && formik.errors.password} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Enter Your Password" fullWidth variant="outlined" className='rounded-pill' helperText={formik.errors.password} InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}/>
        </div>
        <div className='confirm_password'>
          <div className="label my-2">
            <label htmlFor="confirm_password" className='fw-bold'>Confirm Password</label>
          </div>
          <TextField type='password' id="standard-basic" size='small' name='confirm_password' error={formik.touched.confirm_password && formik.errors.confirm_password} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder="Confirm your Password" fullWidth variant="outlined" className='rounded-pill' helperText={formik.errors.confirm_password} InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}/>
        </div>
        <div className='submit_btn my-3'>
          <CustomButton loading={isLoading} text={"Reset Password"} />
        </div>
      </form>
      <AlertDialog open={open} header={"Success"} text={`Congratulations! Your password reset is  successfully, proceed to login`} second_text={"Thank you for choosing Vouch!."} handleClose={handleClose}/>
    </Section>
    </>
  )
}

export default ResetPsw

const Section = styled.section`
@media only screen and (max-width: 575px){
    width: 90vw;
}
`