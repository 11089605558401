import { useQuery } from "@tanstack/react-query"
import { activeCreditFn, allCreditFn, allMerchantOrder, adminProfileFn, creditRepayment, getAllMerchant, getAllSettlement, getSubscriptionPlansFn, getUserSubscriptionPlansFn, getUserAccountSummary, getUserBkStatementFn, getUsers, meFn, merchantOrder, merchantProfile, merchantSettlement, myCreditFn, newUsers, reconnectFn, refreshTokenFn, sendOtpFn, verifyAccountFn, getAdminSubscriptionTypeFn, getMarketPlacesFn, getUserSubscriptionFn, getUserOrderFn, getUserEstimatedCreditFn, initiatePaymentFn, verifyPaymentFn, getSingleMarketPlaceFn, getUserTransactionsHistoryFn, getTransactionsHistoryFn, getSingleUserOrderFn, getUserUpperPlansFn, getAdminUpgradeRequestFn, getUserUpgradeRequestFn, airtimeProviderFn, getDataBundleFn, electricProviderFn, cableProviderFn, getCableProductsFn, getCableAddonFn, getOrderTransactionFn, getScheduleTransaction, getSchedules, getDiscos, getMandateSchedule, getMandates, getUserMandate } from "../fetchingFn"



export const useMe=()=>{
    return useQuery(["me"], meFn)
}
export const useRefreshToken=()=>{
    return useQuery(["refresh_token"], refreshTokenFn)
}
export const useVerifyAccount=(token)=>{
    return useQuery(['verify_account', token], verifyAccountFn);
}
export const useReconnect =()=>{
    return useQuery(["reconnect"], reconnectFn)
}
export const useGetBankStatement=(accountId)=>{
    return useQuery(["bank_statement", accountId], getUserBkStatementFn, {enabled: accountId?true:false, refetchOnMount: true})
    // return useQuery(["bank_statement", accountId], getUserBkStatementFn)
}
export const useGetAllCredit=(pageNumber, status)=>{
    return useQuery(["all_credit", pageNumber ?? 1, status ?? 'all'], allCreditFn)
}
export const useMyCredit=()=>{
    return useQuery(["user_credit"], myCreditFn)
}
export const useActiveCredit=()=>{
    return useQuery(["active_credit"], activeCreditFn)
}
export const useSendOtp = ()=>{
    return useQuery(['send_otp'], sendOtpFn);
}
export const useGetAllMerchant=()=>{
    return useQuery(['all_merchant'], getAllMerchant)
}
export const useGetMerchantOrder=()=>{
    return useQuery(['merchant_order'], merchantOrder)
}
export const useGetAllMerchantOrder=()=>{
    return useQuery(['all_merchant_order'], allMerchantOrder)
}
export const useGetAdminProfile=()=>{
    return useQuery(['admin_profile'], adminProfileFn)
}
export const useGetMerchantProfile=()=>{
    return useQuery(['merchant_profile'], merchantProfile)
}
export const useGetAllSettlement=()=>{
    return useQuery(['all_settlement'], getAllSettlement)
}
export const useGetMerchantSettlement=()=>{
    return useQuery(['merchant_settlement'], merchantSettlement)
}
export const useGetUsers=(status)=>{
    return useQuery(['users', status], getUsers);
}
export const useGetAllUsers=(status)=>{
    return useQuery(['all_users'], getUsers);
}
export const useGetNewUsers =()=>{
    return useQuery(['new_users'], newUsers);
}
export const useGetCreditRepayment=(creditId)=>{
    return useQuery(["credit_repayment", creditId], creditRepayment)
}
export const useGetUserAccountSummary=(accountId)=>{
    return useQuery(["user_account_summmary", accountId], getUserAccountSummary, {enabled: accountId?true:false, refetchOnMount: true})
}

export const useGetUserTransactionHistory=(userID)=>{
    return useQuery(["user_transaction_history", userID], getUserTransactionsHistoryFn)
}
export const useGetOrderTransactionHistory=(orderID, role)=>{
    return useQuery(["order_transaction_history", orderID, role], getOrderTransactionFn)
}
export const useGetTransactionHistory=()=>{
    return useQuery(["transaction_history"], getTransactionsHistoryFn)
}
export const useGetSubscriptionPlan=()=>{
    return useQuery(['admin_subscription_plan'], getSubscriptionPlansFn)
}
export const useGetUserSubscriptionPlan=()=>{
    return useQuery(['user_subscription_plan'], getUserSubscriptionPlansFn)
}

export const useGetUserUpperPlans=()=>{
    return useQuery(['user_upper_plans'], getUserUpperPlansFn)
}

export const useGetUserSubscriptions=()=>{
    return useQuery(['user_subscription'], getUserSubscriptionFn)
}

export const useGetAdminSubscriptionType=(typeId)=>{
    return useQuery(['admin_subscription_type', typeId], getAdminSubscriptionTypeFn)
}

export const useGetAdminUpgradeRequest=(status)=>{
    return useQuery(['admin_upgrade_request', status], getAdminUpgradeRequestFn)
}

export const useGetUserUpgradeRequest=()=>{
    return useQuery(['user_upgrade_request'], getUserUpgradeRequestFn)
}

export const useGetMarketPlace =(role)=>{
    return useQuery(["market_place", role], getMarketPlacesFn)
}
export const useGetSingleMarketPlace =(role)=>{
    return useQuery(["single_market_place", role], getSingleMarketPlaceFn)
}

export const useGetUserOrder =(status)=>{
    return useQuery(["user_order", status], getUserOrderFn)
}

export const useGetUserCreditEstimate =(userID, role)=>{
    return useQuery(['user_credit_estimate', userID, role], getUserEstimatedCreditFn)
}

export const useInitiatePayment=(orderID)=>{
    return useQuery(["initiate_payment", orderID], initiatePaymentFn)
}


export const useVerifyRepayment=(reference)=>{
    return useQuery(["verify_payment", reference], verifyPaymentFn) // Not finished
}


export const useGetSingleUserOrder=(payload)=>{
    return useQuery(["user_single_order", payload], getSingleUserOrderFn)
}
export const useGetAirtimeProvider=()=>{
    return useQuery(["airtime_provider"], airtimeProviderFn)
}
export const useGetCableProvider=()=>{
    return useQuery(["cable_provider"], cableProviderFn)
}
export const useGetElectricProvider=()=>{
    return useQuery(["electric_provider"], electricProviderFn)
}

export const useGetDataBundle=(payload)=>{
    return useQuery(["data_bundle", payload], getDataBundleFn)
}
export const useGetCableProducts=(payload)=>{
    return useQuery(["cable_products", payload], getCableProductsFn)
}
export const useGetCableAddon=(payload)=>{
    return useQuery(["cable_addons", payload], getCableAddonFn)
}

export const useGetSchedules=()=>{
    return useQuery(["schedules"], getSchedules)
}
export const useGetScheduleTransactions=(scheduleID)=>{
    return useQuery(["schedule_transaction", scheduleID], getScheduleTransaction)
}
export const useGetUserMandate=(orderID)=>{
    return useQuery(["user_mandate", orderID], getUserMandate)
}
export const useGetDiscos=()=>{
    return useQuery(["all_discos"], getDiscos)
}

export const useGetMandates=()=>{
    return useQuery(["all_mandates"], getMandates)
}

export const useGetMandateSchedule=(mandateID)=>{
    return useQuery(["mandate_schedule", mandateID], getMandateSchedule)
}

