import React from 'react'

export function IsLoadingLoader() {
  return (
    <main className="loading_container d-flex align-items-center justify-content-center" style={{height: "25vh"}}>
  <img src="https://gcro.openup.org.za/loading.a08ac827.gif" width={50} height={50} alt="loader" />
</main>
  )
}

export function IsErrorLoader({desc}) {
  return (
    <main className="loading_container d-flex align-items-center justify-content-center flex-column gap-3" style={{height: "25vh"}}>
  <img src="https://assets-v2.lottiefiles.com/a/b5641ed8-1152-11ee-ada0-8f4e8e17569e/RpGVFAqeZF.gif" width={50} height={50} alt="loader" />
  <h6>{desc ?? "Oops! An error occurred. please check your connection"}</h6>
</main>
  )
}
