import React, { useState, useEffect } from 'react'
import SettlementTable from './Table/SettlementTable';
import { formatNumber } from '../../../../utils/formatNumber';
import ModalDialogBox from '../../../../components/ModalDialogBox/ModalDialogBox';
import SettlementDetail from './SettlementDetail';
import { useGetMerchantSettlement } from '../../../../redux/hooks/getHooks';

export default function Settlement() {
    const getMerchantSettlement = useGetMerchantSettlement();
    const merchantSettlement = getMerchantSettlement.data?.data?.data?.settlements;
    const [open, setOpen] = useState({status: false, details: {}});
    
    const handleOpen=(status, details)=>{
        setOpen({...open, status: status, details: details})
    }
    const handleClose=()=>{
        setOpen({...open, status: false})
    }

    useEffect(()=>{
      console.log(merchantSettlement);
    })

    const headCell = [
        {
          id: 'date',
          label: 'Date',
        },
        {
          id: 'settlementId',
          label: 'Settlement ID',
        },
        {
          id: 'recipientBank',
          label: 'Recipient Bank',
        },
        {
          id: 'amount',
          label: 'Amount',
        },
        {
          id: 'action',
          label: 'Action',
        }
      ];
      const rows = [
        {
            _id: '1',
            date: "13:30, June 3, 2023", settlementId: '123459w', recipientBank: 'Peter Pharmacy gtb, 1234567890', amount: 50000, action: ''
        }
    ]
  return (
    <>
        <section>
            <section className='my-4 mx-auto'>
                <div className="d-flex gap-3 flex-wrap justify-content-center">
                    <p className='my-auto'>Pending Settlement</p>
                    <p className="fw-bold my-auto">{formatNumber(5000)}</p>
                    <div className="border border-dark py-1 px-3 rounded-pill cursor-pointer" onClick={()=>handleOpen(true, rows[0])}>View Detail</div>
                </div>
            </section>
            <section className="col-lg-11 mx-auto table_header d-flex justify-content-between flex-wrap gap-3">
                    <p className='fw-bold'>Settlement</p>
                </section>
            <SettlementTable topHeaderVisible={true} rows={rows} 
            headCell={headCell} handleOpen={handleOpen}/>
        </section>

        <ModalDialogBox open={open.status} handleClose={handleClose} modalSize={true} maxWidth="md">
            <SettlementDetail />
        </ModalDialogBox>
    </>
  )
}
