
import styled, { keyframes} from "styled-components";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import { useUserData } from "../hooks/useUserData";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import { useResponseModal } from "../../redux/hooks/zustands/useResponseModal";
import { ROUTES } from "../../routes/route";

export default function ResponseModal() {
  const { isOpen, closeModal, data } = useResponseModal();

  const user = useUserData({});

  // const 

  const navigate = useNavigate();

const confirmCompletion=()=>{
  
    if(data?.status){
        if (user?.status) {
            navigate(ROUTES.shop);
          }
          
          else {
            navigate(ROUTES.landing_page);
          }
          closeModal()
    } else{
        closeModal()
    }
}

  return (
    <>
      <ModalDialogBox open={isOpen} handleClose={closeModal}>
      <div className="d-flex flex-column justify-content-center align-items-center">
            
              {data?.status ? (
                <CheckMark>
                  <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </CheckMark>
              ) : (
                  <ErrorContainer>
                    <ErrorIcon>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 24 24"
                        fill="none"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <circle cx="12" cy="12" r="10"></circle>
                        <line x1="9" y1="9" x2="15" y2="15"></line>
                        <line x1="15" y1="9" x2="9" y2="15"></line>
                      </svg>
                    </ErrorIcon>
                  </ErrorContainer>
                )
              }
              <p className="fs-5">
               {data?.message}
              </p>{" "}
           
             
              <br />
              {/* <br /> */}
              <Button
                shape="round"
                size="large"
                style={{
                  background: "#033F4B",
                  color: "#fff",
                  fontSize: "18px",
                }}
                onClick={
                  confirmCompletion}
              >
               OK
              </Button>
            </div>
      </ModalDialogBox>
    </>
  );
}

const CheckMark = styled.div`
  width: 80px;
  height: 80px;
  margin: 3vh auto;

  .check-icon {
    width: 80px;
    height: 80px;
    position: relative;
    border-radius: 50%;
    box-sizing: content-box;
    border: 4px solid #4caf50;

    &::after {
      border-radius: 0 100px 100px 0;
      animation: rotate-circle 4.25s ease-in;
    }

    &::before,
    &::after {
      content: "";
      height: 100px;
      position: absolute;
      background: #ffffff;
      transform: rotate(-45deg);
      z-index: 2;
    }

    .icon-line {
      height: 5px;
      background-color: #4caf50;
      display: block;
      border-radius: 2px;
      position: absolute;
      z-index: 10;

      &.line-tip {
        top: 46px;
        left: 14px;
        width: 25px;
        transform: rotate(45deg);
        animation: icon-line-tip 0.75s;
      }

      &.line-long {
        top: 38px;
        right: 8px;
        width: 47px;
        transform: rotate(-45deg);
        animation: icon-line-long 0.75s;
      }
    }

    .icon-circle {
      top: -4px;
      left: -4px;
      z-index: 10;
      width: 80px;
      height: 80px;
      border-radius: 50%;
      position: absolute;
      box-sizing: content-box;
      border: 4px solid rgba(76, 175, 80, 0.5);
    }

    .icon-fix {
      z-index: 1;
      height: 85px;
      position: absolute;
      transform: rotate(-45deg);
      background-color: #ffffff;
    }
  }

  @keyframes rotate-circle {
    0% {
      transform: rotate(-45deg);
    }

    5% {
      transform: rotate(-45deg);
    }

    12% {
      transform: rotate(-405deg);
    }

    100% {
      transform: rotate(-405deg);
    }
  }

  @keyframes icon-line-tip {
    0% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    54% {
      width: 0;
      left: 1px;
      top: 19px;
    }

    70% {
      width: 50px;
      left: -8px;
      top: 37px;
    }

    84% {
      width: 17px;
      left: 21px;
      top: 48px;
    }

    100% {
      width: 25px;
      left: 14px;
      top: 45px;
    }
  }

  @keyframes icon-line-long {
    0% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    65% {
      width: 0;
      right: 46px;
      top: 54px;
    }

    84% {
      width: 55px;
      right: 0px;
      top: 35px;
    }

    100% {
      width: 47px;
      right: 8px;
      top: 38px;
    }
  }
`;

const drawAnimation = keyframes`
    to {
        stroke-dashoffset: 0;
    }
`;

const ErrorContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
`;

const ErrorIcon = styled.div`
  width: 80px;
  height: 80px;
  margin-right: 10px;

  svg {
    width: 100%;
    height: 100%;
    stroke: red; /* Color of the times icon */
    stroke-width: 1.5px;
    stroke-dasharray: 60;
    stroke-dashoffset: 60;
    animation: ${drawAnimation} 0.85s ease forwards;
  }
`;