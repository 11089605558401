import React, { useState, useEffect } from 'react'
import MerchantSidebar from '../../components/Merchants/Sidebar/MerchantSidebar'
import { Outlet } from 'react-router-dom';
import "./merchantLayout.css";
import { useGetAllMerchant } from '../../redux/hooks/getHooks';
export default function MerchantLayout() {
    const [navIsOpen, setNavIsOpen] = useState(true);
    const getAllMerchant = useGetAllMerchant();
    // console.log(getAllMerchant?.data?.data);
    const handleOpenNav=(param)=>{
        setNavIsOpen(param);
    }
    useEffect(()=>{
      if(window.innerWidth<768){
          setNavIsOpen(false);
      }
    }, [])

  return (
    <>
        <main >
            <MerchantSidebar visible={navIsOpen} show={handleOpenNav}/>
            <section className={!navIsOpen? "page": "page page-with-navbar"} onClick={()=>{
              if(window.innerWidth<768){
                navIsOpen&& handleOpenNav(!navIsOpen)
              }
            }}>
              <section className="col-sm-11 mx-auto">
                <Outlet />
              </section>
            </section>
        </main>
    </>
  )
}
