import { useRef } from "react"
import "./capturePhoto.css";
import loader from "../../../../../../assets/loader1.gif"
export const CapturePhoto=({setFileUrl, videoRef, setOpen, idle, formik, setImageFile})=>{
    const photoRef = useRef();

    const takePhoto=()=>{
        const width = 714;
        const height = width / (16/ 9);

        let video = videoRef.current;
        let photo = photoRef.current;

        photo.width = width;
        photo.height = height;

        let ctx = photo.getContext('2d');
        ctx.drawImage(video, 0, 0, width, height);
        let photoRawRef = photoRef.current;
        let url = photoRawRef.toDataURL();
        
        //convert the url to image file;
        fetch(url)
        .then(res => res.blob())
        .then(blob => {
            const file = new File([blob], "File name",{ type: "image/png" })
            setImageFile(file)
        })
        // formik.setFieldValue("image", url);
        setFileUrl(url);
        setOpen(false);
        stopVideoStream();
    }

    const stopVideoStream = () => {
        const tracks = videoRef.current.srcObject.getTracks();
        tracks.forEach((track) => track?.stop());
        videoRef.current.srcObject = null; // Reset the video source
      };

    return(
        <>
            <main className="camera_section">
                {
                    idle?
                    <img src={loader} className="card-img-top" alt="" />:(
                        <>
                        </>
                    )
                }
                            <div className="camera">
                                <video className="video border" ref={videoRef}></video>
                            </div>
                            <div className={"result "}>
                                <canvas id="myCanvas" ref={photoRef}></canvas>
                            </div>
                            <div className="btn_container" onClick={takePhoto}>
                                <div></div>
                            </div>
            </main>
        </>
    )
}