import { useFormik } from "formik";
import * as yup from "yup";
import AuthHeader from "../AuthHeader";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import AuthButton from "../AuthButton/AuthButton";
import { ROUTES } from "../../../routes/route";
import { useLoginMutation } from "../../../redux/mutation/postMutation";
import { useEffect, useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import "./login.css";
function LoginForm({ handleClick }) {
  const loginMutation = useLoginMutation();
  const navigate = useNavigate();
  //useStates
  const [isLoading, setIsLoading] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  //
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => {
      if (localStorage.loaded) localStorage.removeItem("loaded");
      setIsLoading(true);
      loginMutation.mutate(values);
    },
    validationSchema: yup.object().shape({
      email: yup
        .string()
        .required("Email can not be empty!")
        .email("Invalid email address!"),
      password: yup.string().required("password is required!"),
    }),
  });

  useEffect(() => {
    if (loginMutation.isLoading) {
      setIsLoading(true);
    } else if (loginMutation.isSuccess) {
      setIsLoading(false);

      let response = loginMutation?.data?.data;

      localStorage.setItem("role", response?.data?.role);
      // console.log(response);
      // handleClick(response?.message, "success");
      if (response?.data?.role === "user") {
        localStorage.setItem("accessToken", response?.data?.accessToken);
        localStorage.setItem("refreshToken", response?.data?.refreshToken);
        navigate(ROUTES.shop);
      } else if (response?.data?.role === "admin") {
        // console.log(response?.data?.accessToken);
        localStorage.setItem("adminAccessToken", response?.data?.accessToken);
        localStorage.setItem("adminRefreshToken", response?.data?.refreshToken);
        navigate(ROUTES.adminDashboard);
      } else if (response?.data?.role === "merchant") {
        localStorage.setItem(
          "merchantAccessToken",
          response?.data?.accessToken
        );
        localStorage.setItem(
          "merchantRefreshToken",
          response?.data?.refreshToken
        );
        navigate(ROUTES.merchantDashboard);
      }
    } else if (loginMutation.isError) {
      setIsLoading(false);
      let message = loginMutation?.error?.response?.data?.message;
      let message2 = loginMutation?.error?.message;
      handleClick(message ?? message2, "error", {horizontal: "right", vertical: "top"}, 'login');
    }
  }, [loginMutation?.status]);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  return (
    <>
      <main className="mx-3">
        <form
          className="col-lg-8 col-12 mx-auto"
          onSubmit={formik.handleSubmit}
        >
          <AuthHeader title={"Sign In"} />

          <div className="Email" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="Email">Email *</label>
            </div>
            <TextField
              type="email"
              id="standard"
              name="email"
              required
              error={formik.touched.email && formik.errors.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter your Email Address"
              fullWidth
              className="mainEmail"
              value={formik.values.email}
              variant="outlined"
              size="large"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.email && formik.errors.email}
            </small>
          </div>
          <div className="password" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="password">Password *</label>
            </div>
            <TextField
              type={showPassword ? "text" : "password"}
              id="standard-basic"
              name="password"
              required
              error={formik.touched.password && formik.errors.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              placeholder="Enter password"
              value={formik.values.password}
              fullWidth
              size="large"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
                endAdornment: (
                  <InputAdornment position="start">
                    <IconButton onClick={handleClickShowPassword}>
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.password && formik.errors.password}
            </small>
          </div>

          <div className="submit_btn pb-2">
            <AuthButton loading={isLoading} text={"Sign In"} />
          </div>
          <div className="d-flex justify-content-between gap-4 flex-wrap text-center">
            <p className="my-auto mx-auto">
              <Link to={ROUTES.forgetPsw} className="text-decoration-none">
                <small
                  style={{
                    color: "#B41209",
                    fontSize: "16px",
                    fontWeight: 400,
                  }}
                >
                  Forgotten password
                </small>
              </Link>
            </p>
            <p className="my-auto mx-auto">
              <small className="" style={{ fontSize: "16px", fontWeight: 400 }}>
                New to vouch?{" "}
                <Link
                  className="text-decoration-none mt-5"
                  to={ROUTES.signup}
                  style={{ color: "#B41209", fontSize: "16px" }}
                >
                  Join
                </Link>
              </small>
            </p>
          </div>
        </form>
      </main>
    </>
  );
}

export default LoginForm;
