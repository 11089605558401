import { useState } from 'react'
import SuperMarketOption from './SuperMarketOption'
import SuperStore from './SuperStore'

export default function Supermarket({handleClick}) {
    const [option, setOption] = useState('')

  return (
    <>
        {
            option===''?(
                <SuperMarketOption setOption={setOption}/>
            ): option==='justrite'?(
                <SuperStore handleClick={handleClick}/>
            ): null
        }
    </>
  )
}
