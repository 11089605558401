import React, { useEffect } from 'react'
import AuthHeader from '../../../components/Auth/AuthHeader'
import gotv from "../../../assets/gotv.png"
import startime from "../../../assets/star time.png"
import dstv from "../../../assets/dstv.png"
import { useGetCableProvider } from '../../../redux/hooks/getHooks'
import { useDiscos } from '../../../components/hooks/useDiscos'

export default function CableOption({setCable, cable}) {

    // const getCableProvider = useGetCableProvider();
    const getCableProvider = useDiscos();

    // const providers = getCableProvider?.data?.data?.data?.providers;
    const providers = getCableProvider?.cableProvider

  useEffect(()=>{
    if(providers?.length){
      setCable(providers[0])
    }
  }, [])

  const changeOption = (cable)=>{
    setCable(cable)
  }


  return (
    <div className="col-lg-8 col-12 mx-auto">
        <AuthHeader title={"Pay for Cable Subscription"} />
        <p className="">Choose Cable tv</p>

        <div className='d-flex gap-4 flex-wrap align-items-center'>
        {
                providers?.map((item, index)=>(
                    <div key={index}
                    >
                        <img src={
                          (item)?.toLowerCase()==='dstv'? dstv: (item)?.toLowerCase()==='gotv'? gotv: (item)?.toLowerCase()==='startimes'&&startime
                        } alt={(item)?.toLowerCase()} onClick={()=>changeOption(item)} 
                        className={`p-1 cursor-pointer ${cable===item && 'border border-success border-3'} `} style={{
                          width: "57px",
                        maxWidth: "57px",
                        height: "100%",
                        borderRadius: "597.97px",
                        boxShadow: "0px 4px 4px 0px #00000040",
                        transition: "0.3s ease-in-out"
                      }}
                        
                        />
                
                    </div>

                ))
            }
          

        </div>
    </div>
  )
}
