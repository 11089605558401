import React from "react";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route";
export default function TopBar() {
  const navigate = useNavigate({});

  return (
    <>
      <section className="text-center">
        <h5
          className="fw-bold cursor-pointer"
          onClick={() => navigate(ROUTES.adminDashboard)}
        >
          Vouch Admin Dashboard
        </h5>
      </section>
    </>
  );
}
