
import { Button } from 'antd';
import React from 'react'
import { motion } from "framer-motion"
import { useResponsiveSize } from "../../components/hooks/useResponsiveSize";

export default function BillMenu({setBill, bill, toggleBillMenu}) {

  const responsiveSize = useResponsiveSize({});

    const handleSelectBill=(val)=>{
      setBill(val);
      responsiveSize==='sm' && toggleBillMenu();
    }
  return (
    <>
        <motion.div className='border-end'
        initial={{ x: -300 }}
        animate={{ x: 0 }}
        exit={{ x: -300 }}
        transition={{ duration: 0.5 }}
        style={{
          position: "absolute",
          minHeight: "60vh",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          width: "17rem",
          zIndex: 10
        }}
       
        >
          
        <div className="d-flex flex-column gap-3 pe-3">
            <Button
            className={`${bill==='electricity' && "border-secondary"}`}
            size={"large"}
            style={{
              borderRadius: "20px",
              textTransform: "capitalize"

            }}
            onClick={()=>handleSelectBill('electricity')}
            >
              Buy Electricity
            </Button>
            <Button
            className={`${bill==='airtime' && "border-secondary"}`}
            size={"large"}
            style={{
                borderRadius: "20px",
                textTransform: "capitalize"
  
              }}
            onClick={()=>handleSelectBill('airtime')}
            >
              Buy Airtime
            </Button>
            <Button 
            className={`${bill==='data' && "border-secondary"}`}
            size={"large"}
            style={{
                borderRadius: "20px",
                textTransform: "capitalize"
  
              }}
            onClick={()=>handleSelectBill('data')}
            >
              Buy Data
            </Button>
            <Button 
            className={`${bill==='cable' && "border-secondary"}`}
            size={"large"}
            style={{
                borderRadius: "20px",
                textTransform: "capitalize"
  
              }}
            onClick={()=>handleSelectBill('cable')}
            >
              Pay for Cable TV Subscription
            </Button>

          </div>
        </motion.div>
    </>
  )
}
