import React from 'react'
import { formatNumber } from '../../../../utils/formatNumber'
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function SettlementDetail() {
    const data = [
        {name: "John Doe", product: [
            {name: "Chemiron Tablet", timeStamp: "13:30, June 3, 20023", subTotal: 4000},
            {name: "Chemiron Tablet", timeStamp: "13:30, June 3, 20023", subTotal: 4000}
        ]},
        {name: "John Doe", product: [
            {name: "Chemiron Tablet", timeStamp: "13:30, June 3, 20023", subTotal: 4000},
            {name: "Chemiron Tablet", timeStamp: "13:30, June 3, 20023", subTotal: 4000}
        ]}
    ]
  return (
    <>
        <section className='d-flex flex-column gap-5 col-lg-10 mx-auto'>
            <h5 className="text-center">Details of Settlement for 3rd June 2023</h5>
            <section className="_table table-responsive">
                <TableContainer>
                <Table>
                    <TableHead className=''>
                        <TableCell><b>Customer</b></TableCell>
                        <TableCell><b>Product</b></TableCell>
                        <TableCell><b>Timestamp</b></TableCell>
                        <TableCell><b>Subtotal</b></TableCell>
                        <TableCell><b>Total</b></TableCell>
                    </TableHead>
                    <TableBody>
                        {
                            data?.map((item, index)=>{
                                return(
                        <TableRow>
                            <TableCell>
                                <p className="fw-bold">{item.name}</p>
                            </TableCell>

                            <TableCell>
                                {
                                    item.product.map((item, index)=>{
                                        return(
                                            <TableRow>{item.name}</TableRow>
                                        )
                                    })
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    item.product.map((item, index)=>{
                                        return(
                                            <TableRow>{item.timeStamp}</TableRow>
                                        )
                                    })
                                }
                            </TableCell>
                            <TableCell>
                                {
                                    item.product.map((item, index)=>{
                                        return(
                                            <TableRow>{formatNumber(item.subTotal)}</TableRow>
                                        )
                                    })
                                }
                            </TableCell>
                            <TableCell>
                                <TableRow>
                                </TableRow>
                                <TableRow>{formatNumber(8000)}
                                </TableRow>
                            </TableCell>
                        </TableRow>
                         )
                        })
                    }
                        <TableRow>
                            <TableCell colSpan={4}><b>Total Amount Settled</b></TableCell>
                            <TableCell><b>{formatNumber(8000)}</b></TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
                </TableContainer>
            </section>
        </section>
    </>
  )
}
