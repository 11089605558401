import { Button, Input } from "antd";
import { useState } from "react";
import InitiateMandateDrawer from "./InitiateMandateDrawer";
import { useInitiateMandate } from "../../../redux/hooks/zustands/useInitiateMandate";
import { useFormik } from "formik";
import * as yup from "yup"

export default function NewMandate({ handleClick }){
  const [isLoading, setIsLoading] = useState(false)

  const { openDrawer, closeDrawer } = useInitiateMandate()


  const phonePattern = /^0\d{10}$/

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      bvn: "",
      phone: "",
      email: "",
      tenor: "",
      total_interest: "",
      sc_file: null,
      total_amount: "",
      payment_type: ""
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required("Your First name can not empty!"),
      last_name: yup.string().required("Your Last name can not empty!"),
      phone: yup.string().required("Phone Number can not be empty!").matches(phonePattern, 'Phone number must be 11 digits and valid'),
      email: yup
        .string()
        .required("Email can not be empty!")
        .email("Invalid email address!"),
        bvn: yup.string().required("Phone Number can not be empty!"),
        tenor: yup.string().required("Phone Number can not be empty!"),
        total_interest: yup.string().required("Phone Number can not be empty!"),
        file: yup.string().required("Phone Number can not be empty!")
    }),

  })

 


  const handleSubmit=()=>{

    openDrawer("MANDATE_INFO", {formik})
    

  }




  const onChangeNumber=(e)=>{

    formik.setFieldValue(e.target.name, e.target.value)

  }
    return(
        <>
              <div className="tw-flex tw-items-center tw-justify-center tw-mt-4">
        <div className="tw-w-full tw-max-w-lg tw-px-6 tw-py-4 tw-rounded-lg tw-border tw-border-[#D9D9D996] tw-bg-white tw-shadow-sm">
              <h4 className="tw-font-[600] tw-text-[18px] tw-mb-2">
                Set New e-Mandate
              </h4>
              <form action="">
                <div className="tw-mb-3">
                  <Input name="first_name"  placeholder="Enter Borrower’s First Name" size={"large"} value={formik.values.first_name} className="tw-h-[55px]" status={formik.touched.first_name && formik.errors.first_name && "error"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}/>
                  <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.first_name && formik.errors.first_name}
            </small>
                </div>
                <div className="tw-mb-3">
                  <Input name="last_name" placeholder="Enter Borrower’s Last Name" size={"large"} value={formik.values.last_name} className="tw-h-[55px]" status={formik.touched.last_name && formik.errors.last_name && "error"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}/>
                  <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.last_name && formik.errors.last_name}
            </small>
                </div>
                <div className="tw-mb-3">
                  <Input name="bvn" type="number" placeholder="Enter BVN" size={"large"} value={formik.values.bvn} className="tw-h-[55px]" status={formik.touched.bvn && formik.errors.bvn && "error"}
              onChange={onChangeNumber}
              onBlur={formik.handleBlur}/>
                  <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.bvn && formik.errors.bvn}
            </small>
                </div>
                <div className="tw-mb-3">
                  <Input name="phone"
                    type="number"
                    placeholder="Enter Phone number"
                    size={"large"} value={formik.values.phone} className="tw-h-[55px]"
                    status={formik.touched.phone && formik.errors.phone && "error"}
              onChange={onChangeNumber}
              onBlur={formik.handleBlur}
                  />
                    <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.phone && formik.errors.phone}
            </small>
                </div>
                <div className="tw-mb-3">
                  <Input name="email"
                    type="email"
                    placeholder="Enter Email"
                    size={"large"}
                    value={formik.values.email}
                    className="tw-h-[55px]"
                    status={formik.touched.email && formik.errors.email && "error"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
                  />
                   <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.email && formik.errors.email}
            </small>
                </div>
                
                <div className="tw-mt-4">
                <Button
                onClick={handleSubmit}
                    size="large"
                    shape={"round"}
                    loading={isLoading}
                    className="tw-rounded-[8px] tw-bg-[#000000] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] tw-w-full hover:!tw-bg-[#000000] hover:!tw-text-[#FFF]"
                  >
                    Create mandate request
                  </Button>

                </div>
              </form>
          </div>
        </div>


<InitiateMandateDrawer handleClick={handleClick}/>

        </>
    )
}