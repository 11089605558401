import React, {useCallback, useState} from 'react'
import AdminTransactionHistoryTable from "./AdminTransactionHistoryTable";
import { useGetUserTransactionHistory, useGetSingleUserOrder, useGetOrderTransactionHistory } from "../../../redux/hooks/getHooks";
import TransactionHistoryTable from './TransactionHistoryTable'
import OrderHistoryTable from './OrderHistoryTable'
import moment from 'moment';

export default function TransactionHistory({credData, role}) {



  const penaltyRate = 0.3/100

  const calculatePenalCharge = useCallback((order) => {

    const totalAmount = parseFloat(order?.total_amount);
    const repaymentDate = order?.repaymentDuration
    const daysOfDefault = moment().diff(moment(repaymentDate), "days");

    const dueDateExceeded = new Date(repaymentDate) < new Date();
    if(dueDateExceeded){
      let totalPenalCharges = penaltyRate * totalAmount * daysOfDefault
      
      return totalPenalCharges
    } else return 0
    
  }, []);



  const getTransactionHistory = useGetUserTransactionHistory(credData?.userId)

  const getOrderTransaction = useGetOrderTransactionHistory(credData?.orderId);

  const orderTransactions = getOrderTransaction?.data?.data?.data


  const getSingleUserOrder = useGetSingleUserOrder({
    userId: credData?.userId,
    Status: "all"
  })


  const transactionHistory = getTransactionHistory?.data?.data?.data



  const orderData = transactionHistory?.map(item => item?.order);

  // const userOrders = orderData?.map((order)=>{
  //   return { ...order, penalCharge: calculatePenalCharge(order)}
  // })


  const userOrders = []; //getSingleUserOrder?.data?.data?.data
  // console.log(userOrders)







  const [activeTab, setActiveTab ] = useState("Order History")

    const rows = [
        {
            id: '1123459w',
            status: "Successful", amount: "2000", type: "Subscription", reference: "1235309S7A", remark: "Charges for silver plan", category: "Subscription", date: "21, June 2023"
        },
    ]
  return (
    <React.Fragment>
        <section className='text-center'>
        <h5>{credData?.first_name} {credData?.last_name}</h5>

        <div className="mx-lg-5 mx-3 my-5 d-flex gap-5 justify-content-evenly align-items-center">
          {
            ["Order History", "Transaction History"].map((item, index)=>{
              return(
                <h6 key={index} onClick={()=>setActiveTab(item)} className={`cursor-pointer ${activeTab===item?"text-dark fw-bold": "text-muted"}`}>{item}</h6>

              )
            })
          }


          </div>

          {
            activeTab === "Order History" ? <OrderHistoryTable rows={userOrders}/> : (credData?.orderId? <AdminTransactionHistoryTable rows={(orderTransactions)?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} />: <TransactionHistoryTable rows={transactionHistory?.sort((a, b) => new Date(b?.createdAt)-new Date(a?.createdAt))}/>)
          }
        
        
        </section>
    </React.Fragment>
  )
}
