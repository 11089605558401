import React from 'react'
import SampleDot from '../../../../utils/SampleDot';
import SellToVouch from '../../../../components/Merchants/Dashboard/SellToVouch';
import TransactionTable from './TransactionTable';
import { useGetMerchantOrder } from '../../../../redux/hooks/getHooks';
import { IsLoadingLoader, IsErrorLoader } from '../../../../utils/Loader';
export default function Transaction({handleClick}) {
  const getMerchantOrder = useGetMerchantOrder();
  const merchantOrders = getMerchantOrder?.data?.data?.data?.orders;

    const headCell = [
        {
          id: 'name',
          label: 'Name',
        },
        {
          id: 'orderId',
          label: 'Order ID',
        },
        {
          id: 'product',
          label: 'Product',
        },
        {
          id: 'amount',
          label: 'Amount',
        },
        {
          id: 'transactiontatus',
          label: 'Transaction Status',
        },
        {
          id: 'seeMore',
          label: 'See more',
        }
      ];
      const rows = [
        {
            _id: '1',
            firstName: "John", lastName: "Fixit", location: 'VI', phone: '09182731823', email: 'jfixcoding@gmail.com', status: 'Employed', action: ''
        }
    ]

    
  return (
    <>
        <section>
        <SellToVouch handleClick={handleClick}/>
            <section className="col-lg-10 mx-auto table_header d-flex justify-content-between flex-wrap gap-3">
                    <p className=''>Transaction</p>
                    <div className='d-flex gap-2 flex-wrap'>
                        <p className='d-flex gap-1 my-aut'><SampleDot color={'#F8D86E'}/> Unfulfilled</p>
                        <p className='d-flex gap-1 my-aut'><SampleDot color={'#128338'}/>Fulfilled</p>
                    </div>
                </section>
                {
                  getMerchantOrder?.isLoading ? <IsLoadingLoader /> : getMerchantOrder?.isError ? <IsErrorLoader />:
                <TransactionTable topHeaderVisible={true} rows={merchantOrders} 
                headCell={headCell}/>
                }
        </section>


       
    </>
  )
}
