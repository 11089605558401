
import { Button } from '@mui/material'
import { formatNumber } from '../../../../utils/formatNumber'
import { CancelIcon } from '../../../../icons/icons'
export default function FulfilOrder({products, totalAmount}) {
  return (
   <>
    <section className='my-3'>
            <p className='w-100'>Fulfil the following order to {`John Doe`} <span className='text-white=' style={{color: 'transparent'}}>Fulfil the following order to {`John Doe`}</span></p>
            <section className="table-responsive-md">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        products?.map((product, index)=>{
                            return(
                                <tr key={index}>
                                    <td>{product?.product}</td>
                                    <td> { formatNumber(product?.amount) }</td>
                                    <td>
                                        <Button startIcon={<CancelIcon />} sx={{color: "black", textTransform: 'capitalize'}}>Remove</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
                <tfoot>
                    <tr>
                        <th colSpan={2}>Total</th>
                        <th>{ formatNumber(totalAmount??0) }</th>
                    </tr>
                </tfoot>
            </table>
            </section>
           
        </section>
   </>
  )
}
