import { Typography, Button } from "antd";
import { useMemo, useState, useEffect } from "react";
import { useUserLoanApproval } from "../../../redux/hooks/zustands/useUserLoanApproval";
import { numToWords } from 'num-to-words';
import { useCheckPayment } from "../../../redux/mutation/AdminPostMutation";

const numberToOrdinalWords = (num) => {
  const ones = [
    'zeroth', 'first', 'second', 'third', 'fourth', 'fifth',
    'sixth', 'seventh', 'eighth', 'ninth', 'tenth',
    'eleventh', 'twelfth', 'thirteenth', 'fourteenth',
    'fifteenth', 'sixteenth', 'seventeenth', 'eighteenth', 'nineteenth'
  ];

  const tens = [
    '', '', 'twentieth', 'thirtieth', 'fortieth', 'fiftieth',
    'sixtieth', 'seventieth', 'eightieth', 'ninetieth'
  ];

  if (num < 20) {
    return ones[num];
  } else if (num < 100) {
    const ten = Math.floor(num / 10);
    const one = num % 10;
    return tens[ten] + (one ? '-' + ones[one] : '');
  }

  // Extend this logic for larger numbers as needed
  return num.toString();
};


export default function SetMandate({ data }) {

  const { updateCurrentPage, data: { bank_accounts } } = useUserLoanApproval();


  const [isLoading, setIsLoading] = useState(false);
 

  console.log(bank_accounts);

  const [selectedBankIndex, setSelectedBankIndex] = useState(1);

  const [mandateSet, setMandateSet] = useState({step: 0, status: false, remainSteps: bank_accounts?.length})

  const [accountStep, setAccountStep] = useState({totalStep: bank_accounts?.length, currentStep: 0})

  useEffect(()=>{
    //useEffect to be updating mandateSet and accountStep
  })


  const currentAccount = bank_accounts?.[accountStep?.currentStep]

  console.log(currentAccount);

  const mutation = useCheckPayment(currentAccount?.id, "mandate")


  const mandateCred = currentAccount?.initiate_mandate_data?.transfer_destinations;

  const recipientAccount = useMemo(() => {
    return mandateCred?.[selectedBankIndex];
  }, [selectedBankIndex, mandateCred]);

  const handleChangeRecipientAcc = () => {
    selectedBankIndex ? setSelectedBankIndex(0) : setSelectedBankIndex(1);
  };


  const moneySent=()=>{
    // setAccountStep({...accountStep, currentStep: accountStep?.currentStep+1});
    mutation.mutate("", {onSuccess:()=>{
      setMandateSet({step: accountStep?.currentStep, status: true, remainSteps: bank_accounts?.length - (accountStep?.currentStep + 1)});
    }})
  }


  const nextStep=()=>{
    if(mandateSet?.step === (accountStep?.totalStep -1)){
      updateCurrentPage("BANK_ACCOUNTS", )
    }else{
      setAccountStep({...accountStep, currentStep: accountStep?.currentStep+1});
      setMandateSet({step: mandateSet?.step+1, status: false});
    }
  }


  useEffect(()=>{
    if(mutation.isLoading){
        setIsLoading(true)
    }
    else if(mutation.isError){
        setIsLoading(false)
        const error = mutation?.error?.response?.data

        console.log(error)
    }
    else if(mutation.isSuccess){
        setIsLoading(false)
        const res = mutation?.data?.data?.data

        console.log(mutation?.data?.data)

        // const accounts = res?.companies_accounts
    
         
    }
}, [mutation.status])

  


  return (
    <>
      <div className="col-lg-5 col-md-8 col-12 mx-auto tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white tw-shadow-sm tw-mt-5">
        <h2 className="tw-font-[600] tw-text-[22px] tw-mb-5 tw-text-center">
          FI Lender
        </h2>
        <br />
        <h5 className="tw-font-[400] tw-text-[18px] tw-text-center tw-mb-5">
          Complete Mandate process
        </h5>

        <div className="tw-border tw-rounded-lg tw-border-[#D9D9D996] tw-px-5 tw-py-3">
         
          <div className="col-lg- mx-auto col-md-10 col-12">
            <div>
              <div className="tw-flex">
                <span>{accountStep?.currentStep +1}/{accountStep?.totalStep}</span>
                <div className="tw-w-full">
                  <h4 className="text-center tw-font-[600]">
                    Set {numberToOrdinalWords(accountStep?.currentStep + 1)} Mandate
                  </h4>
                </div>
              </div>
              
              <div className="mt-3">
              {
                            mandateSet?.status ? null : (
                     
                            <div>
                                <p className="mx-">
                                Mono is the payment partner on Vouch. By making the transfer
                                below, you authorize Mono to set a direct debit mandate on
                                your bank account to ensure repayment.{" "}
                                </p>
                                <p className="fw-bold text-center my-4">Transfer 50 naira</p>

                            </div>

                            )
                        }
                <div>
                  <div
                    className="border rounded-3 mx-auto ps-5 pe-3 py-4"
                    style={{ backgroundColor: "#FAF9F9" }}
                  >
                    <div>
                        {
                            mandateSet?.status ? null : (
                      <div className="h-100 d-flex flex-column gap-5 ">
                        <div>
                          <p
                            className="d-flex mb-3"
                            style={{
                              fontWeight: 500,
                              fontSize: "15px",
                              lineHeight: "5px",
                            }}
                          >
                            {"SEND FROM"}
                          </p>
                          <p
                            className="d-flex justify-content-between my-2 align-items-center"
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              color: "#000",
                              lineHeight: "12px",
                            }}
                          >
                            <span className="d-flex flex-column">
                              {currentAccount?.initiate_mandate_data?.account_number ?? "account not found"}
                              <small
                                style={{
                                  fontWeight: 400,
                                  fontSize: "13px",
                                  lineHeight: "5px",
                                  marginTop: "13px",
                                }}
                              >
                                {currentAccount?.initiate_mandate_data?.bank ?? "Not found"}
                              </small>
                            </span>
                            {/* <img
                            src={mandateCred?.[0]?.icon}
                            alt={"Fidelity"}
                            width={50}
                          /> */}
                          </p>
                        </div>

                        <div>
                          <p
                            className="d-flex justify-content-between align-items-center mb-3"
                            style={{
                              fontWeight: 500,
                              fontSize: "15px",
                              lineHeight: "5px",
                            }}
                          >
                            {"SEND TO"}
                            <span
                              className="text-primary fw-bold cursor-pointer"
                              onClick={handleChangeRecipientAcc}
                            >
                              CHANGE
                            </span>
                          </p>
                          <p
                            className="d-flex justify-content-between my-2 align-items-center"
                            style={{
                              fontWeight: 600,
                              fontSize: "20px",
                              color: "#000",
                              lineHeight: "12px",
                            }}
                          >
                            <span className="d-flex flex-column">
                              {recipientAccount?.account_number ?? ""}
                              <small
                                style={{
                                  fontWeight: 400,
                                  fontSize: "13px",
                                  lineHeight: "5px",
                                  marginTop: "12px",
                                }}
                              >
                                {recipientAccount?.bank_name ??
                                  "Paystack Titan"}
                              </small>
                            </span>
                            <img
                            src={recipientAccount?.icon}
                            alt={recipientAccount?.bank_name}
                            width={50}
                          />
                          </p>
                        </div>
                      </div>

                            )
                        }
                      <div className="mt-4 tw-text-center">
                        {
                            mandateSet?.status ? (
                            
                              mandateSet?.remainSteps === 0 ? (
                                <p className="d-flex flex-column gap-4">
                                  <span className="tw-font-[500]">Mandate Set Successfully</span>

                                  <span className="">All mandate successfully set.</span>
                                  <span className="">Thank you</span>

                                </p>
                              ): (
                                <p>
                                  <span className="tw-capitalize">{numToWords(mandateSet?.remainSteps)}</span> more mandates to go
                                </p>

                              )
                            
                            ) :(
                                <p>
                                This Paystack Titan account expires in 25{" "}
                                <small>mins</small>
                                </p>
                            )
                        }
                      </div>
                    </div>
                  </div>


                  <div className={`col-lg-8 mx-auto ${mandateSet?.remainSteps === 0 ? "d-none": "d-flex"} flex-column gap-3 mt-3`}>
                    <Button
                      shape="round"
                      size="large"
                      style={{
                        background: "#033F4B",
                        color: "#fff",
                        fontSize: "18px",
                        height: "55px",
                      }}
                      loading={isLoading}
                      onClick={mandateSet?.status ? nextStep : moneySent}
                    >
                        {
                            mandateSet?.status ? "Next" : "I’ve sent the money"
                        }
                    </Button>
                    <Button
                      shape="round"
                      size="large"
                      style={{
                        background: "#FFFF",
                        border: "2px solid #033F4B",
                        color: "#033F4B",
                        fontSize: "18px",
                        height: "55px",
                      }}
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
