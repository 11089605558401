import { useState } from "react";
import Button from "@mui/material/Button";
import SubscriptionPlan from "../../../components/Subscription/SubscriptionPlan";
import ModalDialogBox from "../../../components/ModalDialogBox/ModalDialogBox";
import CreatePlan from "../../../components/Admin/CreatePlan";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import DrawerBox from "../../../utils/Drawer/DrawerBox";

export default function AdminSubscriptionPlanScreen({ handleClick }) {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <main className="mt-5 mx-2">
        <div className="col-lg-11 mx-lg-auto mx-2">
          <h4 className="header fw-bold fs-5 text-center">Subscription Plans</h4>
          <div className="d-flex justify-content-end mx-lg-4">
            <Button
              variant="contained"
              onClick={() => handleOpen()}
              startIcon={<AddOutlinedIcon />}
              sx={{
                backgroundColor: "#033F4B",
                color: "white",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#033F4B",
                  color: "white",
                },
              }}
            >
              Create New Plan
            </Button>
          </div>
        </div>
        <SubscriptionPlan role={"admin"} handleClick={handleClick} />
      </main>

      <ModalDialogBox open={open} handleClose={handleClose} modalSize={true}>
      {/* <DrawerBox open={open} handleClose={handleClose}> */}
        <CreatePlan handleClose={handleClose} handleClick={handleClick} />
        {/* </DrawerBox> */}
      </ModalDialogBox>
    </>
  );
}
