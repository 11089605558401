import { Link } from "react-router-dom"
import React from 'react'
import { NairaIcon } from '../../../icons/icons'

function StoreDetail({address, pharmacy, phoneNumber}) {
  return (
    <>
        <main className='col-lg-7 col-md-10 col-sm-12 mx-auto'>
            <section className='mx-2 text-center'>
                <div className='text-center'>
                    <label htmlFor="pharmacies" className='fw-bold'>Physical Stores</label>
                </div>
                <hr />
                <div>
                    <p className="fw-bold" style={{fontSize: "2.3vh"}}>X and Y Pharmacy</p>
                    <p><span className="text-muted">{address}</span></p>
                </div>
                <hr />
                <div>
                    <p className="fw-bold" style={{fontSize: "2.3vh"}}>Transaction ID sent to +{phoneNumber ?? 'xxxxxxx'}</p>
                    <p><span className="text-muted"><Link to={""} className='text-decoration-none'>Resend</Link> If you did not receive the code</span></p>
                </div>
                <hr />
                <div>
                    <p className="fw-bold" style={{fontSize: "2.3vh"}}>Maximum Credit Accessible</p>
                    <p><span className="text-muted fs-6"><NairaIcon />9,000</span></p>
                </div>
                <hr />
                <div>
                    <p className="fw-bold " style={{fontSize: "2.3vh"}}>Go to X and Y Pharmacy with the ID sent to your phone</p>
                    <p><Link to="" className='text-decoration-none'>Navigate Location</Link></p>
                </div>
                <hr />
                <div className="mt-4">
                    <p className="">Thank you</p>
                </div>
            </section>
        </main>
    </>
  )
}

export default StoreDetail