import useWindowSize from "./useWindowSize.js"

export const useResponsiveSize=()=>{

    const windowSize = useWindowSize();

    const responsiveScreenWidth =
    windowSize?.width >= 992
      ? "lg"
      : windowSize?.width > 768 && windowSize?.width < 991
      ? "md"
      : windowSize?.width <= 766
      ? "sm"
      : null;


      return responsiveScreenWidth;
}