import React from "react";
import baxi from "../../../assets/baxi.png";
import paystack from "../../../assets/paystack.png";
import mono from "../../../assets/mono.png";

export default function OurPartners() {
  const partners = [
    { name: "Baxi", img: baxi },
    { name: "Paystack", img: paystack },
    { name: "Mono", img: mono },
  ];
  return (
    <>
      <section className="bg-white py-5 ">
        <section className="container-lg mx-lg-5 container-fluid ">
          <h3 className="section_two" style={{ fontWeight: 500 }}>
            Our Partners:
          </h3>
          <div className="d-flex mx-lg-5 gap-4">
            {partners?.map((val) => {
              return (
                <img
                  key={val?.name}
                  src={val?.img}
                  alt={val?.name}
                  style={
                    val.name !== "Baxi"
                      ? {
                          width: "140px",
                          maxWidth: "140px",
                          height: "100%",
                        }
                      : {}
                  }
                />
              );
            })}
          </div>
        </section>
      </section>
    </>
  );
}
