export const formatNumber = (value) => {
    //the value must be a type number
    return new Intl.NumberFormat("en-NG", {
      style: "currency",
      currency: "NGN",
  }).format(+value);
  }


  export const formNumberOnly=(value)=>{
    const nValue = parseFloat(value)
    return nValue?.toLocaleString(undefined, { style: 'decimal'})
  }


  export const formatMoneyNumber = (value) => {
    const cleanedValue = value.replace(/\D/g, '');
  
      // Format the value with commas for thousands separator
      return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  };