import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  LogoutIcon,
  RequestIcon,
  SettingIcon,
  UserIcon,
  CartIcon,
  SubscriptionIcon,
  THistoryIcon,
} from "../../../icons/icons";
import "./sidenav.css";
import { ROUTES } from "../../../routes/route";
import { useSelector } from "react-redux";
import { Badge } from "antd";
import useWindowSize from "../../hooks/useWindowSize";
import HomeIcon from '@mui/icons-material/Home';
import MoneyIcon from '@mui/icons-material/Money';
import { useCallback } from "react";


function SideNav({ userDetail, toggleSideNav, confirmLogout }) {
  const navigate = useNavigate();

  const location = useLocation()?.pathname;


  const { width } = useWindowSize({});

  const { allProducts } = useSelector((state) => state?.product);

  const color = useCallback((path)=>{
    return location===path ? "white" : "#033F4B"
  }, [location]);



  const menu_data = [
    { path: ROUTES.shop, name: "Home", icon: HomeIcon },
    {
      path: ROUTES.request,
      name: "Request",
      icon: RequestIcon,
    },
    { path: ROUTES.credit, name: "Credit", icon: MoneyIcon },
    // { path: ROUTES.shop, name: "Setting", icon: <SettingIcon },
    {
      path: ROUTES.cart,
      name: "Cart",
      icon: allProducts?.products?.length ? (
        <Badge
          size="small"
          color="orange"
          count={allProducts?.products?.length}
        >
          <CartIcon />
        </Badge>
      ) : (
        CartIcon
      ),
    },
    {
      path: ROUTES.subscription,
      name: "Subscription plan",
      icon: SubscriptionIcon,
    },
    {
      path: ROUTES.user_transaction_history,
      name: "Transaction History",
      icon: THistoryIcon,
    },
  ];

  return (
    <>
      <main className={`side_nav_container tw-z-20`}>
        <section className="">
          {width <= 991 && (
            <button
              className="btn rounded profile_name"
              onClick={() => {
                navigate(ROUTES.profile);
                window.innerWidth < 768 && toggleSideNav();
              }}
            >
              {userDetail
                ? `${userDetail?.first_name} ${userDetail?.last_name}`
                : "Anonymous user"}
            </button>
          )}
          <section className="menu_list my-3">
            <div className="tw-flex tw-flex-col tw-gap-y-2">
              {menu_data.map((menu, index) => {
                return (
                  <div className="" key={index}>
                    <Link
                      to={menu.path}
                      className={`side_nav_menu d-flex gap-3 tw-py-3 ${location===menu?.path ? "tw-bg-[#033F4B] tw-text-white px-2 tw-rounded": "tw-text-[#033F4B]"}`}
                      onClick={() => {
                        window.innerWidth < 768 && toggleSideNav();
                      }}
                    >
                      <menu.icon color={color(menu?.path)}/>
                      <span>{menu.name}</span>
                    </Link>
                    <hr />
                  </div>
                );
              })}
             
            </div>
            <div
              className="side_nav_menu d-flex gap-2"
              style={{ cursor: "pointer", paddingBottom: "5vh" }}
              onClick={confirmLogout}
            >
              <LogoutIcon />
              <p className="my-auto">
                <span>Logout</span>
              </p>
            </div>
          </section>
        </section>
      </main>
    </>
  );
}

export default SideNav;
