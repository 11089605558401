import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useGetPosition } from "../hooks/useGetPosition";
import CustomButton from "../CustomButton/CustomButton";
import TextField from "@mui/material/TextField";
import { useOnboardMerchantMutation } from "../../redux/mutation/AdminPostMutation";
import MerchantAdded from "./MerchantAdded";
export default function OnboardMerchant({ handleClick, handleClose }) {
  const position = useGetPosition();
  const [isLoading, setIsLoading] = useState(false);
  const onboardMerchant = useOnboardMerchantMutation();
  const [merchantAdded, setMerchantAdded] = useState(false);

  const formik = useFormik({
    initialValues: {
      business: "",
      personnel: "",
      email: "",
      phone: "",
      address: "",
      location: "",
      category: "",
      latitude: position?.latitude??0,
      longitude: position?.longitude??0,
    },
    enableReinitialize: true,
    onSubmit:(values)=>{
       
        onboardMerchant.mutate(values);
    }
  });

  useEffect(()=>{
    if(onboardMerchant.isLoading){
      setIsLoading(true);
    }
    else if(onboardMerchant.isError){
      setIsLoading(false);
      const msg = onboardMerchant?.error?.response?.data?.message
      handleClick(msg, "error");
    }
    else if(onboardMerchant.isSuccess){
      setIsLoading(false)
      const msg = onboardMerchant?.data?.data?.message
      handleClick(msg, "success");
      setMerchantAdded(true);
    }
  }, [onboardMerchant.status])

  const backToDashboard=()=>{
    setMerchantAdded(false);
    handleClose()
  }
  return (
    <>
    {
      merchantAdded?<MerchantAdded backToDashboard={backToDashboard}/>:
      <main>
        <h5 className="text-center">Onboard Merchant</h5>
        <form onSubmit={formik.handleSubmit} className="d-flex flex-column gap-3">
          <div className="business">
            <label
              htmlFor="itemName"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Name of Business
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Enter name of your business e.g ayz and son"
              name="business"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.business}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.business && formik.errors.business}
            </small>
          </div>
          <div className="email">
            <label
              htmlFor="email"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Email
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Enter email address"
              name="email"
              type="email"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.email}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.email && formik.errors.email}
            </small>
          </div>
          <div className="phone">
            <label
              htmlFor="phone"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Phone
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Provide phone number"
              name="phone"
              type="tel"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.phone}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.phone && formik.errors.phone}
            </small>
          </div>
          <div className="address">
            <label
              htmlFor="address"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Address of business
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Provide address"
              name="address"
              type="tel"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.address}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.address && formik.errors.address}
            </small>
          </div>
          <div className="personnel">
            <label
              htmlFor="personnel"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Name of the business contact person
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Name of the business contact person"
              name="personnel"
              type="text"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.personnel}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.personnel && formik.errors.personnel}
            </small>
          </div>
          <div className="location">
            <label
              htmlFor="location"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              Location
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Enter Location"
              name="location"
              type="text"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.location}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.location && formik.errors.location}
            </small>
          </div>
          <div className="category">
            <label
              htmlFor="category"
              className="fw-bold"
              style={{ color: "#033F4B" }}
            >
              category
            </label>
            <TextField
              className="rounded-pill"
              placeholder="Enter your category e.g Jumia"
              name="category"
              type="text"
              fullWidth
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
              size="small"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik?.values?.category}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.category && formik.errors.category}
            </small>
          </div>
          <CustomButton text={"Onboard"} loading={isLoading}/>
        </form>
      </main>

      }
    </>
  );
}
