import React, { useEffect } from 'react'
import { TextField } from '@mui/material'
import { formNumberOnly } from '../../../../../utils/formatNumber'

const formatSalary = (value) => {
  const cleanedValue = value.replace(/\D/g, '');

    // Format the value with commas for thousands separator
    return cleanedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

function WorkInformation({formik, setenableNext}) {

  useEffect(()=>{
    if (formik?.values.occupation && formik.values.workEmail && formik.values.monthlySalary && formik.values.employer && formik.values.employerAddress) {
      setenableNext(true)
     }
     else{
      setenableNext(false)
     }
  },[formik.values])


  const handleChange = (e) => {
    const formattedValue = formatSalary(e.target.value);
    formik.handleChange({
      target: {
        name: e.target.name,
        value: formattedValue,
      },
    });
  };


  return (
   <>
        <main className='mx-2'>
        <p className="text-center kyc_title" style={{color: "#323C4D",fontSize: "22px", fontStyle: "normal",fontWeight: "500",lineHeight: "normal"}}>Work Information</p>
        <form className='col-lg-10 mx-auto'>
            <div className="occupation" style={{paddingBottom: "20px"}}>
                <label htmlFor="occupation" style={{marginBottom: "4px"}}>Occupation</label>
                <TextField
                type="text"
                id="standard-basic"
                name="occupation"
                onChange={formik?.handleChange}
                value={formik?.values.occupation}
                onBlur={formik?.handleBlur}
                placeholder="e.g. accountant"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
            <div className="workEmail" style={{paddingBottom: "20px"}}>
                <label htmlFor="workEmail" style={{marginBottom: "4px"}}>Work Email</label>
                <TextField
                type="email"
                id="standard-basic"
                name="workEmail"
                onChange={formik.handleChange}
                value={formik?.values.workEmail}
                onBlur={formik?.handleBlur}
                placeholder="e.g. Johndoe@work.com"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
            <div className="monthlySalary" style={{paddingBottom: "20px"}}>
                <label htmlFor="monthlySalary" style={{marginBottom: "4px"}}>Monthly Salary</label>
                <TextField
                type="text"
                id="standard-basic"
                name="monthlySalary"
                onChange={handleChange}
                value={formik?.values.monthlySalary}
                onBlur={formik?.handleBlur}
                placeholder="e.g. 300,000"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
            <div className="employer" style={{paddingBottom: "20px"}}>
                <label htmlFor="employer" style={{marginBottom: "4px"}}>Employer</label>
                <TextField
                type="text"
                id="standard-basic"
                name="employer"
                onChange={formik?.handleChange}
                value={formik?.values.employer}
                onBlur={formik?.handleBlur}
                placeholder="Enter place of work"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
            <div className="employerAddress" style={{paddingBottom: "20px"}}>
                <label htmlFor="employerAddress" style={{marginBottom: "4px"}}>Employer Address</label>
                <TextField
                type="text"
                id="standard-basic"
                name="employerAddress"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values.employerAddress}
                placeholder="Enter employer address"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
        </form>
        </main>
   </>
  )
}

export default WorkInformation