import React from 'react'
import Profile from '../../components/Admin/KYC/ProfileDetail/Profile'

export default function AdminProfile() {
  return (
    <>
        <main>
            <Profile />
        </main>
    </>
  )
}
