import React, { useState, useEffect } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { formatNumber } from "../../../utils/formatNumber";
import { Button } from "antd";
import gotv from "../../../assets/gotv.png";
import startime from "../../../assets/star time.png";
import dstv from "../../../assets/dstv.png";
import { useInitiateBill } from "../../../redux/mutation/postMutation";
import BillAuthComp from "../BillAuthComp";
import { useUserData } from "../../../components/hooks/useUserData";
import { usePaymentModal } from "../../../redux/hooks/zustands/usePaymentModal";
import { useContinueSubscription } from "../../../utils/useNoSubscription";

export default function ConfirmCableInformation({
  formik,
  setNextAction,
  cable,
  handleClick,
  payloadData,
}) {
  const user = useUserData();

  const { contextHolder, continueSubscription} = useContinueSubscription()

  const [openModal, setOpenModal] = useState(false);
  const [payNowLoading, setPayNowLoading] = useState(false);

  const initiateBillMutation = useInitiateBill();

  const { openModal:openPaymentModal } = usePaymentModal()

  // Initialize variables to store total price and codes
  let totalPrice = 0 + formik?.values?.subscriptionPlan?.price;
  const codes = [];

  // Iterate over the data array
  // formik?.values?.subscriptionPlan?.addOns?.forEach((item) => {
  //   // Iterate over the options array in each item
  //   item.options?.forEach((option) => {
  //     // Add the price to the total price
  //     totalPrice += option?.price;
  //   });

  //   // Push the code to the codes array
  //   codes.push(item?.group?.code);
  // });

  const handlePayNowFn = () => {
    console.log(payloadData?.data?.products[0])
    initiateBillMutation.mutate(payloadData?.data?.products[0]);
  };

  const handlePrevAction = () => {
    setNextAction("");
  };

  const handleNextAction = () => {
    if (user?.status) {
      if(user?.data?.subscriptions.length){
        setNextAction("checkout");
      }
      else{
        continueSubscription();
      }
    } else {
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (initiateBillMutation?.isLoading) {
      setPayNowLoading(true);
    } else if (initiateBillMutation?.isSuccess) {
      setPayNowLoading(false);
      const res = initiateBillMutation?.data?.data;

      openPaymentModal({...res?.data, email: payloadData?.data?.products?.[0]?.email})
      
      // if(res?.data?.link){
      //   window.location.href = res?.data?.link;
      // }

    } else if (initiateBillMutation?.isError) {
      setPayNowLoading(false);
      let msg = initiateBillMutation?.error?.response?.data?.message;
      let msg2 = initiateBillMutation?.error?.response?.statusText;

      handleClick(msg ?? msg2, "error", {
        vertical: "top",
        horizontal: "right",
      });
    }
  }, [initiateBillMutation?.status]);
  return (
    <>
      <div className="col-lg-8 col-12 mx-lg-auto mx-2">
        <div className="d-flex align-items-end justify-content-end">
          <span className="cursor-pointer" onClick={handlePrevAction}>
            Back
          </span>
        </div>

        <div className="text-center">
          <h3
            className="fw-semibold d-fle gap-2 align-items-center"
            style={{ fontSize: "28px", fontWeight: 500 }}
          >
            Subscription{" "}
            <img
              src={
                cable?.service_type === "dstv"
                  ? dstv
                  : cable?.service_type === "gotv"
                  ? gotv
                  : cable?.service_type === "startimes" && startime
              }
              alt={cable?.service_type}
              style={{
                width: "100px",
                maxWidth: "100px",
              }}
            />
          </h3>
        </div>

        <AuthHeader title={"Confirm Information"} />
        <div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Email
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formik?.values?.email}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Service Type
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className="text-capitalize"
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {cable?.name}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Plan
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formik?.values?.subscriptionPlan?.name}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Amount
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formatNumber(totalPrice)}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Surcharge
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formatNumber(100)}
              </small>
            </div>
          </div>
        </div>

        <div className="d-flex flex-column gap-3 mt-4">
          <Button
            shape="round"
            size="large"
            style={{
              background: "#033F4B",
              color: "#fff",
              fontSize: "18px",
              height: "55px",
            }}
            loading={payNowLoading}
            onClick={handlePayNowFn}
          >
            Pay Now
          </Button>
          <Button
            shape="round"
            size="large"
            style={{
              background: "#FA9E17",
              color: "#fff",
              fontSize: "18px",
              height: "55px",
            }}
            onClick={handleNextAction}
          >
            Get it now and pay later
          </Button>
        </div>
      </div>

      <BillAuthComp
        handlePayNowFn={handlePayNowFn}
        payNowLoading={payNowLoading}
        openModal={openModal}
        handleCloseModal={handleCloseModal}
        billData={payloadData}
      />




      {contextHolder}
    </>
  );
}
