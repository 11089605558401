import React, { useState, useEffect } from 'react'
import { Outlet } from 'react-router-dom';
import "./adminLayout.css";
import AdminSidebar from '../../components/Admin/Sidebar/AdminSidebar';
import Header from '../../components/Shop/Header/Header';
import useWindowSize from '../../components/hooks/useWindowSize';

export default function AdminLayout({handleClick}) {
    const [navIsOpen, setNavIsOpen] = useState(true);
    const windowSize = useWindowSize({});
    const handleOpenNav=(param)=>{
        setNavIsOpen(param);
    }
    useEffect(()=>{
      if(window.innerWidth<768){
          setNavIsOpen(false);
      }
    }, [])

  

  return (
    <>
        <main style={{backgroundColor: "#FFFFFF"}}>
          
            <AdminSidebar visible={navIsOpen} show={handleOpenNav} handleClick={handleClick}/>
            <section className={!navIsOpen? "page": "page page-with-navbar"} onClick={()=>{
              if(windowSize.width<768){
                navIsOpen && handleOpenNav(false)
              }
            }}>
                <Outlet />
            </section>
        </main>
    </>
  )
}
