import axios from "axios";
import userAuth from "../components/RouteGuard/userAuth";
import { API_URL } from "./api_urls";
import adminAuth from "../components/RouteGuard/adminAuth";
import merchantAuth from "../components/RouteGuard/merchantAuth";
const baseUrl = process.env.REACT_APP_BASE_URL
// const baseUrl = "https://apivouch.com.ng/api"
// const baseUrl = "https://vouch-hiyp.onrender.com/api"

//get request method
export const GetMethod=async(path, role, queryParams)=>{
    const token = role==='USER'?userAuth().token : role === 'ADMIN'?adminAuth().token:role==='MERCHANT' && merchantAuth().token
    // console.log(queryParams);


    const refreshToken = role //if the path is for refresh token the role will be the refresh token
    const headers = {
        "Authorization": `Bearer ${path===API_URL.refreshToken?refreshToken:token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://vouchapi.com.ng"
    }
   const response = await axios.get(baseUrl+path, {headers: headers, params: queryParams})
   return response;
}
// export const MerchantGetMethod=async(path)=>{
//     const token = localStorage.getItem("adminAccessToken");
//     const headers = {
//         "Authorization": `Bearer ${token}`,
//         "Content-Type": "application/json",
//         "Access-Control-Allow-Origin": "https://vouchapi.com.ng"
//     }
//    const response = await axios.get(baseUrl+path, {headers: headers})
//    return response;
// }
//post request method
export const PostMethod= async (path, payload)=>{
    let response = await axios.post(baseUrl+path, payload)
    return response;
}
export const PutMethod= async (path, payload)=>{
    let response = await axios.put(baseUrl+path, payload)
    return response;
}
//post request method with header
export const PostMethodWithHeader= async (path, token, payload)=>{
    // const token = auth.token
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    }
    let response = await axios.post(baseUrl+path, payload, {headers})
    return response;
}
export const PutMethodWithHeader= async (path, token, payload)=>{
    // const token = auth.token
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json"
    }
    let response = await axios.put(baseUrl+path, payload, {headers})
    return response;
}
//post request mthod with file
export const PostMethodWithFile = async(path, payload)=>{
    const auth = userAuth();
    const admin_auth = adminAuth()
    const token = auth.token ?? admin_auth.token
    let fileHeader = {
        "Content-Type" : "amultipart/form-data",
        Authorization: "Bearer "+ token
        }
    const response = await axios.post(baseUrl+path, payload, {headers: fileHeader})
    return response
}
//patch request method
export const PatchMethod=async(path, payload)=>{
    const auth = userAuth();
    const token = auth.token
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://vouchapi.com.ng"
    }
    const response = await axios.patch(baseUrl+path, payload, {headers})
    return response;
}
//delete request method
export const DeleteMethod=async(path, token, payload)=>{
    // const auth = userAuth();
    // console.log(auth);
    // const token = auth.token
    const headers = {
        "Authorization": `Bearer ${token}`,
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "https://vouchapi.com.ng"
    }
    const response = await axios.delete(baseUrl+path,  {headers}, payload)
    return response;
}