import React from 'react'
import ResetPswScreen from '../screens/AuthScreen/ResetPswScreen/ResetPswScreen'

function ResetPsw({handleClick}) {
  return (
    <>
        <ResetPswScreen handleClick={handleClick}/>
    </>
  )
}

export default ResetPsw