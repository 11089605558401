import { useState } from "react";
import CreditSummary from "../../../components/Admin/Community/CreditSummary";
import {
  useGetSchedules,
  useGetTransactionHistory,
} from "../../../redux/hooks/getHooks";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import OrderHeader from "./OrderHeader";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import SearchBar from "../../../utils/SearchBar";

import { Select } from "antd";
import EmandateTable from "../../../components/Admin/Community/EmandateTable";
import EmandateView from "../../../components/Admin/Community/EmandateView";

const filterData = [
  { value: "complete", label: "Comlete" },
  { value: "pending", label: "Pending" },
  { value: "cancel", label: "Cancel" },
  { value: "inProgress", label: "In-progress" },
];

export default function EmandateScreen({ handleClick }) {

  const getSchedules = useGetSchedules();




  const schedules = getSchedules?.data?.data?.data

  // console.log(schedules)

  


  const [open, setOpen] = useState({status: false, role: "", data: {}});
  const [filteredData, setFilteredData] = useState();

  const [filterValue, setFilterValue] = useState("");


    const handleChange = (val) => {
        console.log(val)
      setFilterValue(val);

  //     let filteredOrder = orders?.filter((item)=>{
  //         const complete = item?.repaymentStatus === true;
  //         const pending = item?.repaymentStatus === false;
  //         const cancel = item?.status === 'order delivered';
  //         const inProgress = item?.status === 'pending';
  //         if(val==='all'){
  //             return orders;
  //         }
  //         if(val==='paid'){
  //             return paid;
  //         }
  //         else if(val==='unpaid'){
  //             return unpaid;
  //         }
  //         else if(val==='fulfilled'){
  //             return fulfilled;
  //         }
  //         else if(val==='unfulfilled'){
  //             return unfulfilled;
  //         }
  //         else return null;

  //     })

  //     setFilteredData(filteredOrder);
    };

  //drawer functions==============================
  const handleOpen = (role, data) => {
    console.log(data)
    setOpen({status: true, role, data});
  };

  const handleClose = () => {
    setOpen({...open, status: false});
  };
  //===================================

  //

  const handleFilter = (val) => {
    let updatedData = [];

    if (val.length) {
      updatedData = schedules?.filter((item) => {
        const startsWith = item?.user?.email
          ?.toLowerCase()
          .startsWith(val.toLowerCase());

        const includes = item?.user?.email
          ?.toLowerCase()
          .includes(val.toLowerCase());
        const includesProduct = item?.order_name
          ?.toLowerCase()
          .includes(val.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        }
        // else if(includesProduct){
        //     return includesProduct;
        // }
        else return null;
      });

      setFilteredData(updatedData);
    } else {
      setFilteredData(schedules);
      setFilterValue("all");
    }
  };


  return (
    <>
      <main>
        <div className="px-lg-5 d-flex justify-content-between flex-wrap">
          <div className="d-flex justify-content-between ">
            <p className="">E-mandate</p>
          </div>
        <div className="d-flex flex-column">
            <label htmlFor="" className="fw-bold">Filter</label>
          <Select
            defaultValue="all"
            style={{
              width: 120,
            }}
            onChange={handleChange}
            options={filterData}
          />
        </div>
        </div>
        <SearchBar handleFilter={handleFilter} />
        <OrderHeader role={"Transaction"} />
        {getSchedules.isLoading ? (
          <IsLoadingLoader />
        ) : getSchedules.isError ? (
          <IsErrorLoader />
        ) : (
          <EmandateTable
            rows={schedules?.length ? schedules?.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            ): null}
            handleOpen={handleOpen}
          />
        )}
      </main>

      <DrawerBox open={open.status} size={"50rem"} handleClose={handleClose}>

        <EmandateView data={open?.data} handleClick={handleClick}/>
      </DrawerBox>
    </>
  );
}
