import React from 'react'
import MerchantDashboardScreen from '../../screens/Merchant/MerchantDashboardScreen/MerchantDashboardScreen'

export default function MerchantDashboard() {
  return (
    <>
        <MerchantDashboardScreen />
    </>
  )
}
