import { Result } from 'antd'
import React from 'react'
import CustomButton from "../../CustomButton/CustomButton";
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../routes/route';
import { useUserData } from '../../hooks/useUserData';
import ElectricityReceipt from '../ShopNow/ElectricityReceipt';
import AirtimeReceipt from '../ShopNow/AirtimeReceipt';
import DataReceipt from '../ShopNow/DataReceipt';
import CableReceipt from '../ShopNow/CableReceipt';

const MandateTransferResult = ({transferStep, setTransferStep, closeModal}) => {

  const responseData = transferStep?.data;


  const user = useUserData({});

    const navigate = useNavigate();

    const goToHome=()=>{
      setTransferStep({step: 0, state: 'not sent'})
        closeModal();

        if (user?.status) {
          navigate(ROUTES.shop);
        } else {
          navigate(ROUTES.landing_page);
        }
    }

    const handleTryAgain=()=>{
        setTransferStep({step: 0, state: 'not sent'})
    }
  return (
    <>
        <main>
        <Result
    status={transferStep?.state}
    title={<p className='tw-font-[400] tw-text-[16px]'>{transferStep?.message}</p>}
    
   extra={null}
  >

    <div>
      {responseData?.order?.is_bill ? (
                      responseData?.order?.order_name === "electricity" ? (
                        <ElectricityReceipt data={responseData} />
                      ) : responseData?.order?.order_name === "airtime" ? (
                        <AirtimeReceipt data={responseData} />
                      ) : responseData?.order?.order_name === "data" ? (
                        <DataReceipt data={responseData} />
                      ) : responseData?.order?.order_name === "cable" ? (
                        <CableReceipt data={responseData} />
                      ) : null
                    ) : (
                      ""
                    )}

<div className='mt-4'>
  <CustomButton text={transferStep?.state==="error"? "Try again": "Go to Homepage"} functionFn={transferStep?.state==="error"? handleTryAgain : goToHome}/>

</div>

    </div>

    </Result>

        </main>
    </>
  )
}

export default MandateTransferResult
