import {
  DialogTitle,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { useState, useEffect } from "react";
import ShoppingHistory from "../Shop/ShopNow/ShoppingHistory";
import { useGetUserOrder } from "../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../utils/Loader";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import PayContainer from "../Shop/ShopNow/PayContainer";
import { useUserData } from "../hooks/useUserData";
import { SearchIcon } from "../../icons/icons";
import BillDetail from "../Shop/ShopNow/BillDetail";
import { Modal } from "antd";


function Request({ handleClick }) {
  const getPendingOrders = useGetUserOrder("all");

  const orders = getPendingOrders?.data?.data?.data?.filter(
    (item) => item?.status !== "order delivered" && item?.status !== "repaid"
  );

  const user = useUserData();

  const [open, setOpen] = useState({ status: false, data: "" });

  const [filteredData, setFilteredData] = useState();
  const [filteredSelected, setFilteredSelected] = useState("");

  useEffect(() => {
    setFilteredData(orders);
  }, [orders]);

  const handleOpenDialog = (_, order) => {
    setOpen({ ...open, status: true, data: order });
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  //search filter function==============
  const handleFilter = (val) => {
    let updatedData = [];

    if (val.length) {
      updatedData = filteredData?.filter((item) => {
        const includesName = item?.products.some((product) =>
          product?.name?.toLowerCase().includes(val.toLowerCase())
        );

        const includes = item?.market_place?.name
          ?.toLowerCase()
          .includes(val.toLowerCase());
        if (includesName) {
          return includesName;
        }
        if (includes) {
          return includes;
        } else return null;
      });

      setFilteredData(updatedData);
    } else {
      setFilteredData(orders);
    }
  };
  //

  //filtered by paid or unpaid=============
  const handleChange = (event) => {
    const val = event.target.value;
    setFilteredSelected(event.target.value);

    let filteredUser = orders?.filter((item) => {
      const paid = item?.repaymentStatus === true;
      const unpaid = item?.repaymentStatus === false;
      if (val === "paid") {
        return paid;
      } else if (val === "unpaid") {
        return unpaid;
      } else return null;
    });

    setFilteredData(filteredUser);
  };
  //=================





  return (
    <>
      <main>
        <section className="text-center">
          <DialogTitle className="fw-bold">My Request</DialogTitle>
        </section>
        <section>
          {getPendingOrders.isLoading ? (
            <IsLoadingLoader />
          ) : getPendingOrders.isError ? (
            <IsErrorLoader />
          ) : orders?.length ? (
            <>
              <div className="col-lg-11 mx-auto d-flex justify-content-between">
                <div>
                  {/* <FormControl>
                    <FormLabel id="demo-row-radio-buttons-group-label">
                      Filter
                    </FormLabel>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="female"
                        control={<Radio />}
                        label="Female"
                      />
                      <FormControlLabel
                        value="male"
                        control={<Radio />}
                        label="Male"
                      />
                      <FormControlLabel
                        value="other"
                        control={<Radio />}
                        label="Other"
                      />
                      <FormControlLabel
                        value="disabled"
                        disabled
                        control={<Radio />}
                        label="other"
                      />
                    </RadioGroup>
                  </FormControl> */}
                </div>
                <div>
                  {/* <div className="d-flex gap-2 flex-wrap">
                    <p className="d-flex gap-1 my-aut">
                      <SampleDot color={"#F8D86E"} /> Processing
                    </p>
                    <p className="d-flex gap-1 my-aut">
                      <SampleDot color={"#A76212"} /> Order Completed
                    </p>
                    <p className="d-flex gap-1 my-aut">
                      <SampleDot color={"green"} /> Delivered
                    </p>
                  </div> */}
                  <div className="d-flex gap-3 align-items-center">
                    <TextField
                      type="text"
                      placeholder="Search store or order"
                      fullWidth
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: "14px",
                        },
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) => handleFilter(e.target.value)}
                    />

                    <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Filter
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filteredSelected}
                        label="Filter"
                        onChange={handleChange}
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {["Paid", "Unpaid"]?.map((item, index) => (
                          <MenuItem
                            key={index + "___filter_by"}
                            value={item.toLowerCase()}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              </div>
              {/* <UserOrderTable rows={orders}/> */}
              <ShoppingHistory
                rows={filteredData ?? orders}
                handleOpenDialog={handleOpenDialog}
              />
            </>
          ) : (
            <p className={`card-tex text-center my-2 text-muted fs-5`}>
              <i>You have not made any purchase yet.</i>
            </p>
          )}
        </section>
      </main>

      {open?.data?.is_bill ? (
        <Modal
          centered
          open={open?.status}
          onCancel={handleClose}
          footer={null}
        >
          {" "}
          <BillDetail billData={open?.data} handleClick={handleClick} handleClose={handleClose}/>
        </Modal>
      ) : (
        <ModalDialogBox
          open={open?.status}
          handleClose={handleClose}
          modalSize={false}
        >
          {/* {
          open?.data?.is_bill? <BillDetail billData={open?.data}/>: 
        } */}
          <PayContainer
            order={open?.data}
            dueDate={user?.data?.creditEstimate?.dueDate}
            handleClose={handleClose}
            handleClick={handleClick}
          />
        </ModalDialogBox>
      )}
    </>
  );
}

export default Request;
