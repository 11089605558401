import React, { useRef } from 'react'
import HeaderSection from '../../components/LandingPage/HeaderSection/HeaderSection'
import ShopNowSection from '../../components/LandingPage/ShopNowSection/ShopNowSection'
import BillSection from '../../components/LandingPage/BillSection/BillSection'
import HowItWork from '../../components/LandingPage/HowItWorks/HowItWork'
import AboutVouch from '../../components/LandingPage/AboutVouch/AboutVouch'
import Navbar from '../../components/LandingPage/Navbar/Navbar'
import BecomeMember from "../../components/LandingPage/BecomeMember/BecomeMember"
import OurPartners from '../../components/LandingPage/OurPartners/OurPartners'


function LandingPageScreen() {
  const componentWork = useRef(null);
  const componentAbout = useRef(null);

    const scrollToWork = () => {
    componentWork.current.scrollIntoView({ behavior: 'smooth' });
  };
  const scrollToAbount = () => {
    componentAbout.current.scrollIntoView({ behavior: 'smooth' });
  };

  

  return (
    <>
        <main>
            <Navbar scrollToWork={scrollToWork} scrollToAbount={scrollToAbount}/>
            <HeaderSection />
            <BillSection />
            <ShopNowSection />
            <HowItWork componentWork={componentWork}  />
            <BecomeMember />
            <AboutVouch componentAbout={componentAbout}  />
            <OurPartners />
        </main>
    </>
  )
}

export default LandingPageScreen