import { Button } from "@mui/material";
import "./aboutVouch.css";
import { useState } from "react";
import { useResponsiveSize } from "../../hooks/useResponsiveSize";

const about_us = `Vouch is revolutionizing the way consumers shop by offering a
unique credit platform across both online and offline
marketplaces. Unlike traditional credit models, Vouch allows users
to purchase items with a grace period of 5 to 10 days, without
incurring any extra costs or interest. This means that consumers
can buy now and pay the exact amount later, ensuring they never
miss out on great deals while waiting for their income. Vouch is a
product of Brein and Accion Limited. Through Vouch, we aim to
empower consumers to shop responsibly, without the limitations of
traditional payment methods. Join the Vouch community today and
experience the freedom of shopping on your own terms.`;

function AboutVouch({ componentAbout }) {
  const [more_less, setMore_less] = useState(false);

  const responsiveSize = useResponsiveSize({});

  const maxLength = responsiveSize==='lg' ? 500 : responsiveSize==='md'? 350 : 200;

  const showMore = () => {
    setMore_less(!more_less);
  };
  return (
    <>
      <main className="main_vouch" ref={componentAbout}>
        <div className="row mx-2">
          <div className="col-lg-5">
            <div className="about_vouch_first_content">
              <h1
                data-aos="fade-down"
                className="fw-semibold"
                style={{ fontSize: "40px" }}
              >
                About Vouch
              </h1>
            </div>
          </div>
          <div className="col-lg-7 about_vouch_second_content container-lg container-fluid-md ">
            <h5 data-aos="flip-right" className="about_h5 text-start">
              {about_us?.length > maxLength
                ? more_less
                  ? about_us
                  : about_us?.substring(0, maxLength) + "..."
                : about_us}
            </h5>

            {about_us?.length > maxLength && (
              <div className="text-center">
                <Button onClick={showMore} sx={{
                  color: "#033F4B",
                  fontWeight: 500,
                  fontFamily: "Poppins",
                  }}>
                  {more_less ? "Show Less" : "Show More"}
                </Button>
              </div>
            )}
          </div>
        </div>
      </main>
    </>
  );
}

export default AboutVouch;
