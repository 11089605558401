import {useEffect} from 'react'
import { ROUTES } from '../../routes/route';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useAdminData } from '../hooks/useUserData';
import { isAccessTokenValid, isRefreshTokenValid, isTokenAboutToExpire } from '../../utils/validateToken';
import merchantAuth from './merchantAuth';
import { refreshTokenFn } from '../../redux/fetchingFn';

export default function MerchantProtectedRoute({handleClick}) {
    const merchantProfileData = useAdminData();
    const navigate = useNavigate();
    const auth = merchantAuth();
    const refreshToken = localStorage.getItem('adminRefreshToken');


    // const accessToken = auth?.token;
    // const thresholdSeconds = 10; // time interval
    //   useEffect(() => {
    //   const checkTokenExpiration = () => {
    //     if (isTokenAboutToExpire(accessToken, thresholdSeconds)) {
    //       // Token is about to expire or has already expired
    //       // Perform token refresh here
    //       refreshTokenFn(refreshToken, "merchant")
    //       .then((res) => {
    //         const token = res?.data?.data?.token;
    //         // console.log(token)
    //         localStorage.setItem("merchantAccessToken", token?.accessToken);
    //         localStorage.setItem("merchantRefreshToken", token?.refreshToken);
    //       })
    //       .catch((err) => {
    //           console.log(err?.response?.data?.message, "error")
    //           localStorage.removeItem("merchantAccessToken");
    //           localStorage.removeItem("merchantRefreshToken");
    //           navigate(ROUTES.adminLogin);
    //       });
    //       // console.log("Token is about to expire or has expired.");
    //     } else {
    //       // Token is still valid
    //       // console.log("Token is still valid.");
    //     }
    //   };
    
    //   // Call the function when the component mounts
    //   checkTokenExpiration();
    
    //   // set up a timer to periodically check token expiration
    //   const intervalId = setInterval(checkTokenExpiration, 3000);
    
    //   // Clean up the interval when the component unmounts
    //   return () => clearInterval(intervalId);
    // }, [accessToken]);

    return !auth.status ? <Outlet /> : <Navigate to={ROUTES.merchantLogin} />;
}