import { useState } from "react";
import CreditSummary from "../../../components/Admin/Community/CreditSummary";
import { useGetAllCredit } from "../../../redux/hooks/getHooks";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import OrderHeader from "./OrderHeader";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import OrderTable from "../../../components/Admin/Community/OrderTable";
import { ActiveNotificationIcon } from "../../../icons/icons";
import SearchBar from "../../../utils/SearchBar";
import { Badge } from "antd";

export default function OrderScreen({ handleClick }) {
  const getOrders = useGetAllCredit(1, "all");

  const order_page_2 = useGetAllCredit(2, "all");
  const order_page_3 = useGetAllCredit(3, "all");
  const order_page_4 = useGetAllCredit(4, "all");

  const totalOrders = getOrders?.data?.data?.data?.concat(order_page_2?.data?.data?.data)?.concat(order_page_3)?.concat(order_page_4)

  // console.log(totalOrders)

  const orders = totalOrders?.filter((item) => item?.status==="order in progress" || item?.status==="pending" || item?.status==='declined');
  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(false);

  const [filteredData, setFilteredData] = useState()

  

  //drawer functions==============================
  const handleOpen = (_, data) => { 
    setDataDetail(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //===================================

  //
  const headerData = [
    { value: 0, label: "Transasction Count" },
    { value: <>₦20, 000</>, label: "Total Transaction Amount" },
    { value: <>₦15, 000</>, label: "Total Settlement Received" },
  ];




  const handleFilter=(val)=>{

    let updatedData = [];

    if (val.length) {
      updatedData = orders?.filter((item) => {
        const startsWith = (item?.user?.first_name +" "+item?.user?.last_name)
          ?.toLowerCase()
          .startsWith(val.toLowerCase());

        const includes = ((item?.user?.first_name +" "+ item?.user?.last_name) || item?.id)
          ?.toLowerCase()
          .includes(val.toLowerCase());
        const includesOrderId = (item?.id)
          ?.toLowerCase()
          .includes(val.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else if(includesOrderId){
            return includesOrderId;
        }
         else return null;
      });

      setFilteredData(updatedData);

    }
}








  return (
    <>
      <main>
      <div className="px-lg-5 d-flex justify-content-between flex-wrap">
          <p className="fw-semibold">
            New Order Request
            {
                    orders?.length? (
                      <Badge size="small" color="orange" count={orders?.filter(item=>item?.status==='pending')?.length}
                      onClick={()=>setFilteredData(orders?.filter(item=>item?.status==='pending'))}
                      style={{cursor: "pointer"}}
                      >
                        <ActiveNotificationIcon />
                      </Badge>
                    ): 
                    <ActiveNotificationIcon />
                  }
          </p>
        </div>
        <SearchBar handleFilter={handleFilter} />
        <OrderHeader role={"Order"}/>
        {getOrders.isLoading ? (
          <IsLoadingLoader />
        ) : getOrders.isError ? (
          <IsErrorLoader />
        ) : (
          <OrderTable rows={(filteredData ?? orders)?.sort((a, b) => new Date(b.createdAt)-new Date(a.createdAt))} handleOpen={handleOpen} />
        )}
      </main>

      <DrawerBox open={open} handleClose={handleClose} size={550}>
        <CreditSummary
          order={dataDetail}
          handleClick={handleClick}
          handleCloseSummaryModal={handleClose}
        />
      </DrawerBox>
    </>
  );
}
