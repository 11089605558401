
import React, { useState, useMemo } from "react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useNavigate } from "react-router-dom";
import { useTimeCounter } from "../hooks/useTimeCounter";
import { verifyPaymentFn } from "../../redux/fetchingFn";
import { useResponseModal } from "../../redux/hooks/zustands/useResponseModal";
import { Button } from "antd";
import { formNumberOnly } from "../../utils/formatNumber.js";
import MandateTransferResult from "../Shop/Mandate/MandateTransferResult";
import CheckingMandateTransfer from "../Shop/Mandate/CheckingMandateTransfer";
import { changePaymentMethod } from "../../redux/mutation/postMutation.js";
import { useUserData } from "../hooks/useUserData.js";

const PayWithTransfer = ({
  handleClick,
  setPaymentMethod,
  details,
  handleCloseModal,
  updateData

}) => {
  const navigate = useNavigate();

  const [copied, setCopied] = useState({ status: false, index: null });

  const { openModal } = useResponseModal();

  const user = useUserData();

  const [isLoading, setIsLoading] = useState(false);
  const [loading, setLoading] = useState(false);

  const [transferStep, setTransferStep] = useState({
    step: 0,  
    state: "not sent",
    sentAt: null,
    message: "",
  
  });

  const { timeRemaining, formatTime, stopTimer } = useTimeCounter(
   details?.account_expires_at
  );

  //copying to clippoard
  const handleCopy = (val, index) => {
    navigator.clipboard
      .writeText(val)
      .then(() => {
        setCopied({ ...copied, status: true, index });
      })
      .catch((error) => {
        console.log(error);
        // handleClick("Error copying to clipboard: ", "error", {
        //   vertical: "top",
        //   horizontal: "right",
        // });
      });
  };

  const transferCred = useMemo(() => {
    return [
      { label: "Amount", value: details?.amount ?? 0, copiable: true },
      {
        label: "Account Number",
        value: details?.account_number ?? "",
        copiable: true,
      },
      { label: "Bank", value: details?.bank_name ?? "", copiable: false },
      {
        label: "Account Name",
        value: details?.account_name ?? "",
        copiable: false,
      },
    ];
  }, [details]);

  const confirmTransferDone = () => {
    // navigate(`/verify_payment?reference=${details?.reference}`)
    // handleCloseModal();

    setIsLoading(true);

    const currentTime = new Date(new Date().toISOString());

    // console.log(currentTime);

    setTransferStep({
      ...transferStep,
      step: 1,
      sentAt: currentTime,
      state: "sent",
    });


    // details?.reference
    verifyPaymentFn({ queryKey: ["verify_transfer", details?.reference] })
      .then((res) => {
        // handleCloseModal();
        // openModal({ status: true, message: res?.data?.message });

        // console.log(res?.data?.data)

        setTransferStep({
          ...transferStep,
          step: 2,
          setAt: currentTime,
          state: "recieved",

        });

   

        setTimeout(() => {
          setTransferStep({
            ...transferStep,
            step: 3,
            setAt: currentTime,
            state: "success",
            message: res?.data?.message ?? "Order Complete",
            data: res?.data?.data
          });
        }, 3000);
      })
      .catch((error) => {
        // console.log(error);
        setIsLoading(true)
        
        setTimeout(() => {
          setIsLoading(false)
          setTransferStep({
            ...transferStep,
            step: 1,
            setAt: currentTime,
            state: "error",
            message: error?.response?.data?.message ?? error?.message,
          });
        }, 2000);

        // openModal({
        //   status: false,
        //   message: error?.response?.data?.message ?? error?.message,
        // });
      })
      .finally(() => {
        setIsLoading(false);
      });
  };



  const generateAccountNumber=async()=>{
    setLoading(true);
    try {
      const response = await changePaymentMethod({
          "transaction_id": details?.id,
          "payment_type": "transfer",
          "email": details?.email ?? user?.data?.email,
          "userId": user?.data?.id //"b406f9e8-fb58-4ae9-bca6-c6b20e9b5549"
      });

      // console.log(response);
      const res = response?.data?.data;
      const status = response?.data?.data? true: false
      await updateData(res);
      // return {data: res, status, message: response?.data?.message};
  } catch (error) {
      const errorMessage = error?.response?.data?.message ?? "Unexpected error! please try again";

      openModal({status: false, message: errorMessage});

  }
  finally{
    setLoading(false);
  }
  }

  return (
    <>
      {transferStep?.state === "success" || transferStep?.state === "error" ? (
        <MandateTransferResult
          setTransferStep={setTransferStep}
          transferStep={transferStep}
          closeModal={handleCloseModal}
          // bills = 
        />
      ) : transferStep?.step === 0 ? (
        <div className="col-lg-10 col-md-6 col-12 mx-auto">
          <h5 className="text-center mb-3 tw-text-[14px] tw-font-[300]">
            Please make a transfer to the account specified below:
          </h5>
          <div className="max-w-l mx-auto tw-border tw-rounded-[8px] tw-border-[#CFCDCC99] p-4">
            <p className="fw-semibold text-center mb-2">
              Transfer information
            </p>
            <div className="tw-flex tw-flex-col tw-gap-y-4">
              {transferCred?.map((item, index) => (
                <div
                  className="tw-grid tw-grid-cols-3 tw-gap-x-10"
                  key={index + "__" + item?.id}
                >
                  <p className="my-auto tw-font-[500] tw-text-[14px]">
                    {item?.label}:
                  </p>
                  <p className={`my-auto ${!item?.copiable && "tw-col-span-2"}`}>
                    {item.label === "Amount"
                      ? "NGN " + formNumberOnly(item?.value)
                      : item?.value}
                  </p>
                  {item?.copiable && (
                    <p
                      className="tw-text-[#2C9DB4E5] tw-text-[14px] cursor-pointer"
                      onClick={() => handleCopy(item?.value, index)}
                    >
                      {copied?.status && copied?.index === index
                        ? "copied "
                        : "copy "}
                      <ContentCopyIcon
                        fontSize={"14px"}
                        sx={{ color: "#2C9DB4E5" }}
                      />
                    </p>
                  )}
                </div>
              ))}
            </div>

            <div className="mt-3 text-center">
              {timeRemaining <= 0 ? (
                <div className="tw-flex tw-flex-col tw-items-center">
                  <p className="tw-text-[300] tw-text-[13px]">
                    {" "}
                    <small style={{ color: "#940B0B", fontSize: "16px" }}>
                      This account details has expired
                    </small>
                  </p>
                 <Button type="text" onClick={generateAccountNumber} className="tw-text-[#033F4B] tw-font-[700] tw-w-fit" loading={loading}>Generate New account</Button>
                </div>
              ) : (
                <div className="tw-flex tw-items-center text-center">
                  <p className="tw-text-[300] tw-text-[13px]">
                    This {details?.bank_name} account expires in
                  </p>
                  <div className="rounded-pill w-auto py-1 tw-px-3 tw-text-[#033F4B] tw-font-[700]">
                    {timeRemaining > 0 ? formatTime(timeRemaining) : "00:00"}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="mx-auto d-flex flex-column gap-3 mt-3 ">
            <Button
              shape="round"
              size="large"
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "16px",
                height: "55px",
              }}
              className="tw-w-full"
              loading={isLoading}
              onClick={confirmTransferDone}
            >
              I’ve sent the money
            </Button>
            <Button
              shape="round"
              size="large"
              style={{
                background: "#FFFF",
                border: "2px solid #033F4B",
                color: "#033F4B",
                fontSize: "16px",
                height: "55px",
              }}
              className="tw-w-full"
              onClick={() => setPaymentMethod("anotherMethod")}
            >
              Other payment channel
            </Button>
          </div>
        </div>
      ) : (
        <CheckingMandateTransfer
          transferStep={transferStep}
          setTransferStep={setTransferStep}
        />
      )}
    </>
  );
};

export default PayWithTransfer;
