import React, { useState, useEffect } from 'react'
import { formatNumber } from '../../../../utils/formatNumber'
import OrderForm from './OrderForm'
import ProductItemList from './ProductItemList'
import { useFormik } from 'formik'
import FulfilOrder from './FulfilOrder'
import CompleteTransaction from './CompleteTransaction'
import CustomButton from '../../../CustomButton/CustomButton'
import * as yup from "yup";
export default function OrderSection({handleClick}) {
    const [allProducts, setAllProducts] = useState([]);
    const [isLoading, setIsloading] = useState(false)
    const [fulfillOrder, setFulfillOrder] = useState(null)
    const [ transactionCompleted, setTransactionCompleted] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0)
    const [creditLimit, setCreditLimit] = useState(10000)
    const [addAnotherItem, setAddAnotherItem] = useState(false);

    const formik = useFormik({
        initialValues: {
            product: "",
            amount: ''
        },
        onSubmit:(values, {resetForm})=>{
                if(creditLimit >= values?.amount){
                    setAllProducts((prev)=>[...prev, values])
                    setCreditLimit(()=>{return creditLimit - values?.amount});
                    resetForm();
                }
                else{
                    handleClick("Insufficient credit limit", 'error', {vertical: 'top', horizontal: "right"});
                }
        },
        validationSchema: yup.object().shape({
            product: yup.string().required("Product name can not be empty"),
            amount: yup.string().required("This can not be empty")
          })
    })

    useEffect(()=>{
        if(allProducts?.length){
        const totalCalcAmount = allProducts?.reduce((prevAmount, curr)=>prevAmount + curr.amount, 0)
        setTotalAmount(totalCalcAmount)
        }
    }, [allProducts])

    const handleAddAnotherItem = ()=>{
        setAddAnotherItem(true);
    }
    
    const handleCloseOrderForm=()=>{
        setAddAnotherItem(false);
    }


    //function to remove item from the list
    const removeItem=(index, price)=>{
        let filteredProducts = allProducts?.filter((item, itemIndex)=>itemIndex!==index);
        setAllProducts(filteredProducts);
        setCreditLimit(()=>{return creditLimit + parseFloat(price)});
    }



    //this will submit the order to the server
    const handleSubmit=(param)=>{
        if(!param){
            setFulfillOrder(true);
        }else{
            //submit the products to the server
           
            setIsloading(true)
            setTimeout(()=>{
                setIsloading(false)
                setTransactionCompleted(true);
            }, 3000);
        }
    }
  return (
    <>
        <main className='my-3'>
            {
                transactionCompleted?
                <CompleteTransaction />
                :
                <>
                    {
                        !fulfillOrder?
                    <section style={{minHeight: '60vh'}}>
                        <section className='row'>
                            <h5 className="col-lg-6 col-md-4 fw-bold">John Doe</h5>
                            <div className='col-lg-6 col-md-8 d-flex gap-3'>
                                <p>Maximum Credit:</p>
                                <p className='fw-bold'>{ formatNumber(creditLimit) }</p>
                            </div>
                        </section>
                        {
                            allProducts?.length?
                            <ProductItemList handleAddAnotherItem={handleAddAnotherItem} removeItem={removeItem} products={allProducts}/>:<OrderForm formik={formik}/>
                        }
                        {
                            addAnotherItem &&
                            <OrderForm formik={formik} handleCloseOrderForm={handleCloseOrderForm} allProduct={allProducts} handleClick={handleClick}/>
                        }
                    </section>:
                    <section style={{minHeight: '60vh'}}>
                        <FulfilOrder products={allProducts} totalAmount={totalAmount}/>
                    </section>
        }
                    <section className='text-center my-3' style={{width: '50%', margin: '0 auto'}}>
                        {
                            allProducts?.length?
                        <CustomButton text={fulfillOrder?"Complete":"Next"} functionFn={()=>handleSubmit(fulfillOrder)} loading={isLoading}/>:''
                        }
                    </section>
                </>
            }
        </main>
    </>
  )
}
