import { Button, CircularProgress } from "@mui/material";
import DoneIcon from "@mui/icons-material/Done";
import { DownOutlined } from "@ant-design/icons";
import { Dropdown, message, Space } from "antd";
import MoreVertIcon from "@mui/icons-material/MoreVert";

const items = [
  {
    label: "Valid",
    key: "1",
  },
  {
    label: "Invalid",
    key: "2",
  },
];

export default function Header({
  title,
  actionFn,
  actionText,
  actionLoading,
  actionSuccess,
}) {
 
  return (
    <>
      <section
        className="col-lg-11 mx-auto my-3"
        style={{
          borderBottom: "1px solid #adb5bd",
        }}
      >
        <div className="d-flex justify-content-between align-items-center">
          <p className="fs-6 fw-bold my-auto">{title}</p>

          <div>
              <Button
                onClick={() => actionFn("valid")}
                sx={{
                  fontFamily: "Poppins",
                  color: "#033F4B",
                }}
                disabled={actionSuccess==='valid'}
              >
                {
                  actionSuccess==='valid'? "Approved": "Approve"
                }
                {actionLoading?.status==='valid' && actionLoading?.loading && (
                  <CircularProgress
                    size={26}
                    sx={{
                      position: "absolute",
                      // top: '45%',
                      left: "40%",
                    }}
                  />
                )}
              </Button>

            <Button
              onClick={() => actionFn("invalid")}
              sx={{
                fontFamily: "Poppins",
                color: "#033F4B",
              }}
              disabled={actionSuccess==='invalid'}
            >
               {
                  actionSuccess==='invalid'? "Declined": "Decline"
                }
              {actionLoading?.status==='invalid' && actionLoading?.loading && (
                <CircularProgress
                  size={26}
                  sx={{
                    position: "absolute",
                    // top: '45%',
                    left: "40%",
                  }}
                />
              )}
            </Button>

          </div>
        </div>
      </section>
    </>
  );
}
