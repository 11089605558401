import React from 'react'
import CustomButton from '../../../../CustomButton/CustomButton'
import { Navigate, useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../../routes/route'

function Summary() {
  const navigate = useNavigate();
  return (
    <>
        <main className='col-lg-10 mx-auto'>
        <p className="fw-bold fs-6 text-center">Summary</p>
        <br /><br />
        <section>
          <p className="fw-bold fs-5">Thank you!!</p>
            <div>
                <span>
                Your KYC application has been submitted. Our team will review to complete your onboarding into our community.
                </span>
            </div>
            <div>
              <CustomButton text={"Got to home page"} functionFn={()=>{
                navigate(ROUTES.shop);
              }}/>
            </div>
        </section>
        </main>
    </>
  )
}

export default Summary