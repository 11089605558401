import React from 'react'
import VerifyAccount from '../../../components/Auth/VerifyAccourt/VerifyAccount'
import { useParams } from 'react-router-dom'
function VerifyAccountScreen() {
  const params = useParams();
  const token = params.token;
 
  return (
    <>
        <VerifyAccount token={token}/>
    </>
  )
}

export default VerifyAccountScreen