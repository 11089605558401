import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import moment from "moment";
import { formatNumber, formNumberOnly } from "../../../utils/formatNumber";
import { Button, Chip } from "@mui/material";


function Row(props) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);


  const statusColor = {
    "pending": "alert-warning",
    "order in progress": "alert-warning",
    "repaid": "alert-success",
    "order delivered": "alert-success",
    "rejected": "alert-danger",
    "declined": "alert-danger",
  };


  return (
    <>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} hover>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            style={{ width: 5}}
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell className="nowrap">{row?.user?.first_name} {row?.user?.last_name}</TableCell>
        <TableCell>{row?.market_place?.name}</TableCell>
        <TableCell align="center">{formNumberOnly(row?.total_amount)}</TableCell>
        <TableCell className="nowrap">
          {moment(row?.createdAt).format("LL")}
        </TableCell>
        <TableCell >
          <p style={{ width: '25vh' }}>
            {row?.deliveryLocation}
          </p>
        </TableCell>
        <TableCell className="nowrap">
          {moment(row?.repaymentDuration).format("LL")}
        </TableCell>
        {/* <TableCell align="center">
        {row?.repaymentStatus === true ? "Repaid" : "Not Paid"}
        </TableCell> */}
        <TableCell align="center">
          
           <span
              className={`border-0 alert nowrap ${statusColor[row?.status]} rounded p-1 px-3`}
              style={{
                fontSize: "12px",
              }}
            >
              <i>{row?.status}</i>
            </span>
        </TableCell>
       
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant="h6" gutterBottom component="div">
                Products
              </Typography>
              <Table aria-label="purchases">
                <TableHead>
                  <TableRow>
                    {
                        ['S/N', 'Item Name', 'Category', "Price", "Quantity"].map((val, index)=>(
                            <TableCell key={val+"__"+index} className="fw-bold">
                                {val}
                            </TableCell>
                        ))
                    }
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row?.products.map((product, index) => (
                    <TableRow key={product.name + "_" + index} hover>
                      <TableCell component="th" scope="row">
                        {index+1}.
                      </TableCell>
                      <TableCell>
                        {product?.name}
                      </TableCell>
                      <TableCell>{product?.category}</TableCell>
                      <TableCell >{formNumberOnly(product.price)}</TableCell>
                      <TableCell>{product.quantity}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

// Row.propTypes = {
//   row: PropTypes.shape({
//     calories: PropTypes.number.isRequired,
//     carbs: PropTypes.number.isRequired,
//     fat: PropTypes.number.isRequired,
//     history: PropTypes.arrayOf(
//       PropTypes.shape({
//         amount: PropTypes.number.isRequired,
//         customerId: PropTypes.string.isRequired,
//         date: PropTypes.string.isRequired,
//       }),
//     ).isRequired,
//     name: PropTypes.string.isRequired,
//     price: PropTypes.number.isRequired,
//     protein: PropTypes.number.isRequired,
//   }).isRequired,
// };

export default function CreditTable({rows}) {
  return (
    <>
    <section className="col-lg-1 mx-aut w-100 car">
        <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
            <TableHead>
            <TableRow>
                <TableCell />
                <TableCell className="fw-bold">User Name</TableCell>
                <TableCell className="fw-bold">Store</TableCell>
                <TableCell align="center" className="fw-bold">Amount(₦)</TableCell>
                <TableCell className="fw-bold">Requested Date</TableCell>
                <TableCell style={{width: 200}} className="fw-bold">Delivery Address</TableCell>
                <TableCell className="fw-bold">Repayment Date</TableCell>
                {/* <TableCell className="fw-bold">Payment Status</TableCell> */}
                <TableCell className="fw-bold">Status</TableCell>
            </TableRow>
            </TableHead>
            <TableBody>
            {rows.map((row) => (
                <Row key={row.name} row={row} />
            ))}
            </TableBody>
        </Table>
        </TableContainer>
</section>
    </>
  );
}
