import { IconButton, Toolbar, Tooltip, Typography, alpha } from "@mui/material";
import PropTypes from "prop-types"
import { Delete_Icon, NotificationIcon } from "../../../../../icons/icons";
function TopToolBar(props) {
    const { numSelected, topHeaderVisible } = props;
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} selected
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            {
              topHeaderVisible &&<>
                List of Users
              </>
            }
          </Typography>
        )}
       
        {numSelected > 0 && (
          <Tooltip title="Delete">
            <IconButton>
              <Delete_Icon />
              {/* delete icon */}
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
  }
  
  TopToolBar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    topHeaderVisible: PropTypes.bool
  };

  export default TopToolBar;