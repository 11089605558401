
import { Input, Typography, Button } from 'antd';
import { useState, useEffect } from 'react';
import OtpInput from 'react-otp-input';
import { useCreateMandate } from "../../../redux/mutation/AdminPostMutation";
import { useUserLoanApproval } from "../../../redux/hooks/zustands/useUserLoanApproval";

const { Title } = Typography;



const response = {
  "message": "Please make payment to all this account(s) to compleste the authorization",
  "data": {
      "customer_data": {},
      "id": "91170ff6-e29c-4677-9a56-2d639731fcb0",
      "order_id": "WGEY462148",
      "first_name": "Adeniran",
      "last_name": "Akinboyewa",
      "bvn": "22375918562",
      "phone": "08102637956",
      "email": "adeakinboyewa@gmail.com",
      "address": "Surulere Lagos",
      "mono_customer_id": "66817e6e9eba938ac8b7e1ff",
      "total_amount": "500000",
      "tenor": "6months",
      "stage": 1,
      "total_interest": "500000",
      "start_date": "2024/08/02",
      "end_date": "2025/06/02",
      "status": "inactive",
      "createdAt": "2024-07-04T16:56:06.000Z",
      "updatedAt": "2024-07-04T16:56:06.000Z",
      "companies_schedules": [
          {
              "id": "08b542b6-06f3-4a74-954b-c87aee21f1d9",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "09/07/2024",
              "end_date": "09/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "1cb2edf5-8879-4291-b939-fed7b7177661",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "13/07/2024",
              "end_date": "13/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "38420aab-f21f-40e1-b402-b3f7709ec5df",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "02/07/2024",
              "end_date": "02/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "3d6fc86f-a0e6-4742-8219-17c12990c1fd",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "10/07/2024",
              "end_date": "10/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "5778241c-0cbb-4bf7-9bac-6bb5e57717e9",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "08/07/2024",
              "end_date": "08/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "89e771a2-447b-4362-b367-b3253fcce79c",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "04/07/2024",
              "end_date": "04/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "ace5c894-f476-4f23-9747-f37a5be0cd69",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "12/07/2024",
              "end_date": "12/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "b56195f5-10de-4218-b051-98610cbeaa69",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "03/07/2024",
              "end_date": "03/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "c6139d1d-42ff-415c-b1c8-1f97b558cebc",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "07/07/2024",
              "end_date": "07/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "eca873fa-b4c0-4d08-a395-704e5d382187",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "06/07/2024",
              "end_date": "06/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "ee1db405-789e-4ab3-90fb-fec7a5b8eb8f",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "05/07/2024",
              "end_date": "05/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "fff3b6f2-2dbb-47f0-a9cf-ba939b66f61e",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6,000",
              "amount_paid": "0",
              "start_date": "11/07/2024",
              "end_date": "11/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          }
      ],
      "companies_accounts": [
          {
              "mandate_data": {},
              "initiate_mandate_data": {
                  "id": "mmc_6686e75e639212b9537833d4",
                  "status": "active",
                  "mandate_type": "emandate",
                  "debit_type": "variable",
                  "ready_to_debit": false,  
                  "nibss_code": "RC227914/1580/0003111074",
                  "approved": false,
                  "reference": "MONO5d1d694db6e85d85",
                  "account_name": "ADENIRAN AKINBOYEWA",
                  "account_number": "0253019123",
                  "bank": "WEMA BANK PLC",
                  "bank_code": "035",
                  "customer": "66817e6e9eba938ac8b7e1ff",
                  "description": "Credit repayment",
                  "live_mode": true,
                  "start_date": "2024-08-02T00:00:00.000Z",
                  "end_date": "2025-06-02T00:00:00.000Z",
                  "date": "2024-07-04T18:18:06.400Z",
                  "transfer_destinations": [
                      {
                          "bank_name": "Fidelity",
                          "account_number": "9020025928",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png"
                      },
                      {
                          "bank_name": "Paystack Titan",
                          "account_number": "9880218357",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png"
                      }
                  ],
                  "amount": 50000000
              },
              "id": "0126b1af-6385-48e6-bb54-0d98056e9d76",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "reference": "mmc_6686e75e639212b9537833d4",
              "status": "inactive",
              "createdAt": "2024-07-04T18:18:06.000Z",
              "updatedAt": "2024-07-04T18:18:06.000Z"
          },
          {
              "mandate_data": {},
              "initiate_mandate_data": {
                  "id": "mmc_6686e72f639212b95378330c",
                  "status": "active",
                  "mandate_type": "emandate",
                  "debit_type": "variable",
                  "ready_to_debit": false,
                  "nibss_code": "RC227914/1580/0003111067",
                  "approved": false,
                  "reference": "MONOafd743bc0841a8bd",
                  "account_name": "AKINBOYEWA ADENIRAN ADEOYE",
                  "account_number": "0225660192",
                  "bank": "GUARANTY TRUST BANK PLC",
                  "bank_code": "058",
                  "customer": "66817e6e9eba938ac8b7e1ff",
                  "description": "Credit repayment",
                  "live_mode": true,
                  "start_date": "2024-08-02T00:00:00.000Z",
                  "end_date": "2025-06-02T00:00:00.000Z",
                  "date": "2024-07-04T18:17:19.277Z",
                  "transfer_destinations": [
                      {
                          "bank_name": "Fidelity",
                          "account_number": "9020025928",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png"
                      },
                      {
                          "bank_name": "Paystack Titan",
                          "account_number": "9880218357",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png"
                      }
                  ],
                  "amount": 50000000
              },
              "id": "eb38a2ad-cfc7-40b8-8ab7-523bcc048895",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "reference": "mmc_6686e72f639212b95378330c",
              "status": "inactive",
              "createdAt": "2024-07-04T18:17:19.000Z",
              "updatedAt": "2024-07-04T18:17:19.000Z"
          }
      ]
  }
}

export default function BVN_OTP(){

  const mutation = useCreateMandate()

  const { updateCurrentPage, data: {mandate} } = useUserLoanApproval();

    const [otp, setOtp] = useState('');

    const [isLoading, setIsLoading] = useState(false);



    const onChange = (text) => {
        console.log('onChange:', text);
      };

      const sharedProps = {
        onChange,
      };


      const accounts = response?.data?.companies_accounts



      const validateBVN=()=>{
        const json = {
          "otp": otp,
          "mandate_id": mandate?.id ?? "91170ff6-e29c-4677-9a56-2d639731fcb0"
        }

        
        // setTimeout(()=>{
        //   updateCurrentPage("BANK_ACCOUNTS", {bank_accounts: accounts})
        //   setIsLoading(true)
        // }, 2000)

        mutation.mutate(json)
      }




      useEffect(()=>{
        if(mutation.isLoading){
            setIsLoading(true)
        }
        else if(mutation.isError){
            setIsLoading(false)
            const error = mutation?.error?.response?.data
    
            console.log(error)
        }
        else if(mutation.isSuccess){
            setIsLoading(false)
            const res = mutation?.data?.data?.data
    
            console.log(mutation?.data?.data)

            const accounts = res?.companies_accounts
          
            updateCurrentPage("BANK_ACCOUNTS", {bank_accounts: accounts})
             
        }
    }, [mutation.status])

      
    return(
        <>
             <div className="col-lg-4 col-md-8 col-12 mx-auto tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white tw-shadow-sm tw-mt-5">
        <h2 className="tw-font-[600] tw-text-[22px] tw-mb-5 tw-text-center">FI Lender</h2>
        <br />
        <h5 className="tw-font-[400] tw-text-[18px] tw-text-center tw-mb-5">Complete Mandate process</h5>

        <div className="tw-border tw-rounded-lg tw-border-[#D9D9D996] tw-px-5 tw-py-3">
        {/* <h4 className="tw-font-[600] tw-text-[18px] tw-mb-2">
        Enter OTP to validate BVN
              </h4> */}

              <Title level={5}>Enter OTP to validate BVN</Title>
      {/* <Input.OTP formatter={(str) => str?.toUpperCase()} {...sharedProps} /> */}
      <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                inputType='number'
                containerStyle={{flex: "wrap !important", display: 'flex !important', textAlign: 'center'}}
                inputStyle={{width: '45px', height: '50px', margin: '5px 5px', borderRadius: '10px', border: '1px solid'}}
                renderInput={(props) => <input {...props} />}
                onPaste={(event) => {
                    const data = event.clipboardData.getData('text');
                  }}
                />

                <div >
                  <p className="tw-text-[#033F4B] tw-text-[400] tw-text-[14px] mt-2">If you did not receive OTP, click <span className="tw-text-[#8B0404] tw-cursor-pointer">Resend</span> to receive another one</p>
                </div>
                <div className="tw-mt-5 tw-flex tw-justify-center tw-items-center">
                <Button
                    size="large"
                    shape={"round"}
                    loading={isLoading}
                    onClick={validateBVN}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] px-4 hover:!tw-text-[#FFF]"
                  >
                    Validate
                  </Button>
                </div>
                
        </div>

        </div>
        </>
    )
}