import { useMutation, useQueryClient } from "@tanstack/react-query";
import {
  PatchMethod,
  PostMethod,
  PostMethodWithHeader,
  PutMethod,
  PutMethodWithHeader,
} from "..";
import { API_URL } from "../api_urls";
import adminAuth from "../../components/RouteGuard/adminAuth";
import merchantAuth from "../../components/RouteGuard/merchantAuth";
const auth = adminAuth();
const merchant_auth = merchantAuth();
//admin signup
export const useAdminRegister = () => {
  const registerAdmin = useMutation({
    mutationFn: (payload) => {
      return PostMethod(API_URL.adminRegister, payload);
    },
  });
  return registerAdmin;
};
//admin login
export const useStaffLogin = () => {
  const login = useMutation({
    mutationFn: ({ url, payload }) => {
      return PostMethod(url, payload);
    },
  });
  return login;
};
export const useOnboardMerchantMutation = () => {
  const token = localStorage.getItem("adminAccessToken");
  const onboardMerchant = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(API_URL.onboardMerchant, token, payload);
    },
  });
  return onboardMerchant;
};

//approve kyc
export const useApproveKyc = (userId) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const approveKyc = useMutation({
    mutationFn: (payload) => {
      //payload will include the userId and allowed plan
      const { allowed_plan, approved_kyc } = payload;
      return PutMethodWithHeader(API_URL.approveKyc + userId, token, payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
  });
  return approveKyc;
};
//decline kyc
export const useDeclineKyc = (userId) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const approveKyc = useMutation({
    mutationFn: (payload) => {
      //payload will include the userId and allowed plan
      // const {userId} = payload;
      return PutMethodWithHeader(API_URL.declineKyc + userId, token, payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ["users"],
      });
    },
  });
  return approveKyc;
};

//update Kyc Status kyc
export const useUpdateKycStatus = (userId) => {
  // const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const approveKyc = useMutation({
    mutationFn: (payload) => {
      const { key, status } = payload;
      return PutMethodWithHeader(API_URL.updateKycStatus + userId, token, {
        key,
        status,
      });
    },
    // onSuccess:(data)=>{
    //     queryClient.invalidateQueries({
    //         queryKey: ['users']
    //     })
    // }
  });
  return approveKyc;
};

//admin set user credit limit
export const useSetLimit = () => {
  const token = localStorage.getItem("adminAccessToken");
  const setLimit = useMutation({
    mutationFn: (payload) => {
      //payload will be userId, limiy

      return PostMethodWithHeader(API_URL.setLimit, token, payload);
    },
  });
  return setLimit;
};
// admin create plan
export const useCreateplan = () => {
  const queryClient = useQueryClient({});
  const token = localStorage.getItem("adminAccessToken");
  const createPlan = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(API_URL.createPlan, token, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["admin_subscription_plan"] });
    },
  });
  return createPlan;
};
// admin create plan
export const useCreateSubscriptionType = () => {
  const token = localStorage.getItem("adminAccessToken");
  const createType = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(
        API_URL.createSubscriptionType,
        token,
        payload
      );
    },
  });
  return createType;
};
//update subscription plans
export const useUpdatePlan = (planId) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const updatePlan = useMutation({
    mutationFn: (payload) => {
      return PutMethodWithHeader(API_URL.updatePlan + planId, token, payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["admin_subscription_plan"] });
    },
  });
  return updatePlan;
};

//update subscription type
export const useUpdateType = (typeId) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const updateType = useMutation({
    mutationFn: (payload) => {
      return PutMethodWithHeader(API_URL.updatePlan + typeId, token, payload);
    },
    onSuccess: (data) => {
      // queryClient.invalidateQueries({queryKey: ['all_credit', 'all']})
      queryClient.invalidateQueries({ queryKey: ["all_credit", "pending"] });
    },
  });
  return updateType;
};


//approve or reject plan upgrade
export const useUpgradePlan = (upgradeId) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const upgradePlan = useMutation({
    mutationFn: (payload) => {
      //payload will be status and comment
      return PutMethodWithHeader(API_URL.approve_reject_upgrade + upgradeId, token, payload);
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["admin_upgrade_request", "all"] });
    },
  });
  return upgradePlan;
};



//approve credit
export const useApproveOrder = () => {
  const token = localStorage.getItem("adminAccessToken");
  const approveOrder = useMutation({
    mutationFn: (payload) => {
      return PutMethodWithHeader(API_URL.approveCredit, token, payload);
    }
  });
  return approveOrder;
};

export const useConfirmDelivery = (orderId) => {
  const token = localStorage.getItem("adminAccessToken");
  const confirmDelivery = useMutation({
    mutationFn: () => {
      return PutMethodWithHeader(API_URL.confirmDelivery + orderId, token);
    },
  });
  return confirmDelivery;
};

// confirm repayment
export const useConfirmRepayment = (orderId) => {
  const queryClient = useQueryClient({});
  const token = localStorage.getItem("adminAccessToken");
  const confirmRepayment = useMutation({
    mutationFn: () => {
      return PutMethodWithHeader(API_URL.confirmRepayment + orderId, token, {
        repaymentStatus: true,
      });
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries(["all_credit"]);
    },
  });
  return confirmRepayment;
};

//market place
export const useCreateMarketPlace = () => {
  const queryClient = useQueryClient({});
  const token = localStorage.getItem("adminAccessToken");
  const createMarketPlace = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(API_URL.createMarketPlace, token, payload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["market_place"]);
    },
  });
  return createMarketPlace;
};

//update subscription type
export const useUpdateMarketPlace = (marketPlaceID) => {
  const queryClient = useQueryClient();
  const token = localStorage.getItem("adminAccessToken");
  const updateType = useMutation({
    mutationFn: (payload) => {
      return PutMethodWithHeader(
        API_URL.singleMarketPlace + marketPlaceID,
        token,
        payload
      );
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ["market_place"] });
    },
  });
  return updateType;
};

//debit account
export const useDebitAccount = () => {
  const token = localStorage.getItem("adminAccessToken");
  const debitAcc = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(
        API_URL.debit_account,
        token,
        payload
      );
    }
  });
  return debitAcc;
};


//create mandate

export const useCreateMandate = () => {
  const token = localStorage.getItem("adminAccessToken");
  const createMandate = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(
        API_URL.create_mandate,
        token,
        payload
      );
    }
  });
  return createMandate;
};


//debit for mandate schedule

export const useDebitForSchedule = () => {
  const token = localStorage.getItem("adminAccessToken");
  const debitForSchedule = useMutation({
    mutationFn: (payload) => {
      return PostMethodWithHeader(
        API_URL.debit_account_for_schedule,
        token,
        payload
      );
    }
  });
  return debitForSchedule;
};



export const useCheckPayment = (scheduleID, name) => {
  const token = localStorage.getItem("adminAccessToken");
  const createMandate = useMutation({
    mutationFn: (payload) => {
      return PatchMethod(
        name==="mandate"? API_URL.verifyMandate+scheduleID:
        API_URL.checkPayment+scheduleID,
      );
    }
  });
  return createMandate;
};

//Merchant
