import React from 'react'
import StaffLogin from '../../../components/Admin/AdminLogin/StaffLogin'

export default function MerchantLoginScreen({handleClick}) {
  return (
    <>
        <main>
          <StaffLogin handleClick={handleClick} pageTitle={"Merchant"}/>
        </main>
    </>
  )
}
