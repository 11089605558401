import { useMutation, useQueryClient } from "@tanstack/react-query"
import { PostMethod, PostMethodWithFile, PostMethodWithHeader, PutMethodWithHeader, DeleteMethod } from "../index.js"
import { API_URL } from "../api_urls"
import userAuth from "../../components/RouteGuard/userAuth.js"
import { useUserData } from "../../components/hooks/useUserData.js";
const auth = userAuth();
//register
export const useRegisterMutation = ()=>{
    const registerMutation = useMutation({
        mutationFn: (payload)=>{
            return PostMethod(API_URL.register, payload);
        }
    })
    return registerMutation;
}
//login
export const useLoginMutation=()=>{
    const loginMutation = useMutation({
        mutationFn: (payload)=>{
            return PostMethod(API_URL.login, payload)
        }
    })
    return loginMutation
}
//forget password
export const useForgetPsw=()=>{
    const forgetPsw = useMutation({
        mutationFn: (payload)=>{
            return PostMethod(API_URL.forgetPsw, payload)
        }
    })
    return forgetPsw
}
//reset password
export const useResetPsw=()=>{
    const resetPsw = useMutation({
        mutationFn: (payload)=>{
            return PostMethod(API_URL.resetPsw, payload)
        }
    })
    return resetPsw
}
//upload image for kyc
export const useUploadImage=()=>{
    const uploadImage= useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithFile(API_URL.userImage, payload)
        }
    })
    return uploadImage
}
//kyc
export const useCompleteKyc=()=>{
     const completeKyc = useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithHeader(API_URL.kyc, userAuth()?.token, payload)
        }
    })
    return completeKyc
}

// update profile
export const useUpdateProfile = ()=>{
    const queryClient = useQueryClient();
    const updateProfile = useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithHeader(API_URL.update_profile, userAuth()?.token, payload)
        },
        onSuccess:()=>{
            queryClient.invalidateQueries({queryKey: ['me']})
        }
    })
    return updateProfile
}
// set pin
export const useSetPin = ()=>{
    const queryClient = useQueryClient();
    const setPin = useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithHeader(API_URL.setPin, userAuth()?.token, payload)
        },
        onSuccess:()=>{
            queryClient.invalidateQueries({queryKey: ['me']})
        }
    })
    return setPin
}
// kyc back
export const useBackKyc=()=>{
     const completeKyc = useMutation({
        mutationFn: (payload)=>{
            return PutMethodWithHeader(API_URL.kyc_back_step, userAuth()?.token, payload)
        }
    })
    return completeKyc
    
}
//mono
export const useSubmitMono=()=>{
    const submitMono = useMutation({
        mutationFn: (payload)=>{
            // console.log(payload);
            return PostMethodWithHeader(API_URL.kyc, userAuth()?.token, payload,)
        }
    })
    return submitMono
}
//credit market place
export const useCreditMarketPlace=()=>{
    const creditMarketPlace = useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithHeader(API_URL.creditMarketPlace, userAuth()?.token, payload);
        }
    })
    return creditMarketPlace;
}
//contact us
export const useContactUs=()=>{
    const contactUs= useMutation({
        mutationFn:(payload)=>{
            return PostMethod(API_URL.contactUs, payload)
        }
    })
    return contactUs
}
//verify otp for phone number verification
export const useVerifyOtp=()=>{
    const verifyOtp= useMutation({
        mutationFn:(payload)=>{
            return PostMethodWithHeader(API_URL.kyc, userAuth()?.token, payload);
        }
    })
    return verifyOtp
}
//plan subscription
export const useSubscribePlan=()=>{
    const subscribePlan = useMutation({
        mutationFn:(payload)=>{
            //payload will be planId, subscriptionType, and dueDate(optional)
            return PostMethodWithHeader(API_URL.subscribe, userAuth()?.token, payload)
        }
    })
    return subscribePlan;
}
//update the employment
export const useUpdateEmployment=()=>{
    const queryClient = useQueryClient();
    const updateEmployment = useMutation({
        mutationFn:(payload)=>{
            return PostMethodWithHeader(API_URL.updateEmployment, userAuth()?.token, payload)
        },
         onSuccess:()=>{
            queryClient.invalidateQueries({queryKey: ['me']})
        }
    });
    return updateEmployment;
}
//update the Address
export const useUpdateAddress=()=>{
    const queryClient = useQueryClient();
    const updateAddress = useMutation({
        mutationFn:(payload)=>{
            return PostMethodWithHeader(API_URL.updateAddress, userAuth()?.token, payload)
        },
        onSuccess:()=>{
            queryClient.invalidateQueries({queryKey: ['me']})
        }
    });
    return updateAddress;
}


//delete subscription
export const useDeleteSubscription=(transactionID)=>{
    const deleteSubscription = useMutation({
        mutationFn:()=>{
           
            return DeleteMethod(API_URL.deleteSubscription+transactionID, userAuth()?.token)
        }
    })

    return deleteSubscription;
}


// upgrade plan
export const useUpgradePlan=()=>{
    const queryClient = useQueryClient();
    const upgradePlan = useMutation({
       mutationFn: (payload)=>{
        const {planId} = payload
           return PutMethodWithHeader(API_URL.upgradePlan+planId, userAuth()?.token)
       },
       onSuccess:()=>{
        queryClient.invalidateQueries({queryKey: ['user_upgrade_request']})
    }

   })
   return upgradePlan;
   
}

//initiate bill payment
export const useInitiateBill=()=>{
    const user = useUserData();
    const initiateBill= useMutation({
        mutationFn:(payload)=>{
            return PostMethodWithHeader(user?.status? API_URL.initiate_bill : API_URL.initiate_instant_payment, userAuth()?.token, payload);
        }
    })
    return initiateBill
}

//initiate bill payment
export const useInitiateRepayment=()=>{
    const initiateRepayment= useMutation({
        mutationFn:(payload)=>{
            return PostMethodWithHeader(API_URL.initiate_payment, userAuth()?.token, payload);
        }
    })
    return initiateRepayment
}
//find electric name
export const useFindElectricName=()=>{
    const findElectricName= useMutation({
        mutationFn:(payload)=>{
            return PostMethodWithHeader(API_URL.find_name, userAuth()?.token, payload);
        }
    })
    return findElectricName
}


export const changePaymentMethod=(payload)=>{
    return PostMethod(API_URL.changePaymentMethod, payload);
}




export const useSendSchedule=()=>{
    const sendSchedule= useMutation({
        mutationFn: (payload)=>{
            return PostMethodWithFile(API_URL.initiate_mandate, payload)
        }
    })
    return sendSchedule
}


//cancel order
export const useCancelOrder = () => {
    const queryClient = useQueryClient();
    const token = userAuth()?.token
    const cancelOrder = useMutation({
      mutationFn: (payload) => {
        const { orderID } = payload;
        return PutMethodWithHeader(API_URL.cancel_order + orderID, token);
      },
      onSuccess: (data) => {
        queryClient.invalidateQueries({
          queryKey: ["user_order", "all"],
        });
      },
    });
    return cancelOrder;
  };
