import { Button } from '@mui/material'
import React, { useState } from 'react'
import ModalDialogBox from '../../ModalDialogBox/ModalDialogBox'
import Profile from '../KYC/ProfileDetail/Profile'
import Transaction from './Transaction'
import SetSubscriptionPlan from './SetSubscriptionPlan'
import { useResponsiveSize } from "../../hooks/useResponsiveSize.js"
import TransactionHistory from './TransactionHistory.jsx'
import DrawerBox from "../../../utils/Drawer/DrawerBox";

export default function Options({profileData, role, handleClick, handleCloseOptionModal}) {
    const [open, setOpen] = useState({status: false, index: null})
    const [openDrawer, setOpenDrawer] = useState(false)

    const responsiveSize = useResponsiveSize({})
    const option = {
        user: [
            {id: 1, text: 'View Application'},
            {id: 2, text: 'Transaction'},
            {id: 3, text: 'Confirm Repayment'},
            {id: 4, text: 'Activate New KYC', inActive: true},
            {id: 5, text: 'Suspend Account', inActive: true},
            {id: 6, text: 'Approve Account', inActive: true},
            {id: 7, text: `${profileData?.first_name} transaction History`},
        ],
        order: [
            {text: 'Cancel Order'},
            {text: `View ${profileData?.name??"Customer Name"} Order`},
            {text: 'Settlement Order'}
        ]
    }
    const handleOpen=(index)=>{
        index===7?handleOpenDrawer():
        setOpen({...open, status: true, index})
    }
    const handleClose=()=>{
        setOpen({...open, status: false})
        
    }


    const handleOpenDrawer=()=>{
        setOpenDrawer(true)
    }


    const handleCloseDrawer=()=>{
        setOpenDrawer(false)
    }


    
  return (
   <React.Fragment>
        <section className='text-center'>
            <h5>{profileData?.first_name} {profileData?.last_name} account</h5>
            <p>Current Subscription: <span className="fw-semibold">{profileData?.subscriptions?.length ? profileData?.subscriptions[0]?.plan?.name : "No Subscription"}</span></p>
            <p>What do you want to do?</p>
            <section className='d-flex flex-column gap-3'>
                {
                    option[role].map((item, index)=>{
                        return (
                            <Button 
                            key={index}
                            variant='contained'
                            fullWidth
                            onClick={()=>handleOpen(item.id)}
                            disabled={(item?.inActive) || (item.id !== 1 && !profileData?.status)? true: false}
                            sx={{
                                backgroundColor: "#033F4B",
                                color: "white",
                                textTransform: "capitalize",
                                '&:hover': {
                                    backgroundColor: "#033F4B",
                                    color: "white"
                                }
                            }}
                            >
                                {item.text}
                            </Button>
                        )
                    })
                }
            </section>
        </section>

        <ModalDialogBox open={open.status} handleClose={handleClose} 
        // maxWidth={"lg"}
        // modalSize={false}
        >
           {
            role==='user'?(
                open.index===1?
                <Profile profileData={profileData} handleClick={handleClick} handleCloseProfileModal={handleClose} handleCloseOptionModal={handleCloseOptionModal}/>:
                open.index===2?<Transaction profileData={profileData}/>: open.index===6?<SetSubscriptionPlan adminName={`${ profileData?.first_name} ${ profileData?.last_name}`} user={profileData} handleClick={handleClick} handleClose={handleClose}/>: open.index===7 && <TransactionHistory credData={{...profileData, userId: profileData?.id}} />):
                role==='order'&&(
                    ""
                )
           }
        </ModalDialogBox>




        <DrawerBox
        open= {openDrawer}
        handleClose= {handleCloseDrawer}
        size={responsiveSize === "lg" ? 850 : responsiveSize === "md" && 1000}
      >
                <TransactionHistory credData={{...profileData, userId: profileData?.id}} />

      </DrawerBox>
   </React.Fragment>
  )
}
