import { Checkbox, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from 'prop-types';

function EnhancedTableHead(props) {
    const { onSelectAllClick, numSelected, rowCount, headCell} =
      props;
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{
                'aria-label': 'select all desserts',
              }}
            />
          </TableCell>
          {headCell?.map((headCell) => (
            <TableCell
              key={headCell.id}
            >
              {headCell.label}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
    headCell: PropTypes.array.isRequired
  };

  export default EnhancedTableHead