
import "./adminSidebar.css";
import TopBar from "./TopBar";
import MiddleMenu from "./MiddleMenu";
import BottomMenu from "./BottomMenu";
import { LeftArrowIcon, MenuIcon, RightArrowIcon } from "../../../icons/icons";
import useWindowSize from "../../hooks/useWindowSize";

export default function AdminSidebar({ visible, show, handleClick }) {
  const screenWidth = useWindowSize().width;

  return (
    <>
      <div className="mobile-nav py-3 d-flex justify-content-between"
      >
        <button className="mobile-nav-btn" onClick={() => show(!visible)}>
          <MenuIcon clicked={visible}/>
        </button>
      </div>
      <nav className={`sidebar_nav ${visible ? "" : "sidebar"}`} >
          <button
            className={`nav_btn`}
            type="button"
            onClick={() => show(!visible)}
          >
            {visible ? <LeftArrowIcon size={'5vh'}/> : <RightArrowIcon size={'5vh'}/>}
          </button>
                <TopBar />
              <MiddleMenu fn={() => {
                    window.innerWidth < 768 && show(!visible);
                  }}/>
              <BottomMenu fn={() => {
                    window.innerWidth < 768 && show(!visible);
                  }} handleClick={handleClick}/>
       
      </nav>
    </>
  );
}
