import { create } from "zustand"
export const usePaymentModal = create((set)=>{
    return{
        isOpen: false,
        data: {},

        openModal: (data)=>set((state)=>({isOpen: true, data: {...state.data, ...data}})),
        updateData: (newData)=>set((state) => ({
            data: { ...state.data, ...newData }
        })),

        closeModal:()=>set({isOpen: false})
    }
})