import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TopToolBar from "../../../utils/Table/TopToolBar";
import Status from "../../../utils/Status";
// import { Button } from "@mui/material";
import EnhancedTableHead from "../../../utils/Table/EnhancedTableHead";
import SampleDot from "../../../utils/SampleDot";
import { formatNumber } from "../../../utils/formatNumber";
import moment from "moment";
import { Button, Dropdown } from "antd";

export default function EmandateHistoryTable({ topHeaderVisible, rows, handleOpen, handleToastClick }) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const handleChangePage=()=>{
  //   setPage(page+1)
  // }

  const headCells = [
    {
      label: "Name",
    },
    {
      label: "Customer ID",
    },
    {
      label: "Amount",
    },
    {
      label: "Mandate Status",
    },
    {
      label: "",
    },
    {
      label: "",
    },
  ];


  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, orderId) => {
    const selectedIndex = selected?.indexOf(orderId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, orderId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (agent) => selected?.indexOf(agent) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;

    

  // const items = [
  //   {
  //     key: "1",
  //     label: (
  //       <a
  //         target="_blank"
  //         rel="noopener noreferrer"
  //         href="https://www.antgroup.com"
  //       >
  //         1st menu item
  //       </a>
  //     ),
  //   },
  //   {
  //     key: "2",
  //     label: (
  //       <Button
  //         variant="outlined"
  //         size="small"
  //         sx={{ color: "gray", border: "1px solid gray" }}
  //         onClick={() => handleOpen("order", row)}
  //       >
  //         View Transaction
  //       </Button>
  //     ),
  //   },
  // ];



  const handleCopy = (order_id) => {
    navigator.clipboard
      .writeText(`https://community-kzg6-h4ecw75yo-taiwo02s-projects.vercel.app/user/e_mandate/${order_id}`)
      .then(() => {
        // setCopied({ ...copied, status: true, index });
        handleToastClick("Link Copied to your clipboard: ", "success");
      })
      .catch((error) => {
        console.log(error)
        handleToastClick("Error copying to clipboard: ", "error", {
          vertical: "top",
          horizontal: "right",
        });
      });
  };

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        {rows?.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            {selected?.length ? (
              <TopToolBar
                numSelected={selected?.length}
                topHeaderVisible={topHeaderVisible}
              />
            ) : null}
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected?.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows?.length}
                  headCell={headCells}
                />
                <TableBody>
                  {rows
                    ?.slice(
                      page * rowsPerPage,
                      page * rowsPerPage + rowsPerPage
                    )
                    ?.map((row, index) => {
                      const isItemSelected = isSelected(row.id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          // onClick={(event) => handleClick(event, row.campaign)}
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          selected={isItemSelected}
                          sx={{ cursor: "pointer" }}
                        >
                          <TableCell padding="checkbox">
                            <Checkbox
                            color="primary"
                            // onClick={(event) => handleClick(event, row.id)}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          />
                          </TableCell>
                          <TableCell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                          >
                            <div className="d-flex flex-column">
                              <p className="fw-bold my-auto nowrap">
                                {row?.first_name} {row?.last_name}
                              </p>
                              <small
                                className="text-muted nowrap"
                                style={{ fontSize: "12px" }}
                              >
                                {moment(row?.createdAt).format(
                                  "h:mm:ss, D MMMM YYYY"
                                )}
                              </small>
                            </div>
                          </TableCell>
                          <TableCell>
                              <p className="fw-bold my-auto nowrap" style={{
                                textTransform: "capitalize"
                              }}>
                                {row?.order_id}
                              </p>
                          </TableCell>
                          <TableCell>
                            <p className="my-auto">
                                {row?.balance} / Month
                              {/* {formatNumber(row?.amount ?? 0)} */}
                            </p>
                          </TableCell>

                          <TableCell>
                            {/* <div className="d-flex flex-column text-center">
                              {row?.status}
                            </div> */}
                            
                              <span className={`tw-rounded-full nowrap tw-px-3 tw-py-1 ${row?.status!=='active' ? "tw-bg-yellow-400": "tw-bg-green-400" } `}>{row?.status}</span>
                            
                          </TableCell>
                          <TableCell>
                           
                            <span className={`tw-italic tw-p-2 tw-text-[#890D0D] tw-text-[13px]`} onClick={()=>handleCopy(row?.order_id)}>Copy mandate link</span>
                            {/* Share mandate link */}
                          </TableCell>
                          <TableCell>
                           
                              <Button
                              variant="outlined"
                              size="middle"
                              className="tw-bg-[#000] tw-text-[#FFF]"
                            >
                              View
                            </Button>

                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
            <div></div>
          </Paper>
        ) : (
          <main
            className="d-flex align-items-center justify-content-center flex-column border"
            style={{ height: "25vh", width: "100%" }}
          >
            <h3>E-mandate History is Empty</h3>
          </main>
        )}
      </Box>
    </React.Fragment>
  );
}
