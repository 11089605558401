import {
  DialogTitle,
  Table,
  TableCell,
  TableRow,
  Tooltip,
  TableBody,
  Button,
} from "@mui/material";
import React, { useState } from "react";
import online_market from "../../../assets/cart.png";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route";
import ModalDialogBox from "../../ModalDialogBox/ModalDialogBox";
import OnlineMarketPlace from "../../Shop/OnlineMarketPlace/OnlineMarketPlace";
import "./cart.css";
import { useDispatch, useSelector } from "react-redux";
import { productAction } from "../../../redux/slice/productSlice";
import { formatNumber } from "../../../utils/formatNumber";
import { useUserData } from "../../hooks/useUserData";
import CustomButton from "../../CustomButton/CustomButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal } from "antd";

function Cart({ handleClick }) {
  const { totalPrice, quantity, allProducts, marketPlaces } = useSelector(
    (state) => state.product
  );
  const [isLoading, setIsLoading] = useState(null);

  const [modal, contextHolder] = Modal.useModal({});

  // const marketPlace = marketPlaces?.find(item=>item?.name === allProducts?.marketPlace?.name)



  const { data } = useUserData();
  const sessionKey = data?.id + "_product";

  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState(
    allProducts?.repaymentDuration ?? null
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //default numbers
  const serviceCharge = parseFloat(allProducts?.marketPlace?.service_fee ?? 0);
  const deliveryFee = parseFloat(allProducts?.marketPlace?.delivery_amount ?? 0);

  const handleChange = (id) => {
    setSelectedValue(id);
  };

  const checkoutFn = () => {
    // if(selectedValue){
    setIsLoading(true);
    // dispatch(
    //   productAction.updateSavedProduct({
    //     sessionKey,
    //     repaymentDuration: selectedValue,
    //   })
    // );
    // console.log(selectedValue);
    setTimeout(() => {
      setIsLoading(false);
      navigate(ROUTES.checkout);
    }, 500);
    // }
    // else{
    //   handleClick("select payment method", "error", {vertical: "top", horizontal: "right"})
    // }
  };

  const handleOpen = () => {
    if(data?.subscriptions?.length){
      setOpen(true);
    }
    else{
      continueSubscription()
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const quantityFn = (role, index) => {
    dispatch(
      productAction.manipulateQty({ itemIndex: index, sessionKey, role })
    );
  };

  //radio input style
  const radioStyle = {
    "&.Mui-checked": {
      color: "#033F4B",
    },
  };

  // console.log(allProducts)



  //confirmation to continue to subscription
  const continueSubscription = () => {
    modal.confirm({
      title: 'Important Notice',
      icon: <ExclamationCircleOutlined />,
      content: `You don't have an active subscription, you need to subscribe before continue`,
      okText: 'Continue',
      cancelText: 'Cancel',
      onOk:()=>{
        navigate(ROUTES.subscription);
      }
    });
  };


  return (
    <>
      {allProducts?.products?.length ? (
        <section className="col-lg-11 mx-auto">
          <div className="text-center">
            <DialogTitle>Your Cart</DialogTitle>
          </div>
          <section className="row mx-2">
            <div className="col-lg-8 bg-white py-4" data-aos="fade-down">
              <p className="border-0 border-bottom">Your Order</p>
              <section className="mt-3">
                {allProducts?.products?.map((product, index) => {
                  return (
                    <div className="row mx-2" key={index}>
                      <div className="col-lg-12">
                        <div className="row ">
                          <div className="col-lg-4 col-md-4 col-sm-4 text-start">
                            <img
                              src={allProducts?.marketPlace?.logo}
                              alt={product?.name + "_logo"}
                              className="market_place card-img-top"
                            />
                            <Tooltip
                              title={product?.url ?? "xxxxx"}
                              arrow
                            >
                              <p
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  cursor: "default",
                                  fontSize: "13px"
                                }}
                                className="mt-2"
                              >
                                {product?.url ?? "xxxxx"}
                              </p>
                            </Tooltip>
                          </div>
                          <div className="col-lg-8 col-md-8 col-sm-6">
                            <div className="d-flex justify-content-between">
                              <p className="fw-bold my-auto">
                                {product?.name ?? "xxxxxx"}
                              </p>
                              <p className="d-flex gap-3">
                                {/* <select
                                  className="form-select w-auto py-1"
                                  disabled
                                >
                                  <option value={`1`}>
                                    QTY: {product?.quantity}
                                  </option>
                                </select> */}
                                {formatNumber(parseFloat(product?.price) ?? 0.0)}
                              </p>
                            </div>
                            <div className="d-flex justify-content-between my-2">
                              <p className="fw-medium my-auto">Delivery</p>
                              <p className="">
                                {formatNumber(deliveryFee)}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="my-2 d-flex justify-content-between">
                        <div className="col-6 d-flex gap-3">
                          {/* <p className="my-auto" style={{ cursor: "pointer" }}>
                            Edit
                          </p>
                          <div
                            style={{ height: "auto", border: "0.5px solid" }}
                          ></div> */}
                          <p className="text-danger my-auto">
                            <span
                              className="cursor-pointer"
                              onClick={() =>
                                dispatch(
                                  productAction.removeItem({
                                    index,
                                    sessionKey,
                                  })
                                )
                              }
                            >
                              Remove
                            </span>
                          </p>
                        </div>

                        <div className="col-lg- col-md- d-flex gap-2">
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              backgroundColor: "#FBEBB5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="rounded fs-3 text-center cursor-pointer"
                            onClick={() => quantityFn("dec", index)}
                          >
                            -
                          </div>
                          <p className="my-auto">{product?.quantity}</p>
                          <div
                            style={{
                              width: "30px",
                              height: "30px",
                              backgroundColor: "#FBEBB5",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                            className="rounded fs-3 text-center cursor-pointer"
                            onClick={() => quantityFn("inc", index)}
                          >
                            +
                          </div>
                        </div>
                      </div>
                      <hr />
                    </div>
                  );
                })}

               
              </section>

              <div className="d-flex justify-content-between py-2 px-4">
                <h5>Total</h5>
                <p>{formatNumber(totalPrice ?? 0.0)}</p>
              </div>

              <div
                className="col-lg-4 col-md-6 col-8 mx-auto text-center"
                data-aos="fade-right"
              >
                <Button
                  className="mb-3"
                  variant="contained"
                  onClick={handleOpen}
                  sx={{
                    backgroundColor: "#033F4B",
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#033F4B",
                    },
                  }}
                >
                  Add another item
                </Button>
                <div className="text-center d-none d-md-block">
                  <img
                    src={online_market}
                    alt="online market"
                    className="card-img-top shadow-sm border rounded"
                  />
                <p className="fs-5 my-2">{"Online Market"}</p>
                </div>
              </div>
            </div>
            {allProducts?.products?.length ? (
              <div className="col-lg-4 ">
                <div className="car tw-bg-white tw-shadow h-50 p-2 d-flex flex-column justify-content-between" data-aos="fade-down">
                  <div className="order_summary">
                    <p className="border-0 border-bottom py-2">Order Summary</p>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Subtotal</TableCell>
                          <TableCell>
                            {formatNumber(totalPrice ?? 0.0)}
                          </TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell>
                            Service Charge
                          </TableCell>
                          <TableCell>{formatNumber(serviceCharge)}</TableCell>
                        </TableRow> */}
                        <TableRow>
                          <TableCell className="fw-semibold">
                            Total
                          </TableCell>
                          <TableCell>
                            {formatNumber(totalPrice?? 0.0)}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </div>
                  {/* <div className="payment_option my-4">
              <label htmlFor="payment_option" style={{ fontWeight: 400 }}>
                Select payment option
              </label>
              <div className="row">
                {[
                  { id: 1, value: "Pay Now" },
                  { id: 5, value: "Pay five days later" },
                  { id: 10, value: "Pay ten days later" },
                ].map((item, index) => {
                  return (
                      <div className="col-sm-12" key={index}>
                        <Radio
                          checked={selectedValue === item.id}
                          onChange={() => handleChange(item.id)}
                          value={item.id}
                          sx={radioStyle}
                        />
                        <label htmlFor={item.value}>{item.value}</label>
                      </div>
                  );
                })}
              </div>
            </div> */}
                  <div className="checkout_btn mt-lg-3 pb-lg-3">
                    <CustomButton
                      text={"Check out"}
                      loading={isLoading}
                      functionFn={checkoutFn}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                {/* if the products is empty */}
                <p className={`card-text text-center my-2`}>
                  You have no product in your cart yet.
                </p>
              </>
            )}
          </section>
        </section>
      ) : (
        <div
          className="container mx-auto rounded bg-white border mt-4 d-flex justify-content-center align-items-center shadow-sm"
          style={{ height: "50vh" }}
        >
          <div className="d-flex flex-column align-items-center justify-content-center text-center">
            <div
              className="rounded-circle mb-3 d-flex justify-content-center align-items-center"
              style={{
                height: "15vh",
                width: "15vh",
                backgroundColor: "#F5F5F5",
              }}
            >
              <ShoppingCartIcon sx={{ fontSize: "8vh", color: "#033F4B" }} />
            </div>
            <h4 className="text-sm mb-5">Your cart is empty!</h4>
            <Button
              size="large"
              sx={{
                backgroundColor: "#033F4B",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#045E6E",
                  color: "white",
                },
              }}
              onClick={handleOpen}
            >
              START SHOPPING
            </Button>
          </div>
        </div>
      )}

      <ModalDialogBox open={open} handleClose={handleClose} modalSize={true}>
        {
          <OnlineMarketPlace
            handleClick={handleClick}
            handleClose={handleClose}
          />
        }
      </ModalDialogBox>


      {contextHolder}
    </>
  );
}

export default Cart;
