
import { Input, Typography, Button } from 'antd';
import { useUserLoanApproval } from "../../../redux/hooks/zustands/useUserLoanApproval";


export default function BankAccounts(){

 const { updateCurrentPage, data: { bank_accounts } } = useUserLoanApproval();
    
 console.log(bank_accounts);

 const continueMandate=()=>{
  updateCurrentPage("SET_MANDATE")
 }


      
    return(
        <>
             <div className="col-lg-4 col-md-8 col-12 mx-auto tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white tw-shadow-sm tw-mt-5">
        <h2 className="tw-font-[600] tw-text-[22px] tw-mb-5 tw-text-center">FI Lender</h2>
        <br />
        <h5 className="tw-font-[400] tw-text-[18px] tw-text-center tw-mb-5">Complete Mandate process</h5>

        <div className="tw-border tw-rounded-lg tw-border-[#D9D9D996] tw-px-5 tw-py-3">
        <h4 className="tw-font-[600] tw-text-[18px] tw-mb-2">
        Your Bank Account(s)
              </h4>

              {
                bank_accounts?.map((item, index)=>{
                    return(
                            <div className="tw-flex tw-justify-between tw-my-7 tw-col-span-2 tw-pe- tw-items-center tw-border-b tw-w-full tw-border-b-[#CFCDCC]">
                            <div className="tw-flex tw-gap-x-2 tw-items-center">   
                                <p className="tw-text-lg">{item?.initiate_mandate_data?.bank}</p>
                            </div>
                            <p className="tw-font-[600] tw-text-xl">{item?.initiate_mandate_data?.account_number}</p>{" "}
                            </div>
                    )
                })
            }
            
     

                <div >
                  <p className="tw-text-[#033F4B] tw-text-[400] tw-text-[14px]">By continuing this page you have authorised FI lender to set mandate on all the accounts above</p>
                </div>
                <div className="tw-mt-5 tw-flex tw-justify-center tw-items-center">
                <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF]"
                  onClick={continueMandate}
                 >
                    Continue
                  </Button>
                </div>
                
        </div>

        </div>
        </>
    )
}