import { Box, SwipeableDrawer, IconButton, Toolbar } from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";

import { Drawer } from "antd";





export default function DrawerBox({ open, handleClose, children, anchor, size, title }) {



  return (
    <>
      {/* <SwipeableDrawer
        anchor={anchor ?? "right"}
        open={open}
        onClose={handleClose}
        // hideBackdrop = {true}
      >
        <Box
          sx={{
            // width: { sm: `calc(100%)` },
          }}
        >
          <Toolbar className="d-flex justify-content-end">
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
          <section className="px-3">{children}</section>
        </Box>
      </SwipeableDrawer> */}



      <Drawer open={open} onClose={handleClose} width={size} title={title} className="tw-z-[]">
        <section className="px-lg-3">{children}</section>
      </Drawer>
    </>
  );
}
