import { Radio } from "@mui/material"; 
const durationData = [
    {id: "monthly", value: 'Monthly', month: 1},
    {id: "quarterly", value: 'Quarterly', month: 3},
    {id: "semi-annual", value: 'Semi-annually', month: 6},
    {id: 'annually', value: 'Annually', month: 12}
  ]
export default function PlanDuration({selectedValue, setSelectedValue}){

    
    //radio input style
    const radioStyle = {
        '&.Mui-checked': {
          color: "#033F4B"
        },
      }
  
    return(
        <>
        <div className="d-flex gap-3 flex-wrap justify-content-center">
                      {
                        durationData?.map((item, index)=>{
                          return(
                            <div className="" key={index+`_${item?.id}`}>
                          <Radio
                            checked={selectedValue.name === item?.id}
                            onChange={() => setSelectedValue({name: item?.id, month: item?.month})}
                            sx={radioStyle}
                          />
                          <label htmlFor={item?.value} className="" style={{fontWeight: 600}}>{item?.value}</label>
                        </div>
                          )
                        })
                      }
                    </div>
        </>
    )
}