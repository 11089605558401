import React from "react";
import { formatNumber } from "../../../../utils/formatNumber";
import { Button } from "antd";
import styles from "./productCard.module.css";
import { useDispatch } from "react-redux";
import { superStoreAction } from "../../../../redux/slice/superStoreSlice";

export default function ProductCard({ product, handleClick }) {
  const dispatch = useDispatch({});

  const addToCart = (product) => {
    const nProduct = {...product, quantity: 1}
    dispatch(superStoreAction.addToCart(nProduct));
    handleClick("Item added to cart", "success");
  };
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-12 mt-3">
        <div className="card py-2 px-3">
          <div
            className={styles.productHeader}
            style={{
              backgroundImage: `url(${product?.img})`,
            }}
          ></div>

          <h4
            className="text-black my-3"
            style={{
              fontWeight: 500,
              lineHeight: "12px",
            }}
          >
            {product?.name}
          </h4>
          <div className="d-flex justify-content-between my-2">
            <p>{formatNumber(product?.price)}</p>
            {product?.discount && (
              <p
                style={{
                  color: "#FF3232",
                }}
              >
                <small>-{product?.discount}% off</small>
              </p>
            )}
          </div>
          <Button
            shape="round"
            size="large"
            className="mb-3"
            style={{
              backgroundColor: "#000000",
              color: "white",
            }}
            onClick={()=>addToCart(product)}
          >
            Add to Cart
          </Button>
        </div>
      </div>
    </>
  );
}
