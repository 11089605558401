import React, { useState } from "react";
import { ActiveNotificationIcon, SearchIcon } from "../../../icons/icons";
import MerchantTable from "../../../components/Admin/Merchant/MerchantTable";
import ModalDialogBox from "../../../components/ModalDialogBox/ModalDialogBox";
import OnboardMerchant from "../../../components/Admin/OnboardMerchant";
import Header from "../../../components/Shop/Header/Header";
import {
  useGetAllMerchant,
  useGetAllSettlement,
  useGetUserAccountSummary,
} from "../../../redux/hooks/getHooks";
import {IsLoadingLoader} from "../../../utils/Loader";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import SearchBar from "../../../utils/SearchBar";

export default function AdminMerchantScreen({ handleClick }) {
  const [open, setOpen] = useState(false);
  const { data, isLoading, isError } = useGetAllMerchant();
  const [accountId, setAccountId] = useState("");
  const accountSummary = useGetUserAccountSummary(accountId);
  const getAllSettlement = useGetAllSettlement();
  let allMerchants = data?.data?.data?.merchants
  const allSettlement = getAllSettlement?.data?.data?.data?.settlements;
  // console.log(allSettlement);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleOnClick = (accountId) => {
    setAccountId(accountId);
  };

  const headerData = [
    { value: 0, key: "Transasction Count" },
    { value: <>₦20, 000</>, key: "Total Transaction Amount" },
    { value: <>₦15, 000</>, key: "Total Settlement Received" },
  ];

  //want to make the search function global....
  const searchFn=(searchText)=>{
    let filteredArray = allMerchants.filter((merchant)=>
    merchant.location.toLowerCase().includes(searchText.toLowerCase())
    );
    allMerchants = filteredArray;
    // return filteredArray
  }

  return (
    <React.Fragment>
      <main>
        <p className="my-3 float-end">
          <ActiveNotificationIcon />
        </p>
        <Header data={headerData} />
        <section className="col-lg-10 mx-auto col-md-11 header_content d-flex justify-content-between">
          <p className={`cursor-pointer fw-bold`} onClick={handleOpen}>
            + Onboard new Merchant
          </p>
        </section>
        <SearchBar searchFn={searchFn} dataArray={allMerchants} filterBy="location"/>
        {isLoading ? (
          <IsLoadingLoader />
        ) : isError ? (
          <main
            className="loading_container d-flex align-items-center text-center justify-content-center flex-column gap-3"
            style={{ height: "30vh" }}
          >
            <img
              src="https://assets-v2.lottiefiles.com/a/b5641ed8-1152-11ee-ada0-8f4e8e17569e/RpGVFAqeZF.gif"
              width={80}
              height={80}
              alt="loader"
            />
            <h6>Oops! An error occurred. please check your connection</h6>
          </main>
        ) : allMerchants?.length ? (
          <MerchantTable rows={allMerchants} />
        ) : (
          <main
            className="d-flex align-items-center justify-content-center flex-column"
            style={{ height: "25vh" }}
          >
            <h3>There is no record of merchant yet</h3>
          </main>
        )}
      </main>

      <DrawerBox open={open} handleClose={handleClose}>
        <OnboardMerchant handleClick={handleClick} handleClose={handleClose} />
      </DrawerBox>
    </React.Fragment>
  );
}
