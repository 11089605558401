/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import plan1 from "../assets/regular.png";
import plan2 from "../assets/silver.png";
import plan3 from "../assets/gold.png";
import { Box, Button, CircularProgress } from "@mui/material";
import CustomButton from "../components/CustomButton/CustomButton";
import {
  
  
  useGetUserOrder,
  useGetUserUpperPlans,
  useGetUserUpgradeRequest
} from "../redux/hooks/getHooks";
import {
  useUpgradePlan
} from "../redux/mutation/postMutation";
import ActionButton from "../components/Subscription/ActionButton";
import { IsLoadingLoader, IsErrorLoader } from "../utils/Loader";
import { formNumberOnly } from "../utils/formatNumber";
import { useUserData } from "../components/hooks/useUserData";

import { Modal } from "antd";
import "../components/Subscription/subscribePlan.css";


export default function UpgradeSubscription({
  role,
  eachUser,
  handleClick,
  handleClose,
}) {
  const upgradeMutation = useUpgradePlan({})

  const [selectedPlan, setSelectedPlan] = useState(0);


  const [selectedValue, setSelectedValue] = useState({
    name: "monthly",
    month: 1,
  });

  const [cardHover, setCardHover] = useState({ status: false, index: null });

  const [confirmModal, setConfirmModal] = useState(false);


  const [modal, contextHolder] = Modal.useModal({});



  const [subscribeLoading, setSubscribeLoading] = useState({
    status: false,
    index: null,
  });






  const getUpperPlans = useGetUserUpperPlans();

  const getUserUpgradeRequest = useGetUserUpgradeRequest();

  const upgradeRequest = getUserUpgradeRequest?.data?.data?.data;




  
  let plansData =
  getUpperPlans?.data?.data?.data;

  const planDataIsLoading =
  getUpperPlans?.isLoading;

  const planDataIsError = getUpperPlans?.isError;


  const changePlan = (index, item) => {
    // console.log(item)
    setSelectedPlan(item.id);
  };
  // console.log(user)


  //subcription button function
  const upgradeFn = (plan, index) => {
    
        setSubscribeLoading({ ...subscribeLoading, status: true, index });
        executeSubscription(plan)
  };


  const executeSubscription=(plan)=>{
  
    upgradeMutation.mutate({
      planId: plan?.id
    });
  }




  useEffect(() => {
    if (upgradeMutation.isError) {
      setSubscribeLoading({ ...subscribeLoading, status: false, index: null });
      const resError = upgradeMutation?.error?.response;
      let msg = upgradeMutation?.error?.response?.data?.message;
      let msg2 = upgradeMutation?.error?.response?.statusText;


      if (resError?.status !== 403) {
        handleClick(msg ?? msg2, "error", {
          vertical: "top",
          horizontal: "right",
        });
      }

      // console.log(msg ? msg : msg2, "error");
    } else if (upgradeMutation.isSuccess) {
      setSubscribeLoading({ ...subscribeLoading, status: false, index: null });
      const res = upgradeMutation?.data?.data;

      setConfirmModal(true);

      // handleClick(res?.message, "success")
    }
  }, [upgradeMutation.status]);

  useEffect(() => {
    if (eachUser) {
      setSelectedPlan(eachUser?.allowed_plan);
    }
  }, []);


  return (
    <>
      <main className="col-lg-11 mx-auto">
        <section className="mx-2 text-center">
          <div className="d-flex flex-column mb-2">
            <h4 className="header fw-bold fs-5">
            Subscription Plans
            </h4>






         

            <div>
            
                <div>
                  Plans you can upgrade to
                </div>
              
            </div>
          </div>
          {/* <br /> */}
        




        
          <section className="row mx-lg-auto mx-2">
            {planDataIsLoading ? (
              <IsLoadingLoader />
            ) : planDataIsError ? (
              <IsErrorLoader />
            ) : plansData?.length ? (
              plansData
                ?.sort((a, b) => a.amount - b.amount)
                ?.map((item, index) => {
                  return (
                    <div
                      className="col-lg-4 col-md-6 col-sm-12 cursor-pointer position-relative my-3 mx-aut"
                      key={index}
                      onClick={() => changePlan(index, item)}
                      onMouseEnter={() => {
                        setCardHover({ status: true, index });
                      }}
                      onMouseLeave={() => {
                        setCardHover({ status: false, index });
                      }}
                    >
                      <div
                        className="card h-100 d-flex flex-column justify-content-between pb-2"
                        style={
                          selectedPlan === item.id
                            ? {
                                border: "1px solid #033F4B",
                                background: "#F5F5F5",
                                transition: "all 0.5s",
                              }
                            : {
                                background: "#F5F5F5",
                                border: "none",
                                transition: "all 0.5s",
                              }
                        }
                      >
                        <div>
                          {role === "admin" && (
                            <ActionButton
                              plan={item}
                              handleClick={handleClick}
                            />
                          )}
                          <div className="d-flex flex-column justify-content-between">
                            <div
                              className="plan_header"
                              style={{
                                backgroundImage: `url(${
                                  item?.name === "Regular"
                                    ? plan1
                                    : item?.name === "Silver"
                                    ? plan2
                                    : plan3
                                })`,
                              }}
                            >
                              <div className="plan_name d-flex align-items-center justify-content-center fw-bold rounded-circle bg-white text-dark">
                                {item?.name}
                              </div>
                            </div>

                            <div className="d-flex flex-column">
                              <h5 className="fs-5 fw-bold my-3">
                              ₦{formNumberOnly(
                                  parseFloat(item.amount * selectedValue?.month)
                                )} <small className="text-capitalize" style={{fontSize: "13px"}}>/ {
                                  (selectedValue?.name === "monthly" ? "month": selectedValue?.name === "quarterly"? "quarter": selectedValue?.name === "annually"? "annual" : selectedValue) 
                                }</small>
                              </h5>
                            </div>
                            <div className="text-start px-4">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item?.description,
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        
                          <div style={{ transition: "all 0.5s" }}>
                            <CustomButton
                              text={
                                // subscription?.planId === item.id &&
                                // subscription?.status
                                //   ? upgradeRequest?.find((req)=>req?.planId===item?.id)?.status
                                //   : "Upgrade"
                                upgradeRequest?.find((req)=>req?.planId===item?.id)?.status === 'pending'? "Pending": "Upgrade"
                              }
                              loading={
                                subscribeLoading.index === index &&
                                subscribeLoading.status
                              }
                              disabled={
                                upgradeRequest?.find((req)=>req?.planId===item?.id)?.status === 'pending'
                              }
                              functionFn={() => upgradeFn(item, index)}
                            />
                          </div>
                        
                      </div>
                    </div>
                  );
                })
            ) : (
              <p className={`card-text text-center my-2`}>
                No Upper plans available!!
              </p>
            )}
          </section>
        
        
        </section>
      </main>

      <Modal
        title="Important Notice!!!"
        centered
        open={confirmModal}
        
        footer={[
          <Button
            key="back"
           
            sx={{
              border: "1px solid #033F4B",
              color: "#033F4B",
              marginRight: "1vh",
              marginTop: "1vh",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "#fff",
              },
            }}
            onClick={() => setConfirmModal(false)}
          >
           Close
          </Button>
        
        ]}
      >
        <p className="my-3">
         Your request for a plan upgrade has been sent and will be reviewed
        </p>
      </Modal>

      {contextHolder}
    </>
  );
}

