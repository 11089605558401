import { Button } from "@mui/material";
import moment from "moment";
import React, { useCallback } from "react";
import { formatNumber } from "../../utils/formatNumber";
import { useLocation } from "react-router-dom";
import { useUserData } from "../hooks/useUserData";

function TransactionHistoryCard({
  rows,
  handleOpenDialog,
  handleOpenDetailModal,
}) {
  const location = useLocation({}).pathname;

  const { data } = useUserData();

  const statusColor = {
    pending: "alert-warning",
    "order in progress": "alert-warning",
    repaid: "alert-success",
    "order delivered": "alert-success",
    rejected: "alert-danger",
    declined: "alert-danger",
    "instant payment": "alert-success",
  };

  const formatElectricName = useCallback((val) => {
    let splittedVal = val?.split("_");
    return splittedVal?.[0] + " " + splittedVal?.[1];
  });

  return (
    <>
      <section className="col-lg-11 mx-auto">
        {rows?.length ? (
          rows?.map((row, index) => {
            return (
              <div
                key={row?.id + "____order"}
                className="row mx-3 px-2 pb-3 pt-3 my-3 bg-white"
                style={{
                  boxShadow: "0px 4px 4px 0px #00000040",
                  borderRadius: "10px",
                }}
              >
                <div className="col-lg-3 col-md-5 d-flex flex-column gap-2">
                  <p>
                    Ref. No.:{" "}
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {row?.reference}
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {row?.subscription
                        ? "Subscription"
                        : !row?.order?.is_bill
                        ? "Online Marketplace"
                        : row?.order?.is_bill
                        ? "Bills payment"
                        : ""}
                    </span>
                  </p>
                  <p>
                    <span style={{ fontWeight: 600, fontSize: "16px" }}>
                      {row?.channel ?? "No content"}
                    </span>
                  </p>
                </div>
                <div className="col-lg-9 col-md-7 d-flex flex-column gap-2">
                  <div className="d-flex  justify-content-between flex-wrap">
                    <p>
                      Date :{" "}
                      <span>
                        {moment(row?.createdAt).format("DD MMM, YYYY")}
                      </span>
                    </p>
                    <p
                      className={`cursor-pointer mx-3 border-0 alert ${
                        statusColor[row?.order?.status]
                      } rounded p-1 px-3`}
                      style={{
                        fontSize: "12px",
                      }}
                    >
                      {/* <i>{row?.status}</i> */}
                      {row?.subscription
                        ? "Renewal"
                        : row?.order?.is_credit
                        ? "Repayment"
                        : row?.order?.is_bill
                        ? row?.order?.status
                        : ""}
                    </p>
                  </div>

                  <div className="row">
                    <div className="col-lg-7 d-flex flex-column gap-2">
                      <div className="d-flex flex-column gap-2">
                        {row?.order?.products?.map((item, index) => (
                          <div key={index + "_____product"}>
                            <div
                              className="d-flex justify-content-between gap-2 border p-3"
                              style={{
                                borderRadius: "6px",
                                borderColor: "#CFCDCC4D",
                              }}
                            >
                              <div>
                                <p>
                                  {row?.order?.is_bill ? (
                                    <span className="d-flex flex-column">
                                      <small
                                        style={{
                                          fontWeight: 300,
                                          textTransform: "capitalize",
                                        }}
                                      >
                                        {item?.order_name}
                                      </small>
                                      <span
                                        style={{
                                          fontWeight: 600,
                                          fontSize: "16px",
                                        }}
                                      >
                                          {item?.disco?.toUpperCase()}
                                      </span>
                                    </span>
                                  ) : (
                                    <span
                                      style={{
                                        fontWeight: 600,
                                        fontSize: "16px",
                                      }}
                                    >
                                      {item?.name}
                                    </span>
                                  )}
                                </p>
                                {!row?.order?.is_bill ? (
                                  <div>
                                    <a
                                      href={item?.url}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="nowrap cursor-pointer rounded p-1 text-decoration-none"
                                      style={{
                                        backgroundColor: "#033F4B",
                                        color: "#fff",
                                        cursor: "pointer",
                                        fontSize: "12px",
                                      }}
                                    >
                                      View Item link
                                    </a>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div>
                                <p>
                                  Subtotal :{" "}
                                  <span>
                                    {formatNumber(
                                      (row?.order?.is_bill
                                        ? item?.amount ?? item?.total_amount
                                        : item?.price) ?? 0
                                    )}
                                  </span>
                                </p>
                                {row?.order?.is_bill ? (
                                  ""
                                ) : (
                                  <div
                                    className="py-2 px-3 rounded-3 border"
                                    style={{ backgroundColor: "#fff" }}
                                  >
                                    QTY: {item?.quantity}
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        ))}
                        <div className="d-flex align-items-center justify-content-center mt2-3">
                          <p>
                            Total Amount :{" "}
                            <span style={{ fontWeight: 600, fontSize: "16px" }}>
                              {formatNumber(row?.amount)}
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>

                    {/* <div className="col-lg-5 d-flex flex-column align-items-end gap-2 justify-content-betwee "> */}
                    <div className="col-lg-5 d-flex my-3 justify-content-between align-items-en">
                      <div>
                        {/* <span
                            className={`cursor-pointer border-0 alert ${statusColor[row?.status]} rounded p-1 px-3`}
                            style={{
                              fontSize: "12px",
                            }}
                          >
                            <i>{row?.status}</i>
                          </span> */}
                      </div>
                      <div>
                        {location?.includes("credit") &&
                        row?.status !== "repaid" ? (
                          <Button
                            sx={{
                              backgroundColor: "#033F4B",
                              color: "white",
                              "&:hover": {
                                backgroundColor: "#033F4B",
                              },
                            }}
                            onClick={() => handleOpenDialog("pay", row)}
                          >
                            Pay
                          </Button>
                        ) : (
                          <span
                            className="nowrap py-1 px-3"
                            style={{
                              backgroundColor: "#03010033",
                              color: "#000",
                              cursor: "pointer",
                              borderRadius: "6px",
                              fontWeight: 500,
                            }}
                            onClick={() => handleOpenDialog("pay", row)}
                          >
                            View Detail
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <p className={`card-tex text-center my-2 text-muted fs-5`}>
            <i>No data recorded here!!</i>
          </p>
        )}
      </section>
    </>
  );
}

export default TransactionHistoryCard;
