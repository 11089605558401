import React from 'react'
import SignupScreen from '../screens/AuthScreen/SignupScreen/SignupScreen'

function Signup({handleClick}) {
  return (
    <>
        <SignupScreen handleClick={handleClick}/>
    </>
  )
}

export default Signup