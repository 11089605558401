
import { Paper, Box, CircularProgress, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { formatNumber } from "../../../utils/formatNumber";

export default function OrderSumary({ order, estimatedCredit, handleClick, approveOrder, approveLoading, marketPlaces }) {
  



  const paymentDetails = [
    { id: 1, label: "Delivery Fee", price: order?.delivery_amount ?? 0 },
    { id: 2, label: "Sub total", price: order?.sub_total ?? 0 },
    { id: 3, label: `Service charge (${order?.current_service_charge}% of the subtotal)`, price: parseFloat(order?.service_charge) ?? 0 },
    { id: 4, label: `VAT (${order?.current_vat}% of the subtotal)`, price: order?.vat ?? 0 },
    { id: 5, label: "Total Amount", price: order?.total_amount ?? 0 },
    // { id: 4, label: "Discount", price: 0.0 },
  ];



  return (
    <>
      <div>
        <h5 className="fw-semibold">
          {order?.user?.first_name} {order?.user?.last_name} Order
        </h5>
        <p className="text-center fw-normal">Order Summary</p>
        <TableContainer component={Paper}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                <TableCell align="center" colSpan={2}>
                  <h6 className="fw-medium">Avail. Credit: {formatNumber(estimatedCredit?.remainingCredit ?? 0)}</h6>
                </TableCell>
                <TableCell align="center" colSpan={3}>
                  <h6 className="fw-medium">Max. Credit: {formatNumber(parseFloat(estimatedCredit?.max_amount) ?? 0)}</h6>
                </TableCell>
              </TableRow>
              <TableRow>
            <TableCell colSpan={3}>
              <p className="fw-medium">Order No.: {order?.order_number}</p>
            </TableCell>
            <TableCell align={'center'}>
            <img
                  src={order?.market_place?.logo}
                  alt={order?.market_place?.name+"_logo"} className="card-img-top" style={{width: "100px", height: "100%", maxWidth: "100px"}}
                />
            </TableCell>
          </TableRow>
              {/* <TableRow>
                <TableCell align="right" colSpan={4}>
                  <p className="fw-medium">Order No. SMLS650050</p>
                </TableCell>
              </TableRow> */}
            {
            order?.products?.map((item, index)=>(
                <TableRow key={index+"___product"}>
               <TableCell align="center" colSpan={3}>
                    <div className="d-flex">
                        {/* <img
                        src={order?.market_place?.logo}
                        alt={order?.market_place?.name+"_logo"} className="card-img-top" style={{width: "100px", height: "100%", maxWidth: "100px"}}
                        /> */}
                      <div className="text-start">
                          <h6 className="fw-semibold">
                          {
                                        order?.is_bill? (
                                          <>
                                          <span className="" style={{textTransform: "capitalize"}}>{item?.order_name}</span>({item?.service_type})
                                          </>
                                        ): (
                                          item?.name
                                        )
                                      }
                          </h6>
                          <p
                              style={{ fontSize: "2.5vh", }}
                              className="text-wrap"
                          >
                            {
                              order?.is_bill? (
                                ""
                              ): (

                              <>
                                {item?.quantity} x{" "}
                              <Tooltip
                                      title={item?.url ?? "xxxxx"}
                                      arrow
                                  >
                              <a href={item?.url} target="_blank" rel="noreferrer" 
                              style={{

                              }}
                              className="text-black">{(item?.url)?.substring(0, 40)+"..."}</a>
                              </Tooltip>

                              </>
                              )
                            }
                          </p>
                          <p className="text-muted">{order?.order?.market_place?.name}</p>
                          </div>
                    </div>
                    </TableCell>
                    <TableCell align="right">
                    <h5 className="fw-medium">
                       {formatNumber((order?.order_type==='bills'? (item?.amount ?? item?.total_amount) : item?.price) ?? 0)}
                    </h5>
                    </TableCell>
              </TableRow>
            ))
}

              <TableRow></TableRow>
            </TableHead>
            <TableBody>
              {paymentDetails?.map((item, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + "__" + item?.id}
                  >
                    <TableCell colSpan={3}>{item?.label}</TableCell>
                    <TableCell className="nowrap" align="right">
                      {formatNumber(item?.price)}
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow>
                <TableCell colSpan ={4}>
                    <h5 className="fw-semibold">Payment is due on {moment(order?.repaymentDuration).format("DD MMMM, YYYY")}</h5>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
        {
          order?.status === "pending"&&(
        <div className="d-flex justify-content-between">
          <Box sx={{ m: 1, position: "relative" }}>
            {
              order?.status!=='declined'?(
            <button
              className="btn my-2 rounded-pill mx-2 px-4"
              style={{ backgroundColor: "#BD0C0C", color: "white" }}
              disabled={ order?.status !== "pending" || approveLoading?.status}
              onClick={()=>approveOrder("decline_order")}
            >
              {
                order?.status==='declined'? "Order Declined": "Decline"
              }
              
            </button>

              ): ""
            }
            {approveLoading?.status && approveLoading?.action === 'decline_order' && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "30%",
                  left: "45%",
                  color: "#fff",
                }}
              />
            )}
          </Box>
          <Box sx={{ m: 1, position: "relative" }}>
            <button
              className="btn my-2 rounded-pill mx-4 px-4"
              style={{ backgroundColor: "#124B0D", color: "white" }}
              disabled={order?.status!=="pending" || approveLoading?.status}
              onClick={()=>approveOrder("order_in_progress")}
            >
              Process order
            </button>
            {approveLoading?.status && approveLoading?.action === 'order_in_progress' && (
              <CircularProgress
                size={24}
                sx={{
                  position: "absolute",
                  top: "30%",
                  left: "45%",
                  color: "#fff",
                }}
              />
            )}
          </Box>
        </div>
          )
        }
      </div>
    </>
  );
}
