import { create } from "zustand"
export const useInitiateMandate = create((set)=>{
    return{
        isOpen: false,
        pageTitle: "",
        data: {},

        openDrawer: (title, data)=>set((state)=>({isOpen: true, pageTitle: title, data: {...data}})),
        updateData: (newData)=>set((state) => ({
            data: { ...state.data, ...newData }
        })),
        updateTitle: (title)=>set((state)=>({pageTitle: title}) ),

        closeDrawer:()=>set({isOpen: false})
    }
})