import { DialogTitle } from '@mui/material'
import React, { useEffect, useState } from 'react'
import ProductToBuy from './ProductToBuy/ProductToBuy'
import PharmacyList from './PharmacyList/PharmacyList'
import PhysicalStore from './PhysicalStore/PhysicalStore'
import StoreDetail from './StoreDetail/StoreDetail'
import CustomButton from '../CustomButton/CustomButton'
import { useUserData } from '../hooks/useUserData'

function Pharmacy() {
  const {data } = useUserData({});
  const [activeStep, setActiveStep] = useState(1);
  const [itemName, setItemName] = useState('');
  const [pharmacy, setPharmacy] = useState('');
  const [address, setAddress] = useState(()=>{
    return data?.contact?.address ?? data?.contact?.street
  });
  const nextFn=(val)=>{
    if(activeStep===1){
      setActiveStep(2);
    }
    else if(activeStep===2){
      setActiveStep(3);
    }
    else if(activeStep===3){
      setActiveStep(4);
    }
  }
  return (
    <>
        <main>
            <div className='text-center'>
                <DialogTitle>Pharmacy</DialogTitle>
            </div>
                <section className=''>
                    {
                      activeStep===1?
                      <ProductToBuy setItemName={setItemName}/>:
                      activeStep===2?
                      <PharmacyList setAddress={setAddress} address={address} setPharmacy={setPharmacy} pharmacy={pharmacy}/>:
                      activeStep===3?
                      <PhysicalStore pharmacy={pharmacy} address={address}/>:
                      activeStep===4?
                      <StoreDetail address={address} pharmacy={pharmacy} phoneNumber={data?.phone}/>:null
                    }

{
  activeStep!==4? (<div className="next_btn mx-auto col-lg-3 col-md-5 col-12">
                      <CustomButton text={activeStep===3?'Recieve Token': "Next"} disabled={
                        activeStep===1 && !itemName? true : activeStep===2 && !pharmacy? true: false
                      }functionFn={nextFn}/>
                  </div>):null

}
                </section>
        </main>
    </>
  )
}

export default Pharmacy