import React from 'react'
import logo from "../../assets/new_vouch.png"
function Logo() {
  return (
    <>
            <img
              src={logo}
              width="130"
              alt="Vouch Logo"
              className="cursor-pointer"
            />
    </>
  )
}

export default Logo