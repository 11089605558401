import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button } from "antd";
import { useLoanDrawer } from "../../../redux/hooks/zustands/useLoanDrawer";
import { formatNumber } from "../../../utils/formatNumber";
import { useGetMandateSchedule } from "../../../redux/hooks/getHooks";

const btnColor = {
  "fully paid": "#000000",
  "partially paid": "#99940A",
  "not paid": "#830606",
}

const response = {
  "status": true,
  "message": "Mandate fetched",
  "data": {
      "accounts": [
          {
              "mandate_data": {},
              "initiate_mandate_data": {
                  "id": "mmc_6686e75e639212b9537833d4",
                  "status": "active",
                  "mandate_type": "emandate",
                  "debit_type": "variable",
                  "ready_to_debit": false,
                  "nibss_code": "RC227914/1580/0003111074",
                  "approved": false,
                  "reference": "MONO5d1d694db6e85d85",
                  "account_name": "ADENIRAN AKINBOYEWA",
                  "account_number": "0253019123",
                  "bank": "WEMA BANK PLC",
                  "bank_code": "035",
                  "customer": "66817e6e9eba938ac8b7e1ff",
                  "description": "Credit repayment",
                  "live_mode": true,
                  "start_date": "2024-08-02T00:00:00.000Z",
                  "end_date": "2025-06-02T00:00:00.000Z",
                  "date": "2024-07-04T18:18:06.400Z",
                  "transfer_destinations": [
                      {
                          "bank_name": "Fidelity",
                          "account_number": "9020025928",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png"
                      },
                      {
                          "bank_name": "Paystack Titan",
                          "account_number": "9880218357",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png"
                      }
                  ],
                  "amount": 50000000
              },
              "id": "0126b1af-6385-48e6-bb54-0d98056e9d76",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "reference": "mmc_6686e75e639212b9537833d4",
              "status": "inactive",
              "createdAt": "2024-07-04T18:18:06.000Z",
              "updatedAt": "2024-07-04T18:18:06.000Z"
          },
          {
              "mandate_data": {},
              "initiate_mandate_data": {
                  "id": "mmc_6686e72f639212b95378330c",
                  "status": "active",
                  "mandate_type": "emandate",
                  "debit_type": "variable",
                  "ready_to_debit": false,
                  "nibss_code": "RC227914/1580/0003111067",
                  "approved": false,
                  "reference": "MONOafd743bc0841a8bd",
                  "account_name": "AKINBOYEWA ADENIRAN ADEOYE",
                  "account_number": "0225660192",
                  "bank": "GUARANTY TRUST BANK PLC",
                  "bank_code": "058",
                  "customer": "66817e6e9eba938ac8b7e1ff",
                  "description": "Credit repayment",
                  "live_mode": true,
                  "start_date": "2024-08-02T00:00:00.000Z",
                  "end_date": "2025-06-02T00:00:00.000Z",
                  "date": "2024-07-04T18:17:19.277Z",
                  "transfer_destinations": [
                      {
                          "bank_name": "Fidelity",
                          "account_number": "9020025928",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/fidelity-bank-icon.png"
                      },
                      {
                          "bank_name": "Paystack Titan",
                          "account_number": "9880218357",
                          "icon": "https://mono-public-bucket.s3.eu-west-2.amazonaws.com/images/paystack-icon.png"
                      }
                  ],
                  "amount": 50000000
              },
              "id": "eb38a2ad-cfc7-40b8-8ab7-523bcc048895",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "reference": "mmc_6686e72f639212b95378330c",
              "status": "inactive",
              "createdAt": "2024-07-04T18:17:19.000Z",
              "updatedAt": "2024-07-04T18:17:19.000Z"
          }
      ],
      "schedules": [
          {
              "id": "08b542b6-06f3-4a74-954b-c87aee21f1d9",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "09/07/2024",
              "end_date": "09/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "1cb2edf5-8879-4291-b939-fed7b7177661",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "13/07/2024",
              "end_date": "13/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "38420aab-f21f-40e1-b402-b3f7709ec5df",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "02/07/2024",
              "end_date": "02/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "3d6fc86f-a0e6-4742-8219-17c12990c1fd",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "10/07/2024",
              "end_date": "10/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "5778241c-0cbb-4bf7-9bac-6bb5e57717e9",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "08/07/2024",
              "end_date": "08/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "89e771a2-447b-4362-b367-b3253fcce79c",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "04/07/2024",
              "end_date": "04/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "ace5c894-f476-4f23-9747-f37a5be0cd69",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "12/07/2024",
              "end_date": "12/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "b56195f5-10de-4218-b051-98610cbeaa69",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "03/07/2024",
              "end_date": "03/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "c6139d1d-42ff-415c-b1c8-1f97b558cebc",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "07/07/2024",
              "end_date": "07/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "eca873fa-b4c0-4d08-a395-704e5d382187",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "06/07/2024",
              "end_date": "06/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "ee1db405-789e-4ab3-90fb-fec7a5b8eb8f",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "05/07/2024",
              "end_date": "05/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          },
          {
              "id": "fff3b6f2-2dbb-47f0-a9cf-ba939b66f61e",
              "mandateId": "91170ff6-e29c-4677-9a56-2d639731fcb0",
              "amount": "6000",
              "amount_paid": "0",
              "start_date": "11/07/2024",
              "end_date": "11/07/2024",
              "status": "inactive",
              "createdAt": "2024-07-04T16:56:06.000Z",
              "updatedAt": "2024-07-04T16:56:06.000Z"
          }
      ]
  }
}

export default function LoanDetail() {

  const {openDrawer, data: {mandate}} = useLoanDrawer();

  const {data: data} = useGetMandateSchedule(mandate?.id);
  const response = data?.data
  console.log(response);



  const scheduleData = response?.data





 const handleOpenDrawer=(detail)=>{
    openDrawer('SINGLE_LOAN_DETAIL', {single_schedule: detail, accounts: scheduleData?.accounts})
  }



  return (
    <>
      <div className="tw-w-full tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white">
        <div className="tw-flex tw-flex-col tw-gap-y-1">
          <div className="tw-flex tw-gap-5 tw-flex-wrap">
            <p className="tw-font-[500] tw-text-[14px]">
              Loan Amount: <span className="tw-font-[300]">{mandate?.total_amount}</span>
            </p>
            <p className="tw-font-[500] tw-text-[14px]">
              Tenor: <span className="tw-font-[300]">{mandate?.tenor} {mandate?.payment_type}</span>
            </p>
            <p className="tw-font-[500] tw-text-[14px]">
              Total Interest: <span className="tw-font-[300]">{mandate?.total_interest}</span>
            </p>
          </div>
          <div className="mt-3">
            <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", borderRadius: 2 }}
            >
              <Table
                aria-label="simple table"
                sx={{ borderCollapse: "collapse" }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none" }}>
                      Due Date
                    </TableCell>

                    <TableCell
                      align="center"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Total Amount Due
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {scheduleData?.schedules.map((row, index) => (
                    <TableRow
                      key={row?.start_date + "___" + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td, & th": { borderBottom: "none" },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.start_date}
                      </TableCell>
                      <TableCell align="center">{formatNumber(row?.amount - row?.amount_paid)}</TableCell>
                      <TableCell align="center">
                        <div className="tw-flex tw-gap-x-3">
                          <Button
                            loading={false}
                            size="small"
                            className={`tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF] tw-capitalize
                            ${Number(row?.amount) === Number(row?.amount_paid) ? "tw-bg-[#2A2A29] tw-text-[#FFF] hover:!tw-bg-[#2A2A29]": ((Number(row?.amount) > Number(row?.amount_paid)) && (Number(row?.amount_paid)  !== 0))? "tw-bg-[#99940A] tw-text-[#FFF] hover:!tw-bg-[#99940A]": "tw-bg-[#830606] tw-text-[#FFF] hover:!tw-bg-[#830606]"}
                              `}
                          >
                            {
                              Number(row?.amount) === Number(row?.amount_paid) ? "Fully Paid": ((Number(row?.amount) > Number(row?.amount_paid)) && (Number(row?.amount_paid)  !== 0))? "Partially paid": "Not Paid"
                            }
                          </Button>
                          <span className="tw-text-[#830606] tw-font-[300] tw-cursor-pointer" onClick={()=>handleOpenDrawer(row)}>View</span>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
}
