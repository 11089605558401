import React from "react";
import profile from "../../assets/avatar.png";
import { useFormik } from "formik";
import { TextField } from "@mui/material";
import * as yup from "yup";
import CustomButton from "../CustomButton/CustomButton";
import DrawerBox from "../../utils/Drawer/DrawerBox";
import UpdateAddress from "./UpdateAddress";
import UpdateEmployment from "./UpdateEmployment";
import { formNumberOnly } from "../../utils/formatNumber";
export default function ProfileContent({ detail, handleClick }) {
  const [open, setOpen] = React.useState({ status: false, id: null });
  const handleOpen = (id) => {
    setOpen({ ...open, status: true, id });
  };
  const handleClose = () => {
    setOpen({ ...open, status: false, id: null });
  };
  const profileStyle = {
    backgroundImage: `url(${detail?.profile_picture ?? profile})`,
    marginTop: "3vh",
    borderRadius: "50%",
    width: "30vh",
    height: "30vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const formik = useFormik({
    initialValues: {
      firstName: detail?.first_name ?? "",
      lastName: detail?.last_name ?? "",
      email: detail?.email ?? "",
      phone: detail?.phone ?? "",
      address: detail?.contact?.address ?? "",
      employer: detail?.employmentDetails?.employer ?? "",
      salary: detail?.employmentDetails?.salary ?? 0,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      // console.log(values);
    },
    validationSchema: yup.object().shape({
      firstName: yup.string().required("Your First name can not empty!"),
      lastName: yup.string().required("Your Last name can not empty!"),
      email: yup
        .string()
        .email("Invalid email")
        .required("Your email address is required!"),
      phone: yup.string().required("Your Phone Number can not empty!"),
    }),
  });
  return (
    <>
      <main className="col-lg-6 mx-auto shadow-sm">
        <h3 className="text-center">Profile Page</h3>
        <div className="d-flex justify-content-center align-items-center">
          <div style={profileStyle}></div>
        </div>
        <section>
          <form
            className="d-flex flex-column gap-3 my-4"
            onSubmit={formik.handleSubmit}
          >
            <div className="">
              <label htmlFor="">First Name*</label>
              <TextField
                type="text"
                name="firstName"
                required
                error={formik.touched.firstName && formik.errors.firstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your first name"
                value={formik.values.firstName}
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.firstName && formik.errors.firstName}
              </small>
            </div>
            <div className="lastName ">
              <label htmlFor="lastName">Last Name*</label>
              <TextField
                type="text"
                name="lastName"
                required
                error={formik.touched.lastName && formik.errors.lastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter your last name"
                value={formik.values.lastName}
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.lastName && formik.errors.lastName}
              </small>
            </div>
            <div className="Email ">
              <label htmlFor="Email">Email*</label>
              <TextField
                type="email"
                name="email"
                required
                error={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Your Email Address"
                fullWidth
                size="small"
                value={formik.values.email}
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.email && formik.errors.email}
              </small>
            </div>
            <div className="phoneNumber ">
              <label htmlFor="phoneNumber">Phone Number*</label>
              <TextField
                type="text"
                name="phone"
                error={formik.touched.phone && formik.errors.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="Enter Your Phone Number"
                fullWidth
                size="small"
                variant="outlined"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.phone && formik.errors.phone}
              </small>
            </div>
            <div className="home_address my-2">
              <label htmlFor="home_address">Personal Address</label>
              <div className="d-flex gap-3">
                <TextField
                  type="text"
                  id="standard-basic"
                  name="address"
                  onChange={formik?.handleChange}
                  value={formik?.values.address}
                  onBlur={formik?.handleBlur}
                  placeholder="e.g. 37, lemon grass street"
                  fullWidth
                  className="rounded-pill"
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "20px",
                    },
                  }}
                />
                <p
                  className="my-auto cursor-pointer"
                  style={{ color: "#A50505" }}
                  onClick={() => handleOpen(1)}
                >
                  Update
                </p>
              </div>
            </div>
            <div className="employment my-2">
              <label htmlFor="home_address">Employment</label>
              <div className="d-flex gap-3">
                <TextField
                  type="text"
                  id="standard-basic"
                  name="employer"
                  onChange={formik?.handleChange}
                  value={formik?.values.employer}
                  onBlur={formik?.handleBlur}
                  placeholder="Enter place of work"
                  fullWidth
                  className="rounded-pill"
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "20px",
                    },
                  }}
                />
                <p
                  className="my-auto cursor-pointer"
                  style={{ color: "#A50505" }}
                  onClick={() => handleOpen(2)}
                >
                  Update
                </p>
              </div>
            </div>
            <div className="employment my-2">
              <label htmlFor="home_address">Salary</label>
              <div className="d-flex gap-3">
                <TextField
                  type="text"
                  id="standard-basic"
                  name="salary"
                  onChange={formik?.handleChange}
                  value={formNumberOnly(formik?.values?.salary)}
                  onBlur={formik?.handleBlur}
                  placeholder="E.g 300,000"
                  fullWidth
                  className="rounded-pill"
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "20px",
                    },
                  }}
                />
                {/* <p className='my-auto cursor-pointer' style={{color: '#A50505'}}>Update</p> */}
              </div>
            </div>
            <CustomButton text={"Save"} />
          </form>
        </section>
        <DrawerBox open={open.status} handleClose={handleClose}>
          {open.id === 1 ? (
            <UpdateAddress
              handleClick={handleClick}
              handleClose={handleClose}
              data={detail?.contact}
            />
          ) : open.id === 2 ? (
            <UpdateEmployment
              handleClick={handleClick}
              handleClose={handleClose}
              data={detail?.employmentDetails}
            />
          ) : null}
        </DrawerBox>
      </main>
    </>
  );
}
