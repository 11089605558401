import React from 'react'
import { Navigate, Outlet } from 'react-router-dom';
import { ROUTES } from '../../routes/route';
import userAuth from './userAuth';

function PublicRoute() {
    const auth = userAuth();


    return auth.status ? <Navigate to={ROUTES.shop} /> : <Outlet />
}

export default PublicRoute;