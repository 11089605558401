import { Link, useLocation } from "react-router-dom";
import "./navbar.css";
import Logo from "../../logo/logo";
import useScroll from "../../hooks/useScroll";
import { ROUTES } from "../../../routes/route";
import {
  MenuIcon,
} from "../../../icons/icons";
import { useState } from "react";

function Navbar({ scrollToWork, scrollToAbount }) {
  const [clicked, setclicked] = useState(false);
  const scroll = useScroll();
  const route = useLocation().pathname;

  const landingPageData = [
    // { path: ROUTES.shop_your_needs, name: "Shop Your Needs" },
    { path: ROUTES.bill, name: "Bill Payment" },
    { path: ROUTES.landing_page, name: "How it works", fn: scrollToWork },
    { path: ROUTES.landing_page, name: "About Vouch", fn: scrollToAbount },
    { path: ROUTES.contact, name: "Contact", },
  ];

  const handleClick = () => {
    setclicked(!clicked);
  };
  return (
    <>
      <nav
        className={`nav_container py- ${
          scroll.scrollDirection === "up" && scroll.scrollY > !0
            ? "scrolled"
            : scroll.scrollDirection === "down" &&
              scroll.scrollY > !0 &&
              "hide_nav"
        }`}
      >
        <Link to={ROUTES.landing_page} className="logo py-0">
          <Logo className="logotwo" />
        </Link>
        <ul className={`navbar_menu_list ${clicked ? "active" : ""}`}>
          {landingPageData?.map((item, index) => {
            return (
              <li className={`nav_li `} onClick={item.fn} key={index}>
                <Link to={item.path} className="nav_link">
                  {item.name}
                </Link>
              </li>
            );
          })}
        </ul>
        <div>
        <div className="d-flex gap-4 my-auto">
          <Link
            to={ROUTES.login}
            className="nav_link border-bottom border-dark border-0 pb-1 border-2"
          >
            Sign In
          </Link>
          <Link
            to={ROUTES.signup}
            className="nav_link border-bottom border-dark pb-1 border-2 border-0 d-none d-md-block"
          >
            Get Started
          </Link>
        </div>
        </div>
        <div id="mobile" className="my-auto" onClick={handleClick}>
          <MenuIcon clicked={clicked} />
        </div>
      </nav>
    </>
  );
}

export default Navbar;
