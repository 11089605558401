import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Button } from "antd";
import { useUserLoanApproval } from "../../../redux/hooks/zustands/useUserLoanApproval";
import { useGetUserMandate } from "../../../redux/hooks/getHooks";


const response = {
  "status": true,
  "message": "Preview",
  "data": {
      "first_name": "Taiwo",
      "last_name": "Enoch",
      "bvn": "2233485766",
      "phone": "08102637956",
      "email": "enochtaiwotimothy@gmail.com",
      "total_amount": "500000",
      "tenor": "6months",
      "total_interest": "500000",
      "preview": "true",
      "order_id": "FGLU492114",
      "start_date": "02/07/2024",
      "end_date": "13/07/2024",
      "address": "Surulere Lagos",
      "companies_schedules": [
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "02/07/2024",
              "end_date": "02/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "03/07/2024",
              "end_date": "03/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "04/07/2024",
              "end_date": "04/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "05/07/2024",
              "end_date": "05/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "06/07/2024",
              "end_date": "06/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "07/07/2024",
              "end_date": "07/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "08/07/2024",
              "end_date": "08/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "09/07/2024",
              "end_date": "09/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "10/07/2024",
              "end_date": "10/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "11/07/2024",
              "end_date": "11/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "12/07/2024",
              "end_date": "12/07/2024"
          },
          {
              "mandateId": "",
              "amount": "6000",
              "interest": "5000",
              "total_amount_due": "6000",
              "start_date": "13/07/2024",
              "end_date": "13/07/2024"
          }
      ]
  }
}

export default function LoanSummary({orderID}) {
 

  

  const { updateCurrentPage } = useUserLoanApproval();

  const {data: data} = useGetUserMandate(orderID);
  const response = data?.data

  const schedules = response?.data
  console.log(response);

  const authorizeDirectDebit=()=>{
    updateCurrentPage("BVN_OTP", {mandate: schedules})
  }


  return (
    <>
      <div className="col-lg-6 col-md-10 col-12 mx-auto tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white tw-shadow-sm tw-mt-5">
        <h2 className="tw-font-[600] tw-text-[22px] tw-mb-5 tw-text-center">FI Lender</h2>
        <br />
        <h5 className="tw-font-[400] tw-text-[18px] tw-text-center tw-mb-5">Loan Approval</h5>
        <div className="tw-flex tw-flex-col tw-gap-y-1">
        <p className="tw-font-[500] tw-text-[14px]">{schedules?.first_name} {schedules?.last_name}</p>
          <div className="tw-flex tw-gap-5 tw-flex-wrap">
          <p className="tw-font-[500] tw-text-[14px]">
              Loan Amount: <span className="tw-font-[300]">{schedules?.total_amount}</span>
            </p>
            <p className="tw-font-[500] tw-text-[14px]">
              Tenor: <span className="tw-font-[300]">{schedules?.tenor}</span>
            </p>
            <p className="tw-font-[500] tw-text-[14px]">
              Total Interest: <span className="tw-font-[300]">{schedules?.total_interest}</span>
            </p>
          </div>
          <div className="mt-3">
          <TableContainer
              component={Paper}
              sx={{ boxShadow: "none", borderRadius: 2 }}
             
            >
              <Table
                aria-label="simple table"
                sx={{ borderCollapse: "collapse" }}
                size="small"
              >
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ borderBottom: "none" }}>
                      Due Date
                    </TableCell>
                    <TableCell
                      align="right"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Principal Payment
                    </TableCell>
                    <TableCell
                      align="right"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Interest Payment
                    </TableCell>
                    <TableCell
                      align="right"
                      className="tw-font-[500] tw-text-[14px]"
                      sx={{ borderBottom: "none" }}
                    >
                      Total Amount Due
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {schedules?.companies_schedules?.length ? schedules?.companies_schedules?.map((row, index) => (
                    <TableRow
                      key={row?.start_date + "___" + index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                        "& td, & th": { borderBottom: "none" },
                      }}
                    >
                      <TableCell component="th" scope="row">
                        {row?.start_date}
                      </TableCell>
                      <TableCell align="right">
                        {row?.amount}
                      </TableCell>
                      <TableCell align="right">{row?.interest}</TableCell>
                      <TableCell align="right">{row?.total_amount_due}</TableCell>
                    </TableRow>
                  )): <i>No data found</i>}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
        <div>
          <p className="tw-font-[300] tw-text-[14px] mt-3">Kindly authorize <span className="tw-font-[500]">FI lender</span> to set direct debit mandate on your bank account for loan repayment</p>
        </div>
        <div className="tw-mt-5 tw-flex tw-justify-center tw-items-center">
                <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF]"
                    onClick={authorizeDirectDebit}
                  >
                    Authorize direct debit
                  </Button>
               

                </div>
      </div>
    </>
  );
}
