import "./headerSection.css";
import youngWoman from "../../../assets/vouch.png";
import landing_img from "../../../assets/welcome_img.png";
import StartButtons from "../../Buttons/StartButtons";
import { relatedCompanies } from "../relatedCompanies";
function HeaderSection() {
  return (
    <>
      <main className="__header_section">
        <section className="row container-fluid">
          <article className="col-lg-6 col-12 left_content my-lg-5 mx-lg-5 firstCont">
            <p className="main_text" data-aos="zoom-out">
              Freedom To Shop Across Marketplaces
            </p>
            <span className="smallHeader" data-aos="zoom-in">
            Shop and Pay Bills With Vouch, and Pay Same Amount Later!
            </span>

            <br />
            <br />
            <StartButtons title={"Start Now"} className="button" />
            <div className="related_companies desktop_view">
              <br />
              <br />
              <br />
              <p
              className="shop_with_vouch"
                style={{
                  fontSize: "24px",
                  fontWeight: 400,
                  lineHeight: "36px",
                }}
              >
                Shop with Vouch from{" "}
              </p>
              <div className="tw-flex tw-gap-3">
                {relatedCompanies.map((item, index) => {
                  return (
                    <img
                      src={item.img}
                      alt={item.name}
                      key={index}
                      data-aos={item.aos}
                    />
                  );
                })}

              </div>
            </div>
          </article>
          <article className="col-lg-5 col-12 d-md-flex justify-content-center right_content">
            <img
              src={landing_img}
              alt="landing_page_side_img"
              className="landing_page_side_img"
            />
          </article>
        </section>
      </main>
    </>
  );
}

export default HeaderSection;
