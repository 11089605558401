import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';

import TopToolBar from '../../../utils/Table/TopToolBar';

import EnhancedTableHead from '../../../utils/Table/EnhancedTableHead';

import { formatNumber } from '../../../utils/formatNumber'
import { TextField } from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import moment from 'moment';


export default function TransactionHistoryTable({topHeaderVisible, rows, headCell, handleOpen}) {

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);

    const headCells = [
        {
          label: 'Status',
        },
        {
          label: 'Amount',
        },
        {
          label: 'Type',
        },
        {
          label: 'Payment Reference',
        },
        {
          label: 'Remark',
        },
        {
          label: 'Category',
        },
        {
          label: 'Date',
        }
      ];
      
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rows?.map((n) => n?.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, user_id) => {
      const selectedIndex = selected?.indexOf(user_id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, user_id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected?.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };
  
    const isSelected = (agent) => selected?.indexOf(agent) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  
    return (
    <React.Fragment>
        <section className="col-md-11 mx-auto table_header my-4 ">
          <div className="d-flex gap- flex-wrap justify-content-between align-items-center">
            <h6 className="fw-bold">Transaction</h6>
            <TextField
                      type="text"
                      placeholder="Search..."
                      // fullWidth
                      size="small"
                      InputProps={{
                        style: {
                          fontSize: "14px",
                          borderRadius: "20px",
                        },
                        endAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
          </div>
        </section>
      <Box sx={{ width: '100%' }}>
        {
          rows?.length?(
          <Paper sx={{ width: '100%', mb: 2 }}>
            {
              selected?.length ? (
                <TopToolBar numSelected={selected?.length} topHeaderVisible={topHeaderVisible}/>
              ): null
            }
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
              >
                <EnhancedTableHead
                  numSelected={selected?.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows?.length}
                  headCell={headCells}
                />
                <TableBody>
                  {rows?.map((row, index) => {
                    const isItemSelected = isSelected(row?.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.campaign)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        sx={{ cursor: 'pointer' }}
                        >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          /> */}
                        </TableCell>
                        <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                        {row?.status}
                        </TableCell>
                        <TableCell>{formatNumber(row?.amount)}</TableCell>
                        <TableCell className='nowrap'>{
                          row?.is_bill? "Bill": row?.is_credit? "Credit":
                          row?.subscription? "Subscription": ""
                        }</TableCell>
                        <TableCell>{row?.reference}</TableCell>
                        <TableCell>
                          <p className="text-truncat" style={{width: "100px"}}>{row?.remark ?? "No content"}</p>
                        </TableCell>
                        <TableCell>
                        <div className="d-flex flex-column">
                            <p className="fw-bold my-auto nowrap">
                              {
                                row?.subscription ? "Subscription" : row?.order?.is_credit ? "Online Marketplace" : row?.order?.is_bill?"Bills payment" : "" 
                              }
                            </p>
                            <small className="text-muted nowrap" style={{fontSize: "12px"}}>
                              {
                                row?.subscription ? "" : row?.order?.is_credit ? row?.order?.market_place?.name : row?.order?.is_bill? row?.order?.order_name: "" 
                              }
                            </small>
                          </div>
                        </TableCell>
                        <TableCell >
                          <p className="nowrap">
                        { moment(row?.createdAt).format('DD, MMMM YYYY')}
                          </p>
                        </TableCell>
                        
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: (53) * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            {/* <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={rows?.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            /> */}
          </Paper>
          ): (
            <main
            className="d-flex align-items-center justify-content-center flex-column border"
            style={{ height: "25vh", width: "100%" }}
          >
            <h3>No data available </h3>
          </main>
          )
        }
      </Box>
      </React.Fragment>
    );
  }
