import React, { useEffect, useState } from "react";
import AuthHeader from "../../Auth/AuthHeader";
import { TextField } from "@mui/material";
import AuthButton from "../../Auth/AuthButton/AuthButton";
import { useFormik } from "formik";
import * as yup from "yup";
import { useAdminRegister } from "../../../redux/mutation/AdminPostMutation";

export default function NewAdmin({ handleClick, handleClose }) {
  const [isLoading, setIsLoading] = useState(false);

  const registerMutation = useAdminRegister();

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      phone: "",
      email: "",
      password: "",
      confirm_password: "",
      role: "admin",
    },
    onSubmit: (values, { resetForm }) => {
      registerMutation.mutate(values, { onSuccess: () => resetForm() });
    
    },
    validationSchema: yup.object().shape({
      first_name: yup.string().required("Admin First name can not empty!"),
      last_name: yup.string().required("Admin Last name can not empty!"),
      phone: yup.number().required("Phone Number can not be empty!"),
      email: yup
        .string()
        .required("Email can not be empty!")
        .email("Invalid email address!"),
      password: yup
        .string()
        .required("password is required")
        .min(6, "password should not be less than 6"),
    }),
  });

  useEffect(() => {
    if (registerMutation.isLoading) {
      setIsLoading(true);
    } else if (registerMutation.isSuccess) {
      setIsLoading(false);
      let response = registerMutation?.data?.data;
    
      handleClick(response.message, "success");
      // handleClose();
    } else if (registerMutation.isError) {
      setIsLoading(false);
      
      let message = registerMutation?.error?.response?.data?.error;
      let message1 = registerMutation?.error?.response?.data?.message;
      let message2 = registerMutation?.error?.message;
      handleClick(message ?? message1 ?? message2, "error");
    }
  }, [registerMutation.status]);

  return (
    <>
      <main>
        <form className="col-md-6 mx-auto" onSubmit={formik.handleSubmit}>
          <AuthHeader title={"Register New Admin"} />
          <section className="row mx-2">
            <div className="first_name col-lg-6">
              <div className="label my-2">
                <label htmlFor="firstName">First Name*</label>
              </div>
              <TextField
                type="text"
                name="first_name"
                required
                error={formik.touched.first_name && formik.errors.first_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter the admin last name"
                value={formik.values.first_name}
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.first_name && formik.errors.first_name}
              </small>
            </div>
            <div className="last_name col-lg-6">
              <div className="label my-2">
                <label htmlFor="firstName">Last Name*</label>
              </div>
              <TextField
                type="text"
                name="last_name"
                required
                error={formik.touched.first_name && formik.errors.last_name}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter the admin last name"
                value={formik.values.last_name}
                fullWidth
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.last_name && formik.errors.last_name}
              </small>
            </div>

            <div className="phoneNumber col-lg-6">
              <div className="label my-2">
                <label htmlFor="phoneNumber">Phone Number *</label>
              </div>
              <TextField
                type="text"
                name="phone"
                error={formik.touched.phone && formik.errors.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="Enter Admin Phone Number"
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.phone && formik.errors.phone}
              </small>
            </div>
            <div className="Email col-lg-6">
              <div className="label my-2">
                <label htmlFor="Email">Email *</label>
              </div>
              <TextField
                type="email"
                name="email"
                required
                error={formik.touched.email && formik.errors.email}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter admin Email Address"
                fullWidth
                value={formik.values.email}
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.email && formik.errors.email}
              </small>
            </div>
            <div className="password col-lg-6">
              <div className="label my-2">
                <label htmlFor="password">Password *</label>
              </div>
              <TextField
                type="password"
                name="password"
                required
                error={formik.touched.password && formik.errors.password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Your password"
                fullWidth
                value={formik.values.password}
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.password && formik.errors.password}
              </small>
            </div>
            <div className="password col-lg-6">
              <div className="label my-2">
                <label htmlFor="password">Confirm Password *</label>
              </div>
              <TextField
                type="password"
                name="confirm_password"
                required
                error={formik.touched.confirm_password && formik.errors.confirm_password}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                placeholder="Enter Your password"
                fullWidth
                value={formik.values.confirm_password}
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.confirm_password && formik.errors.confirm_password}
              </small>
            </div>
            <div className="submit_btn">
              <AuthButton loading={isLoading} text={"Add Admin"} />
            </div>
          </section>
        </form>
      </main>
    </>
  );
}
