import { Icon } from "@mui/material";
import BottomFooter from "./BottomFooter";
import "./footer.css";
import { CallIcons, EmailIcon } from "../../../icons/icons";
import { Link } from "react-router-dom";
import Logo from "../../logo/logo";
import logo from "../../../assets/vouch.svg";
function Footer() {
  return (
    <>
      <main className="landing_page_footer">
        <div className="children mx-3">
          <p className="firstChild my-auto">
            {/* <span>
                  <img src={logo} style={{width:"60px", height:"60px", fontWeight:"1000", paddingTop:"0px",}} alt="Vouch Logo"/> 
                </span> */}
            <span>
              <Link to="mailto:hi@vouch.ng" className="nav-link">
                hi@vouch.ng
              </Link>
            </span>
          </p>
          <div className="childrenpara my-auto">
            <p className="my-auto">
              <CallIcons />
            </p>{" "}
            <p className="my-auto" style={{ cursor: "pointer" }}>
              09060002152
            </p>
          </div>
          <div className="d-flex gap-3 mb-3 mb-md-0">
          <a
            href="https://www.facebook.com/profile.php?id=100088139175294&mibextid=ZbWKwL"
            target="_blank"
            rel="noreferrer"
            className="d-flex justify-content-between align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0,0,256,256"
            >
              <g
                fill="#3a559f"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
                style={{ mixBlendMode: "normal" }}
              >
                <g transform="scale(10.66667,10.66667)">
                  <path d="M12,2c-5.511,0 -10,4.489 -10,10c0,5.511 4.489,10 10,10c5.511,0 10,-4.489 10,-10c0,-5.511 -4.489,-10 -10,-10zM12,4c4.43012,0 8,3.56988 8,8c0,4.01447 -2.93468,7.31302 -6.78125,7.89844v-5.51367h2.32813l0.36523,-2.36524h-2.69336v-1.29297c0,-0.983 0.32023,-1.85547 1.24023,-1.85547h1.47656v-2.06445c-0.26,-0.035 -0.8087,-0.11133 -1.8457,-0.11133c-2.166,0 -3.43555,1.144 -3.43555,3.75v1.57422h-2.22656v2.36524h2.22656v5.49414c-3.78401,-0.63806 -6.6543,-3.90867 -6.6543,-7.87891c0,-4.43012 3.56988,-8 8,-8z"></path>
                </g>
              </g>
            </svg>
          </a>
          <a
            href="https://www.instagram.com/vouch4all?igsh=MWxrZWtwNWhocmpieA=="
            target="_blank"
            rel="noreferrer"
            className="d-flex justify-content-between align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0 0 64 64"
            >
              <radialGradient
                id="TGwjmZMm2W~B4yrgup6jda_119026_gr1"
                cx="32"
                cy="32.5"
                r="31.259"
                gradientTransform="matrix(1 0 0 -1 0 64)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset="0" stop-color="#efdcb1"></stop>
                <stop offset="0" stop-color="#f2e0bb"></stop>
                <stop offset=".011" stop-color="#f2e0bc"></stop>
                <stop offset=".362" stop-color="#f9edd2"></stop>
                <stop offset=".699" stop-color="#fef4df"></stop>
                <stop offset="1" stop-color="#fff7e4"></stop>
              </radialGradient>
              <path
                fill="url(#TGwjmZMm2W~B4yrgup6jda_119026_gr1)"
                d="M58,54c-1.1,0-2-0.9-2-2s0.9-2,2-2h2.5c1.9,0,3.5-1.6,3.5-3.5S62.4,43,60.5,43H50c-1.4,0-2.5-1.1-2.5-2.5	S48.6,38,50,38h8c1.7,0,3-1.3,3-3s-1.3-3-3-3H42v-6h18c2.3,0,4.2-2,4-4.4c-0.2-2.1-2.1-3.6-4.2-3.6H58c-1.1,0-2-0.9-2-2s0.9-2,2-2	h0.4c1.3,0,2.5-0.9,2.6-2.2c0.2-1.5-1-2.8-2.5-2.8h-14C43.7,9,43,8.3,43,7.5S43.7,6,44.5,6h3.9c1.3,0,2.5-0.9,2.6-2.2	C51.1,2.3,50,1,48.5,1H15.6c-1.3,0-2.5,0.9-2.6,2.2C12.9,4.7,14,6,15.5,6H19c1.1,0,2,0.9,2,2s-0.9,2-2,2H6.2c-2.1,0-4,1.5-4.2,3.6	C1.8,16,3.7,18,6,18h2.5c1.9,0,3.5,1.6,3.5,3.5S10.4,25,8.5,25H5.2c-2.1,0-4,1.5-4.2,3.6C0.8,31,2.7,33,5,33h17v11H6	c-1.7,0-3,1.3-3,3s1.3,3,3,3l0,0c1.1,0,2,0.9,2,2s-0.9,2-2,2H4.2c-2.1,0-4,1.5-4.2,3.6C-0.2,60,1.7,62,4,62h53.8	c2.1,0,4-1.5,4.2-3.6C62.2,56,60.3,54,58,54z"
              ></path>
              <radialGradient
                id="TGwjmZMm2W~B4yrgup6jdb_119026_gr2"
                cx="18.51"
                cy="66.293"
                r="69.648"
                gradientTransform="matrix(.6435 -.7654 .5056 .4251 -26.92 52.282)"
                gradientUnits="userSpaceOnUse"
              >
                <stop offset=".073" stop-color="#eacc7b"></stop>
                <stop offset=".184" stop-color="#ecaa59"></stop>
                <stop offset=".307" stop-color="#ef802e"></stop>
                <stop offset=".358" stop-color="#ef6d3a"></stop>
                <stop offset=".46" stop-color="#f04b50"></stop>
                <stop offset=".516" stop-color="#f03e58"></stop>
                <stop offset=".689" stop-color="#db359e"></stop>
                <stop offset=".724" stop-color="#ce37a4"></stop>
                <stop offset=".789" stop-color="#ac3cb4"></stop>
                <stop offset=".877" stop-color="#7544cf"></stop>
                <stop offset=".98" stop-color="#2b4ff2"></stop>
              </radialGradient>
              <path
                fill="url(#TGwjmZMm2W~B4yrgup6jdb_119026_gr2)"
                d="M45,57H19c-5.5,0-10-4.5-10-10V21c0-5.5,4.5-10,10-10h26c5.5,0,10,4.5,10,10v26C55,52.5,50.5,57,45,57z"
              ></path>
              <path
                fill="#fff"
                d="M32,20c4.6,0,5.1,0,6.9,0.1c1.7,0.1,2.6,0.4,3.2,0.6c0.8,0.3,1.4,0.7,2,1.3c0.6,0.6,1,1.2,1.3,2 c0.2,0.6,0.5,1.5,0.6,3.2C46,28.9,46,29.4,46,34s0,5.1-0.1,6.9c-0.1,1.7-0.4,2.6-0.6,3.2c-0.3,0.8-0.7,1.4-1.3,2 c-0.6,0.6-1.2,1-2,1.3c-0.6,0.2-1.5,0.5-3.2,0.6C37.1,48,36.6,48,32,48s-5.1,0-6.9-0.1c-1.7-0.1-2.6-0.4-3.2-0.6 c-0.8-0.3-1.4-0.7-2-1.3c-0.6-0.6-1-1.2-1.3-2c-0.2-0.6-0.5-1.5-0.6-3.2C18,39.1,18,38.6,18,34s0-5.1,0.1-6.9 c0.1-1.7,0.4-2.6,0.6-3.2c0.3-0.8,0.7-1.4,1.3-2c0.6-0.6,1.2-1,2-1.3c0.6-0.2,1.5-0.5,3.2-0.6C26.9,20,27.4,20,32,20 M32,17 c-4.6,0-5.2,0-7,0.1c-1.8,0.1-3,0.4-4.1,0.8c-1.1,0.4-2.1,1-3,2s-1.5,1.9-2,3c-0.4,1.1-0.7,2.3-0.8,4.1C15,28.8,15,29.4,15,34 s0,5.2,0.1,7c0.1,1.8,0.4,3,0.8,4.1c0.4,1.1,1,2.1,2,3c0.9,0.9,1.9,1.5,3,2c1.1,0.4,2.3,0.7,4.1,0.8c1.8,0.1,2.4,0.1,7,0.1 s5.2,0,7-0.1c1.8-0.1,3-0.4,4.1-0.8c1.1-0.4,2.1-1,3-2c0.9-0.9,1.5-1.9,2-3c0.4-1.1,0.7-2.3,0.8-4.1c0.1-1.8,0.1-2.4,0.1-7 s0-5.2-0.1-7c-0.1-1.8-0.4-3-0.8-4.1c-0.4-1.1-1-2.1-2-3s-1.9-1.5-3-2c-1.1-0.4-2.3-0.7-4.1-0.8C37.2,17,36.6,17,32,17L32,17z"
              ></path>
              <path
                fill="#fff"
                d="M32,25c-5,0-9,4-9,9s4,9,9,9s9-4,9-9S37,25,32,25z M32,40c-3.3,0-6-2.7-6-6s2.7-6,6-6s6,2.7,6,6S35.3,40,32,40 z"
              ></path>
              <circle cx="41" cy="25" r="2" fill="#fff"></circle>
            </svg>
          </a>
          <a
            href="https://www.linkedin.com/showcase/vouchng/"
            target="_blank"
            rel="noreferrer"
            className="d-flex justify-content-between align-items-center"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              width="25"
              height="25"
              viewBox="0,0,256,256"
            >
              <g
                fill="#0073b1"
                fill-rule="nonzero"
                stroke="none"
                stroke-width="1"
                stroke-linecap="butt"
                stroke-linejoin="miter"
                stroke-miterlimit="10"
                stroke-dasharray=""
                stroke-dashoffset="0"
                font-family="none"
                font-weight="none"
                font-size="none"
                text-anchor="none"
              >
                <g transform="scale(10.66667,10.66667)">
                  <path d="M19,3h-14c-1.105,0 -2,0.895 -2,2v14c0,1.105 0.895,2 2,2h14c1.105,0 2,-0.895 2,-2v-14c0,-1.105 -0.895,-2 -2,-2zM7.738,17v0c-0.697,0 -1.262,-0.565 -1.262,-1.262v-4.477c0.001,-0.696 0.566,-1.261 1.262,-1.261v0c0.697,0 1.262,0.565 1.262,1.262v4.477c0,0.696 -0.565,1.261 -1.262,1.261zM7.694,8.717c-0.771,0 -1.286,-0.514 -1.286,-1.2c0,-0.686 0.514,-1.2 1.371,-1.2c0.771,0 1.286,0.514 1.286,1.2c0,0.686 -0.514,1.2 -1.371,1.2zM16.779,17v0c-0.674,0 -1.221,-0.547 -1.221,-1.221v-2.605c0,-1.058 -0.651,-1.174 -0.895,-1.174c-0.244,0 -1.058,0.035 -1.058,1.174v2.605c0,0.674 -0.547,1.221 -1.221,1.221h-0.081c-0.674,0 -1.221,-0.547 -1.221,-1.221v-4.517c0,-0.697 0.565,-1.262 1.262,-1.262v0c0.697,0 1.262,0.565 1.262,1.262c0,0 0.282,-1.262 2.198,-1.262c1.219,0 2.196,0.977 2.196,3.174v2.605c0,0.674 -0.547,1.221 -1.221,1.221z"></path>
                </g>
              </g>
            </svg>
          </a>
          </div>
        </div>

        <BottomFooter />
      </main>
    </>
  );
}

export default Footer;
