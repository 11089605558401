import { Button } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from '../../../../routes/route';

export default function CompleteTransaction() {
    const navigate = useNavigate();
  return (
    <>
        <section>
            <div className='text-center'>
                <h5>Transaction Completed</h5><br />
                <p>This transaction will be remitted into your bank account</p><br />
                <Button className="fw-bold cursor" sx={{textTransform: "capitalize"}} onClick={()=>{
                    navigate(ROUTES.settlement)
                }}>View Settlement</Button>
            </div>
        </section>
    </>
  )
}
