import {useRef } from "react"
import { TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomButton from "../components/CustomButton/CustomButton";
import { useFormik } from "formik"
import * as yup from "yup";
import { useContactUs } from "../redux/mutation/postMutation";
import Navbar from "../components/LandingPage/Navbar/Navbar";

function Contact({handleClick}) {

    const [isLoading, setIsLoading ] = useState(null);
    const contactUsMutation = useContactUs();
    const backgroundImgStyle = {
        backgroundImage: `linear-gradient(to bottom, rgba(245, 246, 252, 0.52), rgba(178, 205, 217, 0.6)), url(https://png.pngtree.com/thumb_back/fh260/background/20220616/pngtree-women-using-her-smartphone-horizontal-only-women-photography-photo-image_31662380.jpg)`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        height: "90vh"
    }

    const formik = useFormik({
        initialValues: {
            email: "",
            subject: "",
            body: ""
        },
        onSubmit: (values, {resetForm})=>{
            contactUsMutation.mutate(values, {onSuccess:()=>resetForm()});
        },
        validationSchema: yup.object().shape({
            email: yup.string().required("Email can not be empty!").email("Invalid email address!"),
            subject: yup.string().required("Subject is required!"),
            body: yup.string().required("Message Body is required!")
        })
    })
    useEffect(()=>{
        if(contactUsMutation.isLoading){
            setIsLoading(true)
        }
        else if(contactUsMutation.isError){
            const msg = contactUsMutation?.error?.response?.data?.message;
            handleClick(msg, "error");
            setIsLoading(false)
        }
        else if(contactUsMutation.isSuccess){
            const msg = contactUsMutation?.data?.data?.message;
            handleClick(msg, "success");
            setIsLoading(false)
        }
    })

  return (
    <>
      <main style={{minHeight: "100vh"}}>
      <Navbar />
        <section className="mt-4">
          <div className="d-flex justify-content-center align-items-center" style={backgroundImgStyle}> 
                <form className="col-md-4 mx-auto" onSubmit={formik.handleSubmit}>
                    <h2 className="text-center fw-bold">Contact Us</h2>
                    <div className="email my-4">
                        <label htmlFor="email">Email</label>
                        <TextField name="email" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.email} type="email" size="small" placeholder="Email" variant="outlined" sx={{backgroundColor: "#fff"}} fullWidth />
                        <span className={`text-danger`}>{formik?.touched.email && formik.errors.email}</span>
                    </div>
                    <div className="subject my-4">
                        <label htmlFor="subject">Subject</label>
                        <TextField name="subject" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.subject} type="text" size="small" placeholder="Subject" variant="outlined" sx={{backgroundColor: "#fff"}} fullWidth />
                        <span className={`text-danger`}>{formik?.touched.subject && formik.errors.subject}</span>
                    </div>
                    <div className="subject my-3">
                        <label htmlFor="body">Body</label>
                        <textarea name="body" onChange={formik.handleChange} onBlur={formik.handleBlur} value={formik.values.body} style={{resize: "none"}} className="form-control" cols="10" rows="5" placeholder="what you wana say?"></textarea>
                        <span className={`text-danger`}>{formik?.touched.body && formik.errors.body}</span>
                    </div>
                    <div>
                        <CustomButton text={"Submit"} loading={isLoading}/>
                    </div>
                </form>
          </div>
        </section>
        
      </main>
    </>
  );
}

export default Contact;
