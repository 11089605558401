import React, { useEffect } from "react";
import profile from "../../../../../assets/avatar.png";
import { MenuItem, Select, TextField } from "@mui/material";
import { ROUTES } from "../../../../../routes/route";
import KYCStepper from "../../KYCStepper";

import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';

import "./personalInfo.css";
function PersonalInformation({ formik, setenableNext }) {
  const profileStyle = {
    backgroundImage: `url(${formik.values.image?formik.values.image:profile})`
  }

  useEffect(()=>{
    if (formik?.values.gender && formik.values.maritalStatus && formik.values.dateOfBirth && formik.values.nin) {
      setenableNext(true)
    }
    else{
       setenableNext(false);
     }
  }, [formik.values])



  const onChange = (date, dateString) => {
    // console.log(dateString);
    formik.setFieldValue("dateOfBirth", dateString)
  };

  const onChangeNIN=(e)=>{

    formik.setFieldValue("nin", e.target.value)


  }




  return (
    <>
      <main className="my-3 mx-2">
        <p className="text-center kyc_title" style={{color: "#323C4D",fontSize: "20px", fontStyle: "normal",fontWeight: "500",lineHeight: "normal"}}>Bio Data</p>
        <section>
          <div className="d-flex justify-content-center align-item-center text-center">
            <div className="image" style={profileStyle}></div>
            <img src={formik.values.image?formik.values.image:profile} width={150} height={150} alt="" className="rounded-circle" />
          </div>
          <form className="col-lg-10 mx-auto ">
            <div className="gender" style={{paddingBottom: "20px"}}>
              <div className="label" style={{marginBottom: "4px"}}>
                <label htmlFor="gender">Gender *</label>
              </div>
              <Select
                name="gender"
                placeholder="Gender"
                value={formik?.values.gender}
                error={formik?.touched.gender && formik?.errors.gender}
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                fullWidth
                variant="outlined"
                sx={{ borderRadius: "30px" }}
                size="small"
              >
                <MenuItem value="Male">Male</MenuItem>
                <MenuItem value="Female">Female</MenuItem>
                <MenuItem value="Others">Others</MenuItem>
              </Select>
            </div>
            <div className="maritalStatus" style={{paddingBottom: "20px"}}>
              <div className="label" style={{marginBottom: "4px"}}>
                <label htmlFor="maritalStatus">Marital Status</label>
              </div>
              <Select
                name="maritalStatus"
                placeholder="Select marital status"
                defaultValue="Other"
                value={formik?.values.maritalStatus}
                error={
                  formik?.touched.maritalStatus && formik?.errors.maritalStatus
                }
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                fullWidth
                variant="outlined"
                sx={{ borderRadius: "30px" }}
                size="small"
              >
                <MenuItem value="Single">Single</MenuItem>
                <MenuItem value="Married">Married</MenuItem>
                <MenuItem value="Divorce">Divorce</MenuItem>
                <MenuItem value="Other">Other</MenuItem>
              </Select>
            </div>
            {/* <div className="dateOfBirth" style={{paddingBottom: "20px"}}>
              <div className="label" style={{marginBottom: "4px"}}>
                <label htmlFor="dateOfBirth">Date of birth</label>
              </div>
              <TextField
                type="date"
                name="dateOfBirth"
                error={
                  formik?.touched.dateOfBirth && formik?.errors.dateOfBirth
                }
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                fullWidth
                value={formik?.values.dateOfBirth}
                variant="outlined"
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
            </div> */}


            <div className="dateOfBirth" style={{paddingBottom: "20px"}}>
              <div className="label" style={{marginBottom: "4px"}}>
                <label htmlFor="dateOfBirth">Date of birth</label>
              </div>
              <DatePicker defaultValue={formik?.values.dateOfBirth && dayjs(formik?.values.dateOfBirth)} status={ formik?.touched.dateOfBirth && formik?.errors.dateOfBirth && 'error'} format={"DD/MM/YYYY"} onChange={onChange} className="w-100 py-2 border" style={{borderRadius: "30px"}}/>
            </div>




            <div className="nin" style={{paddingBottom: "20px"}}>
              <div className="label" style={{marginBottom: "4px"}}>
                <label htmlFor="nin">NIN *</label>
              </div>
              <TextField
                type="number"
                name="nin"
                value={formik?.values.nin}
                error={formik?.touched.nin && formik?.errors.nin}
                onChange={onChangeNIN}
                onBlur={formik?.handleBlur}
                placeholder="Enter Your NIN"
                maxlength="11"
                
                fullWidth
                variant="outlined"
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik.touched.nin && formik.errors.nin
                }
              </small>
            </div>
          </form>
        </section>
      </main>
    </>
  );
}

export default PersonalInformation;
