/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import { CameraIcon, UploadIcon } from "../../../../../icons/icons";
import "./takeSelfie.css";
import KYCStepper from "../../KYCStepper";
import { useUploadImage } from "../../../../../redux/mutation/postMutation";
import { CircularProgress } from "@mui/material";
import { CapturePhoto } from "./CapturePhoto/CapturePhoto";
import ModalDialogBox from "../../../../ModalDialogBox/ModalDialogBox";
import styled from "styled-components";
function TakeSelfie({
  activeStep,
  kycStep,
  handleClick,
  formik,
  imageFile,
  setImageFile,
  setenableNext,
}) {
  const uploadImageMutation = useUploadImage();
  const [openDropdown, setOpenDropdown] = useState(false);
  const [isLoading, setIsLoading] = useState(null);
  //file url that am using for the background image
  const [fileUrl, setFileUrl] = useState(() => {
    return formik?.values?.image;
  });
  const [open, setOpen] = useState(false);
  const [fileTooLarge, setFileTooLarge] = useState(false);
  const [idle, setIdle] = useState();
  const videoRef = useRef();

  const handleOpen = () => {
    setOpenDropdown(!openDropdown);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const triggerOpenModal = () => {
    setOpen(true);
    getVideo();
  };

  const handleChooseFile = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    
    const txtFile = URL.createObjectURL(file);
    setImageFile(file);
    setFileUrl(txtFile);



  };

  const getVideo = () => {
    setIdle(true);
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices
        .getUserMedia({ video: { width: 1920, height: 1000 } })
        .then((stream) => {
          let video = videoRef.current;
          video.srcObject = stream;
          video?.play();
          setIdle(false);
        })
        .catch((err) => {
          setIdle(false);
          alert("Unexpected error error");
        });
    } else {
      setIdle(false);
      alert("No permission granted");
    }
  };

  useEffect(() => {
    if (fileUrl) {
      setOpenDropdown(false);
    }
  }, [fileUrl]);


  
  const handleFileUpload = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", imageFile);
  
    setIsLoading(true);
    uploadImageMutation.mutate(formData);
  };
  const activeStyle = {
    backgroundImage: `url(${fileUrl ? fileUrl : formik?.values?.image})`,
  };

  //useEffect for mutation
  useEffect(() => {
    if (uploadImageMutation.isLoading) {
      setIsLoading(true);
    } else if (uploadImageMutation.isSuccess) {
      setIsLoading(false);
      setenableNext(true);
      let msg = uploadImageMutation?.data?.data?.message;
      formik.setFieldValue("image", uploadImageMutation?.data?.data?.data);
      setImageFile("");
      handleClick(msg, "success");
    } else if (uploadImageMutation.isError) {
      setIsLoading(false);
      let msg1 = uploadImageMutation?.error?.response?.data?.message;
      let msg2 = uploadImageMutation?.error?.message;
      handleClick(msg1 ?? msg2, "error");
    }
  }, [uploadImageMutation.status]);

  useEffect(() => {
    if ((imageFile || formik?.values.image) && !fileTooLarge) {
      setenableNext(true);
    }
    else{
      setenableNext(false);
    }
  }, [formik, setImageFile, imageFile]);

  return (
    <>
      <main>
        {/* <KYCStepper activeStep={activeStep} steps={kycStep} /> */}
        <p className="selfie_tytle text-center kyc_title">Tap to take a selfie</p>
        <form onSubmit={handleFileUpload}>
          {fileTooLarge && (
            <p className="text-danger text-center mb-1">
            <small className="text-danger text-center mb-1">
              Please upload a picture smaller than 1 MB.
            </small>

            </p>
          )}
          <div
            className="camera_container position-relative"
            style={fileUrl ? activeStyle : null}
          >
            {/* <span onClick={handleOpen}> */}
              {isLoading ? (
                <CircularProgress />
              ) : (
                <label htmlFor="uploadImage">
                <CameraIcon className="cursor-pointer" color={fileUrl ? "#ffff" : null} />
                <input
                        type="file"
                        id="uploadImage"
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => handleChooseFile(e)}
                      />
                </label>
              )}
            {/* </span> */}
            <div className="dropdown_section position-absolute">
              {openDropdown ? (
                <div className="d-flex flex-column">
                  {/* <span className="btn btn-danger" onClick={triggerOpenModal}>
                    Take Photo
                  </span> */}
                  <div
                    className="btn btn-primary"
                    style={{ cursor: "pointer" }}
                  >
                    <label htmlFor="uploadImage">
                      <input
                        type="file"
                        id="uploadImage"
                        className="d-none"
                        accept="image/*"
                        onChange={(e) => handleChooseFile(e)}
                      />
                      <span>Upload Photo</span>
                    </label>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {
            // !formik?.values?.image?
            // imageFile || !formik?.values?.image ? (
            //   <div className="d-flex justify-content-center align-center mx-auto">
            //     <Button
            //       className="btn btn-primary rounded-pill px-4 my-2"
            //       style={{ backgroundColor: "#033F4B" }}
            //       disabled={!imageFile || fileTooLarge || isLoading}
            //       type="submit"
            //     >
            //       <UploadIcon /> Upload
            //     </Button>
            //   </div>
            // ) : null
          }
        </form>
        <br />
        <ModalDialogBox open={open} handleClose={handleClose}>
          <CapturePhoto
            setFileUrl={setFileUrl}
            videoRef={videoRef}
            setOpen={setOpen}
            setImageFile={setImageFile}
            idle={idle}
            formik={formik}
          />
        </ModalDialogBox>
      </main>
    </>
  );
}

export default TakeSelfie;

const Button = styled("button")`
  background-color: #033f4b;
  color: #fff;
  &:hover {
    transition: 0.5s;
    background-color: #033f4b;
    color: #fff;
  }
`;
