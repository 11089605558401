import React, { useState } from "react";
import { SettingIcon, LogoutIcon } from "../../../icons/icons";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { ROUTES } from "../../../routes/route";
import { logoutFn } from "../../../utils/logout";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Modal, Button } from "antd";
export default function BottomMenu({ fn, handleClick }) {
  const navigate = useNavigate();
  const routePath = useLocation().pathname;
  const menu = [
    { id: 0, path: ROUTES.subPlans, title: "Subscription Plans" },
    { id: 1, path: ROUTES.marketPlace, title: "Market places" },
    { id: 2, path: ROUTES.upgradeRequest, title: "Upgrade Request" },
    { id: 3, path: "", title: "Setting", icon: <SettingIcon /> },
    { id: 4, path: "", title: "Manage Admin" },
  ];

  //logout function
  const [modal, contextHolder] = Modal.useModal({});
  const confirmLogout = () => {
    modal.confirm({
      title: "Confirm",
      icon: <ExclamationCircleOutlined />,
      content: "Are you sure to proceed to logout",

      footer: (_, { OkBtn, CancelBtn }) => (
        <>
          <CancelBtn />
          <Button
            onClick={() => {
              logoutFn("ADMIN");
              navigate(ROUTES.login);
            }}
            style={{
              background: "#033F4B",
              color: "#fff",
            }}
          >
            Confirm
          </Button>
        </>
      ),

      // okText: 'Confirm',
      // cancelText: 'Cancel',
      // onOk:()=>{
      //   logoutFn('ADMIN')
      //   navigate(ROUTES.adminLogin);
      // }
    });
  };
  return (
    <>
      <section className="d-flex flex-column gap-2">
        {menu.map((item, index) => {
          return (
            <Link
              to={item.path}
              key={index}
              onClick={fn}
              className={`text-decoration-none text-dark ${
                item.path === routePath && "fw-bold"
              }`}
            >
              <span className="mx-2">{item.icon}</span>
              {item.title}
            </Link>
          );
        })}

        <div
          className="side_nav_menu d-flex gap-2 mt-2"
          style={{ cursor: "pointer" }}
          onClick={confirmLogout}
        >
          <LogoutIcon />
          <p className="my-auto">
            <span>Logout</span>
          </p>
        </div>
      </section>

      {contextHolder}
    </>
  );
}
