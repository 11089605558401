import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ROUTES } from '../../../routes/route'

export default function MiddleMenu({fn}) {
    const routePath = useLocation().pathname;
    const menu = [
        {id: 1, path: ROUTES.community, title: "Community member"},
        {id: 2, path: ROUTES.adminMerchant, title: "Merchant"},
        {id: 3, path: "", title: "Payment and Settlement"},
        // {id: 4, path: ROUTES.subPlans, title: "Subscription Plans"},
        // {id: 5, path: ROUTES.marketPlace, title: "Market places"}
    ]

    const communityMenu = [
        {id: 0, path: ROUTES.member, title: "Member"},
        // {id: 1, path: ROUTES.community, title: "Member"},
        {id: 2, path: ROUTES?.order, title: "Order"},
        {id: 3, path: ROUTES?.transaction, title: "Transaction"},
        // {id: 4, path: ROUTES?.transaction_history, title: "Transaction History"},
        {id: 5, path: ROUTES?.e_mandate_history, title: "E-mandate/Repayment"},
    ]

    const merchantMenu = [
        // {id: 1, path: ROUTES.adminMerchant, title: "Merchant"},
        {id: 2, path: ROUTES?.payment_settlement, title: "Payment and Settlement"}
    ]
  return (
    <>
            <section className='d-flex flex-column gap-5'>
                <div className="d-flex flex-column gap-2">
                    <p className={`ms-1 ${routePath.includes('community') && 'fw-semibold'}`}>Community member</p>
                    {
                        communityMenu.map((item, index)=>{
                            return (
                                    <Link to={item.path} onClick={fn} key={index} className={`ms-3 text-decoration-none text-dark ${item.path===routePath&&'fw-bold'}`}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "13px"
                                      }}
                                    >{item.title}</Link>
                            )
                        })
                    }

                </div>
                <div className="d-flex flex-column gap-2">
                <p className={`ms-1 ${routePath.includes('merchant') && 'fw-semibold'}`}>Merchant</p>
                    {
                        merchantMenu.map((item, index)=>{
                            return (
                                    <Link to={item.path} onClick={fn} key={index} className={`ms-3 text-decoration-none text-dark ${item.path===routePath&&'fw-bold'}`}>{item.title}</Link>
                            )
                        })
                    }

                </div>
        </section>
    </>
  )
}
