import { useState } from "react";
import Button from "@mui/material/Button";
import ModalDialogBox from "../../components/ModalDialogBox/ModalDialogBox";
import CreateMarketPlace from "../../components/Admin/CreateMarketplace";
import MarketPlaceTable from "../../components/Admin/MarketPlaceTable";
import { useGetMarketPlace, useGetAdminUpgradeRequest } from "../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../utils/Loader";
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { Modal } from "antd";
import UpgradeRequestTable from "../../components/Admin/UpgradeRequestTable";
import UpgradeRequestOptions from "../../components/Admin/UpgradeRequestOptions";
import DrawerBox from "../../utils/Drawer/DrawerBox";


export default function AdminUpgradeRequest({ handleClick }) {


    const {data, isLoading, isError, isSuccess} = useGetAdminUpgradeRequest("all")

    // const upgradeRequest = getUpgradeRequest?.data


    const upgradeRequests = data?.data?.data
    


  const [open, setOpen] = useState({status: false, data: ""});

  const handleOpen = (request) => {
    setOpen({...open, status: true, data: request});
  };
  const handleClose = () => {
    setOpen({...open, status: false});
  };

  return (
    <>
      <main className="mt-5 mx-lg-5 mx-2">
        <h4 className="header fw-bold fs-5 text-center">Plan Upgrade Request</h4>
       

        {
            isLoading ? (
                <IsLoadingLoader />
            ) : isError ? (
                <IsErrorLoader />
            ) : (
                upgradeRequests?.length ? (
                    <UpgradeRequestTable  rows={upgradeRequests} handleOpenModal={handleOpen}/>
                ): (
                    <p className={`card-text text-center my-2`}>No Record of market places available!!</p>
                )
            )
        }



        <section >
            
        </section>
      </main>


        <DrawerBox open={open?.status} handleClose={handleClose}>

        <UpgradeRequestOptions handleCloseOptionModal={handleClose} handleClick={handleClick} request={open?.data}/>

        </DrawerBox>




    </>
  );
}
