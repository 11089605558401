import HeaderSection from './HeaderSection'
import ContentBlock from './ContentBlock'
function WorkInfo({formik, editFn}) {
  return (
     <>
        <main>
            <HeaderSection title={"Work Information"} editFn={editFn}/>
            <section>
                <ContentBlock _key={"Occupation"} _value={formik?.values?.occupation ? formik?.values?.occupation:"---"}/>
                <ContentBlock _key={"Work Email"} _value={formik?.values?.workEmail ? formik?.values?.workEmail:"---"}/>
                <ContentBlock _key={"Monthly Salary"} _value={formik?.values?.monthlySalary??"---"}/>
                <ContentBlock _key={"Employer"} _value={formik?.values?.employer?formik?.values?.employer:"---"}/>
                <ContentBlock _key={"Employer's Address"} _value={formik?.values?.employerAddress?formik?.values?.employerAddress:"---"}/>
            </section>
        </main>
    </>
  )
}

export default WorkInfo