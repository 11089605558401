import React from 'react'
import AdminLoginScreen from '../../screens/Admin/AdminLoginScreen/AdminLoginScreen'

export default function AdminLogin({handleClick}) {
  return (
    <>
        <AdminLoginScreen handleClick={handleClick} />
    </>
  )
}
