import { create } from "zustand"

export const useResponseModal = create((set)=>{
    return{
        isOpen: false,
        data: {},

        openModal: (data)=>set((state)=>({isOpen: true, data})),

        closeModal:()=>set({isOpen: false})
    }
})