import React from 'react'

function ContentBlock({_key, _value, edit}) {
  return (
    <>
        <section className='col-lg-11 mx-auto'>
            <div className="row mx-1 my-1">
                        <div className="col-lg-5 col-5">
                            <p className="text-dark my-auto" style={{fontSize: "15px"}}>{_key}</p>
                        </div>
                        <div className="col-lg-7 col-7">
                            <small className="">{_value}</small>
                        </div>
            </div>
        </section>
    </>
  )
}

export default ContentBlock