import React from 'react'
import Header from './Header'

export default function NIN_IDENTITY({data, actionFn, actionLoading, actionSuccess}) {
  return (
    <>
         <main>
            <Header title={"IDENTITY"} actionFn={(status)=>actionFn('ninIdentity', status)} actionLoading={actionLoading} actionSuccess={actionSuccess}/>

            <section className="col-lg-11 mx-auto">
                <div className="row mx-1 my-1">
                  <div className="col-lg-4 col-4">
                    <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                      Bank Acc. Name:
                    </p>
                  </div>
                  <div className="col-lg-6 col-6">
                    <small className="">{!!data?.fullName  ? data?.fullName:"Not Available"}</small>
                  </div>
                </div>
                <div className="row mx-1 my-1">
                  <div className="col-lg-4 col-4">
                    <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                      NIN Number:
                    </p>
                  </div>
                  <div className="col-lg-6 col-6">
                    <small className="">{!!data?.nin  ? data?.nin:"Not Available"}</small>
                  </div>
                </div>
                <div className="row mx-1 my-1">
                  <div className="col-lg-4 col-4">
                    <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                      BVN Number:
                    </p>
                  </div>
                  <div className="col-lg-6 col-6">
                    <small className="">{!!data?.bvn  ? data?.bvn:"Not Available"}</small>
                  </div>
                </div>
              </section>
            </main>
    </>
  )
}
