import { Button } from '@mui/material'
import React, { useState } from 'react'
import { EditIcon } from '../../../icons/icons'
import { useTheme } from '@mui/material/styles';
import prod from "../../../assets/prod.png"
import "./pharmacyList.css";
import ModalDialogBox from '../../ModalDialogBox/ModalDialogBox';
import ChangeDeliveryDetail from '../../Shop/Checkout/ChangeDeliveryDetail';
import ChangeAddress from './ChangeAddress';
function PharmacyList({pharmacy, setPharmacy, address, setAddress}) {
  const theme = useTheme();
  const [open, setOpen] = useState(false)
  const handleOpen=()=>{
    setOpen(true);
  }
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
        <main className='col-lg-7 col-md-10 col-sm-12 mx-auto'>
            <div>
              <label htmlFor="pharmacies">Select any of the listed Pharmacies near you to get your product</label>
            </div>
            <hr />
            <div>
              <div className="d-flex justify-content-between flex-wrap px-3">
                <p className='my-auto'><b>Delivery Address</b></p>
                <Button variant="outlined" className='border-secondary text-secondary' onClick={handleOpen} endIcon={<EditIcon />}>Change Address</Button>
              </div>
              <p><small className='text-muted'>{address ?? 'Not Available'}</small></p>
            </div>
            <hr />
            <section className='pharmacy_list mx-3'>
              {
                [1, 2, 3, 5].map((item, index)=>{
                  return(
                        <div className={`d-flex gap-3 my-3 cursor-pointer ${pharmacy===item? 'border border-secondary border-2 rounded-3': null}`} key={index} onClick={()=>setPharmacy(item)}>
                          <img src={prod} alt="" className='w-25'/>
                          <div className='my-auto'>
                            <h5>X and Y Pharmacy</h5>
                            <p><small>No 10 Access Life Street</small></p>
                          </div>
                        </div>
                  )
                })
              }
            </section>
        </main>
        <ModalDialogBox
        open={open}
        handleClose={handleClose}
        modalSize={false}
      >
          <ChangeAddress handleClose={handleClose} address={address} setAddress={setAddress}/>
       
      </ModalDialogBox>
    </>
  )
}

export default PharmacyList