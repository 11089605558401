import React, { useState, useEffect } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { Select, TextField, MenuItem } from "@mui/material";
import { Button } from "antd";
import { useGetElectricProvider } from "../../../redux/hooks/getHooks";
import { useFindElectricName } from "../../../redux/mutation/postMutation";
import { useUserData } from "../../../components/hooks/useUserData";
import { useDiscos } from "../../../components/hooks/useDiscos";

export default function Electricity({ formik, setNextAction, handleClick }) {
  const [isLoading, setIsLoading] = useState(false);
  const [activateNextBtn, setActivateNextBtn] = useState(false);

  const user = useUserData();

  // const electricProvider = useGetElectricProvider();
  const { electricProvider } = useDiscos();


  const findNameMutation = useFindElectricName();

  // const providers = electricProvider?.data?.data?.data;
  const providers = electricProvider;

  const handleSelectChange = (event) => {
    const selectedValue = event.target.value; // Get the value of the selected option
    
    formik.setFieldValue("distributionCompany", {disco: selectedValue});
  };

  const handleConfirmFn = () => {
    const payloadData = {
      "meter": formik?.values?.meterNumber, //"12345678910",
      "vendType":"PREPAID",
      "disco": formik?.values?.distributionCompany?.disco, //ABUJA,
      "vertical":"ELECTRICITY"
    };

    setIsLoading(true);
    findNameMutation?.mutate(payloadData, {
      onSuccess: (data) => {
        setIsLoading(false);
        const res = data?.data?.data;
     
        formik.setFieldValue("distributionCompany", {
          ...formik?.values?.distributionCompany,
          verifiedData: res,
        });

        // console.log({verifiedData: res})

        setNextAction("confirm_information");
      },
      onError: (err) => {
        setIsLoading(false);
        const errResponse = err?.response?.data?.message
        formik.setFieldError("meterNumber", errResponse);
        // handleClick(errResponse, "error")
      },
    });
  };

  useEffect(() => {
    if (
      formik?.values?.mobileNumber &&
      formik?.values?.distributionCompany &&
      formik?.values?.email &&
      formik?.values?.amount &&
      formik?.values?.meterNumber
    ) {
      setActivateNextBtn(true);
    } else {
      setActivateNextBtn(false);
    }
  }, [formik]);

  const onChangeMeterNumber=(e)=>{
    formik.setFieldValue("meterNumber", e.target.value)
  }

  useEffect(()=>{
    if(Number(formik.values.amount) < 1000){
      formik.setFieldError("amount", "Minimum amount is N1000")
    }

  }, [formik])

  const hasErrors = Object.keys(formik?.errors).length > 0;



  return (
    <>
      <section>
        <form
          className="col-lg-8 col-12 mx-auto"
          onSubmit={formik?.handleSubmit}
        >
          <AuthHeader title={"Buy Electricity"} />

          <div className="meterNumber" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="meterNumber">Meter Number</label>
            </div>
            <TextField
              type="number"
              id="standard-basic"
              name="meterNumber"
              required
              error={formik?.touched.meterNumber && formik?.errors.meterNumber}
              onChange={onChangeMeterNumber}
              onBlur={formik?.handleBlur}
              placeholder="Provide Meter number"
              fullWidth
              className="mainmeterNumber"
              value={formik?.values.meterNumber}
              variant="outlined"
              size="large"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik?.touched.meterNumber && formik?.errors.meterNumber}
            </small>
          </div>

          <div className="" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="meterNumber">Select VendType</label>
            </div>
            <Select
              name="vendType"
              placeholder="Select vendtype"
              value={formik?.values?.vendType ?? ""}
              error={
                formik?.touched.vendType &&
                formik?.errors.vendType
              }
              onChange={formik.handleChange}
              onBlur={formik?.handleBlur}
              fullWidth
              variant="outlined"
              sx={{ borderRadius: "30px" }}
              size="medium"
            >
           
                  <MenuItem value={"PREPAID"}>
                    PREPAID
                  </MenuItem>
                  <MenuItem value={"POSTPAID"}>
                   POSTPAID
                  </MenuItem>
           
            </Select>
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik?.touched.vendType &&
                formik?.errors.vendType}
            </small>
          </div>
          <div className="meterNumber" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="meterNumber">Select distribution company</label>
            </div>
            <Select
              name="distributionCompany"
              placeholder="Gender"
              value={formik?.values.distributionCompany?.disco ?? ""}
              error={
                formik?.touched.distributionCompany &&
                formik?.errors.distributionCompany
              }
              onChange={handleSelectChange}
              onBlur={formik?.handleBlur}
              fullWidth
              variant="outlined"
              sx={{ borderRadius: "30px" }}
              size="medium"
            >
              {providers?.map((item, index) => {
                return (
                  // <MenuItem key={index + item?.name} value={item?.name}>
                  //   {item?.name}
                  // </MenuItem>
                  <MenuItem key={index + item} value={item}>
                    {item} Electricity
                  </MenuItem>
                );
              })}
            </Select>
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik?.touched.distributionCompany &&
                formik?.errors.distributionCompany}
            </small>
          </div>
          <div className="amount" style={{ paddingBottom: "20px" }}>
            <div className="label" style={{ marginBottom: "4px" }}>
              <label htmlFor="amount">Amount</label>
            </div>
            <TextField
              type="number"
              id="standard-basic"
              name="amount"
              required
              error={formik?.touched.amount && formik?.errors.amount}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              placeholder="Enter amount e.g 5,000"
              fullWidth
              className="amount"
              value={formik?.values.amount}
              variant="outlined"
              min={"350"}
              size="large"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik?.touched.amount && formik?.errors.amount}
            </small>
          </div>

          {user?.status ? (
            ""
          ) : (
            <>
              <div className="Email col-12" style={{ paddingBottom: "20px" }}>
                <div className="label" style={{ marginBottom: "4px" }}>
                  <label htmlFor="Email">Email</label>
                </div>
                <TextField
                  type="email"
                  name="email"
                  required
                  error={formik?.touched.email && formik?.errors.email}
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  placeholder="Provide email"
                  fullWidth
                  value={formik?.values.email}
                  variant="outlined"
                  size="medium"
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      fontSize: "14px",
                    },
                  }}
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik?.touched.email && formik?.errors.email}
                </small>
              </div>
              <div
                className="mobileNumber col-12"
                style={{ paddingBottom: "20px" }}
              >
                <div className="label" style={{ marginBottom: "4px" }}>
                  <label htmlFor="mobileNumber">Mobile Number</label>
                </div>
                <TextField
                  type="text"
                  name="mobileNumber"
                  // required
                  error={
                    formik?.touched.mobileNumber && formik?.errors.mobileNumber
                  }
                  onChange={formik?.handleChange}
                  onBlur={formik?.handleBlur}
                  value={formik?.values.mobileNumber}
                  placeholder="Provide mobile number e.g 08012345678"
                  fullWidth
                  variant="outlined"
                  size="medium"
                  InputProps={{
                    style: {
                      borderRadius: "30px",
                      fontSize: "14px",
                    },
                  }}
                />
                <small style={{ fontSize: "13px" }} className="text-danger">
                  {formik?.touched.mobileNumber && formik?.errors.mobileNumber}
                </small>
              </div>
            </>
          )}

          <div className="submit_btn pb-2">
            <Button
              size="large"
              shape="round"
              loading={isLoading}
              disabled={!activateNextBtn || hasErrors}
              style={{
                backgroundColor: (!activateNextBtn || hasErrors)? "#f0f0f0": "#033F4B", // Change background color based on activation state
                color: activateNextBtn ? "white" : "#ccc", // Change text color based on activation state
                width: "100%",
                border: "none", // Remove border
                height: "55px",
              }}
              onClick={handleConfirmFn}
            >
              Continue
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
