import React from 'react'
import Header from './Header'
import { exportExcel } from '../../../../utils/exportExcel'
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { formatNumber } from '../../../../utils/formatNumber';

export default function FinancialStAndIncome({data, handleOpen, statementData, actionFn, actionLoading, actionSuccess}) {


   
    return (
        <>
            <main>
            <Header title={"Financial Statement and Income"} actionText={"Approve"} actionFn={(status)=>actionFn('bankStatement', status)} actionLoading={actionLoading} actionSuccess={actionSuccess}/>
            <section>
                {/* average_income */}
              <section className="col-lg-11 mx-auto">
                <div className="row mx-1 my-1">
                  <div className="col-4">
                    <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                    Average Income:
                    </p>
                  </div>
                  <div className="col-3">
                    <small className="">{data?.average_income ? formatNumber(data?.average_income/100) : "Not Available"}</small>
                  </div>
                  <div className="col-xs-12 col-sm-5 d-flex flex-sm-column gap-3">
                                  <small className={'text-danger cursor-pointer'} onClick={()=>handleOpen('statement_detail')}>View Bank Statement</small>
                                  <small className={'text-danger cursor-pointer'} onClick={()=>handleOpen('nin_detail')}>View NIN Details</small>

                              </div>
                </div>
              </section>
            {/* monthly_income */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Monthly Income:</p>
                              </div>
                              <div className="col-3">
                                  <small className="">{data?.monthly_income ? formatNumber(data?.monthly_income/100):"Not Available"}</small>
                              </div>
                              <div className="col-5">
                                  {/* <small className={'text-danger cursor-pointer'}
                                  onClick={()=>exportExcel({excelData: statementData ?? excelData, fileName: "statement_details"})}
                                  ><FileDownloadOutlinedIcon /> Download Bank Statement</small> */}
                                                                   
                              </div>
                  </div>
              </section>
            {/* Annual income */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Annual Income:</p>
                              </div>
                              <div className="col-lg-6 col-6">
                                  <small className="">{data?.yearly_income ? formatNumber(data?.yearly_income/100) : "Not Available"}</small>
                              </div>
                  </div>
              </section>
            {/* Income Source */}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Income Source:</p>
                              </div>
                              <div className="col-6">
                                  <small className="">{data?.income_sources??"Not Available"}</small>
                              </div>
                  </div>
              </section>
            {/* Bank*/}
            <section className='col-lg-11 mx-auto'>
                  <div className="row mx-1 my-1">
                              <div className="col-4">
                                  <p className="text-dark my-auto" style={{fontSize: "15px"}}>Bank:</p>
                              </div>
                              <div className="col-6">
                                  <small className="">{data?.bank??"Not Available"}</small>
                              </div>
                  </div>
              </section>
            </section>
          </main>
        </>
      )
}
