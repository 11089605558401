
export const logoutFn=(role)=>{
    if(role==='USER'){
        localStorage.removeItem("accessToken");
        localStorage.removeItem("billData");
        localStorage.removeItem("refreshToken");
    }
    
    else if(role==='ADMIN'){
        localStorage.removeItem("adminAccessToken");
        localStorage.removeItem("adminRefreshToken");
    }
    else if(role==='MERCHANT'){
        localStorage.removeItem("merchantAccessToken");
        localStorage.removeItem("merchantRefreshToken");
    }
    
}