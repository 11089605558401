import { Button, Table, TableBody, TableCell, TableRow } from '@mui/material';


const PayWithDirectDebit = ({handleClick, setPaymentMethod, details, handleCloseModal}) => {



  const handleContinue=()=>{
    window.location.href = details?.link;
  }


  return (
    <>
        <div className='col-lg- co'>
        <h5 className="text-center mb-3 tw-text-md tw-font-semibold">Pay With Direct Debit Method</h5>
        <Table className="border-0">

            <TableBody>
            <TableRow>
                <TableCell align="center" colSpan={5}>
                  <h5>{details?.description}</h5>
                  <p>{details?.display_text}</p>
                </TableCell>
              </TableRow>
              <TableRow className="border-0">
                <TableCell colSpan={5} align="center">
                  <Button
                    sx={{
                      backgroundColor: "#333332",
                      color: "#fff",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#333332",
                        color: "white",
                      },
                    }}
                    onClick={handleContinue}
                    
                  >
                    Continue
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      color: "#000000",
                      // "&:hover": {
                      //   backgroundColor: "#333332",
                      //   color: "white",
                      // },
                    }}
                    // variant="outlined"
                    onClick={()=>setPaymentMethod('anotherMethod')}
                  >
                    Choose another method
                  </Button>
                </TableCell>
              </TableRow>
            

            </TableBody>
          </Table>
        </div>
    </>
  )
}

export default PayWithDirectDebit