import { Button, TextField } from '@mui/material'
import React from 'react'

export default function OrderForm({formik, handleCloseOrderForm, allProduct}) {
    
  return (
    <>
        <section>
            <p>* Request phone number and token from the customer</p>
            <form onSubmit={formik.handleSubmit} className="row">
          <section className="col-lg-8 col-md-8 d-flex flex-column gap-3">
            <div className="product">
            <label htmlFor="amount" style={{color: "#033F4B"}}>What is the customer buying?</label>
              <TextField
                type="text"
                name="product"
                error={formik.touched.product && formik.errors.product}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.product}
                placeholder="Enter product customer wants to buy"
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px"
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.product && formik.errors.product}
              </small>
            </div>
            <div className="amount">
                <label htmlFor="amount" style={{color: "#033F4B"}}>How much?</label>
              <TextField
                type="number"
                name="amount"
                error={formik.touched.amount && formik.errors.amount}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.amount}
                placeholder="Enter product price e.g 1,000"
                fullWidth
                variant="outlined"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.amount && formik.errors.amount}
              </small>
            </div>
          </section>
          <div className="col-lg-4 col-md-4 d-flex flex-column gap-2 justify-content-center align-items-center">
            {
              allProduct?.length?
              <Button className="my-3" variant='outlined' sx={{color: "black"}} onClick={handleCloseOrderForm}>
                  Close
              </Button>:null
            }
              <Button type='submit' className="my-3" variant='outlined' sx={{color: "black"}}>
                  Add
              </Button>
          </div>
        </form>
        </section>
    </>
  )
}
