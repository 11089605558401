import React from 'react'
import AdminMerchantScreen from '../../screens/Admin/AdminMerchantScreen/AdminMerchantScreen'

export default function Merchant({handleClick}) {
  return (
    <React.Fragment>
        <AdminMerchantScreen handleClick={handleClick}/>
    </React.Fragment>
  )
}
