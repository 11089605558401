import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import TopToolBar from '../../../utils/Table/TopToolBar';
import Status from '../../../utils/Status';
import { Button, TablePagination } from '@mui/material';
import EnhancedTableHead from '../../../utils/Table/EnhancedTableHead';
import SampleDot from '../../../utils/SampleDot';
export default function MembershipTable({topHeaderVisible, rows, headCell, handleOpen}) {

    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const headCells = [
        {
          id: 'Name',
          label: 'Name',
        },
        {
          id: 'location',
          label: 'Location',
        },
        {
          id: 'phone',
          label: 'Phone',
        },
        {
          id: 'email',
          label: 'Email',
        },
        {
          id: 'status',
          label: 'Employment Status',
        },
        {
          id: 'actions',
          label: 'Actions',
        }
      ];
      
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rows?.map((n) => n.id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, user_id) => {
      const selectedIndex = selected?.indexOf(user_id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, user_id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected?.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(+event.target.value);
      setPage(0);
    };
  
    const isSelected = (agent) => selected?.indexOf(agent) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  
    return (
    <React.Fragment>
        
      <Box sx={{ width: '100%' }}>
        {
          rows?.length?
        <Paper sx={{ width: '100%', mb: 2 }}>
          {
            selected?.length ? (
              <TopToolBar numSelected={selected?.length} topHeaderVisible={topHeaderVisible}/>
            ): null
          }
          <TableContainer>
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rows?.length}
                headCell={headCells}
              />
              <TableBody>
                {
                rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                  const isItemSelected = isSelected(row?.id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row?.campaign)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      >
                      <TableCell padding="checkbox">
                        {/* <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row?.id)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        /> */}
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <div className='d-flex flex-column'>
                          <p className="fw-bold my-auto" style={{whiteSpace: "nowrap"}}>
                            {row?.first_name} {row?.last_name}
                            </p>
                            <small className="text-muted nowrap" style={{fontSize: "13px"}}>{new Date(row?.createdAt).toLocaleString()}</small>
                          </div>
                      </TableCell>
                      <TableCell ><p style={{width: "25vh"}}>{row?.contact?.address ?? "Not Available"}</p></TableCell>
                      <TableCell>{row?.phone}</TableCell>
                      <TableCell>{row?.email}</TableCell>
                      <TableCell>
                          <Status status="employed"/>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex gap-2">
                          <SampleDot color={row?.status?'green': row?.is_declined ? "red" : '#F8D86E'}/>
                          <Button variant='outlined' size='small' sx={{color: 'gray', border: "1px solid gray"}} onClick={()=>handleOpen('user', row)}>Detail</Button>
                        </div>
                      </TableCell>
                      
                    </TableRow>
                  );
                })
              }
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[10, 25, 40]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>:
         <main className="d-flex align-items-center justify-content-center flex-column" style={{height: "25vh"}}>
         <h3>There is no data yet</h3>
       </main>}
      </Box>
      </React.Fragment>
    );
  }
