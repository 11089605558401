import React from 'react'
import HomeScreen from '../screens/HomeScreen/HomeScreen'

function Home() {
  return (
   <>
        <HomeScreen />
   </>
  )
}

export default Home