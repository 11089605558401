import React from 'react'
import ForgotPsw from '../../../components/Auth/ForgotPsw/ForgotPsw'

function ForgotPswScreen({handleClick}) {
  return (
    <>
        <ForgotPsw handleClick={handleClick}/>
    </>
  )
}

export default ForgotPswScreen