import React from 'react'
import ForgotPswScreen from '../screens/AuthScreen/ForgotPswScreen/ForgotPswScreen'

function ForgotPsw({handleClick}) {
  return (
    <>
        <main>
            <ForgotPswScreen handleClick={handleClick}/>
        </main>
    </>
  )
}

export default ForgotPsw