import { Button } from "antd";
  import { useNavigate } from "react-router-dom";
import { useInitiateMandate } from "../../../redux/hooks/zustands/useInitiateMandate";
  
export default function MandateCreatedSuccessfull({handleClick}){


    const navigate = useNavigate();

    const {data: {schedules, formik}, closeDrawer} = useInitiateMandate()


    const baseUrl = `${window.location.protocol}//${window.location.host}`;
    console.log(baseUrl)

       //copying to clippoard
  const handleCopy = (val, index) => {
    navigator.clipboard
      .writeText(`${baseUrl}/user/e_mandate/${schedules?.order_id}`)
      .then(() => {
        // setCopied({ ...copied, status: true, index });
        handleClick("Link Copied to your clipboard: ", "success");
      })
      .catch((error) => {
        console.log(error)
        handleClick("Error copying to clipboard: ", "error", {
          vertical: "top",
          horizontal: "right",
        });
      });
  };

    const backHome=()=>{
      closeDrawer()
      // formik.setFieldValue("first_name", "")
      // formik.setFieldValue("last_name", "")
      // formik.setFieldValue("bvn", "")
      // formik.setFieldValue("phone", "")
      // formik.setFieldValue("email", "")
      navigate("/emandate")
    }


    return(
        <>
            <main className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-y-6 tw-min-h-[50vh]">
                <p className="tw-font-[400] tw-text-[15px]">New mandate request has been successfully created</p>
                <div className="tw-flex tw-flex-col tw-gap-y-3">
                <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF]"
                    onClick={()=>handleCopy()}
                 >
                    Copy and Send to Customer
                  </Button>
                  <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF]"
                    onClick={backHome}
                 >
                    Back to Homepage
                  </Button>
                </div>
            </main>
        </>
    )
}