import React from 'react'
import "./kycVerification.css";
import CustomButton from '../../../CustomButton/CustomButton';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { stepperAction } from '../../../../redux/slice/stepperSlice';
function KycVerification() {
  const navigate = useNavigate({});
  const dispatch = useDispatch({});
  const fn = ()=>{
    navigate("/kyc/complete-kyc");
    dispatch(stepperAction.changeInitiaState('started'))
  }
  return (
    <>
        <main className='verification_container '>
            <section className='py-4 contain col-sm-12 col-md-8 ontainer'>
                {/* <p ><span style={{cursor: "pointer", color: "#1D5D9B"}} onClick={()=>
                        {
                            navigate(-1)
                    }}>Back</span></p> */}
                {/* <article className='col-sm-12 col-md-8 mx-auto aticleCls'> */}
                    <p className="first_text pb-3" >KYC Verification</p>
                    <p className="Second_text">Kindly complete KYC verification to <br /> activate account and start shopping.</p>
                    <br />
                      <div className="col-sm-12 col-md-6 col-lg-5 mx-auto button_contain">
                       <CustomButton text={"Start"} functionFn={fn}/>
                      </div>
       
                {/* </article> */}
            </section>
        </main>
    </>
  )
}

export default KycVerification