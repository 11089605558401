import "./sidebar.css";
import { LeftArrowIcon, MenuIcon, RightArrowIcon } from "../../../icons/icons";
import useWindowSize from "../../hooks/useWindowSize";
import { Link } from "react-router-dom";
import GridViewRoundedIcon from '@mui/icons-material/GridViewRounded';

export default function EmandateSidebar({ visible, show, handleClick }) {
  const screenWidth = useWindowSize().width;

  return (
    <>
      <div className="emandate_mobile-nav py-3 d-flex justify-content-between tw-shadow-sm">
        <button className="mobile-nav-btn" onClick={() => show(!visible)}>
          <MenuIcon clicked={visible} />
        </button>
      </div>
      <nav className={`emandate_sidebar_nav ${visible ? "" : "sidebar"}`}>
        {
          screenWidth < 900 && (
          <button
            className={`nav_btn`}
            type="button"
            onClick={() => show(!visible)}
          >
            {visible ? (
              <LeftArrowIcon size={"5vh"} />
            ) : (
              <RightArrowIcon size={"5vh"} />
            )}
          </button>

          )
        }
        <div className="d-flex gap-3 align-items-center pb-3 ps-3">
          <div
            style={{
              height: "46px",
              width: "46px",
              borderRadius: "50%",
              backgroundColor: "#F8D86E",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              color: "#A51D21",
              fontWeight: 600,
              fontSize: "16px",
            }}
          >
            PP
          </div>
          <div className="d-flex flex-column gap-1">
            <p className="fw-bold my-auto" style={{ fontSize: "16px" }}>
              FI
            </p>
            <p className="fw-medium my-auto" style={{ fontSize: "12px" }}>
              ID: 32947420
            </p>
          </div>
        </div>
        <hr />
        <div className="d-flex flex-column mt-3 mb-3">
        <Link to={""} className={`p-3 rounded text-decoration-none text-dark d-flex align-items-center gap-2 `}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "16px",
                                        backgroundColor: "#FDF5DA",
                                        width: "100%",
                                        fontWeight: 500
                                      }}
                                    >
                                        <GridViewRoundedIcon sx={{color: "#8F490B"}}/>
                                        Dashboard</Link>
        </div>
        <hr />
        <div className="d-flex flex-column mb-3">
        <Link to={"/emandate/loan"} className={`py-2 px-5 rounded text-decoration-none text-dark d-flex align-items-center gap-2 `}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "16px",
                                        width: "100%",
                                        fontWeight: 500
                                      }}
                                    >
                                        Loan</Link>
        <Link to={""} className={`py-2 px-5 rounded text-decoration-none text-dark d-flex align-items-center gap-2 `}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "16px",
                                        width: "100%",
                                        fontWeight: 500
                                      }}
                                    >
                                        Customers</Link>
        <Link to={""} className={`py-2 px-5 rounded text-decoration-none text-dark d-flex align-items-center gap-2 `}
                                    style={{
                                        whiteSpace: "nowrap",
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        fontSize: "16px",
                                        width: "100%",
                                        fontWeight: 500
                                      }}
                                    >
                                        Fulfillment</Link>
        </div>
        {/* <MiddleMenu fn={() => {
                    window.innerWidth < 768 && show(!visible);
                  }}/>
              <BottomMenu fn={() => {
                    window.innerWidth < 768 && show(!visible);
                  }} handleClick={handleClick}/> */}

        
      </nav>
    </>
  );
}
