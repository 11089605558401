import React from 'react'
import logo from "../../../assets/email_template_logo.png"
import Header from './Header'
import WelcomeCard from './WelcomeCard'
import HelpCard from './HelpCard'
import DownwardCard from './DownwardCard'
function BrandWelcomeTemplate() {
  return (
   <>
        <main>
            <Header />
            <WelcomeCard />
            <HelpCard />
            <DownwardCard />
        </main>
   </>
  )
}

export default BrandWelcomeTemplate