import React, { useState } from 'react'
import CustomButton from '../../CustomButton/CustomButton';
import { TextField } from '@mui/material';
import { useFormik } from 'formik';
import { useUserData } from '../../hooks/useUserData';
import { useDispatch } from 'react-redux';
import { productAction } from '../../../redux/slice/productSlice';

export default function ChangeDeliveryDetail({currentLocation, handleClose, handleClick}) {
  const [isLoading, setIsLoading] = useState(null);
  const { data } = useUserData();
  const sessionKey = data?.id+"_product";
  const dispatch = useDispatch({});
    const formik = useFormik({
      initialValues: {
        deliveryLocation: currentLocation??"",
      },
      enableReinitialize: true,
      onSubmit:(values)=>{
        setIsLoading(true);
        setTimeout(() => {
          dispatch(productAction?.changeDeliveryAddress({sessionKey, deliveryLocation: values?.deliveryLocation}))
          handleClick("Delivery location changed successfully", "success", {vertical: 'top', horizontal: 'right'});
          handleClose();
          setIsLoading(false)
        }, 2000);
      }

    })
  return (
    <>
        <section className="" >
            <h5>Change Delivery Detail</h5>
           <form onSubmit={formik.handleSubmit}>
           <div className="address my-2">
                <label htmlFor="delivery_address">Delivery Address</label>
                <TextField
                type="text"
                id="delivery_address"
                name="deliveryLocation"
                onChange={formik?.handleChange}
                onBlur={formik?.handleBlur}
                value={formik?.values.deliveryLocation}
                placeholder="Enter delivery Location"
                fullWidth
                className="rounded-pill"
                size="small"
                InputProps={{
                  style: {
                    borderRadius: "20px",
                  },
                }}
              />
            </div>
                <div className="">
                  <CustomButton
                    text={"Change"}
                    loading={isLoading}
                  />
                </div>

            </form>
                
        </section>
    </>
  )
}
