import React from 'react'

function PaymentDetail() {
  return (
    <>
        <section className='col-lg-10 col-sm-12 mx-auto '>
            <br />
            <div className="row mx-2">
                <article className="col-lg-4 col-md-6 d-flex flex-column my-2">
                    <h3 className='fw-bold'>Shipping</h3>
                    <small className='text-muted'>Smiles Davis</small>
                    <span className='text-muted'>600 Montgomery St</span>
                    <span className='text-muted'>San Francisco, CA 94111</span>
                </article>
                <article className="col-lg-4 col-md-6 d-flex flex-column gap-4 my-2">
                <h3 className='fw-bold'>Payment</h3>
                    <span className='text-muted'>Account Credit ••••</span>
                </article>
            </div>
            <br />
        </section>
    </>
  )
}

export default PaymentDetail