import React from 'react'
import HeaderSection from '../../components/LandingPage/HeaderSection/HeaderSection'
import ShopNowSection from '../../components/LandingPage/ShopNowSection/ShopNowSection'
import HowItWork from '../../components/LandingPage/HowItWorks/HowItWork'
import AboutVouch from '../../components/LandingPage/AboutVouch/AboutVouch'
import Footer from '../../components/LandingPage/Footer/Footer'

function HomeScreen() {
  return (
    <>
        <main>
          <HeaderSection titleText={"Introducing a Zero - Interest Credit Community"}/>
            <ShopNowSection />
            <HowItWork />
            <AboutVouch />
            <Footer />
        </main>
    </>
  )
}

export default HomeScreen