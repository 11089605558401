import React from 'react'
import HeaderSection from './HeaderSection'
import ContentBlock from './ContentBlock'
import { useDispatch } from 'react-redux'
import { stepperAction } from '../../../../../redux/slice/stepperSlice';

function PersonalInfo({formik, editFn}) {
  
  return (
    <>
        <main>
            <HeaderSection title={"Bio Data"} editFn={editFn}/>
            <section>
                <ContentBlock _key={"Gender"} _value={formik.values.gender ?formik.values.gender:"---"}/>
                <ContentBlock _key={"Marital Status"} _value={formik.values.maritalStatus ?formik.values.maritalStatus:"---"}/>
                <ContentBlock _key={"Date of Birth"} _value={formik.values.dateOfBirth ?formik.values.dateOfBirth:"---"}/>
                <ContentBlock _key={"NIN"} _value={formik.values.nin ?formik.values.nin:"---"}/>
            </section>
        </main>
    </>
  )
}
export default PersonalInfo