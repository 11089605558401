import { Button } from "antd";
  import { useNavigate } from "react-router-dom";
  import { useLoanDrawer } from "../../../redux/hooks/zustands/useLoanDrawer";
  
export default function SuccessfullDebit(){

  const {data: {amountDue}, closeDrawer} = useLoanDrawer();

    const navigate = useNavigate();

    const backHome=()=>{
      closeDrawer();
      navigate("/emandate")
    }


    return(
        <>
            <main className="tw-flex tw-justify-center tw-items-center tw-flex-col tw-gap-y-6 tw-min-h-[50vh]">
                <p className="tw-font-[300] tw-text-[15px]">
                {/* <span className="tw-font-[600]">{amountDue}</span> has been successfully debited for June repayment */}
                Payment of <span className="tw-font-[600]">{amountDue}</span> has been successfully Confirmed
                </p>
                <div className="tw-flex tw-flex-col tw-gap-y-3">
              
                  <Button
                    size="large"
                    shape={"round"}
                    loading={false}
                    className="tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] tw-text-[18px] tw-h-[55px] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF]"
                    onClick={backHome}
                  >
                    Back to Homepage
                  </Button>
                </div>
            </main>
        </>
    )
}