import React from 'react'
import styled from 'styled-components'
import shopping_trolley from "../../../assets/shopping-trolley.png"
function HeaderCard() {
  return (
    <>
        <Section>
            <h2>Order <br /> Confirmed!</h2>
        </Section>
    </>
  )
}

export default HeaderCard
const Section = styled.section`
    background-image: url(${shopping_trolley});
    background-size: cover;
    background-position: center;
    height: 50vh;
    width: 100%;
    background-repeat: no-repeat;
    h2{
        color: white;
        font-size: 10vh;
        font-weight: bold;
        padding: 4vh 5vh;
    }
    
`