import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { SearchIcon } from '../icons/icons'

function SearchBar({ handleFilter }) {

  return (
    <React.Fragment>
        <form className="search_input d-flex rounded-pill px-2 border col-lg-4 col-md-5 col-sm-12 my-1">
          <input
            type="text"
            name="search"
            onChange={(e)=>handleFilter(e.target.value)}
            id="search"
            placeholder="Search"
            className="form-control border-0 py-2"
          />
          <p className="my-auto d-flex align-items-center justify-content-center" style={{ cursor: "pointer", height: "5vh", width: "6vh", borderRadius: '50%', color: "#ffff", backgroundColor: "#033F4B" }}>
            <SearchIcon color={"white"}/>
          </p>
        </form>
    </React.Fragment>
  )
}


export default SearchBar
