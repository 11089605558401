import { Input, Select } from "antd";
import LoanHistoryTable from "./LoanHistoryTable";
import LoanWithDrawers from "./LoanWithDrawers";
import { useLoanDrawer } from "../../../redux/hooks/zustands/useLoanDrawer";
import { useGetMandates } from "../../../redux/hooks/getHooks";
const { Search } = Input;


const response = {
  "status": true,
  "message": "Mandate fetched",
  "data": [
      {
          "customer_data": {},
          "id": "66398bf0-4575-4791-aa60-a85979d6bda0",
          "order_id": "JBOM366107",
          "first_name": "Taiwo",
          "last_name": "Enoch",
          "bvn": "2233485766",
          "phone": "08102637956",
          "email": "enochtaiwotimothy@gmail.com",
          "address": "Surulere Lagos",
          "mono_customer_id": null,
          "total_amount": "500000",
          "tenor": "6months",
          "stage": 1,
          "total_interest": "500000",
          "start_date": "02/07/2024",
          "end_date": "13/07/2024",
          "status": "inactive",
          "createdAt": "2024-07-04T20:44:31.000Z",
          "updatedAt": "2024-07-04T20:44:31.000Z",
          "schedulesCount": 12,
          "balance": "72000.00"
      },
      {
          "customer_data": {},
          "id": "91170ff6-e29c-4677-9a56-2d639731fcb0",
          "order_id": "WGEY462148",
          "first_name": "Adeniran",
          "last_name": "Akinboyewa",
          "bvn": "22375918562",
          "phone": "08102637956",
          "email": "adeakinboyewa@gmail.com",
          "address": "Surulere Lagos",
          "mono_customer_id": "66817e6e9eba938ac8b7e1ff",
          "total_amount": "500000",
          "tenor": "6months",
          "stage": 1,
          "total_interest": "500000",
          "start_date": "2024/08/02",
          "end_date": "2025/06/02",
          "status": "inactive",
          "createdAt": "2024-07-04T16:56:06.000Z",
          "updatedAt": "2024-07-04T16:56:06.000Z",
          "schedulesCount": 12,
          "balance": "72000.00"
      },
      {
          "customer_data": {},
          "id": "7f81c686-9701-4641-a1f1-b0dad0392c79",
          "order_id": "MFIH844485",
          "first_name": "Adeniran",
          "last_name": "Akinboyewa",
          "bvn": "22375918562",
          "phone": "08102637956",
          "email": "adeakinboyewa@gmail.com",
          "address": "Surulere Lagos",
          "mono_customer_id": "66817e6e9eba938ac8b7e1ff",
          "total_amount": "500000",
          "tenor": "6months",
          "stage": 1,
          "total_interest": "500000",
          "start_date": "2024/08/02",
          "end_date": "2025/06/02",
          "status": "inactive",
          "createdAt": "2024-07-04T16:55:12.000Z",
          "updatedAt": "2024-07-04T16:55:12.000Z",
          "schedulesCount": 11,
          "balance": "66000.00"
      },
      {
          "customer_data": {},
          "id": "7d8ab658-ada9-4396-914f-45e77e8e4a88",
          "order_id": "GHHE955188",
          "first_name": "Adeniran",
          "last_name": "Akinboyewa",
          "bvn": "22375918562",
          "phone": "08102637956",
          "email": "adeakinboyewa@gmail.com",
          "address": "Surulere Lagos",
          "mono_customer_id": "66817e6e9eba938ac8b7e1ff",
          "total_amount": "500000",
          "tenor": "6months",
          "stage": 1,
          "total_interest": "500000",
          "start_date": "2024/08/02",
          "end_date": "2025/06/02",
          "status": "inactive",
          "createdAt": "2024-07-04T16:46:02.000Z",
          "updatedAt": "2024-07-04T16:46:02.000Z",
          "schedulesCount": 12,
          "balance": "72000.00"
      },
      {
          "customer_data": {},
          "id": "cbb38663-13c6-4d2c-900e-de010ca62ae2",
          "order_id": "CGUH530887",
          "first_name": "Adeniran",
          "last_name": "Akinboyewa",
          "bvn": "22375918562",
          "phone": "08102637956",
          "email": "adeakinboyewa@gmail.com",
          "address": "Surulere Lagos",
          "mono_customer_id": "66817e6e9eba938ac8b7e1ff",
          "total_amount": "500000",
          "tenor": "6months",
          "stage": 1,
          "total_interest": "500000",
          "start_date": "2024/08/02",
          "end_date": "2025/06/02",
          "status": "inactive",
          "createdAt": "2024-07-04T15:12:14.000Z",
          "updatedAt": "2024-07-04T15:12:14.000Z",
          "schedulesCount": 12,
          "balance": "72000.00"
      }
  ]
}

export default function EmandateHistory() {
  const onSearch = (value, _e, info) => console.log(info?.source, value);



  const {openDrawer} = useLoanDrawer();

  const handleChange = (value) => {
    console.log(`selected ${value}`);
  };


  const {data: data} = useGetMandates();
  const response = data?.data
  console.log(response);


const handleOpenDrawer=(data)=>{
    openDrawer('LOAN_DETAIL', {mandate: data})
  }



  return (
    <>
      <main className="tw-mt-3">
        <h3 className="tw-font-[500] tw-text-[18px] tw-my-2 tw-text-black">Mandate History</h3>
        <div className="tw-flex tw-justify-between tw-mb-3">
          <div>
            <Search
              placeholder="Search Name"
              onSearch={onSearch}
              style={{
                width: 200,
              }}
            />
          </div>
          <div className="tw-flex tw-gap-x-2">
            <Select
              defaultValue="last 7 days"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "last 14 days",
                  label: "last 14 days",
                },
                {
                  value: "last 21 days",
                  label: "last 21 days",
                },
                {
                  value: "last 28 days",
                  label: "last 28 days",
                },
              ]}
            />
            <Select
              defaultValue="Feb 14, 2024 Feb 24, 2024"
              style={{
                width: 120,
              }}
              onChange={handleChange}
              options={[
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
                {
                  value: "Feb 14, 2024 Feb 24, 2024",
                  label: "Feb 14, 2024 Feb 24, 2024",
                },
              ]}
            />
          </div>
        </div>


        <LoanHistoryTable rows={response?.data} handleOpen={handleOpenDrawer}/>


        
      </main>



      <LoanWithDrawers />

    </>
  );
}
