import React from 'react'
import { NairaIcon } from '../../../icons/icons'

function PhysicalStore({address, pharmacy}) {
  return (
    <>
        <main className='col-lg-7 col-md-10 col-sm-12 mx-auto'>
            <section className='mx-2'>
                <div className='text-center'>
                    <label htmlFor="pharmacies" className='fw-bold'>Physical Stores</label>
                </div>
                <hr />
                <div>
                    <p className="fw-bold fs-6">X and Y Pharmacy</p>
                    <p><span className="text-muted">{address}</span></p>
                </div>
                <hr />
                <div>
                    <p className="fw-bold fs-6">Maximum Credit Accessible</p>
                    <p><NairaIcon /><span className="text-muted fs-5">9,000</span></p>
                </div>
                <hr />
                <div>
                    <p className="fw-bold text-center">Send transaction ID to your phone</p>
                </div>
            </section>
        </main>
    </>
  )
}

export default PhysicalStore