import { TextField } from "@mui/material";
import { Button } from "antd";
import { useGetScheduleTransactions } from "../../../redux/hooks/getHooks";
import moment from "moment";
import { formatNumber } from "../../../utils/formatNumber";
import { useEffect, useState } from "react";
import { useDebitForSchedule } from "../../../redux/mutation/AdminPostMutation";

export default function EmandateView({data, handleClick}) {

  const {data:transactions} = useGetScheduleTransactions(data?.id)

  const mutation = useDebitForSchedule();

  const [isLoading, setIsLoading] = useState(false);
  

  const [amount, setAmount] = useState("");

  const [disableBtn, setDisableBtn] = useState(false);

  
  const allTransactions = transactions?.data?.data




  useEffect(()=>{
    if(!amount){
      setDisableBtn(true)
    }
    else if(amount > (Number(data?.amount)-Number(data?.amount_paid))){
      setDisableBtn(true)
    }
    else{
      setDisableBtn(false)
    }

  }, [setAmount, amount, data?.amount, data?.amount_paid])



  const debitAccount=()=>{
    mutation.mutate({
      //payload here
      schedule_id: data?.id,
      amount: amount
    })
  }


  useEffect(()=>{
    if(mutation.isLoading){
      setIsLoading(true)
    }
    else if(mutation.isError){
      setIsLoading(false);
      let msg = mutation?.error?.response?.data?.message;
      console.log(msg)
      let msg2 = mutation?.error?.response?.statusText;
      let msg3 = mutation?.error?.message;
      // console.log(marketPlaceMutation?.error);
      handleClick(msg ?? msg2 ?? msg3, "error");
    }
    else if(mutation.isSuccess){
      setIsLoading(false);

      let msg = mutation?.data?.data?.message;

      setAmount("")

      handleClick(msg, "success");
    }
  }, [mutation.status])

  return (
    <>
      <div>
        <h3
          className="text-center"
          style={{ fontWeight: 500, fontSize: "25px", color: "#323C4D" }}
        >
          {data?.order?.user?.first_name} {data?.order?.user?.last_name}
        </h3>
        <p className="text-center fs-5">Repayment</p>
        <div className="d-flex justify-content-between">
          <div>
            <p
              className="fw-bold"
              style={{
                fontWeight: 500,
                lineHeight: "11.77px",
                color: "#000000",
              }}
            >
              Mandate
            </p>
            <p
              className="fw-bold tw-my-2"
              style={{
                fontWeight: 500,
                lineHeight: "11.77px",
                color: "#000000",
              }}
            >
              Start Date:{" "}
              <span style={{ fontWeight: 400 }}>{moment(data?.start_date).format("D MMMM, YYYY")}</span>
            </p>
            <p
              className=""
              style={{
                fontWeight: 500,
                lineHeight: "11.77px",
                color: "#000000",
              }}
            >
              End Date: <span style={{ fontWeight: 400 }}>{moment(data?.end_date).format("D MMMM, YYYY")}</span>
            </p>
          </div>
          <div>
            <p
              className=""
              style={{
                fontWeight: 400,
                fontSize: "15px",
                textAlign: "right",
                lineHeight: "25.92px",
                color: "#000000",
              }}
            >
              Reference: {data?.orderId}
            </p>
          </div>
        </div>
        <div className="d-flex justify-content-between">
          <div>
            <p
              className="my-2"
              style={{
                fontWeight: 400,
                fontSize: "19px",
                textAlign: "right",
                lineHeight: "28.5px",
                color: "#000000",
              }}
            >
              Amount paid: {formatNumber(data?.amount_paid)}
            </p>
          </div>
          <div>
            <p
              className=""
              style={{
                fontWeight: 400,
                fontSize: "19px",
                textAlign: "right",
                lineHeight: "28.5px",
                color: "#000000",
              }}
            >
              Amount remaining: {formatNumber(data?.amount-data?.amount_paid)}  
            </p>
          </div>
        </div>
        <div className="mt-2 mb-4 d-flex flex-column gap-3 ">
          {
            data?.order?.products?.map((item, index)=>{
              return(
          <div
          key={index}
            className="d-flex flex-column align-items-center justify-content-center p-4"
            style={{
              backgroundColor: "#F5F1F1",
              border: "1px 1px 0px 1px #F5F1F1",
            }}
          >
            
            {/* <p
              className=""
              style={{
                fontWeight: 400,
                fontSize: "22px",
                lineHeight: "33px",
                color: "#000000",
              }}
            >
              Item: Pair of Shoes
            </p> */}
            <p
              className=""
              style={{
                fontWeight: 400,
                fontSize: "22px",
                lineHeight: "33px",
                color: "#000000",
              }}
            >
              Item: {item?.name}
            </p>
            {
              item?.url && (
            <a
              href={item?.url}
              target="_blank"
              rel="noreferrer"
              className="text-decoration-none"
              style={{
                color: "#8E1212",
                fontWeight: 400,
                fontSize: "18px",
                lineHeight: "33px",
              }}
            >
              View Link
            </a>
              )
            }
          </div>
              )
            })
          }
          <div
            className="d-flex flex-column gap-3 align-items-center justify-content-center p-4"
            style={{
              backgroundColor: "#F5F1F1",
              border: "1px 1px 0px 1px #F5F1F1",
            }}
          >
            <div>
              <p
                className="text-center"
                style={{
                  fontWeight: 400,
                  fontSize: "22px",
                  lineHeight: "33px",
                  color: "#000000",
                }}
              >
                E-mandate
              </p>

              {/* <div className="d-flex gap-4">
                <Button
                  shape="round"
                  size="large"
                  style={{
                    background: "#000000",
                    color: "#fff",
                    fontSize: "18px",
                    height: "49px",
                  }}
                >
                  Check Balance
                </Button>
                <p className="bg-white p-3 rounded-2">{formatNumber(data?.amount - data?.amount_paid)}</p>
              </div> */}
            </div>
            <TextField
              type="number"
              id="standard-basic"
              name="amount"
              value={amount}
              placeholder="Enter amount"
              className="amount"
              variant="outlined"
              size="large"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
              onChange={setAmount}
            />
            <Button
              shape="round"
              size="large"
              style={{
                backgroundColor: "#124B0D",
                color: "#fff",
                fontSize: "18px",
                height: "49px",
              }}
              disabled={disableBtn}
              loading={isLoading}
              onClick={debitAccount}
            >
              Debit
            </Button>
          </div>
        </div>
        {/* <div className="text-center mt-4">
          <Button
            shape="round"
            size="large"
            style={{
              background: "#BD0C0C",
              color: "#fff",
              fontWeight: 500,
              fontSize: "18px",
              height: "49px",
            }}
          >
            Check Balance
          </Button>
        </div> */}
      </div>
    </>
  );
}
