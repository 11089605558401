import { Button } from '@mui/material'
import React from 'react'
import { formatNumber } from '../../../../utils/formatNumber'
import { CancelIcon } from '../../../../icons/icons'

export default function ProductItemList({handleAddAnotherItem, products, removeItem}) {
  return (
    <>
        <section className='my-3'>
            <p>* Request phone number and token from the customer <span className='text-white=' style={{color: 'transparent'}}>Fulfil the following order to {`John Doe`} Fulfil the following order to {`John Doe`}</span></p>
            <section className="table-responsive-md ">
            <table className='table'>
                <thead>
                    <tr>
                        <th>Product</th>
                        <th>Amount</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {
                        products?.map((product, index)=>{
                            return(
                                <tr key={index}>
                                    <td className="my-auto">{product?.product}</td>
                                    <td className="my-auto">{ formatNumber(product?.amount) }</td>
                                    <td>
                                        <Button startIcon={<CancelIcon />} sx={{color: "black", textTransform: 'capitalize'}} onClick={()=>removeItem(index, product?.amount)}>Remove</Button>
                                    </td>
                                </tr>
                            )
                        })
                    }
                </tbody>
            </table>
            </section>
           <Button sx={{color: "black", textTransform: 'capitalize', fontWeight: 'bold'}} onClick={handleAddAnotherItem}>
                + Add Another Item
           </Button>
        </section>
    </>
  )
}
