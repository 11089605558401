import React from 'react'

function HeaderSection({title, editFn}) {
  return (
    <>
        <section className='col-lg-11 mx-auto mt-3'>
            <div className="d-flex justify-content-between">
                <p className='fs-6 fw-bold my-auto'>{title}</p>
                <span className='my-auto' style={{color: "#8C0C0C", cursor: "pointer"}} onClick={editFn}>Edit</span>
            </div>
        </section>
        <hr />
    </>
  )
}

export default HeaderSection