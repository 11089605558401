import { Button, CircularProgress } from "@mui/material";
import React, { useEffect, useState } from "react";

import { useResponsiveSize } from "../hooks/useResponsiveSize.js";
import TransactionHistory from "./Community/TransactionHistory.jsx";
import DrawerBox from "../../utils/Drawer/DrawerBox";
import { Modal } from "antd";
import { useUpgradePlan } from "../../redux/mutation/AdminPostMutation.js";
import StatementDetails from "./KYC/ProfileDetail/StatementDetails.jsx";
import { useGetBankStatement } from "../../redux/hooks/getHooks.js";

export default function UpgradeRequestOptions({
  request,
  role,
  handleClick,
  handleCloseOptionModal,
}) {
  const upgrade_mutation = useUpgradePlan(request?.id);

  const getUserBkStatement = useGetBankStatement(request?.userId);
  const statementDetailsRes = getUserBkStatement?.data?.data?.data;
  const statements = statementDetailsRes?.statement?.statement
  const identities = statementDetailsRes?.identities

  const [open, setOpen] = useState({ status: false, role: null });
  const [openDrawer, setOpenDrawer] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [comment, setComment] = useState("");

  const responsiveSize = useResponsiveSize({});

  const handleOpen = (val) => {
    val === "view_bank_statement"
      ? handleOpenDrawer()
      : setOpen({ ...open, status: true, val });
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const approve_reject_fn = (action) => {
    setIsLoading(true);
    const data = { comment, status: action==='approve'? "approved": action==='reject' &&'rejected' };
    upgrade_mutation.mutate(data);
  };



  useEffect(()=> {
    if(upgrade_mutation?.isLoading){
        setIsLoading(true);
    }
    else if(upgrade_mutation?.isSuccess){
        setIsLoading(false);
        // const res = upgrade_mutation?.data?.data?.data;

        handleClose()
        handleCloseOptionModal();
    }
    else if(upgrade_mutation?.isError){
        setIsLoading(false);

        const res = upgrade_mutation?.error?.response?.data

        handleClick(res?.message, 'error')
    }

  }, [upgrade_mutation?.status])





  return (
    <React.Fragment>
      <section className="text-center">
        <h5>
          {request?.user?.first_name} {request?.user?.last_name}
        </h5>
        <p>
          Current Subscription:{" "}
          <span className="fw-semibold">
            {request?.user?.subscriptions?.length
              ? request?.user?.subscriptions[0]?.plan?.name
              : "No Subscription"}
          </span>
        </p>
        <p>What do you want to do?</p>
        <section className="d-flex flex-column gap-3">
        <Button
            variant="contained"
            fullWidth
            onClick={() => handleOpen("view_bank_statement")}
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "white",
              },
            }}
          >
            View Bank Statement
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleOpen("approve")}
            disabled={request?.status!=='pending'}
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "white",
              },
            }}
          >
            Approve Request
          </Button>
          <Button
            variant="contained"
            fullWidth
            onClick={() => handleOpen("reject")}
            disabled={request?.status!=='pending'}
            sx={{
              backgroundColor: "#033F4B",
              color: "white",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#033F4B",
                color: "white",
              },
            }}
          >
            Reject Request
          </Button>
          
        </section>
      </section>

      <Modal centered open={open?.status} onCancel={handleClose} footer={null}>
        {
          <>
            <h5 className="text-center">
              {open?.val === "approve" ? "Approve" : "Reject"} Upgrade Request
            </h5>

            <div className="mt-4">
              <label htmlFor="">Provide the comment</label>
              <textarea
                name=""
                id=""
                cols="40"
                rows="10"
                className="form-control"
                placeholder="Provide the comment here"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="mt-2 d-flex justify-content-end">
              <Button
                className=""
                variant="contained"
                disabled={isLoading}
                sx={
                  isLoading
                    ? { cursor: "not-allowed" }
                    : {
                        backgroundColor: "#033F4B",
                        color: "white",
                        "&:hover": {
                          backgroundColor: "#033F4B",
                        },
                      }
                }
                onClick={()=>approve_reject_fn(open?.val)}
              >
                {open?.val === "approve" ? "Approve" : "Reject"}
                {isLoading && (
                  <CircularProgress
                    size={26}
                    sx={{
                      position: "absolute",
                      // top: '45%',
                      left: "50%",
                    }}
                  />
                )}
              </Button>
            </div>
          </>
        }
      </Modal>

      <DrawerBox
        open={openDrawer}
        handleClose={handleCloseDrawer}
        size={responsiveSize === "lg" ? 850 : responsiveSize === "md" && 1000}
      >
        <StatementDetails rows={statements} ownerName={identities?.fullName} />
      </DrawerBox>
    </React.Fragment>
  );
}
