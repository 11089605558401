import { useState, useEffect, useMemo } from "react";
import CreditSummary from "../../../components/Admin/Community/CreditSummary";
import { useGetAllCredit } from "../../../redux/hooks/getHooks";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import OrderHeader from "./OrderHeader";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import OrderTable from "../../../components/Admin/Community/OrderTable";

import SearchBar from "../../../utils/SearchBar";


const filterData = [
    { value: "all", label: "All" },
    { value: "paid", label: "Paid" },
    { value: "unpaid", label: "UnPaid" },
    { value: "fulfilled", label: "Fulfilled" },
    { value: "unfulfilled", label: "Unfulfilled" }
]


export default function TransactionScreen({ handleClick }) {
  const getOrders = useGetAllCredit(1, "all");
  const order_page_2 = useGetAllCredit(2, "all");
  const order_page_3 = useGetAllCredit(3, "all");
  const order_page_4 = useGetAllCredit(4, "all");


  // const orders =  getOrders?.data?.data?.data?.length ? getOrders?.data?.data?.data?.filter((item) => item?.status==="order in progress" || item?.status==="order delivered" || item?.status==="approved"): [];

  const totalOrders = getOrders?.data?.data?.data?.concat(order_page_2?.data?.data?.data)?.concat(order_page_3)?.concat(order_page_4)


  const orders = useMemo(()=>{
    return totalOrders?.length ? totalOrders?.filter((item) => item?.status==="order in progress" || item?.status==="order delivered" || item?.status==="repaid"): []
  }, [totalOrders])

  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState(false);
  const [filteredData, setFilteredData] = useState()


  const [filterValue, setFilterValue] = useState("");


  useEffect(()=>{
        setFilteredData(orders)
  }, [orders])

  const handleChange = (event) => {
    const val = event.target.value;
    setFilterValue(event.target.value);

    let filteredOrder = orders?.filter((item)=>{
        const paid = item?.repaymentStatus === true;
        const unpaid = item?.repaymentStatus === false;
        const fulfilled = item?.status === 'order delivered';
        const unfulfilled = item?.status === 'pending';
        if(val==='all'){
            return orders;
        }
        if(val==='paid'){
            return paid;
        }
        else if(val==='unpaid'){
            return unpaid;
        }
        else if(val==='fulfilled'){
            return fulfilled;
        }
        else if(val==='unfulfilled'){
            return unfulfilled;
        }
        else return null;

    })

    setFilteredData(filteredOrder);
  };


  //drawer functions==============================
  const handleOpen = (_, data) => {
    setDataDetail(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  //===================================

  //


  const handleFilter=(val)=>{

    let updatedData = [];

    if (val.length) {
      updatedData = orders?.filter((item) => {
        const startsWith = (item?.user?.first_name +" "+item?.user?.last_name)
          ?.toLowerCase()
          .startsWith(val.toLowerCase());

        const includes = (item?.user?.first_name +" "+ item?.user?.last_name)
          ?.toLowerCase()
          .includes(val.toLowerCase());
        const includesOrderId = (item?.id)
          ?.toLowerCase()
          .includes(val.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else if(includesOrderId){
            return includesOrderId;
        }
         else return null;
      });

      setFilteredData(updatedData);
    }
    else{
        setFilteredData(orders);
        setFilterValue("all")
    }
}


  return (
    <>
      <main>
      <div className="px-lg-5 d-flex justify-content-between flex-wrap">
      <div className="d-flex justify-content-between ">
                    {/* <p className=''>Transaction</p> */}
                    {/* <p className=''>Quest Transaction</p> */}

              </div>
          {/* <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Filter
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
            
              value={filterValue}
              onChange={handleChange}
            >
                {
                    filterData?.map((item, index)=>(
                        <FormControlLabel key={index+"__filter_by"} value={item?.value} control={<Radio size="small"/>} label={item?.label} />
                    ))
                }
            </RadioGroup>
          </FormControl> */}
        </div>
        <SearchBar handleFilter={handleFilter} />
        <OrderHeader role={"Transaction"}/>
        {getOrders.isLoading ? (
          <IsLoadingLoader />
        ) : getOrders.isError ? (
          <IsErrorLoader />
        ) : (
          filteredData?.length?(
            <OrderTable rows={(filteredData)?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} handleOpen={handleOpen} />
          ): (
            <main
            className="d-flex align-items-center justify-content-center flex-column border"
            style={{ height: "25vh", width: "100%" }}
          >
            <h4 className="text-muted"><i>There is No Delivered or processing orders!!</i></h4>
          </main>
          )
        )}
      </main>

      <DrawerBox open={open} size={"50rem"} handleClose={handleClose}>
        <CreditSummary
          order={dataDetail}
          handleClick={handleClick}
          handleCloseSummaryModal={handleClose}
        />
      </DrawerBox>
    </>
  );
}
