import React, { useEffect, useState } from "react";
import { Navigate, Outlet, useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "../../routes/route";
import {useUserData} from "../hooks/useUserData";
import { refreshTokenFn } from "../../redux/fetchingFn";
import { useGetMarketPlace } from "../../redux/hooks/getHooks";
import { useDispatch, useSelector } from "react-redux";
import { stepperAction } from "../../redux/slice/stepperSlice";
import { productAction } from "../../redux/slice/productSlice";
import userAuth from "./userAuth";
import { useGetAllCredit } from "../../redux/hooks/getHooks";
import { isAccessTokenValid, isRefreshTokenValid, isTokenAboutToExpire } from "../../utils/validateToken";
function ProtectedRoute({ handleClick }) {
  const auth = userAuth();
  const user = useUserData();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const location = useLocation({}).pathname

  const {initialState} = useSelector((state)=> state.stepper)


  //localstorage key
  const sessionKey = user?.data?.id+'_product'

  const products = JSON.parse(sessionStorage.getItem(sessionKey));

  const refreshToken = localStorage.getItem('refreshToken');

  const accessToken = auth?.token;

  const thresholdSeconds = 10; //time interval


  const getMarketPlaces = useGetMarketPlace("user");
    
  const marketPlaces = getMarketPlaces?.data?.data?.data;


  useEffect(()=>{
    dispatch(productAction.setMarketPlaces(marketPlaces))
  }, [dispatch, marketPlaces])




  // useEffect to be confirming if the kyc is completed
  useEffect(() => {

    //there's an issue here, such that, when the page loads, it works fine when I use user?.data?.status, but it affect the time that when payment is not that they redirect to our page, it does'nt show the page e.g Verify payment page because navigating to the shop page..., and it affect the scenario when user want to subscribe and the kyc is not completed they will be redirected to kyc page, but it's affecting it also such that it will not allow the user to continue with kyc, it returns back to shop, but I then use user?.data?.kyc_stepper

    if(user?.data?.kyc_stepper >=7 && !location.includes("verify_payment")){
      navigate(ROUTES.shop)
    }
    // if(user?.data?.kyc_stepper >=7){
    //   navigate(ROUTES.shop)
    // }
       else if (user?.data?.kyc_stepper === 0 && initialState === 'start') {
        navigate(ROUTES.kyc);
      }
      // Add other conditions and state updates here

      else if(user?.data?.kyc_stepper < 5){
        dispatch(stepperAction.setStep(user?.data?.kyc_stepper));
        navigate(ROUTES.complete_kyc);
      }

    else if (user?.data?.kyc_stepper === 5) {
      navigate(ROUTES.otp_verification);
    }
    else if (user?.data?.kyc_stepper === 6) {
        navigate(ROUTES.bank_statement);
      }
    
  }, [dispatch, initialState, user?.data?.kyc_stepper]); //I remove navigate and use only user?.data 


  useEffect(()=>{
    if(products){
        // const totalPrice = products?.products?.reduce((acc, curr)=> acc + (parseFloat(curr.price * curr.quantity)+250), 0)
        dispatch(productAction.setAllProducts(products));
        dispatch(productAction.setTotalPrice())
    }
  }, [products, dispatch])

  //checking if token is expired or about to, and then refresh the token
  

  useEffect(() => {
  const checkTokenExpiration = () => {
    if (isTokenAboutToExpire(accessToken, thresholdSeconds)) {
      // Token is about to expire or has already expired
      // Perform token refresh or handle accordingly
      refreshTokenFn(refreshToken, 'user')
      .then((res) => {
        const token = res?.data?.data?.token;
        // console.log(token)
        localStorage.setItem("accessToken", token?.accessToken);
        localStorage.setItem("refreshToken", token?.refreshToken);
      })
      .catch((err) => {
          // console.log(err?.response?.data?.message, "error")
          localStorage.removeItem("accessToken");
          localStorage.removeItem("refreshToken");
          navigate(ROUTES.login);
      });
    } else {
      // Token is still valid
      // console.log("Token is still valid.");
    }
  };

  // Call the function when the component mounts
  checkTokenExpiration();

  // Optionally, you can set up a timer to periodically check token expiration
  const intervalId = setInterval(checkTokenExpiration, 3000);

  // Clean up the interval when the component unmounts
  return () => clearInterval(intervalId);
}, [accessToken]);

  return auth.status ? 
  (
    <Outlet /> 
  )
  : <Navigate to={ROUTES.login} />;
}

export default ProtectedRoute;
