import React from 'react'

export default function Status({status}) {
  return (
          <>
            <span className={`alert opacity-75 p-2 rounded-pill`} style={{color: "#D6A243", 
            background: "rgba(214, 162, 67, 0.12)"
          }}>
                {status}
            </span>
          </>
  )
}
