import { Dialog, DialogContent, DialogTitle, Backdrop, IconButton, Toolbar } from '@mui/material'
import React from 'react'
import Slide from '@mui/material/Slide';
import CloseIcon from '@mui/icons-material/Close';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });


function ModalDialogBox({open, handleClose, title, children, modalSize, maxWidth, ...rest}) {


  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));


  
  return (
   <>
        <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} fullScreen={fullScreen} maxWidth={maxWidth ?? "lg"} {...rest}
        
       slots={{
        backdrop: BlurBackdrop
      }}
        >
            <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            </Toolbar>
            {/* <DialogTitle ></DialogTitle> */}
            <DialogContent>
                {children}
            </DialogContent>
        </Dialog>
   </>
  )
}


// Custom Backdrop component with blur effect
const BlurBackdrop = (props) => {
  return (
    <Backdrop {...props} sx={{ backdropFilter: 'blur(8px)' }} />
  );
};

export default ModalDialogBox