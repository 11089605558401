import { MenuItem, TextField } from "@mui/material";
import React, { useEffect } from "react";
import { states } from "../../../../../redux/states";
import { Select } from "antd";


import Autocomplete from "@mui/material/Autocomplete";
// import TextField from '@mui/material/TextField';




function AddressInformation({ formik, setenableNext }) {
  useEffect(() => {
    if (
      formik?.values.homeAddress &&
      formik.values.city &&
      formik.values.state
    ) {
      setenableNext(true);
    }
    else{
      setenableNext(false);
    }
  }, [formik.values]);


  



  const modifiedStates = states?.filter(item=>item==="Lagos").map((state) => ({
    label: state,
    value: state,
  }));

  // console.log(states, modifiedStates);

  const onChange = (value) => {
    formik.setFieldValue("state", value);
  };
  const onSearch = (value) => {
  
  };

  const handleChange = (event, value) => {
    formik.setFieldValue("state", value?.value);
  };

  const filterOption = (input, option) =>
    (option?.label ?? "").toLowerCase().includes(input.toLowerCase());




    const getOptionLabel = (option) => {
      return option?.label || ''; // Return empty string if option is null or undefined
    };

  return (
    <>
      <main className="mx-2">
        <p
          className="text-center kyc_title"
          style={{
            color: "#323C4D",
            fontSize: "22px",
            fontStyle: "normal",
            fontWeight: "500",
            lineHeight: "normal",
          }}
        >
          Address Information
        </p>
        <form className="col-lg-10 mx-auto">
          <div className="home_address" style={{ paddingBottom: "20px" }}>
            <label htmlFor="home_address" style={{ marginBottom: "4px" }}>
              Home Address
            </label>
            <TextField
              type="text"
              id="standard-basic"
              name="homeAddress"
              onChange={formik?.handleChange}
              value={formik?.values.homeAddress}
              onBlur={formik?.handleBlur}
              placeholder="e.g. 37, lemon grass street"
              fullWidth
              className="rounded-pill"
              size="small"
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
            />
          </div>
          <div className="city" style={{ paddingBottom: "20px" }}>
            <label htmlFor="city" style={{ marginBottom: "4px" }}>
              City
            </label>
            <TextField
              type="text"
              id="standard-basic"
              name="city"
              onChange={formik?.handleChange}
              value={formik?.values.city}
              onBlur={formik?.handleBlur}
              placeholder="e.g. victoria island"
              fullWidth
              className="rounded-pill"
              size="small"
              InputProps={{
                style: {
                  borderRadius: "20px",
                },
              }}
            />
          </div>
          <div className="state" style={{ paddingBottom: "20px" }}>
            <label htmlFor="state" style={{ marginBottom: "4px" }}>
              State
            </label>
            {/* <Select
              name="state"
              value={formik?.values.state}
              onChange={formik?.handleChange}
              onBlur={formik?.handleBlur}
              fullWidth
              variant="outlined"
              sx={{ borderRadius: "30px" }}
              size="small"
            >
              {states.map((state, index) => {
                return (
                  <MenuItem key={index} value={state}>
                    {state}
                  </MenuItem>
                );
              })}
            </Select> */}

            <Select
              showSearch
              placeholder="Select state"
              optionFilterProp="children"
              value={formik?.values?.state}
              onChange={onChange}
              onSearch={onSearch}
              filterOption={filterOption}
              options={modifiedStates}
              size="large"
              className="w-100"
              // style={{ borderRadius: "50px" }}
            />

            {/* <Autocomplete
            
              options={modifiedStates}
              getOptionLabel={getOptionLabel}
              renderInput={(params) => <TextField {...params} 
              
              />}
              style={{ borderRadius: '20px' }}
              size="small"
              // Additional props for search functionality
              filterOptions={(options, { inputValue }) =>
                options.filter((option) =>
                  option.label.toLowerCase().includes(inputValue.toLowerCase())
                )
              }
              isOptionEqualToValue={(option, value) => option.value === value?.value}
              value={formik?.values?.state}
              onChange={handleChange}
            /> */}
          </div>
        </form>
      </main>
    </>
  );
}

export default AddressInformation;
