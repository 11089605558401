import React from 'react'
import SignupForm from '../../../components/Auth/Signup/SignupForm'

function SignupScreen({handleClick}) {
  return (
    <>
        <SignupForm handleClick={handleClick}/>
    </>
  )
}

export default SignupScreen