import React from 'react'
import SampleDot from '../../../utils/SampleDot'

export default function OrderHeader({role}) {
  return (
    <>
         <section className="col-md-11 mx-auto table_header my-4 d-flex justify-content-between flex-wrap gap-2">
          <h6 className="fw-bold">{role}</h6>
          <div className="d-flex gap-2 flex-wrap">
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"#F8D86E"} /> Unfulfilled
            </p>
            <p className="d-flex gap-1 my-aut">
              <SampleDot color={"green"} /> Fulfilled
            </p>
          </div>
        </section>
    </>
  )
}
