import React from 'react'
import "./AuthHeader.css"


function AuthHeader({title}) {
  return (
   <>
        <main className='text-center my-3'>
            <h3 className='fw-semibold' style={{fontSize: "25px", fontWeight: 500}}>{title}</h3>
        </main>
   </>
  )
}

export default AuthHeader