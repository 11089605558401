import { Button } from '@mui/material'
export default function MerchantAdded({backToDashboard}){
    return (
        <>
            <section className='d-flex justify-content-center align-item-center' style={{height: '60vh'}}>
                <article style={{color: "#033F4B"}} className="d-flex flex-column justify-content-center align-item-center gap-5">
                <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSuO-neWKBSr3PwUbxYIcauijNYjh4r5XicoIP-rd-UdA&s" alt="Check Mark" />
                    <p className='fs-4 fw-bold nowrap'>Merchant has been added <span style={{color: "transparent"}}>Merchant has been added</span></p>
                    <Button className="fw-bold cursor" sx={{textTransform: "capitalize", color: "#033F4B", fontSize: '2.5vh'}} onClick={
                        backToDashboard}>Back to Dashboard</Button>
                </article>
            </section>
        </>
    )
}