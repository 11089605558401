import { Button, TextField } from "@mui/material";
import { useFormik } from "formik";
import React, { useState } from "react";
import { searchOrderFn} from "../../../redux/fetchingFn"
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import OrderSection from "./Order/OrderSection";
import * as yup from "yup";
export default function SellToVouch({handleClick}) {
  const [open, setOpen] = useState(null);
  const [isLoading, setIsLoading] = useState(null);
  const formik = useFormik({
    initialValues: {
      phone: "",
      token: "",
    },
    onSubmit: (values) => {
      setIsLoading(true)
      handleOpen();
      searchOrderFn({token: values.token, phone: values.phone}).then((res) => {
      
        setIsLoading(false)
      }).catch(err=>{
       
        setIsLoading(false)
      });
    },
    validationSchema: yup.object().shape({
      phone: yup.number().required("Phone number is required"),
      token: yup.string().required("Token is required")
    })
  });

  const handleOpen=()=>{
    setOpen(true);
  }
  const handleClose=()=>{
    setOpen(false);
  }
  return (
    <>
      <section className="border rounded my-3 col-lg-8 mx-auto col-md-10 col-12 p-3" style={{background: "rgba(248, 244, 231, 0.26)"}}>
        <h4 className="fw-bold">Sell To Vouch Customer</h4>
        <p>* Request phone number and token from the customer</p>
        <form className="row" onSubmit={formik.handleSubmit}>
          <section className="col-lg-8 col-md-8 d-flex flex-column gap-3">
            <div className="customer_phone_number">
              <TextField
                type="text"
                name="phone"
                error={formik.touched.phone && formik.errors.phone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                placeholder="Enter Customer Phone number"
                fullWidth
                variant="outlined"
                // size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.phone && formik.errors.phone}
              </small>
            </div>
            <div className="token_from_customer">
              <TextField
                type="text"
                name="token"
                error={formik.touched.token && formik.errors.token}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.token}
                placeholder="Enter token from customer"
                fullWidth
                variant="outlined"
                // size="small"
                InputProps={{
                  style: {
                    borderRadius: "30px",
                  },
                }}
              />
              <small style={{ fontSize: "13px" }} className="text-danger">
                {formik.touched.token && formik.errors.token}
              </small>
            </div>
          </section>
          <div className="col-lg-4 col-md-4 d-flex justify-content-center my-3 align-items-center">
            <Button variant="contained" type="submit" disabled={formik?.values?.phone && formik?.values?.token ? false: true} sx={{backgroundColor: "#000"}}>
                Go
            </Button>
          </div>
        </form>
      </section>

      <DrawerBox open={open} handleClose={handleClose} >
            <OrderSection handleClick={handleClick}/>
        </DrawerBox>
    </>
  );
}
