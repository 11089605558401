import { useState, useMemo } from "react";
import { Button, Tooltip } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import moment from "moment";
import { formatNumber } from "../../utils/formatNumber";
import ModalDialogBox from "../ModalDialogBox/ModalDialogBox";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { initiateRepaymentFn } from "../../redux/fetchingFn";
import LoadingButton from "@mui/lab/LoadingButton";
import { useResponsiveSize } from "../hooks/useResponsiveSize.js";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useUserData } from "../hooks/useUserData";
import { usePaymentModal } from "../../redux/hooks/zustands/usePaymentModal.js";

export default function HistoryDetail({
  handleClick,
  handleClose,
  order,
  dueDate,
}) {
  const { data } = useUserData();

  const { openModal: openPaymentModal } = usePaymentModal();

  const statusColor = {
    pending: "alert-warning",
    "order in progress": "alert-warning",
    repaid: "alert-success",
    "order delivered": "alert-success",
    rejected: "alert-danger",
    declined: "alert-danger",
  };

  const paymentDetails = [
    (!order?.subscription && !order?.order?.is_bill) && { id: 1, label: "Delivery Fee", price: order?.order?.delivery_amount ?? 0 },
    { id: 2, label: "Sub total", price: order?.order?.sub_total ?? 0 },
    {
      id: 3,
      label: `Service charge (${order?.order?.current_service_charge}% of the subtotal)`,
      price: parseFloat(order?.order?.service_charge) ?? 0,
    },
    {
      id: 4,
      label: `VAT (${order?.order?.current_vat}% of the subtotal)`,
      price: order?.order?.vat ?? 0,
    },
    { id: 5, label: "Total Amount", price: order?.amount ?? 0 },
    // { id: 4, label: "Discount", price: 0.0 },
  ].filter(Boolean);

  const [paymentMethod, setPaymentMethod] = useState("");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentCredential, setPaymentCredential] = useState(null);

  const responsiveSize = useResponsiveSize({});

  //   const initiatePayment = useInitiatePayment(order?.id)

  //   const paymentCred = initiatePayment?.data?.data
  // console.log(paymentCred)

  const truncateSize = useMemo(() => {
    return responsiveSize === "lg" ? 40 : responsiveSize === "md" ? 20 : 10;
  }, [responsiveSize]);

  const morePayMethod = () => {
    setIsLoading(true);
    initiateRepaymentFn(order?.id)
      .then((res) => {
        const paymentCred = res?.data?.data;
        setPaymentCredential(paymentCred);
        sessionStorage.setItem("payment_purpose", "order");

        openPaymentModal({ ...paymentCred, email: data?.email });

        // window.location.href = paymentCred?.link;
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        const msg = error?.response?.data?.message;
        const msg2 = error?.response?.statusText;
        handleClick(msg ?? msg2, "error", {
          vertical: "top",
          horizontal: "right",
        });
      });
  };

  const handlePaymentMethod = (method) => {
    setOpen(true);
    setPaymentMethod(method);

    // handleClose();
  };

  const handleCloseInnerDialong = () => {
    setOpen(false);
  };

  const [copied, setCopied] = useState({ status: false, index: null });

  //copying to clippoard
  const handleCopy = (val, index) => {
    navigator.clipboard
      .writeText(val)
      .then(() => {
        setCopied({ ...copied, status: true, index });
      })
      .catch((error) => {
        handleClick("Error copying to clipboard: ", "error", {
          vertical: "top",
          horizontal: "right",
        });
      });
  };

  console.log(order);

  return (
    <>
      <div className="row">
        <div className="col-md-9 order-2 order-md-1">
          <div
            className="card"
            style={{
              border: "0.7px solid #F1F1F1",
            }}
          >
            <Table stickyHeader aria-label="sticky table">
              <TableRow>
                <TableCell colSpan={4}>
                  <p className="fw-medium">Ref No.: {order?.reference}</p>
                </TableCell>
              </TableRow>
              {order?.order?.products?.map((item, index) => (
                <TableRow key={index + "___product"}>
                  <TableCell align="center" colSpan={3}>
                    <div className="d-flex">
                      <div className="text-start">
                        <h6 className="tw-text-base tw-font-[500]">
                          {order?.order?.is_bill ? (
                            <>
                              <span
                                className=""
                                style={{ textTransform: "capitalize" }}
                              >
                                {item?.order_name}
                              </span>
                              ({item?.disco})
                            </>
                          ) : (
                            item?.name
                          )}
                        </h6>
                        <p style={{ fontSize: "2.5vh" }} className="text-wrap">
                          {order?.order?.is_bill ? (
                            ""
                          ) : (
                            <>
                              {item?.quantity} x{" "}
                              <Tooltip title={item?.url ?? "xxxxx"} arrow>
                                <a
                                  href={item?.url}
                                  target="_blank"
                                  rel="noreferrer"
                                  style={{}}
                                  className="text-black"
                                >
                                  {item?.url?.substring(0, truncateSize) +
                                    "..."}
                                </a>
                              </Tooltip>
                            </>
                          )}
                        </p>
                        <p className="text-muted">
                          {order?.market_place?.name}
                        </p>
                      </div>
                    </div>
                  
                  </TableCell>

                  <TableCell align="right">
                    <h5 className="fw-medium">
                      {formatNumber(
                        (order?.order?.is_bill
                          ? item?.amount ?? item?.total_amount
                          : item?.price) ?? 0
                      )}
                    </h5>
                  </TableCell>
                </TableRow>
              ))}


              {
                order?.order?.products?.[0]?.order_name === 'electricity' && (
                <TableRow>
                  <TableCell>
                    <p className="tw-text-base tw-font-[400]">Token</p>
                   
                  </TableCell>

                  <TableCell align="right" colSpan={3}>
                    <h5 className="fw-medium">
                    {order?.order?.bill_response_data?.token}
                    </h5>
                  </TableCell>
                </TableRow>
                )
              }

              <TableBody>
                {paymentDetails?.map((item, index) => {
                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      tabIndex={-1}
                      key={index + "__" + item?.id}
                    >
                      <TableCell colSpan={3}>{item?.label}</TableCell>
                      <TableCell className="nowrap" align="right">
                        {formatNumber(item?.price)}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        </div>

        <div className="col-md-3 order-1 order-md-2 order-1 order-md-2">
          <div className="d-flex align-items-end">
            <span
              className={`border-0 alert ${
                statusColor[order?.status]
              } rounded p-1 px-3`}
              style={{
                fontSize: "12px",
              }}
            >
              {order?.subscription
                ? "Renewal"
                : order?.order?.is_credit
                ? "Repayment"
                : order?.order?.is_bill
                ? "Instant payment"
                : ""}
            </span>
          </div>
          <div>
            <p
              style={{
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Channel:
              <span
                style={{
                  fontWeight: 300,
                  fontSize: "14px",
                }}
                className="ms-1"
              >
                {order?.channel ?? "No content"}
              </span>
            </p>
            <p
              style={{
                fontWeight: 600,
                fontSize: "16px",
              }}
            >
              Category:
              <span
                className="ms-1"
                style={{ fontWeight: 300, fontSize: "16px" }}
              >
                {order?.subscription
                  ? "Subscription"
                  : order?.order?.is_credit
                  ? "Online Marketplace"
                  : order?.order?.is_bill
                  ? "Bills payment"
                  : ""}
              </span>
            </p>
          </div>
        </div>
      </div>

      <ModalDialogBox
        open={open}
        handleClose={handleCloseInnerDialong}
        modalSize={responsiveSize === "sm" ? true : false}
        maxWidth={responsiveSize === "lg" ? "sm" : "md"}
      >
        {paymentMethod === "transfer" && (
          <Table>
            <TableBody>
              {[
                { label: "Amount", value: 18.5 },
                { label: "Account Number", value: "1234567890" },
              ]?.map((item, index) => {
                return (
                  <TableRow
                    hover
                    role="checkbox"
                    tabIndex={-1}
                    key={index + "__" + item?.id}
                  >
                    <TableCell colSpan={3}>{item?.label}</TableCell>
                    <TableCell className="nowrap">
                      {item.label === "Amount"
                        ? "NGN" + item?.value
                        : item?.value}
                    </TableCell>
                    <TableCell className="nowrap">
                      <Button
                        startIcon={<ContentCopyIcon />}
                        onClick={() => handleCopy(item?.value, index)}
                      >
                        {copied?.status && copied?.index === index
                          ? "Copied"
                          : "Copy"}
                      </Button>
                    </TableCell>
                  </TableRow>
                );
              })}
              <TableRow hover tabIndex={-1}>
                <TableCell colSpan={3}>Bank Name</TableCell>
                <TableCell className="nowrap" colSpan={2}>
                  Titian Bank
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell colSpan={5} align="center">
                  <Button
                    sx={{
                      backgroundColor: "#333332",
                      color: "#fff",
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#045E6E",
                        color: "white",
                      },
                    }}
                    onClick={() => handlePaymentMethod("transfer")}
                  >
                    I have made payment
                  </Button>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell align="center" colSpan={5}>
                  <Button
                    sx={{
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "#333332",
                        color: "white",
                      },
                    }}
                    variant="outlined"
                  >
                    Choose another method
                  </Button>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        )}
      </ModalDialogBox>
    </>
  );
}
