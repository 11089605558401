import React, { useEffect, useState } from "react";
import AuthHeader from "../../../components/Auth/AuthHeader";
import { formatNumber } from "../../../utils/formatNumber";
import { Button } from "antd";
import { useInitiateBill } from "../../../redux/mutation/postMutation";
import BillAuthComp from "../BillAuthComp";
import { useUserData } from "../../../components/hooks/useUserData";
import { usePaymentModal } from "../../../redux/hooks/zustands/usePaymentModal";
import { useContinueSubscription } from "../../../utils/useNoSubscription";

export default function ConfirmAirtimeInformation({ formik, setNextAction, handleClick, payloadData }) {

  const { openModal:openPaymentModal} = usePaymentModal();

  const [payNowLoading, setPayNowLoading] = useState(false);

  const user = useUserData();


  const { contextHolder, continueSubscription } = useContinueSubscription();

  const [openModal, setOpenModal] = useState(false);


  const initiateBillMutation = useInitiateBill();

  const handlePayNowFn = () => {
    initiateBillMutation.mutate(payloadData?.data?.products[0]);
  };

   const handlePrevAction=()=>{
    setNextAction("")
  }

 const handleNextAction = () => {
    if(user?.status){
      if(user?.data?.subscriptions.length){
        setNextAction("checkout");
      }
      else{
        continueSubscription();
      }
    }
    else{
      setOpenModal(true);
    }
  };


const handleCloseModal = () => {
    setOpenModal(false);
  };

  useEffect(() => {
    if (initiateBillMutation?.isLoading) {
      setPayNowLoading(true);
    } else if (initiateBillMutation?.isSuccess) {
      setPayNowLoading(false);
      const res = initiateBillMutation?.data?.data;

      openPaymentModal({...res?.data, email: payloadData?.data?.products?.[0]?.email})

      // if(res?.data?.link){
      //   window.location.href = res?.data?.link;
      // }

    } else if (initiateBillMutation?.isError) {
      setPayNowLoading(false);
      let msg = initiateBillMutation?.error?.response?.data?.message;
      let msg2 = initiateBillMutation?.error?.response?.statusText;

      handleClick(msg ?? msg2, "error", {
        vertical: "top",
        horizontal: "right",
      });
    }
  }, [initiateBillMutation?.status]);
  return (
    <>
      <div className="col-lg-8 col-12 mx-lg-auto mx-2">
        <div className="d-flex align-items-end justify-content-end">
          <span className="cursor-pointer" onClick={handlePrevAction}>Back</span>
        </div>
        <AuthHeader title={"Confirm Information"} />
        <div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Phone Number
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formik?.values?.mobileNumber}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Servie Type
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                Mobile Data ({payloadData?.data?.products?.[0]?.disco})
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Mobile Data
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formik?.values?.dataBundle?.desc}
              </small>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-lg-5 col-5">
              <p
                className="text-dark my-auto"
                style={{ fontSize: "14px", fontWeight: 500, color: "#4F4F4F" }}
              >
                Amount
              </p>
            </div>
            <div className="col-lg-7 col-7">
              <small
                className=""
                style={{ color: "#4F4F4F", fontSize: "14px", fontWeight: 400 }}
              >
                {formatNumber(formik?.values?.dataBundle?.price)}
              </small>
            </div>
          </div>
        </div>


        <div className="d-flex flex-column gap-3 mt-4">
            <Button
              shape="round"
              size="large"
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "18px",
                height: "55px",
              }}
              loading={payNowLoading}
              onClick={handlePayNowFn}
              
            >
              Pay Now
            </Button>
            <Button
              shape="round"
              size="large"
              style={{
                background: "#FA9E17",
                color: "#fff",
                fontSize: "18px",
                height: "55px",
              }}

              onClick={handleNextAction}

            >
              Get it now and pay later
            </Button>
          </div>
      </div>



      <BillAuthComp handlePayNowFn={handlePayNowFn} payNowLoading={payNowLoading} openModal={openModal} handleCloseModal={handleCloseModal} billData={payloadData} />
    {contextHolder}
    </>
  );
}
