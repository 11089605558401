import { useState, useEffect } from "react";
import MembershipTable from "../../../components/Admin/Community/MembershipTable";
import { useGetAllUsers, useGetUsers } from "../../../redux/hooks/getHooks";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import MembershipHeader from "./MembershipHeader";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import Options from "../../../components/Admin/Community/Options";
import SearchBar from "../../../utils/SearchBar";
import { ActiveNotificationIcon } from "../../../icons/icons";
import FormControl from "@mui/material/FormControl";
import { Badge } from "antd";
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Select, MenuItem, InputLabel } from "@mui/material";

export default function MemberScreen({ handleClick }) {
  const getApprovedUsers = useGetUsers(true);
  const getNewUsers = useGetUsers(false);
  const getAllUsers = useGetAllUsers();


  const newUsers = getNewUsers?.data?.data?.data;
  const approvedUsers = getApprovedUsers?.data?.data?.data;
  const allUsers = getAllUsers?.data?.data?.data;




  const [open, setOpen] = useState(false);
  const [dataDetail, setDataDetail] = useState({});

  const [filterValue, setFilterValue] = useState("");
  const [filteredData, setFilteredData] = useState()

  let usersData = allUsers;

  const handleChange = (event) => {
    const val = (event.target.value)?.toLowerCase();
    setFilterValue(event.target.value);


    let filteredUser = usersData?.filter((item)=>{
      const hasSubscriptions = item?.subscriptions?.length > 0;
  
      // Check if the first subscription status is 'subscribed'
      const isSubscribed = hasSubscriptions && item.subscriptions[0].status === true;
      
      // Check if the user has no subscriptions or the first subscription status is not 'subscribed'
      const isUnsubscribed = !hasSubscriptions || item.subscriptions[0].status === false;
      
      // Check if the user is pending or approved
      const isPending = item?.status === false;
      const isApproved = item?.status === true;
    
      if (val === '') {
        // If no value is specified, return all users
        return true;
      } else if (val === 'subscribed') {
        return isSubscribed;
      } else if (val === 'unsubscribed') {
        return isUnsubscribed;
      } else if (val === 'pending') {
        return isPending;
      } else if (val === 'approved') {
        return isApproved;
      } else {
        return null; // Handle invalid val here
      }

    })
    setFilteredData(filteredUser);
  };

  const handleOpen = (_, data) => {
    setDataDetail(data);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };




//   const filterBy = filterValue==='subscribed'


  

  useEffect(()=>{
        setFilteredData(usersData)
  }, [approvedUsers, newUsers])



  const handleFilter=(val)=>{

    let updatedData = [];

    if (val.length) {
      updatedData = filteredData?.filter((item) => {
        const startsWith = (item?.first_name +" "+item?.last_name)
          ?.toLowerCase()
          .startsWith(val.toLowerCase());

        const includes = (item?.first_name +" "+item?.last_name)
          ?.toLowerCase()
          .includes(val.toLowerCase());

        if (startsWith) {
          return startsWith;
        } else if (!startsWith && includes) {
          return includes;
        } else return null;
      });

      setFilteredData(updatedData);

    }
    else{
        setFilteredData(usersData);
        setFilterValue('');
    }
}

  return (
    <>
      <main className="mt-3">
        <div className="px-lg-5 px-3 d-flex justify-content-between flex-wrap">
          <div className="d-flex gap-4 flex-wrap">
          <p className="fw-semibold">
            New Members 
            {
                    newUsers?.length? (
                      <Badge size="small" color="orange" count={newUsers?.length}
                      onClick={()=>setFilteredData(newUsers)}
                      style={{cursor: "pointer"}}
                      >
                        <ActiveNotificationIcon />
                      </Badge>
                    ): 
                    <ActiveNotificationIcon />
                  }
           
          </p>
          <p className="fw-semibold">
            Member with complete kyc
            {
                    newUsers?.length? (
                      <Badge size="small" color="orange" count={newUsers?.filter(user=>user?.kyc_stepper >=7)?.length} onClick={()=>setFilteredData(newUsers?.filter(user=>user?.kyc_stepper >=7))} style={{cursor: "pointer"}}>
                        <HowToRegIcon />
                      </Badge>
                    ): 
                    <HowToRegIcon />
                  }
           
          </p>
          </div>
          <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                      <InputLabel id="demo-select-small-label">
                        Filter
                      </InputLabel>
                      <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        value={filterValue}
              onChange={handleChange}
                        label="Filter"
                        
                      >
                        <MenuItem value="">
                          <em>None</em>
                        </MenuItem>
                        {["Subscribed", "Unsubscribed", "Pending", "Approved"]?.map((item, index) => (
                          <MenuItem
                            key={index + "___filter_by"}
                            value={item.toLowerCase()}
                          >
                            {item}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>




          {/* <FormControl>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Filter
            </FormLabel>
            <RadioGroup
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              row
              value={filterValue}
              onChange={handleChange}
            >
                <FormControlLabel value="all" control={<Radio size="small"/>} label="All" />
              <FormControlLabel
                value="subscribed"
                control={<Radio size="small"/>}
                label="Subscribed"
            
              />
              <FormControlLabel value="unsubscribed" control={<Radio size="small"/>} label="Unsubscribed" />
              <div className="ps-3 d-flex flex-column">
                <FormControlLabel value="pending" control={<Radio size="small"/>} label="Application under review" />
                <FormControlLabel value="approved" control={<Radio size="small"/>} label="Approved" />
              </div>
            </RadioGroup>
          </FormControl> */}
        </div>
        <MembershipHeader />
        <SearchBar handleFilter={handleFilter}/>
        {getApprovedUsers.isLoading ? (
          <IsLoadingLoader />
        ) : getApprovedUsers.isError ? (
          <IsErrorLoader />
        ) : (
          <MembershipTable rows={(filteredData ?? allUsers)?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))} handleOpen={handleOpen} />
        )}
      </main>

      <DrawerBox open={open} handleClose={handleClose}>
        <Options
          profileData={dataDetail}
          role={"user"}
          handleClick={handleClick}
          handleCloseOptionModal={handleClose}
        />
      </DrawerBox>
    </>
  );
}
