import { Button } from "antd";
import { useState, useEffect } from "react"
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingOutlined } from '@ant-design/icons';
import { Spin } from 'antd';
import { Modal } from "antd";

export default function TransactionPin({finalFn, closeModal, openModal}){

    const [isLoading, setIsLoading] = useState(false)

      //================================= the functions behind the pin  inputs
  const [pin, setPin] = useState(["", "", "", ""]); // Initial state for pin input

  // Function to handle button click
  const handleButtonClick = (digit) => {
    const updatedpin = [...pin]; // Create a copy of the current pin array
    const index = updatedpin.findIndex((value) => value === ""); // Find the first empty input box
    if (index !== -1) {
      updatedpin[index] = digit; // Set the value of the empty input box to the clicked digit
      setPin(() => {
        return updatedpin;
      }); // Update the pin state
    }
  };

  useEffect(() => {
    if (pin[3] !== "") {
      const pinValue = pin.join("");
      setIsLoading(true);
      setTimeout(() => {

        finalFn(pinValue)
        closeModal()
       
        setIsLoading(false);
        setPin(["", "", "", ""]);
      }, 500);
    }
  }, [pin]);

      // Function to handle delete button click
  const handleDeleteButtonClick = () => {
    const updatedpin = [...pin]; // Create a copy of the current pin array
    const lastNonEmptyIndex = updatedpin
      .map((value, index) => ({ value, index }))
      .filter(({ value }) => value !== "")
      .pop(); // Find the last non-empty input box
    if (lastNonEmptyIndex) {
      updatedpin[lastNonEmptyIndex.index] = ""; // Remove the value from the last non-empty input box
      setPin(updatedpin); // Update the pin state
    }
  };

  //================================ the end of the pin input functions



    return(
        <>
         <Modal open={openModal} onCancel={closeModal} footer={null}
         maskClosable={false}
      width={325}>
            <div className="w-auto mx-auto mt-3 p-3 d-flex align-items-center flex-column justify-content-center p-2">
            <p className="tw-mb-3">Enter your transaction pin</p>
            <div className="d-flex gap-2">
              {pin.map((value, index) => (
                <input
                  key={index}
                  className="pin-input rounded-3 border bg-white border-secondary border-1 text-center fs-4"
                  style={{
                    width: "4rem",
                    height: "4rem",
                    cursor: "grabbing"
                  }}
                  type="password"
                  value={value}
                  disabled
                />
              ))}
            </div>
            <div className="row mt-3 justify-content-end">
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 0, "x"].map((item, i) => (
                <div key={i} className="col-4 mb-2">
                    <Button
                    style={ {
                        backgroundColor: item === 'x'? 'red': 'white',
                            width: '4rem',
                            height: "3.5rem"
                        }
                    }
                    className={`d-flex align-items-center justify-content-center fs-5 font-medium shadow-sm`}
                    onClick={() => {
                        if (item === "x") {
                        handleDeleteButtonClick();
                        } else {
                        handleButtonClick(String(item));
                        }
                    }}
                    
                    >
                    {item === "x" ? (
                        isLoading ? (
                            <Spin
                            indicator={
                            <LoadingOutlined
                                style={{
                                fontSize: 24,
                                color: "white"
                                }}
                                spin
                            />
                            }
                        />
                        ) : (
                        <CancelIcon size={"1.5rem"} sx={{color: "white"}} />
                        )
                    ) : (
                        item
                    )}
                    </Button>
                </div>
              ))}
            </div>
          </div>
          </Modal>
        </>
    )
}