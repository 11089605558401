
  import { Button, Checkbox } from "antd";
import { useLoanDrawer } from "../../../redux/hooks/zustands/useLoanDrawer";
import { formatNumber } from "../../../utils/formatNumber";
import { useCheckPayment } from "../../../redux/mutation/AdminPostMutation";
import { useEffect, useState } from "react";
  

  
  export default function SingleLoanDetail() {

    const {updateTitle, updateData, data: {single_schedule}, openDrawer} = useLoanDrawer();

    console.log(single_schedule?.id)

    const mutation = useCheckPayment(single_schedule?.id)

    const [isLoading, setIsLoading] = useState(false)


    const handleOpenDrawer=(amountDue)=>{
      updateTitle('DEBIT_ACCOUNT')
      updateData({amountDue})
    }


    const confirmPayment=()=>{
      mutation.mutate({})
    }


    useEffect(()=>{
      if(mutation.isLoading){
          setIsLoading(true)
      }
      else if(mutation.isError){
          setIsLoading(false)
          const error = mutation?.error?.response?.data

  
          console.log(error)
      }
      else if(mutation.isSuccess){
          setIsLoading(false)
          const res = mutation?.data?.data?.data
        
          openDrawer('SUCCESSFULL_DEBIT', {amountDue: res?.total_amount_due})
      }
  }, [mutation.status])


    return (
      <>
        <div className="tw-w-full tw-px-1 tw-py-4 tw-rounded-lg tw-bg-white">
          <div className="tw-flex tw-flex-col tw-gap-y-4">
            <div className="tw-flex tw-gap-5 tw-flex-wrap">
              <p className="tw-font-[500] tw-text-[14px]">
                Amount Due: <span className="tw-font-[300]">{formatNumber(single_schedule?.amount - single_schedule?.amount_paid)}</span>
              </p>
              <p className="tw-font-[500] tw-text-[14px]">
                Due Date: <span className="tw-font-[300]">{single_schedule?.start_date}</span>
              </p>
              <p className="tw-font-[500] tw-text-[14px]">
               Status: <span className="tw-font-[300]">
               {
                              Number(single_schedule?.amount) === Number(single_schedule?.amount_paid) ? "Fully Paid": ((Number(single_schedule?.amount) > Number(single_schedule?.amount_paid)) && (Number(single_schedule?.amount_paid)  !== 0))? "Partially paid": "Not Paid"
                            }
               </span>
              </p>
            </div>
            <div>
                <p className="tw-font-[500] tw-text-[14px]">
                    Amount to be paid: <span className="tw-font-[300]">5 month</span>
                </p>
                <div className="tw-mt-4 tw-flex tw-flex-col tw-gap-y-3">
                    <div className="tw-flex tw-gap-3 tw-items-center">
                        {/* <Checkbox >Debit {Number(single_schedule?.amount) - Number(single_schedule?.amount_paid)}</Checkbox> */}
                        <p>Debit {Number(single_schedule?.amount) - Number(single_schedule?.amount_paid)}</p>
                        <Button
                            loading={false}
                            className={`tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF] tw-capitalize`}
                            onClick={()=>handleOpenDrawer(Number(single_schedule?.amount) - Number(single_schedule?.amount_paid))}
                          >
                            Go to debit
                          </Button>
                    </div>
                    <div className="tw-flex tw-gap-3 tw-items-center">
                        {/* <Checkbox >Customer already paid through alternative channel</Checkbox> */}
                        <p>Customer already paid through alternative channel</p>
                        <Button
                            loading={isLoading}
                            className={`tw-rounded-[8px] tw-bg-[#2A2A29] tw-text-[#FFF] hover:!tw-bg-[#2A2A29] hover:!tw-text-[#FFF] tw-capitalize`}
                          onClick={confirmPayment}
                          >
                            Confirm payment
                          </Button>
                    </div>
                </div>
            </div>
            
          </div>
        </div>
      </>
    );
  }
  