import React from 'react'
import Header from '../../Shop/Header/Header'
import { Button } from '@mui/material'
import TransactionTable from './TransactionTable'
import { formatNumber } from '../../../utils/formatNumber'

export default function Transaction({profileData}) {
    const headerData = [
        {value: 0, key: "Transasction Count"},
        {value: <>₦20, 000</>, key: "Total Credit Amount"},
        {value: <>₦15, 000</>, key: "Outstanding Repayment"}
    ]

    const rows = [
        {
            _id: '1123459w',
            firstName: "John", lastName: "Fixit", merchant: "RIO pharmacy", product: "Chemiron Tab..", downPayment: 0, amount: formatNumber(3000), repayment: "active", action: ''
        },
    ]
  return (
    <React.Fragment>
        <section className='text-center'>
        <h5>{profileData?.firstName} {profileData?.lastName} account</h5>
        <h6>Transaction</h6>
        <Header data={headerData}/>
        <div className='my-3'>
        <Button
                            variant='contained'
                            
                            sx={{
                                backgroundColor: "#033F4B",
                                color: "white",
                                padding: 'auto 1vh',
                                textTransform: "capitalize",
                                '&:hover': {
                                    backgroundColor: "#033F4B",
                                    color: "white"
                                }
                            }}
                            >
                                Initiate repayment
                            </Button>
        </div>
        <TransactionTable rows={rows}/>
        </section>
    </React.Fragment>
  )
}
