import React from 'react'
import HeaderSection from './HeaderSection'
import ContentBlock from './ContentBlock'
function AddressInfo({formik, editFn}) {
  return (
    <>
        <main>
            <HeaderSection title={"Address Information"} editFn={editFn}/>
            <section>
                <ContentBlock _key={"Home Address"} _value={formik.values.homeAddress?formik.values.homeAddress:"---"}/>
                <ContentBlock _key={"City"} _value={formik.values.city?formik.values.city:"---"}/>
                <ContentBlock _key={"State"} _value={formik.values.state?formik.values.state:"---"}/>
            </section>
        </main>
    </>
  )
}

export default AddressInfo