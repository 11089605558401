import { useState } from "react";
import SubscriptionPlan from "../components/Subscription/SubscriptionPlan";
import Subscription from "../components/Subscription/Subscription";
import { useUserData } from "../components/hooks/useUserData";

export default function UserSubscription({ handleClick }) {
  const { data } = useUserData();

  const [showPlans, setShowPlans] = useState(
    data?.subscriptions?.length && data?.subscriptions[0]?.status ? false : true
    );

  const upgradeFn = () => {
    setShowPlans(true);
  };

  // console.log(data);

  return (
    <>
      {showPlans ? (
        <SubscriptionPlan handleClick={handleClick} role={(data?.subscriptions?.length && data?.subscriptions[0]?.status) ? "upgradePlan": "user"} />
      ) : (
        <Subscription upgradeFn={upgradeFn} plan={data?.subscriptions[0]} user={data}/>
      )}
    </>
  );
}
