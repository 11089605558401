import {useState} from 'react';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import { Button } from '@mui/material';
import TopToolBar from '../../../../utils/Table/TopToolBar';
import EnhancedTableHead from '../../../../utils/Table/EnhancedTableHead';
import { formatNumber } from '../../../../utils/formatNumber';
const Status=({status})=>{
    return (
            <>
              <span className={`alert opacity-75 p-2 rounded-pill`} style={{color: "#fff", 
              background: "#12833880"
            }}>
                  {status}
              </span>
            </>
    )
  }
  
export default function TransactionTable({topHeaderVisible, rows, headCell}) {
  const SampleDot=({color})=>{
    return(
        <>
            <span className='rounded-circle my-auto' style={{backgroundColor: color, height: '8px', width: '8px'}}></span>
        </>
    )
}

const headCells = [
  {
    id: 'name',
    label: 'Name',
  },
  {
    id: 'orderId',
    label: 'order ID',
  },
  {
    id: 'product',
    label: 'Product',
  },
  {
    id: 'amount',
    label: 'Amount',
  },
  {
    id: 'status',
    label: 'Transaction Status',
  },
  {
    id: 'actions',
    label: 'Actions',
  }
];

  //doomy data
  const rowsI = [
    {
        _id: '1',
        name: "John Fixit Halleluja", joinDate: '15:30:20, 21. Dec.2023', orderId: "123459w", product: 'Chemiron Tab..', amount: 3000, transactionStatus: 'Fullfilled', action: ''
    }
]
    const [selected, setSelected] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleSelectAllClick = (event) => {
      if (event.target.checked) {
        const newSelected = rowsI?.map((n) => n._id);
        setSelected(newSelected);
        return;
      }
      setSelected([]);
    };
  
    const handleClick = (event, user_id) => {
      const selectedIndex = selected?.indexOf(user_id);
      let newSelected = [];
  
      if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, user_id);
      } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
      } else if (selectedIndex === selected?.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
      } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(
          selected.slice(0, selectedIndex),
          selected.slice(selectedIndex + 1),
        );
      }
  
      setSelected(newSelected);
    };
  
    const isSelected = (agent) => selected?.indexOf(agent) !== -1;
  
    // Avoid a layout jump when reaching the last page with empty rows.
    const emptyRows =
      page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;
  
    
  
    return (
      <Box sx={{ width: '100%' }}>
        <Paper sx={{ width: '100%', mb: 2 }}>
          {
            selected?.length ? (
              <TopToolBar numSelected={selected?.length} topHeaderVisible={topHeaderVisible}/>
            ): null
          }
          <TableContainer>
            {
              rows?.length?
            <Table
              sx={{ minWidth: 750 }}
              aria-labelledby="tableTitle"
            >
              <EnhancedTableHead
                numSelected={selected?.length}
                onSelectAllClick={handleSelectAllClick}
                rowCount={rowsI?.length}
                headCell={headCells}
              />
              <TableBody>
                {rowsI?.map((row, index) => {
                  const isItemSelected = isSelected(row._id);
                  const labelId = `enhanced-table-checkbox-${index}`;
                  return (
                    <TableRow
                      hover
                      // onClick={(event) => handleClick(event, row.campaign)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}
                      sx={{ cursor: 'pointer' }}
                      >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          onClick={(event) => handleClick(event, row._id)}
                          checked={isItemSelected}
                          inputProps={{
                            'aria-labelledby': labelId,
                          }}
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        id={labelId}
                        scope="row"
                        padding="none"
                      >
                        <div className='d-flex flex-column'>
                          <p className="fw-bold my-auto nowrap">
                            {row.name}
                            </p>
                            <small className="text-muted">{new Date(row?.joinDate).toLocaleString()}</small>
                          </div>
                      </TableCell>
                      <TableCell>{row?.order_number}</TableCell>
                      <TableCell>{row?.product}</TableCell>
                      <TableCell>{formatNumber(row?.amount)}</TableCell>
                      <TableCell>
                          <Status status={row?.transactionStatus}/>
                      </TableCell>
                      <TableCell>
                        <div className="d-flex gap-2">
                          <SampleDot color={'green'}/>
                          <Button variant='outlined' size='small' sx={{color: 'gray', border: "1px solid gray"}}>View</Button>
                        </div>
                      </TableCell>
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>:
            <>
                  <p className="text-center py-4 text-danger">No Merchant Record yet</p>
            </>
            }
          </TableContainer>
          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Paper>
      </Box>
    );
  }
