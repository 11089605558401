import React from 'react'

export default function TopBar() {
  return (
    <>
        <section className='text-center'>
            <h5 className='fw-bold'>Peter Pharmacy</h5>
        </section>
    </>
  )
}
