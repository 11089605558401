import { useEffect, useMemo, useState } from 'react'
import CustomButton from '../../CustomButton/CustomButton'
import { useVerifyOtp } from '../../../redux/mutation/postMutation';
import OtpInput from 'react-otp-input';
import {useUserData} from '../../hooks/useUserData';
import { ROUTES } from '../../../routes/route';
import { useNavigate } from 'react-router-dom';
import { sendOtpFn } from '../../../redux/fetchingFn';
import "./NumberVerification.css"
import { CircularProgress } from '@mui/material';
function NumberVerification({handleClick}) {
    const [otp, setOtp] = useState('');
    const [isLoading, setIsLoading] = useState(null)
    const [resendLoading, setResendLoading] = useState(null)
    const otpMutation = useVerifyOtp();
    const user = useUserData();
    const navigate = useNavigate();
    // useEffect(()=>{
    //     sendOtpFn();
    // }, [])
    const btnStyle={
        cursor: "pointer",
        color: "#8C0C0C",
        fontSize: "0.7rem",
        disable: resendLoading
    }
    const verifyOtp=()=>{
        otpMutation.mutate({otp});
    }

    useEffect(()=>{
        if(otpMutation.isLoading){
            setIsLoading(true)
        }
        else if(otpMutation.isError){
            setIsLoading(false)
            const msg = otpMutation?.error?.response?.data?.message
            handleClick(msg, 'error')
        }
        else if(otpMutation.isSuccess){
            setIsLoading(false)
            const res = otpMutation?.data?.data
     
                !res?.status ?? handleClick(res?.message, 'error')
       
            if(res.status){
                navigate(ROUTES.bank_statement)
            }
        }
    }, [otpMutation.status])

    // useMemo(()=>{

    // })

    const resendCode=()=>{
        setOtp('')
        setResendLoading(true);
        sendOtpFn().then((res)=>{
            const resData = res?.data
            handleClick(resData?.message, resData?.status?'success':'error', {vertical: "top", horizontal: "right"})
            setResendLoading(false);
        }).catch((err)=>{
            setResendLoading(false);
        })
    }


    useEffect(()=>{
        if(otp.length === 4){
            verifyOtp()
        }

    }, [otp?.length])
  return (
    <>
    <main className='my-3'>
        <section className="col-lg-8 mx-2 mx-lg-auto">
        <p className="fw-bold fs-6 text-center">Enter OTP code to phone number</p><br />
        <span>An OTP code has been sent to <code>{user?.data?.phone}</code>. Kindly input it.</span><br />
            <section className="my-3 d-flex justify-content-center">
                <OtpInput 
                value={otp}
                onChange={setOtp}
                numInputs={4}
                renderSeparator={<span>-</span>}
                inputType='number'
                containerStyle={{flex: "wrap !important", display: 'flex !important', textAlign: 'center'}}
                inputStyle={{width: '45px', height: '50px', margin: '5px 5px', borderRadius: '10px', border: '1px solid'}}
                renderInput={(props) => <input {...props} />}
                onPaste={(event) => {
                    const data = event.clipboardData.getData('text');
                    
                  }}
                />
            </section>
            <div className='d-flex justify-content-end'>
                <p style={btnStyle} className='position-relative' onClick={resendCode}>
                    Resend Code
                    {resendLoading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        position: 'absolute',
                        top: '20%',
                        left: '40%'
                        }}
                    />
                    )}
                </p>
                {/* <p style={btnStyle}>Edit phone number</p> */}
            </div><br />
            <CustomButton text="Enter" functionFn={verifyOtp} loading={isLoading}/>
        </section>
    </main>
    </>
  )
}

export default NumberVerification