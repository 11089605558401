import { useState, useEffect } from "react";
import OtpInput from "react-otp-input";
import { Button } from "antd";
import { useSetPin } from "../../../redux/mutation/postMutation";
import { useFormik } from "formik";
import * as yup from "yup";

export default function SetTransactionPin({ handleClick, handleCloseModal }) {
  const setPinMutation = useSetPin();

  const [isLoading, setIsLoading] = useState(false);

  const [disable, setDisable] = useState(false);

  const formik = useFormik({
    initialValues: {
      pin: "",
      confirmPin: "",
    },
    validationSchema: yup.object().shape({
      pin: yup
        .string()
        .required("Pin is required")
        .min(4, "Pin must be 4 character")
        .max(4, "Pin must be 4 character"),
      confirmPin: yup
        .string()
        .oneOf([yup.ref("pin"), null], "Pin must match")
        .required("Confirm Pin is required"),
    }),
    onSubmit: (values) => {
      const rr = setPinMutation.mutate(
        {
          pin: values.confirmPin,
        },
        {
          onError: (error)=>{
            if(error?.response?.data?.message==="You have already set your pin"){
              handleCloseModal();
            }
          },
          onSuccess: (data) => {

            console.log("Mutation success:", data);
            console.log("Calling handleCloseModal on success");

            handleCloseModal();

            const res = data?.data

            // window.location.reload();



            if (res?.status) {
              setIsLoading(false);
      
              handleClick(res?.message, "success");
      
            }
      
            !res?.status ?? handleClick(res?.message, "error");

          },
          onSettled:()=>{

          }
        }
      );
      console.log(rr)
    },
  });

  useEffect(() => {
    if (formik.errors.length > 0 || formik.touched.length === 0) {
      setDisable(true);
    } else {
      setDisable(false);
    }
  }, [formik]);

  const handleSetPin = () => {
    formik.handleSubmit();
  };

  const handlePinChange = (val) => {
    formik.setFieldValue("pin", val);
  };

  const handleConfirmPinChange = (val) => {
    formik.setFieldValue("confirmPin", val);
  };

  useEffect(() => {
    if (setPinMutation.isLoading) {
      setIsLoading(true);
    } else if (setPinMutation.isError) {
      setIsLoading(false);
      let message = setPinMutation?.error?.response?.data?.message;
      let msg3 = setPinMutation?.error?.response?.data?.error;
      let message2 = setPinMutation?.error?.message;

      handleClick(message ? message : msg3 ?? message2, "error");

    } else if (setPinMutation.isSuccess) {
      setIsLoading(false);
      const res = setPinMutation?.data?.data;

      handleCloseModal();


    }
  }, [setPinMutation?.status]);

  return (
    <>
      <section className="col-lg-8 mx-2 mx-lg-auto">
        <p className="fw-bold fs-6 text-center">
          Create Transaction Pin <br />
          {/* <small className={message.status === "error" ? "text-danger" : ""}>
            {message.msg}
          </small> */}
        </p>
        <section className="my-3 d-flex flex-column gap-3 text-center justify-content-center">
          <div>
            <label htmlFor="" className="text-start">
              Set Pin
            </label>
            <OtpInput
              name="pin"
              value={formik.values.pin}
              onChange={handlePinChange}
              numInputs={4}
              // renderSeparator={<span>-</span>}
              inputType="number"
              containerStyle={{
                flex: "wrap !important",
                display: "flex !important",
                // textAlign: "center",
              }}
              inputStyle={{
                width: "50px",
                height: "50px",
                margin: "5px 10px",
                borderRadius: "10px",
                border: "1px solid",
              }}
              renderInput={(props) => <input {...props} />}
              onPaste={(event) => {
                const data = event.clipboardData.getData("text");
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.pin && formik.errors.pin}
            </small>
          </div>
          <div>
            <label htmlFor="" className="text-start">
              Confirm Pin
            </label>
            <OtpInput
              name="pin"
              value={formik.values.confirmPin}
              onChange={handleConfirmPinChange}
              numInputs={4}
              // renderSeparator={<span>-</span>}
              inputType="number"
              containerStyle={{
                flex: "wrap !important",
                display: "flex !important",
                // textAlign: "center",
              }}
              inputStyle={{
                width: "50px",
                height: "50px",
                margin: "5px 10px",
                borderRadius: "10px",
                border: "1px solid",
              }}
              renderInput={(props) => <input {...props} />}
              onPaste={(event) => {
                const data = event.clipboardData.getData("text");
              }}
            />
            <small style={{ fontSize: "13px" }} className="text-danger">
              {formik.touched.confirmPin && formik.errors.confirmPin}
            </small>
          </div>
        </section>
        <div className="d-flex justify-content-end">
          <Button
            onClick={handleSetPin}
            size="large"
            shape="round"
            style={{
              background: disable ? "" : "#033F4B",
              color: disable ? "grey" : "#fff",
              fontSize: "18px",
              width: "100%",
              height: "55px",
              border: "none",
            }}
            loading={isLoading}
          >
            Create Pin
          </Button>
        </div>
      </section>
    </>
  );
}
