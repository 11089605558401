import React from 'react'
import LoginScreen from '../screens/AuthScreen/LoginScreen/LoginScreen'

function Login({handleClick}) {
  return (
   <>
        <LoginScreen handleClick={handleClick}/>
   </>
  )
}

export default Login