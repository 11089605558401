import { Link } from "react-router-dom";
import bills from "../../../assets/bills.png";
import mtn from "../../../assets/mtn.png";
import glo from "../../../assets/glo.png";
import gotv from "../../../assets/gotv.png";
import startime from "../../../assets/star time.png";
import dstv from "../../../assets/dstv.png";
import ikedc from "../../../assets/ikedc.png";
import ikeja from "../../../assets/ikeja.png";
import abuja from "../../../assets/abuja.png";
import { ROUTES } from "../../../routes/route";
import { styled } from "styled-components";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { Autoplay, Pagination, Navigation } from 'swiper/modules';

const billsData = [
  { name: "Ikedc Electric", img: ikedc },
  { name: "Ikeja Electric", img: ikeja },
  { name: "Abuja Electric", img: abuja },
  { name: "DSTV", img: dstv },
  { name: "GOTV", img: gotv },
  { name: "Startime", img: startime },
  { name: "MTN", img: mtn },
  { name: "GLO", img: glo },
];
function BillSection() {
  return (
    <>
      <main className="shop_now_section py-5 bg-white">
        <section className="container-lg mx-aut mx-lg-5 container-fluid row ">
          <div className="col-lg-7 col-md-7 my-auto">
            <div data-aos="zoom-in-down">
              <h3 className="section_two" style={{ fontWeight: 500, textTransform: "capitalize" }}>
                Never let a low balance dim your lights!
              </h3>
              <br />
              <p
                className="secondary_text"
                style={{ fontSize: "22px", fontWeight: 300 }}
              >
                Buy airtime, pay bills, and keep the electricity flowing with
                options to pay now or later. Stay connected, stay powered!
              </p>
            </div>

           


            <div className="bottom_content row">
              <div className="related_companies col-12 d-flex">
              <Swiper
              slidesPerView={5}
        // spaceBetween={5}
        centeredSlides={false}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        // navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper d-flex align-items-center justify-content-center"
      >
                {billsData.map((item, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <img
                      
                        src={item?.img}
                        alt={item?.name}
                        className="mx-1"
                        style={{
                          width: "50px",
                          maxWidth: "50px",
                          height: "100%",
                          borderRadius: "597.97px",
                          boxShadow: "0px 4px 4px 0px #00000040",
                        }}
                      />
          
                    </SwiperSlide>
                  );
                })}
                 <SwiperSlide>
                 <div style={{
                        width: "100px",
                        maxWidth: "100px",
                        height: "100%",
                        borderRadius: "597.97px",
                        boxShadow: "0px 4px 4px 0px #00000040",
                      }} className="d-flex align-items-center justify-content-center">
                        <span>And More</span>
                      </div>
          
                    </SwiperSlide>
       
      </Swiper>
                

              </div>
              <div className="col-12 mt-4">
                <Link
                  to={ROUTES?.bill}
                  style={{
                    textDecoration: "none",
                    fontWeight: 500,
                    size: "19px",
                    lineHeight: "28.5px",
                    color: "#2f2e2e",
                    transition: "all 0.3s",
                  }}
                >
                  <Strong className="border-black border-2 border-bottom border-0">Start Here</Strong>
                </Link>
              </div>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 mt-4 col-12 shop_now_left_section d-flex align-items-end text-end justify-content-end"
            data-aos="zoom-in"
          >
            <img
              src={bills}
              alt="product marketplace"
              className="card-img-top"
            />
          </div>
        </section>
      </main>
    </>
  );
}

export default BillSection;

const Strong = styled("strong")`
        cursor: pointer;
        padding: 3px;
        font-size: 20px;
        // line-height:43.5px;
    }

    @media only screen and (max-width: 820px){
        font-size: 30px;
    }
    @media only screen and (max-width: 768px){
        font-size: 23px;
    }
`;
