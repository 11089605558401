import React, { useState } from "react";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import TopToolBar from "../../../utils/Table/TopToolBar";
import Status from "../../../utils/Status";
import { Button } from "@mui/material";
import EnhancedTableHead from "../../../utils/Table/EnhancedTableHead";
import SampleDot from "../../../utils/SampleDot";
import { formatNumber } from "../../../utils/formatNumber";
import moment from "moment";


export default function OrderTable({
  topHeaderVisible,
  rows,
  headCell,
  handleOpen,
}) {
  const [selected, setSelected] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  // const handleChangePage=()=>{
  //   setPage(page+1)
  // }


  const headCells = [
    // {
    //   label: "Customer",
    // },
    {
      label: "Ref. Number",
    },
    {
      label: "Category",
    },
    {
      label: "Product",
    },
    {
      label: "Amount",
    },
    {
      label: "Status",
    },
    {
      label: "Payment",
    },
    {
      label: "Channel",
    },
    {
      label: "Actions",
    },
  ];

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = rows?.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, orderId) => {
    const selectedIndex = selected?.indexOf(orderId);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, orderId);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected?.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const isSelected = (agent) => selected?.indexOf(agent) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows?.length) : 0;


  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }}>
        {rows?.length ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            {selected?.length ? (
              <TopToolBar
                numSelected={selected?.length}
                topHeaderVisible={topHeaderVisible}
              />
            ) : null}
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <EnhancedTableHead
                  numSelected={selected?.length}
                  onSelectAllClick={handleSelectAllClick}
                  rowCount={rows?.length}
                  headCell={headCells}
                />
                <TableBody>
                  {rows?.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)?.map((row, index) => {
                    const isItemSelected = isSelected(row.id);
                    const labelId = `enhanced-table-checkbox-${index}`;
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.campaign)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                        sx={{ cursor: "pointer" }}
                      >
                        <TableCell padding="checkbox">
                          {/* <Checkbox
                            color="primary"
                            onClick={(event) => handleClick(event, row.id)}
                            checked={isItemSelected}
                            inputProps={{
                              "aria-labelledby": labelId,
                            }}
                          /> */}
                        </TableCell>
                        {/* <TableCell
                          component="th"
                          id={labelId}
                          scope="row"
                          padding="none"
                        >
                          <div className="d-flex flex-column">
                            <p className="fw-bold my-auto nowrap">
                            {row?.user?.email}
                            </p>
                            <small className="text-muted nowrap" style={{fontSize: "12px"}}>
                              {
                                moment(row?.createdAt).format('h:mm:ss, D MMMM YYYY')
                              }
                            </small>
                          </div>
                        </TableCell> */}
                        <TableCell className="nowrap">{row?.reference}</TableCell>
                        <TableCell>
                        <div className="d-flex flex-column">
                            <p className="fw-bold my-auto nowrap">
                              {
                                row?.subscription ? "Subscription" : row?.order?.is_credit ? "Online Marketplace" : row?.order?.is_bill?"Bills payment" : "" 
                              }
                            </p>
                            <small className="text-muted nowrap" style={{fontSize: "12px"}}>
                              {
                                row?.subscription ? "" : row?.order?.is_credit ? row?.order?.market_place?.name : row?.order?.is_bill? row?.order?.order_name: "" 
                              }
                            </small>
                          </div>
                        </TableCell>
                        <TableCell>
                          <p className="my-auto" style={{maxWidth: '20vh',
                            whiteSpace: "nowrap",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                          }}>
                            {
                                row?.subscription? row?.subscription?.planId :
                              row?.order?.is_bill? (
                                <>
                                <span className="" style={{textTransform: "uppercase"}}>{row?.order?.products[0]?.service_type}</span>
                                </>
                              ): (
                                row?.order?.is_credit?
                                <span className="d-flex flex-column">
                                {
                                row?.order?.products[0]?.name
                                } {row?.order?.products?.length > 1 && `and ${row?.order?.products?.length - 1} product more`
                                }
                                <a href={row?.order?.products[0]?.url} className="text-sm text-decoration-none text-center" style={{color: "#740C0C"}}>View link</a>

                                </span>: null
                              )
                            }
                          </p>
                        </TableCell>
                        <TableCell>
                        {formatNumber(row?.amount)}
                        </TableCell>
                        <TableCell>
                          <div className="d-flex flex-column text-center">
                          {
                                row?.subscription ? "Renewal" : row?.order?.is_credit ? "Repayment" : row?.order?.is_bill?"Instant payment" : "" 
                              }
                          </div>
                        </TableCell>
                        <TableCell>
                          <div className="d-flex flex-column text-center">
                            <p className="my-auto text-muted text-capitalize">
                             {
                              row?.subscription? "Paid" : row?.order?.default? "Default" : row?.order?.is_credit? "Credit" : row?.order?.is_bill?"Paid": ""
                             }
                            </p>
                            <small className="text-muted nowrap" style={{fontSize: "12px"}}>{moment(row?.repaymentDuration).format("D, MMMM YYYY") ?? "No content"}</small>
                          </div>
                        </TableCell>
                        <TableCell>{row?.channel ?? "xxxx"}</TableCell>
                        <TableCell>
                          <div className="d-flex gap-2">
                            <SampleDot color={row?.status==='pending'? '#F8D86E': row?.status==='approved' && "green"} />
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{ color: "gray", border: "1px solid gray" }}
                            //   onClick={()=>handleOpen("order", row)}
                            >
                              View
                            </Button>
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                  {emptyRows > 0 && (
                    <TableRow
                      style={{
                        height: 53 * emptyRows,
                      }}
                    >
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
          <div>

          </div>
          </Paper>
        ) : (
          <main
            className="d-flex align-items-center justify-content-center flex-column border"
            style={{ height: "25vh", width: "100%" }}
          >
            <h3>History is Empty</h3>
          </main>
        )}
      </Box>
    </React.Fragment>
  );
}