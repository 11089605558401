import React from 'react'
import VerifyAccountScreen from '../screens/AuthScreen/VerifyAccountScreen/VerifyAccountScreen'

function VerifyAccount() {
  return (
    <>
        <VerifyAccountScreen />
    </>
  )
}

export default VerifyAccount