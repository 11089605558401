import React from 'react'
import Header from '../../../components/Shop/Header/Header'
import { formatNumber } from '../../../utils/formatNumber';
import { Outlet } from 'react-router-dom';
import { useGetMerchantSettlement } from '../../../redux/hooks/getHooks'
export default function MerchantDashboardScreen() {
  const getMerchantSettlement = useGetMerchantSettlement();
    const allSettlement = getMerchantSettlement.data?.data?.data?.settlements;

    const headerData = [
        {value: 0, key: "Transasction Count"},
        {value: <>₦20, 000</>, key: "Total Transaction Amount"},
        {value: allSettlement?.length ?? formatNumber(15000), key: "Total Settlement Received"}
    ]

  return (
    <>
        <main>
            <Header data={headerData}/>
            <Outlet />
        </main>
        
        
    </>
  )
}
