import React from 'react'
import ProductCard from './ProductCard'
import sofa from "../../../assets/sofa.png"
import prod from "../../../assets/prod.png"
import deliv from "../../../assets/deliv.png"
import step_1 from "../../../assets/step_1.png"
import step_2 from "../../../assets/step_2.png"
import step_3 from "../../../assets/step_3.png"
import nfc_tech from "../../../assets/nfc_tech.png"
import StartButtons from '../../Buttons/StartButtons'
import { ROUTES } from '../../../routes/route'
import { useNavigate } from 'react-router-dom'
import "./howItWork.css"

function HowItWork({componentWork}) {
    const navigate = useNavigate();
    const data = [
        {desc: "Sign up and complete KYC in just a minute!", title: "Sign Up", img: step_1},
        {desc: "Choose your credit plan", title: "Choose Credit Plan", img: step_2},
        {desc: "Shop at affiliated stores or share item web link on Vouch to pay later.", title: "Shop on credit", img: step_3},
    ]
    const imageStyle={
        width: "95%"
    }
  return (
    <>
    <main className='how_it_work py-5' ref={componentWork}>
        <section className="col-11 mx-auto text-center d-flex flex-column gap-4">
            <div className="header" >
                <h3 className='text-white title_text' >How Vouch Works</h3>
                <p className="text-light steps_it_take" >It takes just 4 steps to shop on credit in a marketplace of your choice</p>
    <br />
                {/* <p  className="text-center text-white small_text">Unlock shopping freedom with Vouch: Pay later across multiple marketplaces without interest or extra costs. Shop now, pay in 5 to 10 days, and never miss out on great deals again!</p> */}
            </div>
            <section className="other_contents">
                <div className="row mx-2">
                        {
                            data.map(({desc, title, img, brake}, index)=>{
                                return (
                                         <div className="col-lg-4 col-md-6 col-12 my-2 mx-auto text-center text-light" key={index}>
                                <h4 className="steps_it_take" >{title}</h4>
                                    <img src={img} alt={`step`} className='card-img-top border rounded' style={imageStyle}/>
                                    <p className={`text-center secondary_text text-light mt-3`} style={{}} >
                                   {desc}
                                    </p>
                        </div>
                            )
                        })
                        }
                        {/* <div className="col-lg-3 col-md-6 col-12" data-aos="fade-down">
                        <ProductCard name={name} title={title} image={img} brake={brake} />
                    </div> */}
                    </div>
            </section>
            <div className="text-light">
                <StartButtons title={"Get Started"} color={'white'}/>
            </div>
        </section>
        </main>
       
    </>
  )
}

export default HowItWork