import { Button, Modal } from "antd";
import React from "react";
import {
  DialogTitle,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
} from "@mui/material";
import { formatNumber } from "../../../../utils/formatNumber";
import { useSelector } from "react-redux";
import CartCard from "./CartCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

export default function SuperStoreCheckout({ changeCurrentStage }) {
  const [contextHolder] = Modal.useModal({});

  const { allProducts, totalAmount } = useSelector((state) => state.superStore);

  return (
    <>
      <section className="col-lg-11 mx-auto">
        <div className="text-center">
          <DialogTitle>Cart</DialogTitle>
        </div>
        {allProducts?.length ? (
          <section className="">
            <div className="row mx-2">
              <div className="col-md-8 col-sm-12">
                <div className="bg-white my-3">
                  <div>
                    {allProducts?.map((product, index) => {
                      return <CartCard key={index} product={product} />;
                    })}
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card h-100 p-2 d-flex flex-column justify-content-between">
                  <div className="order_summary">
                    <p className="border-0 border-bottom py-2">Order Summary</p>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>Subtotal</TableCell>
                          <TableCell>{formatNumber(totalAmount)}</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell className="fw-semibold">Total</TableCell>
                          <TableCell>{formatNumber(totalAmount)}</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                    <div className="mt-4">
                      <Select
                        size="small"
                        name="category"
                        defaultValue="Select store location"
                        fullWidth
                        // onChange={formik.handleChange}
                        // onBlur={formik.handleBlur}
                        value={""}
                        renderValue={(selected) => {
                          if (!selected) {
                            return <em>Placeholder</em>;
                          }
                          return selected
                        }}
                      >
                        <MenuItem value="pickup">via pickup</MenuItem>
                        <MenuItem value="delivery">via delivery</MenuItem>
                      </Select>
                      <div className="py-2 px-3 mt-3 border rounded">
                        <p
                          style={{
                            color: "#033F4B",
                          }}
                        >
                          Store Address
                        </p>
                        <p
                          style={{
                            color: "#033F4B",
                          }}
                        >
                          Justrite Shomolu
                        </p>
                        <p
                          style={{
                            color: "#000000",
                          }}
                        >
                          0801234578
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="checkout_btn mt-lg-5 mt-3">
                    <Button
                      size="large"
                      shape="round"
                      // loading={isLoading}
                      // disabled={!activateNextBtn}

                      style={{
                        backgroundColor: "#033F4B",
                        width: "100%",
                        color: "white",
                        border: "none",
                        height: "55px",
                      }}
                      onClick={() => changeCurrentStage("checkout")}
                    >
                      Continue
                    </Button>
                  </div>
                </div>
              </div>
              {/* ) : (
                        <>
                        <p className={`card-text text-center my-2`}>
                            You have no product in your cart yet.
                        </p>
                        </>
                    )} */}
            </div>
          </section>
        ) : (
          <div
            className="container mx-auto rounded bg-white border mt-4 d-flex justify-content-center align-items-center shadow-sm"
            style={{ height: "50vh" }}
          >
            <div className="d-flex flex-column align-items-center justify-content-center text-center">
              <div
                className="rounded-circle mb-3 d-flex justify-content-center align-items-center"
                style={{
                  height: "15vh",
                  width: "15vh",
                  backgroundColor: "#F5F5F5",
                }}
              >
                <ShoppingCartIcon sx={{ fontSize: "8vh", color: "#033F4B" }} />
              </div>
              <h4 className="text-sm mb-5">Your cart is empty!</h4>
              <Button
                size="large"
                sx={{
                  backgroundColor: "#033F4B",
                  color: "#fff",
                  "&:hover": {
                    backgroundColor: "#045E6E",
                    color: "white",
                  },
                }}
                onClick={() => changeCurrentStage("")}
              >
                START SHOPPING
              </Button>
            </div>
          </div>
        )}
      </section>

      {/* <ModalDialogBox open={open} handleClose={handleClose} modalSize={true}>
      {
        <OnlineMarketPlace
          handleClick={handleClick}
          handleClose={handleClose}
        />
      }
    </ModalDialogBox> */}

      {/* {contextHolder} */}
    </>
  );
}
