import React, { useCallback, useEffect, useState } from "react";
import { useFormik } from "formik";
import ElectricityBase from "./electricity";
import BillMenu from './BillMenu';
import Airtime from "./airtime";
import CableTv from "./CableTv";
import MobileData from "./mobile_data";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useUserData } from "../../components/hooks/useUserData";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AnimatePresence, motion } from "framer-motion";
import { useResponsiveSize } from "../../components/hooks/useResponsiveSize";
import { useGetDiscos, useGetMarketPlace } from '../../redux/hooks/getHooks'
import { useDiscos } from "../../components/hooks/useDiscos";
import BillResponse from "../../components/ResponseModal/bill/BillResponse";
import { useQueryClient } from "@tanstack/react-query";

export default function UtilityPages({ bill_name, handleClick }) {
  const [bill, setBill] = useState("electricity");

  const [openBillMenu, setOpenBillMenu] = useState(true);

  const [isOpen, setIsOpen] = useState({state: false, data: ""});



  const queryClient = useQueryClient();

  //discos
  // const {data:discosData} = useGetDiscos();


  const handleOpenModal=(data)=>{
    setIsOpen({state: true, data})
  }

  const handleCloseModal=()=>{
    setIsOpen({...isOpen, state: false})
  }





  

  const [billData, setBillData ] = useState()

  const { data, status } = useUserData();

  const getMarketPlaces = useGetMarketPlace("user");
  const marketPlace = getMarketPlaces?.data?.data?.data?.find(
    (val) => val?.status && (val?.name)?.toLowerCase() === 'bills'
  );


  const responsiveSize = useResponsiveSize({});

  const location = useLocation().pathname;

  useEffect(()=>{
    if(localStorage.billData){
      let localStorageBillData = JSON.parse(localStorage.getItem('billData'))

      setBillData(localStorageBillData)

      setBill(localStorageBillData?.data?.order_name)

      setTimeout(()=>{
        localStorage.removeItem("billData")
      }, 5000)

      
    }
    else{
      setBillData(null)
      setBill('electricity')
    }
  }, [])


  useEffect(()=>{
    queryClient.invalidateQueries(['me'])
}, [])




  useEffect(() => {
    if (bill_name) {
      setBill(bill_name);
    }
  }, [bill_name]);
  


  const formik = useFormik({
    initialValues: {
      meterNumber: "",
      distributionCompany: "",
      vendType: "PREPAID",
      amount: "",
      mobileNumber: data?.phone ?? "",
      network: "",
      dataBundle: "",
      email: data?.email ?? "",
      dstvCardNumber: "",
      subscriptionPlan: "",
      decoderNumber: "",
      smartCardNumber: "",
      service: "",
      marketPlace: marketPlace ?? "",
      name: data ? `${data?.first_name} ${data?.last_name}` : ""
    },
    enableReinitialize: true,
  });

  const toggleBillMenu = () => {
    setOpenBillMenu(!openBillMenu);
  };



  useEffect(()=>{
    if(responsiveSize==='sm'){
      setOpenBillMenu(false)
    }
    else{
      setOpenBillMenu(true)
    }
  }, [responsiveSize])
  return (
    <>
      <main
        className="bg-white"
        style={{
          transition: "all 0.5s",
          position: "relative",
        }}
      >
        {location==="/bill" && <Navbar />}
        <section className="container d-flex gap-3">
          <AnimatePresence>
            {openBillMenu && <BillMenu setBill={setBill} bill={bill} toggleBillMenu={toggleBillMenu}/>}
          </AnimatePresence>

          <AnimatePresence>
            <motion.div
              className="w-100 d-flex"
              style={{
                marginLeft: `${openBillMenu ? "18rem" : "0"}`,
              }}
              initial={{ x: -300 }}
              animate={{ x: 0 }}
              exit={{ x: -300 }}
              transition={{ duration: 0.5 }}
            >
              {(responsiveSize === "sm") && (
                <div className="mt-3">
                  <span className="cursor-pointer" style={{

                  }} onClick={toggleBillMenu}>
                    {openBillMenu ? (
                      <ArrowBackIosIcon sx={{ fontSize: "5vh" }} />
                    ) : (
                      <ArrowForwardIosIcon sx={{ fontSize: "5vh" }} />
                    )}
                  </span>
                </div>
              )}

              {
                (responsiveSize==='sm' && openBillMenu) ? null : (
              <div className="w-100">
                {bill === "electricity" ? (
                  <ElectricityBase formik={formik} handleClick={handleClick} savedData={billData?.data?.order_name==='electricity' ? billData : null} openModal={handleOpenModal}/>
                ) : bill === "airtime" ? (
                  <Airtime formik={formik} handleClick={handleClick} savedData={billData?.data?.order_name==='airtime' ? billData : null} openModal={handleOpenModal}/>
                ) : bill === "data" ? (
                  <MobileData formik={formik} handleClick={handleClick} savedData={billData?.data?.order_name==='data' ? billData : null} openModal={handleOpenModal}/>
                ) : bill === "cable" ? (
                  <CableTv formik={formik} handleClick={handleClick} savedData={billData?.data?.order_name==='cable' ? billData : null} openModal={handleOpenModal}/>
                ) : (
                  "coming soon"
                )}
              </div>

                )
              }
            </motion.div>
          </AnimatePresence>
        </section>
      </main>


              <BillResponse isOpen={isOpen.state} closeModal={handleCloseModal} data={isOpen.data}/>

    </>
  );
}
