import { useEffect } from "react"
import { useGetAdminProfile, useGetMerchantProfile, useGetUserSubscriptions, useMe, useGetUserCreditEstimate, useGetMarketPlace } from "../../redux/hooks/getHooks"
import { useDispatch } from "react-redux";
import { productAction } from "../../redux/slice/productSlice";

export const useUserData=()=>{
    const {data, error, isLoading} = useMe();
    const sub = useGetUserSubscriptions();

    const dispatch = useDispatch({})

    const getMarketPlaces = useGetMarketPlace("user");
    
  const marketPlaces = getMarketPlaces?.data?.data?.data;

  useEffect(()=>{
    dispatch(productAction.setMarketPlaces(marketPlaces))
  }, [])

  if(isLoading){
    const msg = error?.response?.data?.message
    return {status: false, msg}
  }

    if(!error){
        const responseData = data?.data?.data?.user
        const creditEstimate = data?.data?.data?.credit_estemate
        const details = {...responseData, creditEstimate: {...creditEstimate}}
        return {status: true, data: details}
    }
    else if(error){
        // console.log(error?.response);
        const msg = error?.response?.data?.message
        return {status: false, msg}
    }
}

export const useAdminData=()=>{
    const {data, error} = useGetAdminProfile();




    if(!error){
        const responseData = data?.data?.data
        return {status: true, data: responseData}
    }
    else if(error){
        const msg = error?.response?.data?.message


        return {status: false, msg}
    }
}
export const useMerchantData=()=>{
    const {data, error} = useGetMerchantProfile();
    if(!error){
        const responseData = data?.data?.data?.profileData
        return {status: true, data: responseData}
    }
    else if(error){
        const msg = error?.response?.data?.message


        return {status: false, msg}
    }
}