import React from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import profile from "../../../../assets/avatar.png";
import { Button } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { useGetBankStatement } from "../../../../redux/hooks/getHooks";

const columns = [
  { id: "s_n", label: "S/N", width: 20 },
  { id: "type", label: "Type", width: 50 },
  { id: "amount", label: "Amount", width: 70 },
  {
    id: "narration",
    label: "Narration",
    width: 250,
  },
  // {
  //   id: 'balance',
  //   label: 'Balance',
  //   width: 170,
  // },
  {
    id: "date",
    label: "Date",
    width: 170,
  },
  {
    id: "currency",
    label: "Currency",
    width: 50,
  },
];

export default function NINDetails({ details, userId }) {
  const profileStyle = {
    backgroundImage: `url(${profile})`,
    width: "30vh",
    height: "30vh",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
  };

  const queryClient = useQueryClient();


  const getUserBkStatement = useGetBankStatement(userId);

  
  const statementDetailsRes = getUserBkStatement?.data?.data;

  const ninDetails = statementDetailsRes?.data?.nin_identities


  const handleRetry=()=>{
    queryClient.invalidateQueries(["bank_statement", userId])
  }


  return (
    <>
      <div className="p-1 p-md-3">
        <h3 className="text-center">NIN Details</h3>
        <div className="d-flex justify-content-center">
          <div style={profileStyle}></div>
        </div>

        {ninDetails?.nin ? (
          <div className="row mx-2 mt-4">
            <div className="col-sm-6">
              <p>
                Name: {ninDetails?.surname} {ninDetails?.firstname}{" "}
                {ninDetails?.middlename}
              </p>
            </div>
            <div className="col-sm-6">
              <p>NIN Number: {ninDetails?.nin ?? "NIL"}</p>
            </div>
            <div className="col-sm-6">
              <p>Phone Number: {ninDetails?.telephoneno ?? "NIL"}</p>
            </div>
            <div className="col-sm-6">
              <p>Title: {ninDetails?.title ?? "NIL"}</p>
            </div>
            <div className="col-sm-6">
              <p>Gender: {ninDetails?.gender === "m" ? "Male(M)" : "Female(F)"}</p>
            </div>
            <div className="col-sm-6">
              <p>Profession: {ninDetails?.profession ?? "NIL"}</p>
            </div>

            <div className="col-sm-6">
              <p>Marital Status: {ninDetails?.maritalstatus ?? "NIL"}</p>
            </div>
            <div className="col-sm-6">
              <p>Date of Birth {ninDetails?.birthdate ?? "NIL"}</p>
            </div>
            <div className="col-sm-6">
              <p>Residence address: {ninDetails?.residence_address ?? "NIL"}</p>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-center mt-3">
            <Button
              shape="round"
              size="large"
              style={{
                background: "#033F4B",
                color: "#fff",
                fontSize: "18px",
                height: "55px",
                padding: "0 50px",
              }}
              loading={getUserBkStatement?.isLoading}
              onClick={handleRetry}
            >
              Retry
            </Button>
          </div>
        )}
      </div>
    </>
  );
}
