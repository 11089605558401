import React, { useState, useEffect } from "react";
import SubscriptionPlan from "../../Subscription/SubscriptionPlan";

import { useDeclineKyc } from "../../../redux/mutation/AdminPostMutation";

import ModalDialogBox from "../../ModalDialogBox/ModalDialogBox";
import { Button } from "@mui/material";

import CircularProgress from "@mui/material/CircularProgress";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../routes/route";


export default function SetSubscriptionPlan({
  adminName,
  user,
  handleClick,
  handleClose,
}) {
  const declineMutation = useDeclineKyc(user?.id);

  const queryClient = useQueryClient();

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [comment, setComment] = useState("");

  const navigate = useNavigate({});

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleDecline = () => {

    const data = { comment }

    declineMutation.mutate(data, {
      onSuccess:()=>{
        queryClient.invalidateQueries(["users"])
      }
    });
  };


  const handleCancel = () => {
    setIsModalOpen(false);
  };


  useEffect(() => {
    if (declineMutation?.isLoading) {
      setIsLoading(true);
    } else if (declineMutation?.isError) {
      setIsLoading(false);

      let msg = declineMutation?.error?.response?.data?.message;
      let msg2 = declineMutation?.error?.response?.statusText;

      handleClick(msg ?? msg2, "error", {
        vertical: "top",
        horizontal: "right",
      });
    } else if (declineMutation?.isSuccess) {
      setIsLoading(false);
      const msg = declineMutation?.data?.data?.message;
      setComment("")
      handleClick(msg, "success", { vertical: "top", horizontal: "bottom" });
      handleCancel();
      handleClose()
      navigate(ROUTES.adminDashboard)
    }
  }, [declineMutation?.status]);

  return (
    <>
      <SubscriptionPlan
        role={"user_plans"}
        declineUserFn={showModal}
        eachUser={user}
        handleClick={handleClick}
        handleClose={handleClose}
      />

      <ModalDialogBox open={isModalOpen} handleClose={handleCancel}>
        <div
          className="card border-0"
          
        >
          

          <div className="mt-4">
            <label htmlFor="">Provide the comment</label>
            <textarea
              name=""
              id=""
              cols="40"
              rows="10"
              className="form-control"
              placeholder="Provide the comment here"
              value={comment}
              onChange={(e)=>setComment(e.target.value)}
            ></textarea>
          </div>

          <div className="mt-2 d-flex justify-content-end">
            <Button
              className=""
              variant="contained"
              disabled={isLoading}
              sx={
                isLoading
                  ? { cursor: "not-allowed" }
                  : {
                      backgroundColor: "#033F4B",
                      color: "white",
                      "&:hover": {
                        backgroundColor: "#033F4B",
                      },
                    }
              }
              onClick={handleDecline}
            >
              Submit
              {isLoading && (
                <CircularProgress
                  size={26}
                  sx={{
                    position: "absolute",
                    // top: '45%',
                    left: "50%",
                  }}
                />
              )}
            </Button>
          </div>
        </div>
      </ModalDialogBox>

    </>
  );
}
