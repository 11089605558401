import { useParams } from "react-router-dom";
import LoanSummary from "./LoanSummary";
import { useUserLoanApproval } from "../../../redux/hooks/zustands/useUserLoanApproval";
import BVN_OTP from "./BVN_OTP";
import BankAccounts from "./BankAccounts";
import SetMandate from "./SetMandate";

export default function UserLoanApprovalPages(){

    const params = useParams();
  const orderID = params.orderId;


  const { currentPage } = useUserLoanApproval();

  console.log(orderID)


    return(
        <>
            {
                currentPage==="LOAN_SUMMARY" ? <LoanSummary orderID={orderID}/> : currentPage==="BVN_OTP" ? <BVN_OTP /> : currentPage==="BANK_ACCOUNTS" ? <BankAccounts /> : currentPage==="SET_MANDATE"? <SetMandate />: null
            }
            
        </>
    )
}