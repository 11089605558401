import React from 'react'
import LandingPageScreen from '../screens/LandingPageScreen/LandingPageScreen'

function LandingPage() {
  return (
    <>
        <LandingPageScreen />
    </>
  )
}

export default LandingPage