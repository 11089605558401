import React from 'react'
import MerchantLoginScreen from '../../screens/Merchant/MerchantLoginScreen/MerchantLoginScreen'

export default function MerchantLogin({handleClick}) {
  return (
    <>
        <MerchantLoginScreen handleClick={handleClick}/>
    </>
  )
}
