import React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useImperativeHandle, forwardRef, useRef } from 'react';
import { Slide } from '@mui/material';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SlideTransition(props) {
  return <Slide {...props} direction="up" />;
}
function SlideTransitionWithDirection(props) {
  return <Slide {...props} direction="down" />;
}

export default function CustomizedSnackbars(props) {

  const { severity, open, message, handleClose, origin} = props
  return (
    <Snackbar open={open} autoHideDuration={4000} onClose={handleClose} anchorOrigin={origin??{vertical: "bottom", horizontal: "left"}} TransitionComponent={origin?.vertical==='top'?SlideTransitionWithDirection: SlideTransition}>
    <Alert onClose={handleClose} severity={severity} sx={{ width: '100%', fontSize: "12px" }}>
      {message}
    </Alert>
  </Snackbar>
  );
}