import React from 'react'
import Header from './Header'

export default function AddressInformation({data, actionFn, actionLoading, actionSuccess}) {


  return (
    <>
        <main>
        <Header title={"Address Information"} actionText={"Approve"} actionFn={(status)=>actionFn('contact', status)} actionLoading={actionLoading} actionSuccess={actionSuccess}/>
        <section>
            {/* home address */}
          <section className="col-lg-11 mx-auto">
            <div className="row mx-1 my-1">
              <div className="col-lg-4 col-4">
                <p className="text-dark my-auto" style={{ fontSize: "15px" }}>
                  Home Address:
                </p>
              </div>
              <div className="col-lg-6 col-6">
                <small className="">{!!data?.address ? data?.address: "Not Available"}</small>
              </div>
              <div className="col-lg-2 col-2">
              <small className={data?.addressMatch?'text-success':'text-danger'}>
                                {
                                  data?.addressMatch?'Match':'Not Match'
                                }
                                </small>
                          </div>
            </div>
          </section>
        {/* city */}
        <section className='col-lg-11 mx-auto'>
              <div className="row mx-1 my-1">
                          <div className="col-4">
                              <p className="text-dark my-auto" style={{fontSize: "15px"}}>City:</p>
                          </div>
                          <div className="col-lg-6 col-6">
                              <small className="">{!!data?.city? data?.city : 'Not Available'}</small>
                          </div>
              </div>
          </section>
        {/* state */}
        <section className='col-lg-11 mx-auto'>
              <div className="row mx-1 my-1">
                          <div className="col-4">
                              <p className="text-dark my-auto" style={{fontSize: "15px"}}>State:</p>
                          </div>
                          <div className="col-lg-6 col-6">
                              <small className="">{!!data?.state ? data?.state : "Not Available"}</small>
                          </div>
              </div>
          </section>
        </section>
      </main>
    </>
  )
}
