import { createSlice } from "@reduxjs/toolkit";

const stepperSlice = createSlice({
    name: "stepper",
    initialState: {
        kycStep: [{}, {}, {}, {}],
        initialState: 'start',
        activeStep: 0
    },
    reducers: {
        increaseActiveStep: (state, action)=>{
            state.activeStep = state.activeStep + 1
        },
        decreaseActiveStep: (state, action)=>{
            if(state.activeStep > 0){
                state.activeStep = state.activeStep - 1
            }
            else{
                state.activeStep = 0
            }
        },
        setStep:(state, action)=>{
            // console.log(action.payload)
            state.activeStep = action.payload
        },
        changeInitiaState:(state, action)=>{
            state.initialState = action.payload
        }
    }
})

export const stepperAction = stepperSlice.actions
export default stepperSlice.reducer