import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import Navbar from "../../components/LandingPage/Navbar/Navbar";
import { useLocation } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { useUserData } from "../../components/hooks/useUserData";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { AnimatePresence, motion } from "framer-motion";
import { useResponsiveSize } from "../../components/hooks/useResponsiveSize";
import { useGetMarketPlace } from '../../redux/hooks/getHooks'
import ShopMenu from "./ShopMenu";
import Supermarket from "./Supermarket";

export default function ShopNeeds({ bill_name, handleClick }) {
  const [bill, setBill] = useState("supermarkets");

  const [openBillMenu, setOpenBillMenu] = useState(true);
  

  const [billData, setBillData ] = useState()

  const { data, status } = useUserData();

  const getMarketPlaces = useGetMarketPlace("user");
  const marketPlace = getMarketPlaces?.data?.data?.data?.find(
    (val) => val?.status && (val?.name)?.toLowerCase() === 'bills'
  );


  const responsiveSize = useResponsiveSize({});

  const location = useLocation().pathname;

  useEffect(()=>{
    if(localStorage.billData){
      let localStorageBillData = JSON.parse(localStorage.getItem('billData'))

      setBillData(localStorageBillData)

      setBill(localStorageBillData?.data?.order_name)

 
      
    }
    else{
      setBillData(null)
      setBill('supermarkets')
    }
  }, [])

  useEffect(() => {
    if (bill_name) {
      setBill(bill_name);
    }
  }, [bill_name]);
  


 
  const toggleBillMenu = () => {
    setOpenBillMenu(!openBillMenu);
  };



  useEffect(()=>{
    if(responsiveSize==='sm'){
      setOpenBillMenu(false)
    }
    else{
      setOpenBillMenu(true)
    }
  }, [responsiveSize])
  return (
    <>
      <main
        className="bg-white"
        style={{
          transition: "all 0.5s",
          position: "relative",
        }}
      >
        {location==="/shop-your-needs" && <Navbar />}
        <section className="col-lg-11 mx-auto d-flex gap-3">
          <AnimatePresence>
            {openBillMenu && <ShopMenu setBill={setBill} bill={bill} toggleBillMenu={toggleBillMenu}/>}
          </AnimatePresence>

          <AnimatePresence>
            <motion.div
              className="w-100 d-flex position-relative px-3"
              style={{
                marginLeft: `${openBillMenu ? "18rem" : "0"}`,
              }}
              initial={{ x: -300 }}
              animate={{ x: 0 }}
              exit={{ x: -300 }}
              transition={{ duration: 0.5 }}
            >
              {(responsiveSize === "sm") && (
                <div className="mt-3 position-absolute top-0 start-0">
                  <span className="cursor-pointer" style={{

                  }} onClick={toggleBillMenu}>
                    {openBillMenu ? (
                      <ArrowBackIosIcon sx={{ fontSize: "5vh" }} />
                    ) : (
                      <ArrowForwardIosIcon sx={{ fontSize: "5vh" }} />
                    )}
                  </span>
                </div>
              )}

              {
                (responsiveSize==='sm' && openBillMenu) ? null : (
              <div className="w-100">
                {
                bill === "supermarkets" ? (
                  <Supermarket handleClick={handleClick} />
                )
               
                : (
                  "coming soon"
                )}
              </div>

                )
              }
            </motion.div>
          </AnimatePresence>
        </section>
      </main>
    </>
  );
}
