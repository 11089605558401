/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Checkbox,
  DialogTitle,
  Divider,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { EditIcon } from "../../../icons/icons";
import { Link, useNavigate } from "react-router-dom";
import CustomButton from "../../CustomButton/CustomButton";
import { useUserData } from "../../hooks/useUserData";
import ModalDialogBox from "../../ModalDialogBox/ModalDialogBox";
import ChangeDeliveryDetail from "./ChangeDeliveryDetail";
import ChangeRepaymentOption from "./ChangeRepaymentOption";
import { useDispatch, useSelector } from "react-redux";
import { formatNumber } from "../../../utils/formatNumber";
import { useCreditMarketPlace } from "../../../redux/mutation/postMutation";
import moment from "moment";
import { ROUTES } from "../../../routes/route";
import { useQueryClient } from "@tanstack/react-query"
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { productAction } from "../../../redux/slice/productSlice";
import { IsLoadingLoader } from "../../../utils/Loader";
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import "./checkout.css"
import TransactionPin from "../../Auth/TransactionPin/TransactionPin";
import Mandate from "../Mandate/Mandate";
import { useMandateModal } from "../../../redux/hooks/zustands/useMandateModal";





const config = {
  title: 'Congratulation!!!',
  content: (
    <>
     Your order has been created successfully!!!

    </>
  )
};




function Checkout({ handleClick }) {
  const { data } = useUserData();

  const [isLoading, setIsLoading] = useState(null);
  const [open, setOpen] = useState({ status: null, type: "", payload: "" });
  const [isAgreed, setIsAgreed] = useState(null);
  const [pageLoading, setPageLoading] = useState(true);



  const [openModal, setOpenModal] = useState(false);

  const { totalPrice, allProducts } = useSelector((state) => state.product);

  const [modal, contextHolder] = Modal.useModal();

  const { openModal : openMandateModal } = useMandateModal();


  const navigate = useNavigate({});
  const dispatch = useDispatch({});

  const hasSubscription = data?.subscriptions?.length > 0

  const queryClient = useQueryClient();

  const sessionKey = data?.id + "_product";

  //default number/amount
  const service_charge = allProducts?.marketPlace?.service_fee ?? 0;
  const vat = Number(allProducts?.marketPlace?.tax) ?? 0;

  //calculating repayment date
  const repaymentDate = hasSubscription? moment()
    .add(data?.subscriptions[0]?.plan?.repayment_duration, "days")
    .format("DD MMMM, YYYY"): null;

  const marketPlaceMutation = useCreditMarketPlace();

  const buyProduct = (pin) => {
    const productData = {
      data: {
        marketId: allProducts?.marketPlace?.id,
        deliveryLocation: allProducts?.deliveryLocation,
        products: allProducts?.products,
      }
    };

    if(isAgreed){
        //checking of subscription status
        
        if(data?.subscriptions[0]?.status){
          marketPlaceMutation.mutate({...productData, pin: String(pin)}, {
            onSuccess:()=>{
              dispatch(productAction.setAllProducts({}))
              queryClient.invalidateQueries("user_order");
            }
          })
        }else{
          modal.confirm({
            title: 'Important Notice!!',
            icon: <ExclamationCircleOutlined />,
            content: "You don't have an active subscription plan, you have a pending subscription payment, do you want to proceed to subscription?",
            okText: 'Continue',
            cancelText: 'Cancel',
            onOk:()=>{
              navigate(ROUTES.subscription);
            }
          });
        }
    }
    else{
      handleClick(
        "You must agree to our terms and condition before proceeding!",
        "error",
        { vertical: "top", horizontal: "center" }
      );
    }
    
   
  };

  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  useEffect(() => {
    setTimeout(() => {
      if (allProducts) {
        setPageLoading(false);
      } else {
        setPageLoading(true);
      }
    }, 1000);
  }, []);

  //mutation useEffect
  useEffect(() => {
    if (marketPlaceMutation.isLoading) {
      setIsLoading(true);
    } else if (marketPlaceMutation.isSuccess) {
      setIsLoading(false);
      // console.log(marketPlaceMutation?.data?.data);
      let response = marketPlaceMutation?.data?.data;
      console.log(response)
     


      console.log(response)

      // x
      // handleClick(msg, "success");
      dispatch(productAction.setAllProducts({}))
      sessionStorage.removeItem(sessionKey);

    

      modal.success({
        title: 'Congratulation!!!',
        content: (
          <>
          {response?.message}
           {/* Your order has been created successfully!!! */}
           {/* {
            response?.data?.mandate && (
              <>
              <p>By continue this process, they will be charging you</p>
              </>
            )
           } */}
           
          </>
        ),
        onOk:()=>{
          // if(response?.data?.mandate){
          //   window.location.href = response?.data?.mandate
          // }
          // else{
          // }

          if(response?.data?.mandate){
            console.log(response?.data)
    
            openMandateModal(response?.data?.mandate)
           
          }
          else{
            navigate(ROUTES.shop);
          }
        }
      })

    } else if (marketPlaceMutation.isError) {
      setIsLoading(false);
      let msg = marketPlaceMutation?.error?.response?.data?.message;
      console.log(msg)
      let msg2 = marketPlaceMutation?.error?.response?.statusText;
      let msg3 = marketPlaceMutation?.error?.message;
      // console.log(marketPlaceMutation?.error);
      handleClick(msg ?? msg2 ?? msg3, "error");
    }
  }, [marketPlaceMutation?.status]);



    //modal
    const handleOpenModal = () => {
      if (isAgreed) {
        setOpenModal(true);
      } else {
        handleClick(
          "You must agree to our terms and condition before proceeding!",
          "error",
          { vertical: "top", horizontal: "center" }
        );
      }
    };

  
  const handleCancelModal = () => {
    setOpenModal(false);
  };




  return (
    <>
      <section className="col-lg-11 mx-auto tw-relative">
        <div className="tw-absolute">
          <LoadingButton
            // loading={true}
            loadingPosition="center"
            variant="outlined"
            onClick={() => navigate(ROUTES.cart)}
            className="my-3 mx-3 tw-absolute tw-top-0"
            sx={{
              color: ""
            }}
          >
            <ArrowBackIcon />
          </LoadingButton>
        </div>
        <div className="text-center">
          <DialogTitle>Checkout</DialogTitle>
        </div>
        {pageLoading ? (
          <div>
            {/* loading */}
            <IsLoadingLoader  />
          </div>
        ) : (
          <section className="row mx-2">
            <section className="col-lg-8 col-md-12 order-lg-1 order-2 my-2">
              <div className="card py-2">
                <div className="d-flex justify-content-between flex-wrap px-3 tw-my-3">
                  <p className="my-auto fw-semibold">
                    Delivery details
                  </p>
                  <Button
                    variant="outlined"
                    className="border-secondary text-secondary"
                    onClick={() =>
                      setOpen({
                        ...open,
                        status: true,
                        type: "delivery",
                        payload: allProducts?.deliveryLocation,
                      })
                    }
                    endIcon={<EditIcon />}
                  >
                    Change
                  </Button>
                </div>
                {/* <Divider /> */}
                <hr />
                <div className="delivery_detail_content px-3 py-3">
                  <p className="fw-semibold">
                    {data ? `${data?.first_name} ${data?.last_name}` : "xxxxxx"}
                  </p>
                  <p className="fw-semibold">{data?.phone ?? "xxxxxx"}</p>
                  <p className="fw-semibold">{allProducts?.deliveryLocation ?? "xxxxxx"}</p>
                </div>
                <hr />
                <div className="repayment_section">
                  {/* <div className="d-flex justify-content-between flex-wrap px-3">
                    <p className="my-auto">
                      <b>Repayment Option</b>
                    </p>
                    <Button
                      variant="outlined"
                      className="border-secondary text-secondary"
                      onClick={() =>
                        setOpen({ ...open, status: true, type: "repay" })
                      }
                      endIcon={<EditIcon />}
                    >
                      Change
                    </Button>
                  </div> */}
                  <hr />
                  <div className="repayment_date px-3 py-3">
                    <label
                      htmlFor="repayment_date"
                      style={{ fontWeight: "500" }}
                    >
                      Repayment date
                    </label>
                    <p className="">{repaymentDate ?? "xxxxx"}</p>
                    {/* <p>
                        <code>
                        {
                          allProducts?.repaymentDuration === 1 ? "Pay Now" : allProducts?.repaymentDuration===5?"Pay Five days later":allProducts?.repaymentDuration===10&&"Pay Ten days later"
                        }
                        </code>
                      </p> */}
                    <div>
                      <label htmlFor="notice" className="fw-semibold">
                      Terms and Condition:
                      </label>
                      <div className="my-auto">
                       <ol>
                        <li className="my-2">There will be 0.3% of the total amount charged in this transaction per day for every day of default.</li>
                        <li className="my-2">The return policy of {allProducts?.marketPlace?.name} shall be applicable to this order.</li>
                        <li className="my-2">{allProducts?.marketPlace?.name} Shall be held responsible for return and replacement of items/products due to defect and delivery. (Delivery tracking number will be sent to you)</li>
                        <li className="my-2">The cancellation of this order is not guaranteed</li>
                       </ol>
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
                <div className="text-center tw-mt-3">
                  <Checkbox onChange={(e) => setIsAgreed(e.target.checked)} />
                  <label htmlFor="terms_condition" style={{fontSize: "18px"}}>
                   <small> I hereby agree to the{" "}
                    <Link to="" className="text-decoration-none">
                      Terms and conditions 
                    </Link>{" "}
                    in this checkout</small>
                  </label>
                  <div className="col-lg-4 mx-auto">
                    <CustomButton
                      text={"Buy"}
                      functionFn={handleOpenModal}
                      disabled={!isAgreed}
                      
                      // functionFn={() =>
                      //   setOpen({
                      //     ...open,
                      //     status: true,
                      //     type: "mandate",
                      //     payload: allProducts?.deliveryLocation,
                      //   })
                      // }
                      loading={isLoading}
                    />
                  </div>
                </div>
              </div>
            </section>
            <section className="col-lg-4 col-md-12 order-lg-2 order-1 my-2">
              <div className="card p-3 h-100">
                <div className="order_detail_section">
                  <div className="d-flex justify-content-between flex-wrap px-3 mb-3">
                    <p className="my-auto fw-semibold ">
                      Order details
                    </p>
                    {/* <Button
                      variant="outlined"
                      className="border-secondary text-secondary"
                      endIcon={<EditIcon />}
                    >
                      Change
                    </Button> */}
                  </div>
                  <div className="content mt-3">
                    {/* <div className="text-center">
                      <img
                        src={allProducts?.marketPlace?.logo}
                        alt={allProducts?.marketPlace?.name}
                        className="w-50 mx-auto"
                      />
                    </div> */}
                    {allProducts?.products?.map((product, index) => {
                      return (
                        <div className="row" key={index}>
                          <div className="col-12 my-3">
                            {/* <img src={allProducts?.marketPlace?.logo}
                        alt={allProducts?.marketPlace?.name}
                        className="img-fluid mx-auto" style={{
                          width: "50px",
                          height: "50px"
                        }}/> */}
                        <img
                              src={allProducts?.marketPlace?.logo}
                              alt={product?.name + "_logo"}
                              className="checkout_market_place card-img-top my-2"
                            />
                            {/* <p className="fw-medium">
                              {product?.name.substring(0, 60) + '...' ?? "xxxxx"}
                            </p> */}
                            <Tooltip title={product?.url ?? "....xxxxx"} arrow>
                              <p
                                className="my-auto"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  fontSize: "13px"
                                }}
                              >
                                {product?.url ?? "....xxxxx"}
                              </p>
                            </Tooltip>
                          </div>
                          <div className="col-md-6 my-auto">
                            {/* <Tooltip title={product?.url ?? "....xxxxx"} arrow>
                              <p
                                className="my-auto"
                                style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}
                              >
                                {product?.url ?? "....xxxxx"}
                              </p>
                            </Tooltip> */}
                            <p className="my-auto" style={{
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                }}>
                              {product?.name}
                            </p>
                          </div>
                          <div className="col-md-6 my-auto d-flex justify-content-between flex-wrap">
                            <div>
                              <p className="my-auto">
                                {/* ₦{product?.price} */}
                              </p>
                            </div>
                            <div className="py-2 px-3 rounded-3 border" style={{backgroundColor: "#fff"}}>
                                QTY: {product?.quantity}
                            </div>
                           
                          </div>
                          <Divider
                            color="#FBEBB5"
                            sx={{ width: "95%", margin: "1.5vh auto" }}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
                <div className="price_total_amount mt-3 mb-4">
                  <div className="subtotal d-flex justify-content-between px-3 my-2">
                    <p className="my-auto">
                      Subtotal
                    </p>
                    <p className="my-auto">
                      {formatNumber(totalPrice ?? 0.0)}
                    </p>
                  </div>
                  <div className="tax d-flex justify-content-between gap-3 px-3 my-2">
                    <p className="my-auto">
                    Service charge <small style={{fontSize: "12px"}}>({service_charge}% of the subtotal)</small>
                    </p>
                    <p className="my-auto">{formatNumber(totalPrice * service_charge/100)}</p>
                  </div>
                  <div className="tax d-flex justify-content-between gap-3 px-3 my-2">
                    <p className="my-auto">
                    VAT <small style={{fontSize: "12px"}}>({vat}% of the subtotal)</small>
                    </p>
                    <p className="my-auto">{formatNumber(totalPrice * vat/100)}</p>
                  </div>
                  <hr />
                  <div className="total d-flex justify-content-between flex-wrap px-3 my-2">
                    <p className="my-auto fw-semibold">
                      Total
                    </p>
                    <p className="my-auto">
                      {formatNumber(totalPrice + (totalPrice * service_charge/100) + (totalPrice * vat/100) ?? 0.0)}
                    </p>
                  </div>
                </div>
              </div>
            </section>
          </section>
        )}
      </section>
      <ModalDialogBox
        open={open.status}
        handleClose={handleClose}
        modalSize={false}
      >
        {open.type === "delivery" ? (
          <ChangeDeliveryDetail
            handleClose={handleClose}
            handleClick={handleClick}
            currentLocation={open.payload}
          />
        ) :
          open.type === "repay" && (
            <ChangeRepaymentOption
              repaymentDuration={allProducts?.repaymentDuration}
              handleClose={handleClose}
            />
          )
        }
      </ModalDialogBox>

     
        <TransactionPin
          finalFn={buyProduct}
          openModal={openModal}
          closeModal={handleCancelModal}
        />

      

      {contextHolder}
    </>
  );
}

export default Checkout;
