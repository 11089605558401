import React, { useEffect } from 'react'
import "./shoplayout.css";
import { Outlet } from 'react-router-dom'
function ShopLayout() {


  return (
    <>
        <main className='shop_layout_container'>
            <section className='my-lg-4'>
                <Outlet />
            </section>
        </main>
    </>
  )
}

export default ShopLayout