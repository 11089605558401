export const API_URL = {
    // end points url go dey here

    // auth
    register: "/auth/signup",
    login: "/auth/signin",
    verifyAccount: "/auth/verify_email/",
    recoverPsw: "/auth/recover-password",
    resetPsw: "/auth/reset_password",
    forgetPsw: "/auth/forgot_password",
    refreshToken: "/auth/refresh_token/",
    changePassword: '/auth/change_password',
    setPin: "/user/set_pin",
    //admin auth
    adminLogin: "/auth/login/admin",
    adminRegister: "/auth/create_admin",
    //users
    usersPlan: "/admin/plan/user-profile/",
    users: "/admin/get_user",
    me: "/user/profile",
    update_profile: "/user/update_profile",
    userImage: "/user/upload_image",
    kyc: "/auth/kyc",
    resend_bvn_otp: "/auth/resend_bvn_otp",
    kyc_back_step: "/auth/kyc_back", // when previous is clicked
    mono: "/users/monoId",
    re_connect: "/users/re_connect",
    bankStatement: "/admin/user_statement/",
    userAccountSummary: "/users/summary/",
    approveKyc: "/admin/approve_kyc/",
    declineKyc: "/admin/decline_kyc/",
    updateKycStatus: "/admin/update_kyc_status/",
    setLimit: "/users/setLimit",
    updateEmployment: "/users/updateEmployment",
    updateAddress: "/users/updateAddress",

    upperPlans: "/user/upper_plans",

    upgradePlan: "/user/upgrade_plan/",

    userUpgradeReq: "/user/upgrade_requests",


    adminUpgradeReq: "/admin/plan_upgrade_requests/",


    approve_reject_upgrade: "/admin/plan_upgrade_update/",


    //orders
    user_orders: "/user/get_order/",




    //credit
    allCredit: "/admin/user-credit-request/",
    myCredit: "/credits/my",
    activeCredit: "/credits/active",
    creditMarketPlace: "/user/order",

    approveCredit: "/admin/confirm-credit-request",
    confirmRepayment: "/admin/confirm-repayment/",
    confirmDelivery: "/admin/confirm-delivery/",

    user_credit_estimate: "/admin/user-credit-estimate/",

    cancel_order: "/user/cancel_order/",

    //bvn verification, repayment



    //messages
    contactUs: "/messages/contactUs",
    send_otp: "/auth/resend_otp",
    verify_otp: "/messages/verifyOTP",

    //merchant
    allMerchant: "/merchants",
    merchantProfile: "/merchants/profile",
    merchantOrder: "/merchantOrders/me",
    allMerchantOrder: "/merchantOrders",
    newUsers: "/admin/pending_user",
    onboardMerchant: "/merchants/onboard",
    searchOrder: "/merchantOrders/search",
    //settlement
    getAllSettlement: "/settlements",
    mySettlement: "/settlements/my",

    //subscription
    getSubscriptionPlans_user: "/user/plans",
    getUserSubscriptions: "/user/subscription",
    getSubscriptionPlans_admin: "/admin/plan",
    getSubscriptionType_admin: "/admin/subscriptionTypes/",
    updateSubscriptionType_admin: "/admin/subscriptionType/",
    createPlan: "/admin/plan",
    createSubscriptionType: "/admin/subscriptionType",
    updatePlan: "/admin/plan/",
    subscribe: "/user/subscription",
    cancelSubscription: "/subscriptions/cancel",

    deleteSubscription: "/user/delete_subscription/",

    //market place
    createMarketPlace: "/admin/create_market",
    getAllMarketPlace: "/admin/markets",

    singleMarketPlace: "/admin/market/",


    //transactions
    initiate_payment: "/transaction/initiate_repayment/",
    verify_payment: "/transaction/verify_payment/",
    verify_mandate: "/user/validate_mandate/",
    transaction_history: "/transaction/transactions/",
    order_transaction: "/transaction/order_transactions/",
    singleUserOrder: "/admin/user_orders",

    //bills
    airtime_provider: "/user/airtime_providers",
    electric_provider: "/user/electric_providers",
    cable_provider: "/user/cable_providers",
    cable_addons: "/user/cable_adons",
    cable_product: "/user/cable_products",
    initiate_bill: "/user/initiate_bill",
    initiate_instant_payment: "/user/initiate_instant_payment",
    data_bundle: "/user/data_bundles",
    find_name: "/user/verify_account",

    changePaymentMethod: '/transaction/change_payment_method',

    get_discos: "/user/get_discos",


    //company
    initiate_mandate: "/company/initiate_mandate",
    debit_account: "/company/debit_account",
    create_mandate: "/company/create_mandate",

    get_mandate: "/company/get_mandates",
    get_schedules: "/company/get_schedules/",
    checkPayment: "/company/confirm_payment/",
    debit_account: "/company/debit_account",
    veriyMandate: "/company/verify_mandate/",

    get_user_mandate: "/company/get_mandate/",


    //mandate
    scheduleMandate: "/admin/schedules",
    scheduleTransaction: "/admin/schedule_transactions/",

    debit_account_for_schedule: "/admin/schedule/debit"
}