
import { useNavigate } from "react-router-dom";
import { styled } from "styled-components";
import { ROUTES } from "../../routes/route";


function StartButtons({title, color}) {
  const navigate = useNavigate();

  const startNow = () => {
      navigate(ROUTES.signup)
  }
  return ( 
    <>
      <Button data-aos="fade-right" className="">
        <strong className={`start_now_btn text-decoration-underlin text-${color??''} border-2 border-${color ??'black'} border-bottom border-0`} onClick={startNow}>{title}</strong>
      </Button>
    </>
  );
}

export default StartButtons;

const Button = styled("p")`
strong{
        cursor: pointer;
        padding: 3px;
        font-size: 20px;
        // line-height:43.5px;
    }

    @media only screen and (max-width: 820px){
      strong{
        font-size: 30px;
      }
    }
    @media only screen and (max-width: 768px){
      strong{
        font-size: 23px;
      }
    }
`
