import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { styled } from 'styled-components'

function CustomButton({loading, text, functionFn, disabled, ...rest}) {
  return (
   <>
     <Box sx={{ m: 1, position: 'relative' }}>
        <Button className='btn rounded-pill mt-4 tw-h-[55px]'
        
          disabled={loading || disabled}
          
          type='submit'
          {...rest}
          onClick={functionFn}
          style={loading || disabled ? {cursor: "not-allowed"}:{}}
        >
         {text}
        </Button>
        {loading && (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              top: '45%',
              left: '50%'
            }}
          />
        )}
      </Box>
   </>
  )
}

export default CustomButton

const Button = styled("button")`
    background-color: #033F4B;
    color: #fff;
    font-size: 16px;
    width: 100%;
    &:hover{
      transition: 0.5s;
      border: 1px solid #068DA9;
      color: #3461FF;
    }
`