import { Button, Dropdown, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { formatNumber } from "../../../utils/formatNumber";
import { Input } from "antd";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import NewMandate from "../../../components/Emandates/mandateForm/NewMandate";
import EmandateHistory from "../../../components/Emandates/emandateHistory/EmandateHistory";
import { useFormik } from "formik";
import * as yup from "yup";
import { useGetMandates } from "../../../redux/hooks/getHooks";

export default function EmandateDashboard({handleClick}) {



  const {data: data} = useGetMandates();
  const response = data?.data

  const totalAmount = response?.data?.reduce((acc, curr)=> acc + (parseFloat(curr.balance)), 0)



  return (
    <>
      <div>
        <div className="row mx-2">
          <div className="col-sm-6">
            <div
              className="tw-h-32 tw-flex tw-flex-col tw-justify-between tw-p-5 tw-rounded"
              style={{
                backgroundColor: "#FBFBFB",
              }}
            >
              <div className="tw-flex tw-justify-between">
                <p className="tw-font-[500] tw-text-[14px]">Total Value</p>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: ("All time"
                        ),
                        key: "0",
                      },
                      {
                        label: "Today",
                        key: "1",
                      },
                      {
                        type: "divider",
                      },
                      {
                        label: "Yesterday",
                        key: "3",
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <Space className="tw-cursor-pointer">
                    All time
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </div>
              <p className="tw-font-[700] tw-text-[20px]">{formatNumber(totalAmount ?? 0)}</p>
            </div>
          </div>
          <div className="col-sm-6">
            <div
              className="tw-h-32 tw-flex tw-flex-col tw-justify-between tw-p-5 tw-rounded"
              style={{
                backgroundColor: "#FBFBFB",
              }}
            >
              <div className="tw-flex tw-justify-between">
                <p className="tw-font-[500] tw-text-[14px]">Total Value</p>
                <Dropdown
                  menu={{
                    items: [
                      {
                        label: ("All time"
                        ),
                        key: "0",
                      },
                      {
                        label: "Today",
                        key: "1",
                      },
                      {
                        type: "divider",
                      },
                      {
                        label: "Yesterday",
                        key: "3",
                      },
                    ],
                  }}
                  trigger={["click"]}
                >
                  <Space className="tw-cursor-pointer">
                    All time
                    <DownOutlined />
                  </Space>
                </Dropdown>
              </div>
              <p className="tw-font-[700] tw-text-[20px]">{formatNumber(totalAmount ?? 0)}</p>
            </div>
          </div>
        </div>

      <NewMandate handleClick={handleClick}/>

      <EmandateHistory handleClick={handleClick}/>



      </div>
    </>
  );
}
