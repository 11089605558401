import { Button } from "antd";
import React, { useState, useEffect } from "react";
import {
  Box,
  Chip,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
} from "@mui/material";
// import { useGetCableAddon } from "../../../redux/hooks/getHooks";
import { formatNumber } from "../../../utils/formatNumber";
import { useUserData } from "../../../components/hooks/useUserData";
import { useFindElectricName } from "../../../redux/mutation/postMutation";

export default function CableForm({
  formik,
  setNextAction,
  cable,
  cableProducts,
  handleClick
}) {
  const [activateNextBtn, setActivateNextBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const user = useUserData();


  // const getCableAddon = useGetCableAddon({
  //   service_type: cable?.service_type,
  //   product_code: formik?.values?.subscriptionPlan?.code,
  // });

  const findNameMutation = useFindElectricName();

  // const add_ons = getCableAddon?.data?.data?.data
   
  
  const handleSelectChange = (event) => {
    const selectedValue = event.target.value; // Get the value of the selected option
    console.log(selectedValue)
    const selectedObject = cableProducts.find(
      (option) => option.code === selectedValue
    );
    formik.setFieldValue("subscriptionPlan", selectedObject);
  };


  const handleConfirmFn = () => {
    const payloadData = {
      // service_type: cable?.service_type,
      // account_number: formik?.values?.decoderNumber,

      "meter": formik?.values?.decoderNumber, //"12345678910",
      "vendType":"PREPAID",
      "disco": cable, //"DSTV",
      "vertical":"TV"
    };

    setIsLoading(true);
    findNameMutation?.mutate(payloadData, {
      onSuccess: (data) => {
        setIsLoading(false);
        const res = data?.data?.data;

     
        formik.setFieldValue("distributionCompany", {
          ...formik?.values?.distributionCompany,
          ...res,
        });


        setNextAction("confirm_information");
      },
      onError: (err) => {
        setIsLoading(false);
        const errResponse = err?.response?.data?.message
        formik.setFieldError("decoderNumber", errResponse);
        // handleClick(errResponse, "error")
      },
    });
  };


  const handleNextAction = () => {
    setNextAction("confirm_information");
  };

  useEffect(() => {
    if (
      formik?.values?.email &&
      formik?.values?.subscriptionPlan &&
      formik?.values?.decoderNumber &&
      formik?.values?.subscriptionPlan?.price
    ) {
      setActivateNextBtn(true);
    } else {
      setActivateNextBtn(false);
    }
  }, [formik]);


  const onChangeNumber=(e)=>{
    formik.setFieldValue(e.target.name, e.target.value)

  }




  return (
    <>
      <form
        className="col-lg-8 col-12 mx-auto mt-3"
        onSubmit={formik?.handleSubmit}
      >
        {/* <div className="text-center">
       <h3 className='fw-semibold d-fle gap-2 align-items-center' style={{fontSize: "28px", fontWeight: 500}}>
       <img
               
                src={gotv}
                alt={"_logo"}
               
                style={{
                  width: "100px",
                  maxWidth: "100px",
                }}
              /> {" "} Subscription
       </h3>
       </div> */}

        <div className="Email col-12" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="Email">Email</label>
          </div>
          <TextField
            type="email"
            name="email"
            required
            error={formik?.touched.email && formik?.errors.email}
            onChange={formik?.handleChange}
            onBlur={formik?.handleBlur}
            placeholder="Enter email"
            fullWidth
            value={formik?.values.email}
            variant="outlined"
            size="medium"
            InputProps={{
              style: {
                borderRadius: "30px",
                fontSize: "14px",
              },
            }}
          />
          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.email && formik?.errors.email}
          </small>
        </div>
        <div className="mobileNumber col-12" style={{paddingBottom: "20px"}}>
            <div className="label" style={{marginBottom: "4px"}}>
              <label htmlFor="mobileNumber" >
                Mobile Number
              </label>
            </div>
            <TextField
              type="number"
              name="mobileNumber"
              // required
              error={formik?.touched.mobileNumber && formik?.errors.mobileNumber}
              onChange={onChangeNumber}
              onBlur={formik?.handleBlur}
              value={formik?.values.mobileNumber}
              placeholder="Provide mobile number e.g 08012345678"
              fullWidth
              variant="outlined"
              size="medium"
              InputProps={{
                style: {
                  borderRadius: "30px",
                  fontSize: "14px",
                },
              }}
            />
            <small style={{fontSize: "13px"}} className="text-danger">
                {
                  formik?.touched.mobileNumber && formik?.errors.mobileNumber
                }
              </small>
          </div>

        <div className="decoderNumber col-12" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="decoderNumber">
              {
                (cable)?.toLowerCase() === "gotv"? "Decoder Number (IUC)": (cable)?.toLowerCase()==='dstv'? "DSTV Card Number": "Smart Card Number"
              }
            </label>
          </div>
          <TextField
            type="number"
            name="decoderNumber"
            // required
            error={
              formik?.touched.decoderNumber && formik?.errors.decoderNumber
            }
            onChange={onChangeNumber}
            onBlur={formik?.handleBlur}
            value={formik?.values.decoderNumber}
            placeholder="Enter decoder number"
            fullWidth
            variant="outlined"
            size="medium"
            InputProps={{
              style: {
                borderRadius: "30px",
                fontSize: "14px",
              },
            }}
          />
          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.decoderNumber && formik?.errors.decoderNumber}
          </small>
        </div>

        <div className="bundle" style={{ paddingBottom: "20px" }}>
          <div className="label" style={{ marginBottom: "4px" }}>
            <label htmlFor="bundle">Select Subscription Plan</label>
          </div>
          <Select
            name="subscriptionPlan"
            placeholder="Compact - NGN 12,500"
            value={formik?.values.subscriptionPlan?.code ?? ""}
            error={
              formik?.touched.subscriptionPlan &&
              formik?.errors.subscriptionPlan
            }
            onChange={handleSelectChange}
            onBlur={formik?.handleBlur}
            fullWidth
            variant="outlined"
            sx={{ borderRadius: "30px", 
            
              display: "block",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxLines: 1
             }}
            size="medium"
          >
            {cableProducts?.map((product, index) => {
              return (
                <MenuItem key={index + product?.desc} value={product?.code}>
                  {product?.desc?.length > 40?(product?.desc)?.substring(0, 40)+"...": product?.desc} -  ({formatNumber(product?.price)})
                </MenuItem>
              );
            })}
          </Select>
          <small style={{ fontSize: "13px" }} className="text-danger">
            {formik?.touched.subscriptionPlan &&
              formik?.errors.subscriptionPlan}
          </small>
        </div>
       

        
        <div className="submit_btn pb-2">
            <Button
              size="large"
              shape="round"
              loading={isLoading}
              disabled={!activateNextBtn}
              style={{
                backgroundColor: activateNextBtn ? "#033F4B" : "#f0f0f0", // Change background color based on activation state
          
                color: activateNextBtn ? "white" : "#ccc", // Change text color based on activation state
                width: "100%",
                border: "none", // Remove border
                height: "55px",
              }}
              onClick={handleConfirmFn}
            >
              Continue
            </Button>
          </div>
      </form>

    </>
  );
  }
