import { Radio } from '@mui/material';
import React, {useState} from 'react'
import CustomButton from '../../CustomButton/CustomButton';
import PropTypes from 'prop-types';
import { useUserData } from '../../hooks/useUserData';
import { useDispatch } from 'react-redux';
import { productAction } from '../../../redux/slice/productSlice';

export default function ChangeRepaymentOption({repaymentDuration, handleClose}) {
  const { data } = useUserData();
  const sessionKey = data?._id+"_product"
  const dispatch = useDispatch();
  const [selectedValue, setSelectedValue] = useState(repaymentDuration);

  const handleChange = (id) => {
    setSelectedValue(id);
  };

  const confirmFn=()=>{
    dispatch(productAction.updateSavedProduct({repaymentDuration: selectedValue, sessionKey}));
    handleClose();
  }
//radio input style
  const radioStyle = {
    '&.Mui-checked': {
      color: "#033F4B"
    },
  }
  return (
    <>
        <section>
            <h4 className='text-center'>Change Repayment Option</h4>
            <article>
            <div className="row">
                  {[
                    { id: 1, value: "Pay Now" },
                    { id: 5, value: "Pay five days later" },
                    { id: 10, value: "Pay ten days later" },
                  ].map((item, index) => {
                    return (
                      <>
                        <div className="col-sm-12" key={index}>
                          <Radio
                            checked={selectedValue === item.id}
                            onChange={() => handleChange(item.id)}
                            value={item.id}
                            sx={radioStyle}
                          />
                          <label htmlFor={item.value}>{item.value}</label>
                        </div>
                      </>
                    );
                  })}
                </div>
                <div className="">
                  <CustomButton
                    text={"Confirm"}
                    functionFn={confirmFn}
                  />
                </div>
            </article> 
        </section>
    </>
  )
}

ChangeRepaymentOption.propTypes = {
  repaymentDuration: PropTypes.number.isRequired,
  handleClose: PropTypes.func.isRequired
}
