import { useMemo, useState } from "react";
import HeaderContainer from "../../../components/Admin/Dashboard/HeaderContainer";
import { SearchIcon } from "../../../icons/icons";
import SampleDot from "../../../utils/SampleDot";
import CreditTable from "./CreditTable";
import SettlementTable from "../../Merchant/MerchantDashboardScreen/Settlement/Table/SettlementTable";
import ModalDialogBox from "../../../components/ModalDialogBox/ModalDialogBox";
import SettlementDetail from "../../Merchant/MerchantDashboardScreen/Settlement/SettlementDetail";
import {
  useGetAllCredit,
  useGetAllSettlement,
  useGetAllMerchant,
  useGetUsers,
} from "../../../redux/hooks/getHooks";
import MerchantTable from "../../../components/Admin/Merchant/MerchantTable";
import { IsErrorLoader, IsLoadingLoader } from "../../../utils/Loader";
import NewUsersTable from "../../../components/Admin/Community/NewUsersTable";
import DrawerBox from "../../../utils/Drawer/DrawerBox";
import Options from "../../../components/Admin/Community/Options";
import NewAdmin from "../../../components/Admin/NewAdmin/NewAdmin";
import ActiveMerchantTable from "./ActiveMerchantTable";
import MembershipTable from "../../../components/Admin/Community/MembershipTable";

function DashboardScreen({ handleClick }) {
  const [activeIndex, setActiveIndex] = useState({ index: 0, code: "" });
  const [status, setStatus] = useState("shopper");
  const [open, setOpen] = useState({ status: false, details: {} });
  const [openDrawer, setOpenDrawer] = useState({ status: false, role: "" });
  const [profile, setProfile] = useState({});

  const [todayDate, setTodayDate] = useState(false);

  //usequeries
  const getAllSettlement = ""//useGetAllSettlement();
  const getAllCredit = useGetAllCredit(1, "all");
  const getNewUsers = useGetUsers(false);
  const getAllMerchants = ""//useGetAllMerchant();
  const getUsers = useGetUsers(true);
  //active users
  const activeUsers = getUsers?.data?.data?.data?.filter(user=>user?.subscriptions?.length && user?.subscriptions[0]?.status);

  const newUsers = getNewUsers?.data?.data?.data;
  const allSettlement = getAllSettlement.data?.data?.data?.settlements;
  const credits = getAllCredit?.data?.data?.data;
  // console.log(credits)
  const allCredit = credits?.length ? credits?.filter(
    (item) =>
      item.status === "order in progress" ||
      item.status === "repaid" ||
      item.status === "order delivered" ||
      item.status === "rejected"
  ): [];

  const allMerchant = getAllMerchants.data?.data?.data?.merchants;

  //active merchants
  const activeMerchants = allMerchant?.filter(
    (merchant) => merchant?.isKycApproved === true
  ); //

  //activated users
  // const activeUsers = allUsers?.filter(user=>user?.isKycApproved === true)

  const changeStatus = (status) => {
    setStatus(status);
    changeActiveIndex(0, "newApplicant");
  };
  const changeActiveIndex = (index, code) => {
    setActiveIndex({ ...activeIndex, index: index, code: code });
  };
  const handleOpen = (status, details) => {
    setOpen({ ...open, status: status, details: details });
  };
  const handleClose = () => {
    setOpen({ ...open, status: false });
  };

  const handleOpenDrawer = (role, profileDetail) => {
    setProfile(profileDetail);
    setOpenDrawer({ ...openDrawer, status: true, role: role });
  };
  const handleCloseDrawer = () => {
    setOpenDrawer({ ...openDrawer, status: false });
  };

  const settlementHeadCell = [
    {
      id: "date",
      label: "Date",
    },
    {
      id: "settlementId",
      label: "Settlement ID",
    },
    {
      id: "recipientBank",
      label: "Recipient Bank",
    },
    {
      id: "amount",
      label: "Amount",
    },
    {
      id: "action",
      label: "Action",
    },
  ];
  const settlementHeadRows = [
    {
      _id: "1",
      date: "13:30, June 3, 2023",
      settlementId: "123459w",
      recipientBank: "Peter Pharmacy gtb",
      amount: 50000,
      action: "",
    },
  ];



  return (
    <>
      <main className="col-lg-11 mx-lg-auto mx-3">
        <HeaderContainer
          changeStatus={changeStatus}
          activeIndex={activeIndex}
          changeActiveIndex={changeActiveIndex}
          status={status}
          handleOpenDrawer={handleOpenDrawer}
        />
        <br />
        <section>
          <section className="d-flex justify-content-between flex-wrap gap-2">
            <div className="search_input d-flex rounded-pill px-2 border col-lg-4 col-md-6 col-sm-12 my-1">
              <input
                type="search"
                name="search"
                id="search"
                placeholder="Search"
                className="form-control border-0"
              />
              <span className="my-auto" style={{ cursor: "pointer" }}>
                <SearchIcon />
              </span>
            </div>
            <div>
              <span>Today </span>
              <input
                type="checkbox"
                name=""
                id=""
                onChange={(e) => setTodayDate(e.target.checked)}
              />
            </div>
          </section>
          <br />
          {activeIndex.index === 0 ? (
            ""
          ) : (
            <section className="table_header d-flex justify-content-between flex-wrap gap-2">
              <p className="">New Users / Under review</p>
              <div className="d-flex gap-2 flex-wrap">
                <p>New Application</p>
                <p className="d-flex gap-1 my-aut">
                  <SampleDot color={"#F8D86E"} /> Under review
                </p>
                <p className="d-flex gap-1 my-aut">
                  <SampleDot color={"green"} /> Activated
                </p>
              </div>
            </section>
          )}

          {status === "shopper" ? (
            activeIndex.index === 0 ? (
              getNewUsers.isLoading ? (
                <IsLoadingLoader />
              ) : getNewUsers.isError ? (
                <IsErrorLoader />
              ) : (
                <NewUsersTable
                  topHeaderVisible={true}
                  rows={newUsers}
                  handleOpen={handleOpenDrawer}
                />
              )
            ) : activeIndex.index === 1 ? (
              getUsers.isLoading ? (
                <IsLoadingLoader />
              ) : getUsers.isError ? (
                <IsErrorLoader />
              ) : (
                <MembershipTable
                  topHeaderVisible={true}
                  rows={activeUsers}
                  handleOpen={handleOpenDrawer}
                />
              )
            ) : (
              activeIndex.index === 2 &&
              (getAllCredit.isLoading ? (
                <IsLoadingLoader />
              ) : getAllCredit.isError ? (
                <IsErrorLoader />
              ) : allCredit?.length ? (
                <CreditTable topHeaderVisible={true} rows={allCredit} />
              ) : (
                <p className={`card-tex text-center my-2 text-muted fs-5`}>
                  <i>No data recorded here!!</i>
                </p>
              ))
            )
          ) : (
            status === "merchant" &&
            (activeIndex.index === 0 ? (
              getAllMerchants.isLoading ? (
                <IsLoadingLoader />
              ) : getAllMerchants.isError ? (
                <IsErrorLoader desc={getAllMerchants?.error?.response?.data?.message ?? getAllMerchants?.error?.message}/>
              ) : (
                <MerchantTable topHeaderVisible={true} rows={allMerchant} />
              )
            ) : activeIndex.index === 1 ? (
              getAllMerchants.isLoading ? (
                <IsLoadingLoader />
              ) : getAllMerchants.isError ? (
                <IsErrorLoader desc={getAllMerchants?.error?.response?.data?.message ?? getAllMerchants?.error?.message}/>
              ) : (
                <ActiveMerchantTable
                  topHeaderVisible={true}
                  rows={activeMerchants}
                />
              )
            ) : activeIndex.index === 2 ? (
              getAllSettlement.isLoading ? (
                <IsLoadingLoader />
              ) : getAllSettlement.isError ? (
                <IsErrorLoader desc={getAllSettlement?.error?.response?.data?.message ?? getAllSettlement?.error?.message}/>
              ) : (
                <SettlementTable
                  topHeaderVisible={true}
                  rows={allSettlement}
                  headCell={settlementHeadCell}
                  handleOpen={handleOpen}
                />
              )
            ) : (
              activeIndex.index === 3 &&
              (getAllCredit.isLoading ? (
                <IsLoadingLoader />
              ) : getAllCredit.isError ? (
                <IsErrorLoader desc={getAllCredit?.error?.response?.data?.message ?? getAllCredit?.error?.message}/>
              ) : (
                <CreditTable topHeaderVisible={true} rows={allCredit} />
              ))
            ))
          )}
        </section>
      </main>

      <ModalDialogBox
        open={open.status}
        handleClose={handleClose}
        modalSize={true}
        maxWidth="md"
      >
        <SettlementDetail />
      </ModalDialogBox>

      <DrawerBox open={openDrawer.status} handleClose={handleCloseDrawer}>
        {openDrawer.role === "user" ? (
          <Options
            profileData={profile}
            role={"user"}
            handleClick={handleClick}
            handleCloseOptionModal={handleCloseDrawer}
          />
        ) : (
          <NewAdmin handleClick={handleClick} handleClose={handleCloseDrawer} />
        )}
      </DrawerBox>
    </>
  );
}

export default DashboardScreen;
