import { Box, CircularProgress } from '@mui/material'
import React from 'react'
import { styled } from 'styled-components'

function AuthButton({loading, text}) {
  return (
   <>
     <Box sx={{ m: 1, position: 'relative' }} >
        <Button className='btn rounded-pill mt-3'
          disabled={loading}
          type='submit'
          style={loading ? {cursor: "not-allowed"} : {}}
        >
         {text}
        </Button>
        {loading && (
          <CircularProgress
            size={26}
            sx={{
              position: 'absolute',
              top: '45%',
              left: '50%',
            }}
          />
        )}
      </Box>
   </>
  )
}

export default AuthButton

const Button = styled("button")`
    background-color: #033F4B;
    color: #fff;
    font-size: 16px;
    width: 100%;
    height:56px;
    &:hover{
      transition: 0.5s;
      border: 1px solid #068DA9;
      color: #3461FF;
    }
`